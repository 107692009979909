import React from "react";
import {connect} from "react-redux";
import TrMainTableSearch from "./TrMainTableSearch";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {
    selectActiveRowSearch
} from "../../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../../store/search/actionCreators/search_RootStateActionCreator";
import {
    collectDataForAdditionalSectionsSearch_Normal,
    createPreviewBySelectDocumentSearch,
    resetDocumentPreviewSearch
} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import {ActionQueue} from "../../../store/rootReducer";



class TbodySearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
        this.setRef = this.setRef.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
    }

    setRef = (lRef) => {

        //проверка от зацикливания
        if (lRef)
            if (lRef.current)
                lRef.current.focus();
    }
    selectDocument(event) {
        event.persist();

        let nextActiveRow= null;
        if (!this.props.body) return
        for (let i = 0; i < this.props.body.length; i++) {
            if(this.props.body[i].info.Id == this.props.activeRow?.info.Id){
                if(event.keyCode == 40) nextActiveRow = this.props.body[i+1]
                else if(event.keyCode == 38) nextActiveRow = this.props.body[i-1]
            }
        }
        if(nextActiveRow){
            this.props.ActionQueue({
                List : [
                    {action : this.props.selectActiveRowSearch, params : {RowInfo : nextActiveRow}, name : "selectActiveRow"},
                    {action : this.props.resetDocumentPreviewSearch, params : {}, name : "resetDocumentPreview"},
                    {action : this.props.createPreviewBySelectDocumentSearch, params : {}, name : "createPreviewBySelectDocument"},
                    {action : this.props.collectDataForAdditionalSectionsSearch_Normal, params : {}, name : "collectDataForAdditionalSections_Normal"},
                ],
                debug : false,
            });
            const viewMode = this.props.tabs.find(tab => tab.Id === this.props.activeTab.Id)?.viewMode;
            if(viewMode === RootComponentsStateViewModeTypes.SearchApp.MainTableOnly) {
                this.props.changeDocumentViewMode({ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal});
            }
        }
    }
    render() {
        const {
            searchResults,
            activeTab
        } = this.props;
        const searchResultsIndex = searchResults?.findIndex(item => item.Id === activeTab.Id);
        const body = searchResults[searchResultsIndex]?.mainTable?.body;

        if(!body || body.length <= 0) {
            return null;
        }

        return (
            <tbody onKeyDown={(event) => {
                this.selectDocument(event)
            }}>
            {
                body.map((item, index) => {
                    return (
                        <TrMainTableSearch key={createUniqueIdString(6)} data={item}
                                           lIndex = {index}
                                           // Получаем от дочки только сам реф
                                           setRef={(Item) => {
                                               this.setRef(Item)
                                           }}/>
                    );
                })
            }
            </tbody>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tabs: state.search.rootState.tabs,
        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    collectDataForAdditionalSectionsSearch_Normal,
    createPreviewBySelectDocumentSearch,
    resetDocumentPreviewSearch,
    selectActiveRowSearch,
}

export default connect(mapStateToProps,mapDispatchToProps)(TbodySearch);
