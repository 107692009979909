import React from "react";
import {connect} from "react-redux";
import {
    setTreeSearchQuery,
    treeSearchPackage
} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {changePackagesViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";



class TreePackageSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            TreeSearch : "", // текст для поиска
            clearHover : false,
            debounceAnchor: null
        };

        this.SearchSubmit = this.SearchSubmit.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.clearInput = this.clearInput.bind(this);
    }


    // componentWillUnmount() {
    //     this.clearInput()
    // }

    SearchSubmit(event) {
        event.preventDefault();
    }

    inputOnChange(event) {

        this.props.setTreeSearchQuery({Text: event.target.value})
        if (this.state.debounceAnchor) clearTimeout(this.state.debounceAnchor)

        this.setState({debounceAnchor:
            setTimeout(() => {
                this.startSearch(this.props.treeSearch)
            }, 800)
        })

    }

    startSearch(SearchText) {
        if(this.props?.searchText) {
            this.props.searchText(SearchText);
        }
        if(this.props.tableRole==="doc"){
            if(SearchText.length > 1) {
                this.props.treeSearchPackage({
                    Input : SearchText,
                    TableType :this.props.tableRole,
                    IsSearch: true

                    // Next : {
                    //     action : () => {
                    //         this.props.changePackagesViewMode({
                    //             ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree_Search
                    //         })
                    //     },
                    //     params : {}
                    // }
                });
            }
            // else{
            //     console.log("пустой поиск1");
            //     this.props.changePackagesViewMode({
            //         ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree
            //     })
            // }
        } else {
            if(SearchText.length > 1) {
                this.props.treeSearchPackage({
                    Input : SearchText,
                    TableType :this.props.tableRole,

                    Next : {
                        action : () => {
                            this.props.changePackagesViewMode({
                                ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree_Search
                            })
                        },
                        params : {}
                    }
                });
            }
            // else{
            //     console.log("пустой поиск2");
            //     this.props.changePackagesViewMode({
            //         ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree
            //     })
            // }
        }



    }

    // сбрасываем поле ввода
    clearInput() {
        this.startSearch("");
        this.props.setTreeSearchQuery("")
        this.props.changePackagesViewMode({
            ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree
        })
    }

    render() {
            // для отображения красного крестика
        let searchIcon = this.state.clearHover
            ?  "icon-Delete icon-color-red"
            : "icon-Search ";



        return(
            <div className={"tree-pack-search"} style={{marginBottom: "1rem"}}>
                <div className="input-icon h-40px">
                    <form onSubmit={this.SearchSubmit}>
                        <input type="text"
                               className="form-control form-control-lg form-control-solid h-40px"
                               placeholder="Фильтр"
                               value={this.props.treeSearch}
                               onChange={this.inputOnChange}
                               onKeyDown={(event)=>{
                                   if(event.code  === "Backspace" && this.props.treeSearch.length <= 2) {
                                       this.clearInput()
                                   }
                               }}
                        />
                    </form>
                    <span>
                        <span className="svg-icon svg-icon-lg" style={{cursor : "pointer"}}
                              onMouseEnter={()=>{this.setState({clearHover : true})}}
                              onMouseLeave={()=>{this.setState({clearHover : false})}}
                              onClick={this.clearInput}>
                            <i className={"svg-icon svg-icon-lg " + searchIcon}/>
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        treeSearch: state.packages.treeSearchQuery
    }
}

const  mapDispatchToProps = {
    treeSearchPackage,
    changePackagesViewMode,
    setTreeSearchQuery
}


export default connect(mapStateToProps, mapDispatchToProps)(TreePackageSearch);
