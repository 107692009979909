import React from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay"
import PopoverTitle from "react-bootstrap/PopoverTitle";
import PopoverContent from "react-bootstrap/PopoverContent";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";

export class MyTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delayEnd : false
        };

        this.timer = null;

        this.moreInfoTooltip = this.moreInfoTooltip.bind(this);
    }

    componentDidMount() {

        if(this.props.delay) {
            this.timer = setTimeout(() =>{
                this.setState({
                    delayEnd : true
                }) ;
            },this.props.delay);
        } else {
            this.setState({
                delayEnd : true
            }) ;
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.timer = null;
    }


    moreInfoTooltip() {
        return (
            <Overlay target={this.props.target.current}
                     show={this.props.show}
                     placement={this.props.placement}>
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <Popover id={`popover-positioned`} arrowProps={arrowProps} content {...props} placement={placement}>
                        <PopoverTitle as="h3">{parse(sanitizeHtml(this.props.title))}</PopoverTitle>
                        <PopoverContent>
                            {parse(sanitizeHtml(this.props.text))}
                        </PopoverContent>
                    </Popover>
                )}
            </Overlay>
        );
    }

    render() {
        if(this.state.delayEnd) {
            if(this.props.moreInfo) {
                return this.moreInfoTooltip();
            }
            else {
                return (
                    <Overlay target={this.props.target.current}
                             show={this.props.show}
                             placement={this.props.placement}>
                        {({ placement, arrowProps, show: _show, popper, ...props }) => (
                            <Popover id={`popover-positioned`} arrowProps={arrowProps} content {...props} placement={placement}>
                                {parse(this.props.text)}
                            </Popover>
                        )}
                    </Overlay>
                );
            }
        } else {
            return  null;
        }

    }
}