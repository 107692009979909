import {TreeTypes} from "../../TreeCombine";

// папка в дереве пакетов
export class PackagesFolder {

    // number
    Id;
    // string
    Name;
    // number
    Type;
    // boolean
    Closed;
    // boolean
    ContainsChildren;
    // string (преобразовать в DateTime)
    CreateDate;
    // string (преобразовать в DateTime)
    ModifyDate;

    // number
    IdFilial;
    // number
    IdParent;

    // class PackagesFolder или PackagesDocument
  //  Children = [];
    // obj TreeTypes
    TreeType = TreeTypes.FOLDER;


    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.Name = DataFromServer.Name;
        this.Type = DataFromServer.Type;
        this.Closed = DataFromServer.Closed;
        this.ContainsDocs = DataFromServer.ContainsDocs;
        this.ContainsChildren = DataFromServer.ContainsChilds;
        this.CreateDate = DataFromServer.CreateDate;
        this.ModifyDate = DataFromServer.ModifyDate;
        this.IdFilial = DataFromServer.IdFilial;
        this.IdParent = DataFromServer.IdParent;
    }

    // setChildren(array) {
    //     this.Children = __.deepCopy(array);
    //     return this;
    // }


}