// генерация дерева из полученных данных
import {__} from "../../../tools/HelpFunctions";
import {store} from "../../../index";
import {FILIAL_TREE_ACTIONS} from "../filialActionsList";

export const toggleFilialFolders = ({IdFolder, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let openedFolders;
            openedFolders = __.deepCopy(store.getState().filial.tree.openedFolders);

            let openedFoldersSet = new Set(openedFolders);

            if(openedFoldersSet.has(IdFolder)) openedFoldersSet.delete(IdFolder)
            else openedFoldersSet.add(IdFolder);


            dispatch({type : FILIAL_TREE_ACTIONS.TOGGLE_FOLDERS, payload : {
                    openedFolders : Array.from(openedFoldersSet),
                }});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения открыт/закрыт папок дерево пакетов (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const treeSearchFilial = ({SearchText,IsSearch,Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let allFilial=store.getState().globalState.filial.All;
            let foundFilial=[];

            for(let filial of allFilial){
                if(filial.Name.toLowerCase().indexOf(SearchText.toLowerCase())!==-1){
                    foundFilial.push(filial)
                }
            }

            dispatch({type : FILIAL_TREE_ACTIONS.FOUND_FILIAL, payload : {
                    foundFilial:foundFilial,
                    IsSearch:IsSearch
                }});


                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}