//действия связанные с параметрами поиска
export const SEARCH_INPUTS_ACTIONS = {
    //получение документов
    GET_DOC : "SEARCH | INPUTS | GET_DOC",
    //сохранение параметров поиска
    UPDATE_INPUTS_FIELD : "SEARCH | INPUTS | UPDATE_INPUTS_FIELD",
};

export const SEARCH_TABLE_ACTIONS = {
    //получение документов
    COMBINE_ATTRIBUTE : "SEARCH | TABLE | COMBINE_ATTRIBUTE",
    //запись выбранной строки
    SELECT_ACTIVE_ROW : "SEARCH | TABLE | SELECT_ACTIVE_ROW",
    // установить номер страницы пагинации в таблице
    SET_PAGINATION_PAGE_NUMBER : "SEARCH | TABLE | SET_PAGINATION_PAGE_NUMBER",
    //устоновить дефолтную ширину столбцов
    SET_DEFAULT_WIDTH_OF_COLUMN : "SEARCH | TABLE | SET_DEFAULT_WIDTH_OF_COLUMN",
    // установить кол-во элементов отображаемых в таблице
    SET_PAGINATION_ON_PAGE_SIZE : "SEARCH | TABLE | SET_PAGINATION_ON_PAGE_SIZE",
    // сохраняем документ (строку в таблице) как отмеченную
    ADD_ROW_TO_CHECKED : "SEARCH | TABLE | ADD_ROW_TO_CHECKED",
    // удаляем документ (строку в таблице) из отмеченных
    DELETE_ROW_FROM_CHECKED : "SEARCH | TABLE | DELETE_ROW_FROM_CHECKED",
    // добавляем все документы (строки в таблице) к отмеченным
    CHECK_ALL_ROWS_ON_PAGE : "SEARCH | MAIN_TABLE | CHECK_ALL_ROWS_ON_PAGE",
    // активна ли кнопка вписать
    IS_FIT_IN_WIDTH : "SEARCH | MAIN_TABLE | IS_FIT_IN_WIDTH",
};


export const SEARCH_PREVIEW_ACTIONS = {
    // сброс предпросмотра
    RESET : "SEARCH | PREVIEW | RESET",
    // сбор и сохранение данных для нового выбранного документа
    CREATE_BY_NEW_OPEN_DOC : "SEARCH | PREVIEW | CREATE_BY_NEW_OPEN_DOC",
    // сбор данных для отображения в дополнительных разделах
    COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL : "SEARCH | ADDITIONAL_SECTIONS | COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL",
    // обновить данные по подразделениям
    UPDATE_DATA_FOR_DEPARTMENTS_SECTION : "SEARCH | ADDITIONAL_SECTIONS | UPDATE_DATA_FOR_DEPARTMENTS_SECTION",
    // выбор активного (показанного) файла для предпросмотра
    SELECT_ACTIVE_FILE : "SEARCH | PREVIEW | SELECT_ACTIVE_FILE",
    // выбрать активный файл предпросмотра в карточке документа
    SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS : "SEARCH | CARDS | SELECT_ACTIVE_PREVIEW_FILE",
    // выбрать активную для просмотра карточку документа
    SELECT_ACTIVE_DOC_CARDS : "SEARCH | CARDS | SELECT_ACTIVE_DOC_CARDS",
    // открытие или закрытие дополнительных разделов
    TOGGLE_ADDITIONAL_SECTIONS_OPEN : "SEARCH | ADDITIONAL_SECTIONS | TOGGLE_ADDITIONAL_SECTIONS_OPEN",
    // создание новой карточки документов
    CREATE_DOC_CARDS : "SEARCH | CARDS | CREATE_DOC_CARDS",
    TYPE_File : "DOCUMENT | PREVIEW | TYPE_File",
    SELECT_VIEW_FOR_ACTIVE_FILE: "SEARCH | PREVIEW | SELECT_VIEW_FOR_ACTIVE_FILE",
    SET_FILE_CONTENT: "SEARCH | PREVIEW | SET_FILE_CONTENT",
    // обновление подписи
    UPDATE_SIGNATURE : "SEARCH | PREVIEW | UPDATE_SIGNATURE",
    // Список файлов для просмотра подписей
    SET_ROW_FILE_FOR_SHOW_SIGNATURE : "SEARCH | PREVIEW | SET_ROW_FILE_FOR_SHOW_SIGNATURE",
};

// временные данные во избежании повторных запросов к серверу
export const SEARCH_TEMPORARY_DATA =  {
    // сохранить произвольные данные
    SAVE : "SEARCH | TEMPORARY_DATA | SAVE",
    // стереть все данные
    CLEAR : "SEARCH | TEMPORARY_DATA | CLEAR",
}

// глобальное состояние для компонента DocumentApp
export const SEARCH_ROOT_STATE_ACTIONS = {
    // изменить режим отображения
    CHANGE_VIEW_MODE : "SEARCH | ROOT_STATE | CHANGE_VIEW_MODE",
    TOGGLE_SHOW_ASIDE_MENU: "SEARCH | ROOT_STATE | TOGGLE_SHOW_ASIDE_MENU",
    ADD_TAB: "SEARCH | ROOT_STATE | ADD_TAB",
    CLOSE_TAB: "SEARCH | ROOT_STATE | CLOSE_TAB",
    ADD_ACTIVE_TAB: "SEARCH | ROOT_STATE | ADD_ACTIVE_TAB",

};
