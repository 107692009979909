import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import { ResizableBox  } from 'react-resizable';


class ThMainTableSearch extends React.Component  {
    constructor(props) {
        super(props);

        this.state = {
            defaultWidth : (this.props.data.Value.length * 13) + 70,
            //TODO aa - исправлен баг кнопки вписать
            width :  this.props.data.Size ? this.props.data.Size : (this.props.data.Value.length * 13) + 70,
            // width : (this.props.data.Value.length * 13) + 70
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.props.setWidth(0);
    }

    onResize = (event, {element, size, handle}) => {
        this.setState({width: size.width});
        this.props.setWidth(size.width);
    };

    render() {

        let width = {
            width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
            position : "relative",
            paddingLeft: "0px",
        }
        if(this.props.isWrap) {
            width = {
                width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
                position : "relative",
                paddingLeft: "0px",

                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace : "nowrap",
            }
        }

        let styleSpan ={
            marginRight:"20px",
        }
        let styleI ={
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }

        if(this.props.data.Name !== "Version.Version" && this.props.data.UserVisible) {
            return (
                    <th className="i2color"
                        style={width}
                        title={this.props.data.Value}>
                        <ResizableBox  style={{display: "inline-block"}}
                                       width={this.state.width}
                                       height={0}
                                       onResize={this.onResize}
                                       minConstraints={[this.state.defaultWidth / 2.5, 0]}
                                       maxConstraints={[this.state.defaultWidth * 3, 0]}
                                       axis={'x'}
                                       handle={(h, ref) => <span style={{cursor : "col-resize", position : "absolute", right : 0, width : "24px", background : "#fff", height : "24px"}} ref={ref}><i className={"svg-icon icon-Doc_view_2"}/></span>}
                        >
                            <span style={styleSpan}>
                              <span style={styleI}>  <i className="svg-icon icon-color-original svg-icon-sm icon-Filter fsic"> </i></span>&nbsp;&nbsp;
                                {this.props.data.Value}
                            </span>
                        </ResizableBox >
                    </th>

            );
        } else return null;
    }
}


const  mapStateToProps = state => {
    return {
        isWrap:state.globalState.app.isWrap,
    }
}

const  mapDispatchToProps = {
}

export default connect(mapStateToProps,mapDispatchToProps)(ThMainTableSearch);
