// @ts-ignore
import {__} from "./HelpFunctions.ts";

export interface IPropertyFilters {
    PropertyName : string,
    Operator? : number | string,
    FilterDataAsStringList? : string[]
}

export enum FiltersType {
    SIMPLE = "SIMPLE",
    ENTITY = "ENTITY"
}

export class EntityFilters {

    _EntityName : string | null;
    _PropertyFilters : IPropertyFilters[];
    [key : string] : any;

    constructor() {
        this._EntityName = null;
        this._PropertyFilters = [];
    }

    // установить имя фильтра
    setEntityName(name : string) : EntityFilters {
        this._EntityName = name;
        return this;
    }

    // добавить Property
    // add(params : {Name : string, Value : any , Operator? : number | string}) : EntityFilters {
    //     if(params.Operator) {
    //         this._PropertyFilters.push({
    //             PropertyName : params.Name,
    //             Operator : params.Operator,
    //             FilterDataAsStringList : __.isString(params.Value)
    //                 ? [params.Value]
    //                 : [params.Value.toString()],
    //         });
    //     } else {
    //         this._PropertyFilters.push({
    //             PropertyName : params.Name,
    //             FilterDataAsStringList : __.isString(params.Value)
    //                 ? [params.Value]
    //                 : [params.Value.toString()],
    //         });
    //     }
    //     return this;
    // }

    // добавить Property
    add(params : {Name : string, Value? : any , Operator? : number | string}) : EntityFilters {
        if(params.Operator !== undefined) {
            if(params.Value) {
                if(Array.isArray(params.Value)) {
                    let stringArray = [];
                    for (let i = 0; i < params.Value.length; i++) {
                        if(__.isString(params.Value[i])) {
                            stringArray.push(params.Value[i]);
                        }
                        else {
                            stringArray.push(params.Value[i].toString());
                        }
                    }

                    this._PropertyFilters.push({
                        PropertyName : params.Name,
                        Operator: params.Operator,
                        FilterDataAsStringList : stringArray
                    });
                }
                else {
                    this._PropertyFilters.push({
                        PropertyName : params.Name,
                        Operator: params.Operator,
                        FilterDataAsStringList : __.isString(params.Value)
                            ? [params.Value]
                            : [params.Value.toString()],
                    });
                }
            }
            else {
                this._PropertyFilters.push({
                    PropertyName : params.Name,
                    Operator : params.Operator,
                });
            }

        } else {
            if(params.Value) {
                if(Array.isArray(params.Value)) {
                    let stringArray = [];
                    for (let i = 0; i < params.Value.length; i++) {
                        if(__.isString(params.Value[i])) {
                            stringArray.push(params.Value[i]);
                        }
                        else {
                            stringArray.push(params.Value[i].toString());
                        }
                    }

                    this._PropertyFilters.push({
                        PropertyName : params.Name,
                        FilterDataAsStringList : stringArray
                    });
                }
                else {
                    this._PropertyFilters.push({
                        PropertyName : params.Name,
                        FilterDataAsStringList : __.isString(params.Value)
                            ? [params.Value]
                            : [params.Value.toString()],
                    });
                }
            }
            else {
                this._PropertyFilters.push({
                    PropertyName : params.Name,
                });
            }
        }
        return this;
    }

    // добавить атрибут со значением, кроме EntityName
    addAttribute(params : {Name : string, Value : any}) : EntityFilters {
        if(params.Name !== "EntityName") {
            this[params.Name] = params.Value;
        }
        return this;
    }

    // получить собранный EntityFilters
    get() : object {
        let result = {
            EntityName : this._EntityName,
            PropertyFilters : this._PropertyFilters
        };
        for(let key in this) {
            if(key !== "_EntityName" && key !== "_PropertyFilters") {
                // @ts-ignore
                result[key] = this[key];
            }
        }
        return result;
    }
}

export class Filter {

    _filter : any;
    _type : FiltersType;

    constructor(type : FiltersType) {
        this._filter = type === FiltersType.SIMPLE
            ? {}
            : { EntityFilters : [] };
        this._type = type;
    }

    add(filter : {Name : string, Value : any} | EntityFilters) : Filter {
        if(this._type === FiltersType.SIMPLE) {
            this.addAttribute({Name : filter.Name, Value : filter.Value});
        }
        else if(this._type === FiltersType.ENTITY) {
            if (filter instanceof EntityFilters) {
                this._filter.EntityFilters.push(filter.get());
            } else {
                this._filter.EntityFilters.push(filter);
            }
        }
        return this;
    }

    addAttribute(params : {Name : string, Value : any}) : Filter {
        if(params.Name !== "EntityFilters") {
            this._filter[params.Name] = params.Value;
        }
        return this;
    }

    get() : object {
        return this._filter;
    }
}

export class Sorting {

    _sorting : any;

    constructor() {
        this._sorting = {
            PropertySortings : []
        };
    }

    add(sorting : {PropertyName : string, Direction : number}) : Sorting {
        this._sorting.PropertySortings.push(sorting);
        return this;
    }

    addAttribute(params : {Name : string, Value : any}) : Sorting {
        if(params.Name !== "PropertySortings") {
            this._sorting[params.Name] = params.Value;
        }
        return this;
    }

    get() : object {
        return this._sorting;
    }
}
