

export class ScreenResolution {
    constructor() {
        // разрешение монитора
        this.screenWidth = window.screen.width;
        this.screenHeight = window.screen.height;

        // доступный размер экрана
        this.availableScreenWidth = window.screen.availWidth;
        this.availableScreenHeight = window.screen.availHeight;

        this.updateBrowserSize();
        this._aspectRatio();
    }

    _gcd(a,b) { // наибольший общий делитель
        if (b === 0) {
            return a;
        }
        return this._gcd (b, a % b);
    }

    getAspectRatio () {
        return this.aspectRatio;
    }

    _aspectRatio() {

        let gcd = this._gcd(this.screenWidth, this.screenHeight);

        this.aspectRatio = {
            string : this.screenWidth / gcd + ":" + this.screenHeight / gcd,
            number : this.screenWidth / this.screenHeight
        };
    }

    updateBrowserSize() {
        // размер окна в котором помешается страница
        this.windowInnerWidth = document.documentElement.clientWidth;
        this.windowInnerHeight = document.documentElement.clientHeight;

        // вроде бы аналогичные тому что выше, пока хз какие использовать
        // window.innerWidth
        // window.innerHeight;


        // Размер внешнего окна
        // размер окна бразера включая все панели
        this.windowOuterWidth = window.outerWidth;
        this.windowOuterHeight = window.outerHeight;

        // размеры отображаемого содержимого (отрендеренного контента)
        this.pageWidth = document.documentElement.scrollWidth;
        this.pageHeight = document.documentElement.scrollHeight;
    }



}