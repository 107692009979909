import {DOCUMENT_ROOT_STATE_ACTIONS} from "../documentActionsList";
import {store} from "../../../index";

// изменяем отображение
export const changeDocumentViewMode = ({ViewMode,isSearch = false,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(isSearch) {
                dispatch({type: DOCUMENT_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE_SEARCH, payload: ViewMode});
                return
            }

            await dispatch({type: DOCUMENT_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE, payload: ViewMode});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// изменяем отображение
export const docsToggleShowAsideMenu = ({isOpen = null}) => {
    return async dispatch => {
        try {
            if(isOpen === null) {
                isOpen = store.getState().document.rootState.isOpenAsideMenu;
            }

            await dispatch({type: DOCUMENT_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU, payload: !isOpen});
        } catch (exception) {
            console.log("Ошибка изменения видимости меню (catch): ", exception);
        }
    }
}
