import React from "react";
import {connect} from "react-redux";
import TableSettingModal from "./TableSettingModal";
import DownloadDocModal from "./DownloadDocModal";
import {ModalTypes} from "../../../tools/StaticTypes";
import ChangeDocumentTypeModal from "./ChangeDocumentTypeModal";
import AlertModal from "./AlertModal";
import ChangeAttributes from "./ChangeAttributes";
import InfoModal from "./InfoModal";
import CrudPackagesModal from "./CrudPackagesModal";
import DeleteDocumentModal from "./DeleteDocumentModal";
import ChangeDocumentTypeModalOriginal from "./ChangeDocumentTypeModalOriginal";
import NewDocumentByVersion from "./NewDocumentByVersion";
import ChangeDocumentSections from "./ChangeDocumentSections";
import TransferPackageModal from "./TransferPackageModal";
import ChangeDepartments from "./ChangeDepartments";
import SignaturesInfoModal from "../../additionalDataSection/SignatureInfoModal";
import ChangeVersionDocInPackage from "./ChangeVersionDocInPackage";


class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }


    }
    render() {
        let {modal} = this.props;

        switch (modal.name) {
            case ModalTypes.documents.mainTable.Settings:
                return (<TableSettingModal />);
            case ModalTypes.documents.additionalSection.DownloadDoc:
                return(<DownloadDocModal />);
            case ModalTypes.documents.mainTable.ChangeDocumentType:
                return(<ChangeDocumentTypeModal />);
            case ModalTypes.documents.mainTable.ChangeDocumentTypeOriginal:
                return(<ChangeDocumentTypeModalOriginal />);
            case ModalTypes.documents.mainTable.NewDocumentByVersion:
                return(<NewDocumentByVersion />);
            case ModalTypes.documents.additionalSection.ChangeDocAttributes:
                return(<ChangeAttributes />);
            case ModalTypes.app.alert:
                return(<AlertModal />);
            case ModalTypes.app.info:
                return(<InfoModal />);
            case ModalTypes.app.signaturesInfo:
                return(<SignaturesInfoModal />);
            case ModalTypes.packages.crud:
                return(<CrudPackagesModal />);
            case ModalTypes.documents.mainTable.DeleteDocument:
                return(<DeleteDocumentModal />);
            case ModalTypes.documents.mainTable.ChangeDocumentSections:
                return(<ChangeDocumentSections />);
            case ModalTypes.documents.mainTable.ChangeDepartments:
                return(<ChangeDepartments data={modal.data}/>);
            case ModalTypes.packages.transfer:
                return (<TransferPackageModal />);
            case ModalTypes.packages.ChangeVersionDocInPackage:
                return (<ChangeVersionDocInPackage />);
            default: return  null;
        }

    }
}


const  mapStateToProps = state => {
    return {
        modal : state.globalState.app.modal
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
