import React from "react";
import stylePagination from "../../../media/styles/pagination.module.css";

import {connect} from "react-redux";

import Pagination from "../pagination/Pagination";


import TheadSearch from "./TheadSearch";
import TbodySearch from "./TbodySearch";
import MainTableMenuSearch from "./MainTableMenuSearch";
import {
    combineMainTableAttributesSearch,
    setDefaultWidthOfColumn,
    setPaginationPageNumberSearch
} from "../../../store/search/actionCreators/search_table";
import {ActionQueue} from "../../../store/rootReducer";
import {searchByParams} from "../../../store/search/actionCreators/search_Inputs";
import PaginationCounterSearch from "./PaginationCounterSearch";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {changeSearchViewMode} from "../../../store/search/actionCreators/search_RootStateActionCreator";
import {__} from "../../../tools/HelpFunctions";
import SearchTabs from "../../tabs/SearchTabs";

class TableSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevCells: []
        }
        this.handlePageChange = this.handlePageChange.bind(this);

        this.fitInContainerSize = this.fitInContainerSize.bind(this);
        this.unFitInContainerSize = this.unFitInContainerSize.bind(this);
        this.setWidth = this.setWidth.bind(this);
        this.tableWidthRef = React.createRef();
        //TODO Чтобы ничего не перерендеривалось
        this.memoRef = React.createRef();

    }

    setWidth(itemSize, lItem) {
        const {activeTab, searchResults} = this.props;
        const mainTable = searchResults.find(item => item.Id === activeTab.Id)?.mainTable;
        if (this.state.prevCells.length == 0) {
            this.setState({prevCells: __.deepCopy(mainTable.header)});
        }
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);

            let lObjCell =  (Cells.find(item => item.Value === lItem.Value))
            if (lObjCell)
                lObjCell.Size = itemSize;
            //TODO использован реф для того, чтобы не перерисовывались дочки, т.к. если делать через стейт, то дочки перерисовываются, это и задержки и ошибки при ресайзе.
            // this.setState({prevCells: Cells});
            this.memoRef = Cells;
        }
    }

    unFitInContainerSize() {
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);
            this.props.setDefaultWidthOfColumn({updatedHeaders: Cells});
        }
    }

    fitInContainerSize() {
        const {activeTab, searchResults} = this.props;
        const mainTable = searchResults.find(item => item.Id === activeTab.Id)?.mainTable;
        let TableWidth = this.tableWidthRef?.current?.offsetWidth - 100;
        let Cells = __.deepCopy(mainTable.header);
        if (this.memoRef && this.memoRef.length)
            this.setState({prevCells: this.memoRef})
        else
            this.setState({prevCells: Cells});
        let RowWidth = 0;
        if (Cells && TableWidth) {
            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
                RowWidth += ((Cells[i].Value.length * 13) + 70); //Cells[i].SizeDefault ? Cells[i].SizeDefault :
            }

            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) {
                let size = (TableWidth / 100) * (((Cells[i].Value.length * 13) + 70) / RowWidth * 100); //Cells[i].SizeDefault ? Cells[i].SizeDefault :
                    Cells[i].Size = size < 100 ? 100 : size;
                // }
            }
            this.props.setDefaultWidthOfColumn({updatedHeaders: Cells});
        }
    }

    // переключение страниц пагинации
    handlePageChange(pageNumber) {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData, params : {data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "TableSearch101"}}, name : "setLoaderModalData"},
                {action : this.props.setPaginationPageNumberSearch, params : {PaginationPageNumber : pageNumber}, name : "setPaginationPageNumberSearch"},
                {action : this.props.searchByParams, params : {PaginationPageNumber: pageNumber, Id: this.props.activeTab.Id}, name : "searchByParams"},
                {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                {action : this.props.setLoaderModalData, params : {key: "TableSearch105"}, name : "setLoaderModalData"},
                {action : this.props.changeSearchViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.SearchApp.MainTableOnly}, name : "changeSearchViewMode"},
            ],
            debug : false,
        });
    }

    render() {
        let isEmpty = false;
        const {activeTab, searchResults} = this.props;
        const currentSearchIndex = searchResults?.findIndex(item => item.Id === activeTab.Id);
        const mainTable = searchResults[currentSearchIndex]?.mainTable;
        const totalInServer = mainTable?.collection.totalInServer
        if(Math.ceil((totalInServer > 2000 ? 2000 : totalInServer) / Number.parseInt(mainTable?.paginationPageSize))){
            isEmpty = true
        }
        const searchResultsIndex = searchResults?.findIndex(item => item.Id === activeTab.Id);
        const body = searchResults[searchResultsIndex]?.mainTable?.body;

        return (
            <>
                <SearchTabs />
                {<MainTableMenuSearch fitInContainerSize={this.fitInContainerSize}  unFitInContainerSize={this.unFitInContainerSize}/>}
                <div className="card-body py-0 table-responsive" style={{minHeight : "70vh"}} ref={this.tableWidthRef}>
                    <div className={`${(!body || body.length <= 0) ? 'text-center' : ''}`} onScroll={()=>
                    {
                        if(this.props.contextMenu.name !== "") {
                            this.props.setContextMenuData({});
                        }
                    }}>
                        {(!body || body.length <= 0) ?
                            <span className='text-muted'>Нет данных для отображения</span>
                            :
                            <table className="table table-head-custom table-striped table-hover table-vertical-center" id="mainTable" style={{ tableLayout : "fixed"}}>
                                {<TheadSearch setWidth = {(itemSize, item) => this.setWidth(itemSize, item)}/>}
                                {<TbodySearch/> }
                            </table>
                        }
                    </div>
                    </div>

                    <div className="card-footer d-flex justify-content-between align-items-center flex-wrap pt-3 pb-3">
                        <Pagination
                            activePage={mainTable?.paginationPageNumber} // активная страница
                            itemsCountPerPage={Number.parseInt(mainTable?.paginationPageSize)} // элементов на странице
                            totalItemsCount={mainTable?.collection.totalInServer > 2000 ? 2000 : mainTable?.collection.totalInServer} // всего элементов
                            pageRangeDisplayed={mainTable?.paginationButtonsCount} // сколько кнопок отображать
                            onChange={this.handlePageChange}  // обработчик переключения
                            itemClass={stylePagination.item} // стили item-ов
                            linkClass={"btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"} // стиль для ссылок
                            activeLinkClass={"btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"} // стиль для активных ссылок
                        />
                        {
                            isEmpty ?
                                <span className='text-muted'>
                                        Cтраница {mainTable?.paginationPageNumber} из {Math.ceil((mainTable?.collection.totalInServer > 2000 ? 2000 : mainTable?.collection.totalInServer) / Number.parseInt(mainTable?.paginationPageSize))}
                                </span>
                                :
                                <span className='text-muted'>
                                    Нет данных для отображения
                                </span>

                        }
                        <PaginationCounterSearch
                            totalItemsCount={mainTable?.collection.totalInServer} // всего элементов
                            activePage={mainTable?.paginationPageNumber} // активная страница
                            paginationPageSize={Number.parseInt(mainTable?.paginationPageSize)} // элементов на странице
                        />
                    </div>


            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeTab: state.search.rootState.activeTab,
        contextMenu :  state.globalState.app.contextMenu,
        searchResults: state.search.searchState.searchResults
    }
}


const  mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    searchByParams,
    setDefaultWidthOfColumn,
    setLoaderModalData,
    setModalData,
    setPaginationPageNumberSearch,
    // setContextMenuData,
    //resetMainTableForUpdate,
}

export default connect(mapStateToProps,mapDispatchToProps)(TableSearch);




