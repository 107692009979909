import React from "react";
import {connect} from "react-redux";
import {
    contextMenuTypes, ModalTypes, PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData, setModalData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    createDocumentCards, selectActiveDocumentCard,
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentSearchInputsContextMenu, changeViewMode} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {combineMainTableAttributes, mainTableSearchDoc} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {changePackagesViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";
import {
    handlerSearchPackages,
    searchByParamsPackages,
    updateInputsFieldPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {changeViewFilter} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {SelectActivePackageNode} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {store} from "../../../index";
import {API} from "../../../tools/API_NEW/API";
import {treeSelectNode} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";

class ContextMenuMainTableTr extends React.Component {
    constructor(props) {
        super(props);
        this.lFlagCopyUrl1C = this.props.globalSettings.WithCopyUrl1C === "1";

        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;


        this.copy = this.copy.bind(this);
        this.addDocCard = this.addDocCard.bind(this);
        this.search = this.search.bind(this);
        this.isDocumentApp = this.isDocumentApp.bind(this);



    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTR) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    copy() {
        let copytext = document.createElement('input');

        copytext.value = this.isDocumentApp()
            ? this.props.contextMenu.data.item.Value
            : this.props.contextMenu.data?.itemData.Value

        document.body.appendChild(copytext);
        copytext.select();
        document.execCommand('copy');
        document.body.removeChild(copytext);

        this.props.setModalData({
            name : ModalTypes.app.alert,
            data : {content: `Содержимое скопировано`, disableButton: true}
        });

        setTimeout(() => {
            this.props.setModalData({});
            // //Новый прелоадер
            // this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr96"});
            }, 500);
        this.props.setContextMenuData({});
    }



    async addDocCard() {

        try {

        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents"}
        // });
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "ContextMenuMainTableTr113"}
            }));


        //TODO Задача № 21548
        // if(this.isDocumentApp()) {
        // this.props.createDocumentCards({
        //     IdDoc: this.props.contextMenu.data.tr.Version.IdDoc,
        //     IdVersion: this.props.contextMenu.data.tr.Version.Id,
        //     Next: {
        //         action: () => {
        //             this.props.selectActiveDocumentCard({
        //                 CardId: store.getState().document.cards.collection.find(card => card.IdDoc === this.props.contextMenu.data.tr.Version.IdDoc).Id,
        //                 Next: {
        //                     action: () => {
        //                         this.props.changeDocumentViewMode({
        //                             ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards,
        //                             Next: {
        //                                 action: () => {
        //                                     this.props.setModalData({});
        //                                 },
        //                                 params: {}
        //                             }
        //                         })
        //                     },
        //                     params: {}
        //                 }
        //             });
        //             this.props.setStateLeftAsideMenu({
        //                 isClose: true,
        //                 rootName: "DocumentApp"
        //             });
        //             this.props.updateUserSettings({
        //                 Name: UserSettingsNames.DOCUMENT_CONFIG,
        //                 Action: UserSettingsActionsTypes.Update,
        //                 Data: {
        //                     IdDoc: this.props.contextMenu.data.tr.Version.IdDoc,
        //                     IdVersion: this.props.contextMenu.data.tr.Version.Id,
        //                     Name: `${this.props.contextMenu.data.tr.Info.Name} ${this.props.contextMenu.data.tr.Info.ShortDescription ?? ""}`
        //                 }
        //             });
        //             this.props.changeViewMode({
        //                 isSearch: false
        //             });
        //         },
        //         params: {}
        //     }
        // });
        // } else {
        //     let docRefInfo = await API.search().verifyDocClientLinkIds({
        //         linkIds: {
        //             IdDoc: this.props.contextMenu.data.item.Node.Id,
        //             IdFilial: this.props.activeFilialId
        //         }
        //     });
        //
        //     history.replace(`/documents`);
        //     let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}&c=${docRefInfo.IdDoc}`;
        //     history.replace(link, true);
        //
        //     this.props.createDocumentCards({
        //         IdDoc: docRefInfo.IdDoc,
        //         IdVersion: this.props.contextMenu.data.item.Node.Version.Id,
        //         packInfo: {
        //             ...this.props.contextMenu.data.item,
        //             IdDocGroup: docRefInfo.IdDocGroup,
        //             DocGroupName: this.props.contextMenu.data.item.Node.Name
        //         },
        //         Next: {
        //             action: () => {
        //                 this.props.selectActiveDocumentCard({
        //                     CardId: store.getState().document.cards.collection.find(card => card.IdDoc === docRefInfo.IdDoc).Id,
        //                     Next: {
        //                         action: () => {
        //                             this.props.changeDocumentViewMode({
        //                                 ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards,
        //                                 Next: {
        //                                     action: () => {
        //                                         this.props.setModalData({});
        //                                     },
        //                                     params: {}
        //                                 }
        //                             })
        //                         },
        //                         params: {}
        //                     }
        //                 });
        //                 this.props.setStateLeftAsideMenu({
        //                     isClose: true,
        //                     rootName: "DocumentApp"
        //                 });
        //                 this.props.updateUserSettings({
        //                     Name: UserSettingsNames.DOCUMENT_CONFIG,
        //                     Action: UserSettingsActionsTypes.Update,
        //                     Data: {
        //                         IdDoc: docRefInfo.IdDoc,
        //                         IdVersion: this.props.contextMenu.data.item.Node.Version.Id,
        //                         Name: `${this.props.contextMenu.data.item.Node.Name} ${this.props.contextMenu.data.item.Node.ShortDescription ?? ""}`
        //                     }
        //                 });
        //                 this.props.changeViewMode({
        //                     isSearch: false
        //                 });
        //             },
        //             params: {}
        //         }
        //     })
        //
        // }
        // // this.props.createDocumentCards({
        // //     IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
        // //     IdVersion : this.props.contextMenu.data.tr.Version.Id,
        // //     isRightClick : true,
        // // });
        // // this.props.updateUserSettings({
        // //     Name : UserSettingsNames.DOCUMENT_CONFIG,
        // //     Action : UserSettingsActionsTypes.Update,
        // //     Data : {
        // //         IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
        // //         IdVersion : this.props.contextMenu.data.tr.Version.Id,
        // //         Name: `${this.props.contextMenu.data.tr.Info.Name} ${this.props.contextMenu.data.tr.Info.ShortDescription}`
        // //     }
        // // });
        // //
        // this.props.setContextMenuData({});
        await this.props.createDocumentCards({
            IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
            IdVersion : this.props.contextMenu.data.tr.Version.Id,
            isRightClick : true,
        });
        await this.props.updateUserSettings({
            Name : UserSettingsNames.DOCUMENT_CONFIG,
            Action : UserSettingsActionsTypes.Update,
            Data : {
                IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
                IdVersion : this.props.contextMenu.data.tr.Version.Id,
                Name: `${this.props.contextMenu.data.tr.Info.Name} ${this.props.contextMenu.data.tr.Info.ShortDescription}`
            }
        });

        this.props.setContextMenuData({});
        }
        finally {
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr254"});
            let res = await API.documents().getContentForCurrentFile({IdDoc : this.props.contextMenu.data.tr.Version.IdDoc, IdVersion : this.props.contextMenu.data.tr.Version.Id,})
            if (res?.errorCode) {
             this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: `Ошибка открытия: файл поврежден`, type: "fail"
                        }
                    });
            }
        }
    }

    isDocumentApp() {
        return !this.props.contextMenu.data?.itemData
    }


    search(){
        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {content: "Загрузка документов...", disableButton: true, fullBackground: false, gif: "documents"}
        // })
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка документов...", disableButton : true, fullBackground : false, gif : "documents", key : "ContextMenuMainTableTr284" }
        }));
        if (this.isDocumentApp()) {
            this.props.changeDocumentViewMode({
                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
            });

            this.props.setStateLeftAsideMenu({
                isClose: false,
                Next: {
                    action: () => {
                        this.props.changeViewMode({
                            isSearch: true,
                            Next: {
                                action: () => {
                                    setTimeout(() => { // TODO: исправить этот кастыль
                                        this.props.changeDocumentSearchInputsContextMenu({
                                            Attribute: this.props.contextMenu.data.item,
                                            Value: this.props.contextMenu.data.item.Value,
                                            isConstant: this.props.contextMenu.data.item.IsConstant,
                                            Next: {
                                                action: () => {
                                                    this.props.mainTableSearchDoc({
                                                        Next: {
                                                            action: () => {
                                                                this.props.combineMainTableAttributes({
                                                                    Next: {
                                                                        action: () => {
                                                                            // this.props.setModalData({});
                                                                            //Новый прелоадер
                                                                            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr306"});
                                                                        },
                                                                        params: {}
                                                                    }})
                                                            },
                                                            params: {}
                                                        }
                                                    });
                                                },
                                                params: {}
                                            }
                                        });
                                    }, 50);
                                },
                                params: {}
                            }
                        })
                        this.props.setContextMenuData({});
                    },
                    params: {}
                }
            });
        } else {
            this.props.changePackagesViewMode({
                ViewMode: RootComponentsStateViewModeTypes.PackagesApp.Search
            })
            const itemData = this.props.contextMenu.data?.itemData
            const RegDateAsString = itemData.Name === "RegDate"
                ? this.props.contextMenu.data?.itemData.Value.split("T")[0].split("-").reverse().join(".")
                : this.props.searchInputs.RegDateAsString
            const ModifyDate = itemData.Name === "Version.ModifyTime"
                ? this.props.contextMenu.data?.itemData.Value.split("T")[0].split("-").reverse().join(".")
                : this.props.searchInputs.ModifyDate
            const RegNumber = itemData.Name === "RegNumber"
                ? this.props.contextMenu.data?.itemData.Value
                : this.props.searchInputs.RegNumber
            const Name = itemData.Name === "Name"
                ? this.props.contextMenu.data?.itemData.Value
                : this.props.searchInputs.Name
            const TypeName = itemData.Name === "TypeName"
                ? this.props.contextMenu.data?.itemData.Value
                : this.props.searchInputs.TypeName

            const lActiveNode = this.props.contextMenu.data?.item.Node.IdPackageDocRef
            this.props.setStateLeftAsideMenu({
                isClose: false,
                Next: {
                    action: () => {
                        setTimeout(() => { // TODO: исправить этот кастыль
                            this.props.updateInputsFieldPackage({
                                TableRole: "main",
                                RegDateAsString,
                                RegNumber,
                                Name,
                                TypeName,
                                ModifyDate,
                                Next: {
                                    action: () => {
                                        this.props.searchByParamsPackages({
                                            Next: {
                                                action: () => {
                                                    this.props.handlerSearchPackages({
                                                        Next: {
                                                            action: () => {
                                                                this.props.SelectActivePackageNode({
                                                                    Id: lActiveNode,
                                                                    contextSearch: true,
                                                                    TableType: "main",
                                                                })
                                                            },
                                                            params: {}
                                                        }
                                                    })
                                                },
                                                params: {}
                                            }
                                        })
                                    },
                                    params: {}
                                }
                            });
                        }, 50);
                        this.props.setContextMenuData({});
                    },
                    params: {}
                }
            });
            this.props.changeViewFilter({
                Type: "documents",
                TableType: this.props.tableRole,
                lStoreValue: PackagesViewFilters.OnlyDocuments
            });
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr397"});
        }
    }


    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };


        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
            onMouseEnter={(event) => {this.setState({isHover : true})}}
            onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item"
                        onClick={() => {

                            const lIdDoc = this.props.contextMenu.data?.tr?.Info?.Id ?? ( this.props.contextMenu.data?.tr?.info?.Id ??
                                (this.props.contextMenu.data?.item?.Type == "PackagesDocument" ? (
                                    this.props.contextMenu.data?.item?.Node?.Id ?? null)  : null)
                            );
                            const lIdPack =(this.props.contextMenu.data?.item?.Type != "PackagesDocument") ? this.props.contextMenu.data?.item?.Node?.Id : null;

                            if (lIdDoc || lIdPack)
                            {
                                __.copyUrl(lIdDoc, lIdPack);
                                __.showModalCopyComplete(this.props.setModalData);
                            }
                            else {
                                __.copyUrl();
                                __.showModalCopyComplete(this.props.setModalData);
                            }
                            this.props.setContextMenuData({});}}
                        style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                            </span>
                            Скопировать ссылку
                        </span>
                    </li>
                    {this.lFlagCopyUrl1C &&
                        <li className="context-menu__item"
                            onClick={() => {
                                const lIdDoc = this.props.contextMenu.data?.tr?.Info?.Id ?? ( this.props.contextMenu.data?.tr?.info?.Id ??
                                    (this.props.contextMenu.data?.item?.Type == "PackagesDocument" ? (
                                        this.props.contextMenu.data?.item?.Node?.Id ?? null)  : null)
                                );
                                const lIdPack =(this.props.contextMenu.data?.item?.Type != "PackagesDocument") ? this.props.contextMenu.data?.item?.Node?.Id : null;

                                if (lIdDoc || lIdPack) {
                                    __.copyUrl1c(lIdDoc, lIdPack);
                                    __.showModalCopyComplete(this.props.setModalData);
                                } else {
                                    __.copyUrl1c();
                                    __.showModalCopyComplete(this.props.setModalData);
                                }
                                this.props.setContextMenuData({});}}
                            style={{cursor : "pointer"}}>
                            <span className="context-menu__link">
                                <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                    <i className="svg-icon svg-icon-sm text-primary icon-c_16"> </i>
                                </span>
                                Скопировать ссылку для 1С
                            </span>
                        </li>
                    }
                    {
                        store.getState().router.location.pathname.indexOf("packages") == -1 &&
                        store.getState().router.location.pathname.indexOf("search") == -1 &&
                        <>
                            <li className="context-menu__item" onClick={this.copy} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                                    </span>
                                    Скопировать содержимое
                                </span>
                            </li>
                            <li className="context-menu__item" onClick={this.search} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Search_menu"> </i>
                                    </span>
                                    Фильтр по значению
                                </span>
                            </li>
                            <li className="context-menu__item" onClick={this.addDocCard} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Table_edit_3"> </i>
                                    </span>
                                    Карточка документа
                                </span>
                            </li>
                        </>
                    }
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeFilialId : state.globalState.filial.Active.Id,
        contextMenu :  state.globalState.app.contextMenu,
        searchInputs: state.packages.search.searchInputs,
        globalSettings: state.globalState.settings.Content
    }
}

const  mapDispatchToProps = {
    changeDocumentSearchInputsContextMenu,
    changeDocumentViewMode,
    changePackagesViewMode,
    changeViewFilter,
    changeViewMode,
    combineMainTableAttributes,
    createDocumentCards,
    handlerSearchPackages,
    mainTableSearchDoc,
    updateInputsFieldPackage,
    updateUserSettings,
    searchByParamsPackages,
    selectActiveDocumentCard,
    SelectActivePackageNode,
    setContextMenuData,
    setStateLeftAsideMenu,
    setModalData,
    setLoaderModalData,
    treeSelectNode,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuMainTableTr);
