import React from "react";
import {connect} from "react-redux";
import {HelpFunctions} from "../../tools/HelpFunctions";
import parse from "html-react-parser";

class FilesListDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenMenu: false,
        };


    }


    render() {
        // const {docName, preview, selectActivePreviewFile} = this.props;
        const { preview, selectActivePreviewFile} = this.props;
        const {isOpenMenu} = this.state;

        // TODO в дропдауне должен отображаться выбранный файл или название документа?
        const docName = preview.files[preview.activeFileIndex].name;

        return (
            <>
                <div className={`dropdown-menu ${isOpenMenu ? "show" : ""}`} style={{position: 'absolute', top: '3.5rem'}} aria-labelledby="dropdownMenuReference">
                    {
                        preview.files.map((file, index) => {
                            return (
                                <a key={file.name} href="#"
                                   className={`dropdown-item ${preview.activeFileIndex === index ? "active" : ""}`}
                                   title={file.name.replace('<span class=\'searchResultHighlight\'>', "").replace('</span>', "")}
                                   onClick={(ev) => {
                                       ev.preventDefault();
                                       this.setState({isOpenMenu: false})
                                       selectActivePreviewFile(file, index)
                                   }}>
                                    {HelpFunctions.cutLongString(file.name, 20, true)}
                                </a>
                            )
                        })
                    }
                </div>
                <div className="btn-group m-2">
                    <button type="button" className="btn btn-outline-secondary p-2"
                            title={docName.replace('<span class=\'searchResultHighlight\'>', "").replace('</span>', "")}
                            style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >
                        {HelpFunctions.cutLongString(parse(docName), 15, true)}
                    </button>
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                            id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            data-reference="parent"
                            onClick={() => {
                                this.setState({isOpenMenu: !isOpenMenu})
                            }}>
                        <span className="sr-only"/>
                    </button>

                    {/* Блок для скрытия дропдауна по клику вне блока*/}
                    {
                        isOpenMenu &&
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            background: 'rgba(0, 0, 0, 0)',
                            zIndex: 999
                        }}
                             onClick={() => {
                                 this.setState({isOpenMenu: false})
                             }}/>
                    }
                    {/* END */}
                </div>

            </>

        )
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FilesListDropdown);
