import {REPORT_PARAMS} from "../reportActionsList";
import {store} from "../../../index";
import {__} from "../../../tools/HelpFunctions";
//сохранить значения из MySelect
export const saveValue = ({value=null,nameField=null,Next = null, Error = null}) =>{

    return async (dispatch) => {
        try {
            value=value.toString()
            let reportValue = __.deepCopy(store.getState().report.leftMenu.params);



            let foundIndex = reportValue.findIndex(s => s.Name === nameField);



            if(foundIndex < 0) {
                reportValue.push({value})
            }
            else {


                if(reportValue[foundIndex].TypeCode == 5) {
                    if(reportValue[foundIndex].IsList) {
                        if(!Array.isArray(value)){

                            reportValue[foundIndex].value.push({
                                label : value,
                                value : value
                            });
                        }else {
                            reportValue[foundIndex].value = value;
                        }

                    }
                    else {

                        reportValue[foundIndex].value = value;
                    }
                }
                else {
                    reportValue[foundIndex].value = value;
                }
            }


            //let newValue = oldValue.push(value)
            dispatch({
                type : REPORT_PARAMS.REPORT_MULTIPLE_PARAMS_SAVE,
                payload : reportValue});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров  (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }

}
