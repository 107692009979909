import React from "react";
import {connect} from "react-redux";
import Iframe from 'react-iframe'
import {Dropdown} from "react-bootstrap";
import {HelpFunctions} from "../../tools/HelpFunctions";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    PackageAddNodesTree,
    selectActiveFileForPreviewPackages, selectViewActiveFileForPreview,
    setFilesContent
} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {setPreviewSizePackages} from "../../store/packages/actionCreators/package_MainTableActionCreator";
import {verifyDocClientLinkIdsDocs} from "../../store/search/actionCreators/search_table";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";
import {
    AdminLevelEnum,
    ModalTypes,
    RootComponentsStateViewModeTypes, UserSettingsActionsTypes, UserSettingsNames
} from "../../tools/StaticTypes";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    collectDataForAdditionalSections_Normal,
    createDocumentCards, createPreviewBySelectDocument, resetDocumentPreview,
    selectActiveDocumentCard
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import FilesListDropdown from "./FilesListDropdown";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {changeViewMode} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    getDocGroupStructureNodes,
    getDocumentGroupsForTree,
    getDocumentsForTree, renderStructureTree,
    toggleFoldersForTree,
    toggleIsLoading,
    treeSelectNode
} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {ActionQueue} from "../../store/rootReducer";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {EntityFilters, Filter, FiltersType} from "../../tools/Filters";

class DocPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backToTableTooltipShow: false,
            addNewCardTooltipShow: false,
            goToDocumentsTooltipShow: false,
            selectFileTooltipShow: false,
            halfScreenTooltipShow: false,
            fullScreenTooltipShow: false,
            openInNewTabTooltipShow: false,
            redactorModeTooltipShow: false,
            uploadFileTooltipShow: false,
            uploadFileAttachmentTooltipShow: false,
            deleteDocumentTooltipShow: false,
            infoTooltipShow: false,
            hidePreviewShow: false,
            changeVersionDocTooltipShow: false,
            docRefInfo: null,
            rotateIcon: false,

            kostil: 0
        };

        this.backToTableRef = React.createRef();
        this.addNewCardRef = React.createRef();
        this.goToDocumentsRef = React.createRef();
        this.selectFileRef = React.createRef();
        this.halfScreenRef = React.createRef();
        this.fullScreenRef = React.createRef();
        this.openInNewTabRef = React.createRef();
        this.redactorModeRef = React.createRef();
        this.uploadFileAttachmentRef = React.createRef();
        this.deleteDocumentRef = React.createRef();
        this.hidePreviewRef = React.createRef();
        this.infoRef = React.createRef();
        this.changeVersionDocRef = React.createRef();


        this.selectActivePreviewFile = this.selectActivePreviewFile.bind(this);
        this.render_cardBody = this.render_cardBody.bind(this);
        this.setPreviewSize = this.setPreviewSize.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.changeVersionDoc = this.changeVersionDoc.bind(this);
    }


    componentDidMount() {
        // this.props.setStateLeftAsideMenu({
        //     isClose : false
        // });

        if (!this.props.tree.isLoaded) { // проверить загружены ли данные, чтобы предотвратить повторную загрузку
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List: [
                    // {action : this.props.toggleIsLoading, params : {}, name : "toggleIsLoading"},
                    {
                        action: this.props.getDocumentsForTree,
                        params: {filial: this.props.filial},
                        name: "getDocumentsForTree"
                    },
                    {
                        action: this.props.getDocumentGroupsForTree,
                        params: {filial: this.props.filial},
                        name: "getDocumentGroupsForTree"
                    },
                    // {action : this.props.createTree, params : {}, name : "createTree"},
                    {
                        action: this.props.getDocGroupStructureNodes,
                        params: {filial: this.props.filial},
                        name: "getDocGroupStructureNodes"
                    },
                    {action: this.props.renderStructureTree, params: {}, name: "renderStructureTree"},
                    // {action : this.props.toggleIsLoading, params : {}, name : "toggleIsLoading"},
                ],
                debug: true,
            });
        }
    }

    deleteDocument() {
        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type: "deleteDocument",
                activeDocument: this.props.activeDocument,
                activeNode: this.props.activeNode,
            }
        });
    }

    changeVersionDoc() {
        this.props.setModalData({
            name: ModalTypes.packages.ChangeVersionDocInPackage,
            data: {
                TableType: this.props.tableRole,
                IsDownload: false
            }
        });
    }

    setPreviewSize(Size) {
        this.props.setPreviewSizePackages({Size});
    }

    // меняем активный файл предпросмотра
    selectActivePreviewFile(item, index) {
        this.props.setFilesContent({
            document: item.isAttach ? item : this.props.activeDocument.Node,
            TableType: this.props.tableRole
        });
        this.props.selectActiveFileForPreviewPackages({
            Index: index,
            Type: item.type,
            TableType: "main",
            Next: {
                action: () => {
                    this.setState({
                        kostil: this.state.kostil + 1
                    });
                },
                params: {}
            }
        });
    }

    render_cardBody() {
        let preview = this.props.activeDocument.preview;
        let body = <div className="card-body"/>;
        let activeLink = "";

        if (preview.activeView === "preview") {
            activeLink = preview.files[preview.activeFileIndex].previewLink;
            //
            // if(preview.activeView === "preview") {
            //     link = preview.files[preview.activeFileIndex].previewLink;
            // } else link = preview.files[preview.activeFileIndex].link;
            //if (preview.files[preview.activeFileIndex].type === "image") {
            body = (
                <div className="card-body beautiful-scroll-10">
                    <img src={activeLink} alt="" style={{
                        boxShadow: "0 0 15px -5px grey",
                        width: "100%"
                    }}/>
                </div>
            );
            //}
            // else {
            //     body = (
            //         <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
            //             <Iframe url={preview.files[preview.activeFileIndex].previewLink}
            //                     width="100%"
            //                     height="100%"
            //                     id="test"
            //                     display="initial"
            //                     position="relative"
            //             />
            //         </div>
            //     );
            // }
        } else {
            // let activeFileIndex = preview.files.findIndex(file => file.type === 'pdf')
            activeLink = preview.files[preview.activeFileIndex].originalLink;
            body = (
                <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
                    <Iframe url={activeLink}
                            width="100%"
                            height="100%"
                            id="test"
                            display="initial"
                            position="relative"
                    />
                </div>
            );
        }

        // if(preview.files[preview.activeFileIndex].type === "image") {
        //     body = (
        //         <div className="card-body beautiful-scroll-10" style={{}}>
        //             <img src={preview.files[preview.activeFileIndex].link} alt="" style={{width : "100%"}} />
        //         </div>
        //     );
        // }
        // else {
        //     body = (
        //         <div className="card-body beautiful-scroll-10" style={{width : "100%", height : "100%"}}>
        //             <Iframe url={preview.files[preview.activeFileIndex].link}
        //                     width="100%"
        //                     height="100%"
        //                     id="test"
        //                     display="initial"
        //                     position="relative"/>
        //         </div>
        //     );
        // }
        // let body = <div className="card-body"/>;
        // if (preview.isReady) {

        //     if (preview.typeFile == "image") {
        //         if (preview.files[preview.activeFileIndex].type === "image") {
        //             body = (
        //                 <div className="card-body beautiful-scroll-10" style={{}}>
        //                     <img src={preview.files[preview.activeFileIndex].link} alt="" style={{width: "100%"}}/>
        //                 </div>
        //             );
        //         } else {
        //             body = (
        //                 <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
        //                     <Iframe url={preview.files[preview.activeFileIndex].link}
        //                             width="100%"
        //                             height="100%"
        //                             id="test"
        //                             display="initial"
        //                             position="relative"/>
        //                 </div>
        //             );
        //         }
        //     } else {
        //         let activeFileIndex = preview.files.findIndex(file => file.type === 'pdf')
        //         body = (
        //             <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
        //                 <Iframe url={preview.files[activeFileIndex].link}
        //                         width="100%"
        //                         height="100%"
        //                         id="test"
        //                         display="initial"
        //                         position="relative"/>
        //             </div>
        //         );
        //     }
        // }

        return body;
    }


    async goToDocuments(isCard = false) {
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "PackagesPreview360" }
        }));
        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[this.props.activeDocument.Node.Id]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[this.props.activeDocument.Node.Id][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: this.props.activeDocument.Node.Id,
                    IdFilial: this.props.activeFilialId
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: this.props.activeDocument.Node.Id,
                    data: docRefInfo
                }
            });
        }
        // let docRefInfo = await API.search().verifyDocClientLinkIds({
        //     linkIds: {
        //         IdDoc: this.props.activeDocument.Node.Id,
        //         IdFilial: this.props.activeFilialId
        //     }
        // });

        if (docRefInfo.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `
                        Ошибка: ${docRefInfo.message.ExceptionName} <br/>
                        Время: ${docRefInfo.message.ExceptionTime} <br/>
                        Сообщение: ${docRefInfo.message.Message} <br/>
                    `
                }
            });
            return;
        } else {
            //TODO Некорректный переход из Пакета в реестр
            if (!isCard)
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
                });
            //TODO Некорректный переход из Пакета в реестр - обманка роутинга, чтобы в строке 470 Routing.js let complete = true;
            // history.replace(`/documents`);
            let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;

            if (isCard) {
                link += `&c=${docRefInfo.IdDoc}`;
            }
            //TODO Задача № 19608 для обновления страницы добавлен true, push убран


            // this.props.setModalData({
            //     name: ModalTypes.app.alert,
            //     data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents"}
            // });


            // ----------------------------------------------------


            const groupTree = store.getState().document.tree.render.children.find(o => o.info.Id === docRefInfo.IdDocGroup)
            const typeTree = groupTree?.children.find(type => type.info.Id === docRefInfo.IdDocType);

            if(!typeTree) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content : `Недостаточно прав для открытия документа`}
                });
                store.dispatch(setLoaderModalData({keyDeleted: "PackagesPreview378" }));
                return
            }

            if (isCard) {
               let getLastVersion = await API.documents().getAllVersions({
                    filter : new Filter(FiltersType.ENTITY).add(
                        new EntityFilters().setEntityName("DocVersion").add({
                            Name : "IdDoc",
                            Value : this.props.activeDocument.Node.Id
                        }).get()
                    ).get(),
                    sorting : null,
                }).then((res) => {
                   getLastVersion = res.Records[res.Records.length - 1]
               })
                   .then(() => {
                       this.props.createDocumentCards({
                           IdDoc: docRefInfo.IdDoc,
                           IdVersion: getLastVersion.Id,
                           packInfo: {
                               ...this.props.activeDocument,
                               IdDocGroup: docRefInfo.IdDocGroup,
                               DocGroupName: groupTree?.info.Name
                           },
                           Next: {
                               action: () => {
                                   this.props.selectActiveDocumentCard({
                                       CardId: store.getState().document.cards.collection.find(card => card.IdDoc === docRefInfo.IdDoc).Id,
                                       Next: {
                                           action: () => {
                                               this.props.changeDocumentViewMode({
                                                   ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                                                   Next: {
                                                       action: () => {
                                                           this.props.toggleFoldersForTree({
                                                               IdFolder: docRefInfo.IdDocGroup,
                                                               KeepOpen: true,
                                                               Next: {
                                                                   action: () => {
                                                                       this.props.treeSelectNode({
                                                                           Node: typeTree,
                                                                           Next: {
                                                                               action: () => {
                                                                                   this.props.mainTableDownloadDocCollection({
                                                                                       id: docRefInfo.IdDoc ?? null,
                                                                                       ActiveNode: typeTree,
                                                                                       Next: {
                                                                                           action: () => {
                                                                                               this.props.combineMainTableAttributes({
                                                                                                   ActiveNode: typeTree,
                                                                                                   Next: {
                                                                                                       action: () => {
                                                                                                           let activeRow = store.getState().document.mainTable.body.find(tr => tr.Version.IdDoc === docRefInfo.IdDoc)
                                                                                                           this.props.selectActiveRow({
                                                                                                               RowInfo: activeRow,
                                                                                                               Next: {
                                                                                                                   action: async () => {
                                                                                                                       await this.props.resetDocumentPreview({});
                                                                                                                       await this.props.createPreviewBySelectDocument({});
                                                                                                                       setTimeout(() => {
                                                                                                                           store.dispatch(changeDocumentViewMode({
                                                                                                                               ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                                                                                                                           }))
                                                                                                                           // this.props.setModalData({});
                                                                                                                           //Новый прелоадер
                                                                                                                           this.props.setLoaderModalData({keyDeleted: "PackagesPreview415"});
                                                                                                                           history.replace(link, true);
                                                                                                                       }, 1000)
                                                                                                                   }
                                                                                                               }
                                                                                                           })

                                                                                                       }
                                                                                                   }
                                                                                               })
                                                                                           }
                                                                                       }
                                                                                   })
                                                                               }
                                                                           }
                                                                       })
                                                                   }
                                                               }
                                                           })
                                                       },
                                                       params: {}
                                                   }
                                               })
                                           },
                                           params: {}
                                       }
                                   });
                                   this.props.setStateLeftAsideMenu({
                                       isClose: true,
                                       rootName: "DocumentApp"
                                   });
                                   this.props.updateUserSettings({
                                       Name: UserSettingsNames.DOCUMENT_CONFIG,
                                       Action: UserSettingsActionsTypes.Update,
                                       Data: {
                                           IdDoc: docRefInfo.IdDoc,
                                           IdVersion: this.props.activeDocument.Node.Version.Id,
                                           Name: `${this.props.activeDocument.Node.Name} ${this.props.activeDocument.Node.ShortDescription ?? ""}`
                                       }
                                   });
                                   this.props.changeViewMode({
                                       isSearch: false
                                   });
                               },
                               params: {}
                           }
                       })
                   })

            } else {
                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                    Next: {
                        action: () => {
                            this.props.toggleFoldersForTree({
                                IdFolder: docRefInfo.IdDocGroup,
                                KeepOpen: true,
                                Next: {
                                    action: () => {
                                        this.props.treeSelectNode({
                                            Node: typeTree,
                                            Next: {
                                                action: () => {
                                                    this.props.mainTableDownloadDocCollection({
                                                        id: docRefInfo.IdDoc ?? null,
                                                        ActiveNode: typeTree,
                                                        Next: {
                                                            action: () => {
                                                                this.props.combineMainTableAttributes({
                                                                    ActiveNode: typeTree,
                                                                    Next: {
                                                                        action: () => {
                                                                            let activeRow = store.getState().document.mainTable.body.find(tr => tr.Version.IdDoc === docRefInfo.IdDoc)
                                                                            this.props.selectActiveRow({
                                                                                RowInfo: activeRow,
                                                                                Next: {
                                                                                    action: () => {
                                                                                        this.props.resetDocumentPreview({
                                                                                            Next: {
                                                                                                action: () => {
                                                                                                    this.props.createPreviewBySelectDocument({
                                                                                                        Next: {
                                                                                                            action: () => {
                                                                                                                this.props.collectDataForAdditionalSections_Normal({});
                                                                                                                this.props.changeDocumentViewMode({
                                                                                                                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
                                                                                                                    Next: {
                                                                                                                        action: () => {
                                                                                                                            history.replace(link, true);
                                                                                                                            // this.props.setModalData({});
                                                                                                                            //Новый прелоадер
                                                                                                                            this.props.setLoaderModalData({keyDeleted: "PackagesPreview505"});
                                                                                                                            },
                                                                                                                        params: {}
                                                                                                                    }
                                                                                                                });
                                                                                                                },
                                                                                                            params: {}
                                                                                                        }
                                                                                                    });
                                                                                                    },
                                                                                                params: {}
                                                                                            }
                                                                                        });
                                                                                        },
                                                                                    params: {}
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        },
                        params: {}
                    }
                })
                this.props.setStateLeftAsideMenu({
                    isClose: false,
                    rootName: "DocumentApp"
                });
                this.props.updateUserSettings({
                    Name: UserSettingsNames.DOCUMENT_CONFIG,
                    Action: UserSettingsActionsTypes.Update,
                    Data: {
                        IdDoc: docRefInfo.IdDoc,
                        IdVersion: this.props.activeDocument.Node.Version.Id,
                        Name: `${this.props.activeDocument.Node.Name} ${this.props.activeDocument.Node.ShortDescription ?? ""}`
                    }
                });
                this.props.changeViewMode({
                    isSearch: false
                });
            }
        }
    }


    selectViewActiveFile(type) {
        this.props.selectViewActiveFileForPreview({View: type, TableType: this.props.tableRole});
        this.setState({
            kostil: this.state.kostil + 1
        });
    }


    render() {
        if (!this.props.activeDocument) {
            return null;
        }
        let docName = `${this.props.activeDocument.Node.Name} ${this.props.activeDocument.Node.ShortDescription}`;
        let preview = this.props.activeDocument.preview;

        let content = this.render_cardBody();

        let halfButtonStyle = {};
        let fullButtonStyle = {};
        let halfButtonIcon = {};
        let fullButtonIcon = {};
        if (this.props.viewModePreview_Size === "half") {
            halfButtonStyle = {
                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            halfButtonIcon = {
                color: "white"
            };
        }
        if (this.props.viewModePreview_Size === "full") {
            fullButtonStyle = {
                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            fullButtonIcon = {
                color: "white"
            };
        }

        const isHidePreview = this.props.viewModePreview_Size === "hide";
        const isAttachFiles = preview.files.filter(file => file.isAttach).length > 0;
        const activeLink = preview.activeView === "preview" ? preview.files[preview.activeFileIndex].previewLink : preview.files[preview.activeFileIndex].originalLink;
        const isAuto = this.props.activeDocument?.Node.IsAuto;
        return (
            <>
                <div className={`${isHidePreview ? 'card-body preview-hide border-0' : 'card-header beautiful-scroll-5'}`}>
                    <div className={`row ${!isHidePreview ? 'flex-lg-nowrap' : "justify-content-center"}`}>
                        {
                            this.state.hidePreviewShow &&
                            <MyTooltip target={this.hidePreviewRef}
                                       text={`${isHidePreview ? "Показать" : "Скрыть"} предпросмотр документа`}
                                       show={this.state.hidePreviewShow}
                                       placement={"top"} delay={500}
                            />
                        }

                        <button type="button" className="btn btn-outline p-0" onClick={() => {
                            this.setState({rotateIcon: !this.state.rotateIcon})
                            this.setPreviewSize(isHidePreview ? "normal" : "hide")
                        }}
                                ref={this.hidePreviewRef}
                                onMouseEnter={() => {this.setState({hidePreviewShow: true})}}
                                onMouseLeave={() => {this.setState({hidePreviewShow: false})}}>
                                    <span className="btn btn-icon btn-sm">
                                        <i style={{fontSize: '2rem'}} className={`svg-icon icon-window_out_${this.state.rotateIcon ? "right" : "left"} icon-color-primary text-success`} />
                                    </span>
                        </button>
                        {isHidePreview && <div className="separator separator-solid mb-2 mt-2" style={{minWidth: '3vw'}} />}
                        {!isHidePreview &&
                            <div className="card-title">
                                {
                                    isAttachFiles ?
                                        <FilesListDropdown docName={docName}
                                                           preview={preview}
                                                           selectActivePreviewFile={this.selectActivePreviewFile}
                                        />
                                        :
                                        <h4 className="card-label text-weight-bolder text-color-primary" title={docName} style={{whiteSpace: "nowrap"}}>
                                            {HelpFunctions.cutLongString(docName, 15, true)}
                                        </h4>
                                }
                            </div>
                        }

                        <div className={`d-flex align-items-center justify-content-center ${isHidePreview ? "flex-wrap" : ""}`}>
                            {/*{*/}
                            {/*    this.state.infoTooltipShow &&*/}
                            {/*    <MyTooltip target={this.infoRef}*/}
                            {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                            {/*               show={this.state.infoTooltipShow}*/}
                            {/*               placement={"left"} delay={500}*/}
                            {/*    />*/}
                            {/*}*/}
                            {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                            {/*      ref={this.infoRef}*/}
                            {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                            {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                            {/*             <i className="svg-icon icon-Info"/>*/}
                            {/*         </span>*/}
                            <div className="btn-group">
                                {
                                    this.state.deleteDocumentTooltipShow &&
                                    <MyTooltip target={this.deleteDocumentRef}
                                               text={"Исключить документ из пакета"}
                                               show={this.state.deleteDocumentTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                    <span className="btn btn-icon btn-sm" ref={this.deleteDocumentRef}
                                          onClick={()=>{this.deleteDocument()}}
                                          onMouseEnter={()=>{this.setState({deleteDocumentTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({deleteDocumentTooltipShow : false})}}>
                                        <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                    </span>
                                }
                                {
                                    this.state.addNewCardTooltipShow &&
                                    <MyTooltip target={this.addNewCardRef}
                                               text={"Карточка документа"}
                                               show={this.state.addNewCardTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm" ref={this.addNewCardRef}
                                      onClick={()=>{this.goToDocuments(true)}}
                                      onMouseEnter={()=>{this.setState({addNewCardTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({addNewCardTooltipShow : false})}}>
                                    <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                </span>
                            </div>
                            {isHidePreview && <div className="separator separator-solid mb-2 mt-2" style={{minWidth: '3vw'}} />}
                            <div className="btn-group">
                                {
                                    this.state.changeVersionDocTooltipShow &&
                                    <MyTooltip target={this.changeVersionDocRef}
                                               text={"Изменить версию документа в пакете"}
                                               show={this.state.changeVersionDocTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    (this.props?.adminLevel >= 1 && !isAuto) &&
                                    <span className="btn btn-icon btn-sm" ref={this.changeVersionDocRef}
                                          onClick={()=>{this.changeVersionDoc()}}
                                          onMouseEnter={()=>{this.setState({changeVersionDocTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({changeVersionDocTooltipShow : false})}}>
                                        <i className="svg-icon icon-Table_edit_1 icon-color-primary"/>
                                    </span>
                                }
                                {
                                    this.state.goToDocumentsTooltipShow &&
                                    <MyTooltip target={this.goToDocumentsRef}
                                               text={"Перейти в реестр"}
                                               show={this.state.goToDocumentsTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm" ref={this.goToDocumentsRef}
                                      onClick={()=>{this.goToDocuments()}}
                                      onMouseEnter={()=>{this.setState({goToDocumentsTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({goToDocumentsTooltipShow : false})}}>
                                    <i style={(isHidePreview && isAuto) ? {fontSize: "2rem"} : {}} className="svg-icon icon-left_window icon-color-primary"/>
                                </span>
                            </div>
                    </div>
                    </div>
                </div>
                <div style={{marginTop: "10px", color: "darkGrey"}}>Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл</div>
                {!isHidePreview &&
                    <>
                        {content}
                        <div className="card-footer">
                            <div className="btn-toolbar-preview card-toolbar">

                                {
                                    this.state.selectFileTooltipShow &&
                                    <MyTooltip target={this.selectFileRef}
                                               text={"Выбрать файл предпросмотра"}
                                               show={this.state.selectFileTooltipShow}
                                               placement={"left"} delay={500}
                                    />
                                }
                                <Dropdown>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle" ref={this.selectFileRef}
                                                     onMouseEnter={()=>{this.setState({selectFileTooltipShow : true})}}
                                                     onMouseLeave={()=>{this.setState({selectFileTooltipShow : false})}}>
                                        <i className="svg-icon icon-Doc_view_12"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item key={createUniqueIdString(3)}
                                                       className={`dropdown-item ${preview.activeView === "original" ? "active" : ""}`}
                                                       onClick={() => this.selectViewActiveFile("original")}>Оригинал
                                        </Dropdown.Item>
                                        <Dropdown.Item key={createUniqueIdString(3)}
                                                       className={`dropdown-item ${preview.activeView === "preview" ? "active" : ""}`}
                                                       onClick={() => this.selectViewActiveFile("preview")}>Предпросмотр
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {
                                    this.state.halfScreenTooltipShow &&
                                    <MyTooltip target={this.halfScreenRef}
                                               text={`Увеличенный предпросмотр`}
                                               show={this.state.halfScreenTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-outline-success btn-circle btn-sm" ref={this.halfScreenRef}
                                      onMouseEnter={()=>{this.setState({halfScreenTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({halfScreenTooltipShow : false})}}
                                      onClick={()=>{this.setPreviewSize("half")}}
                                      style={halfButtonStyle}>
                            <i className="svg-icon icon-Doc_view_2" style={halfButtonIcon}/>
                        </span>

                                {
                                    this.state.fullScreenTooltipShow &&
                                    <MyTooltip target={this.fullScreenRef}
                                               text={"Полноэкранный предпросмотр"}
                                               show={this.state.fullScreenTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-outline-success btn-circle btn-sm" ref={this.fullScreenRef}
                                      onMouseEnter={()=>{this.setState({fullScreenTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({fullScreenTooltipShow : false})}}
                                      onClick={()=>{this.setPreviewSize("full")}}
                                      style={fullButtonStyle}>
                            <i className="svg-icon icon-Doc_view_3" style={fullButtonIcon}/>
                        </span>

                                {
                                    this.state.openInNewTabTooltipShow &&
                                    <MyTooltip target={this.openInNewTabRef}
                                               text={"Открыть в новой вкладке"}
                                               show={this.state.openInNewTabTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <a href={activeLink} ref={this.openInNewTabRef}
                                   onMouseEnter={() => {
                                       this.setState({openInNewTabTooltipShow: true})
                                   }}
                                   onMouseLeave={() => {
                                       this.setState({openInNewTabTooltipShow: false})
                                   }}
                                   target="_blank"
                                   rel="noreferrer"
                                   className="btn btn-icon btn-outline-success btn-circle btn-sm">
                                    <i className="svg-icon icon-Doc_view_5"/>
                                </a>
                            </div>
                        </div>
                    </>

                }


            </>
        );

    }
}


const mapStateToProps = state => {
    return {
        activeDocument: state.packages.tree.activeDocument,
        activeNode: state.packages.tree.activeNode,
        viewModePreview_Size: state.packages.rootState.viewModePreview_Size,
        activeFilialId: state.globalState.filial.Active.Id,
        adminLevel: state.globalState.user.AdminLevel,
        filial: state.globalState.filial.Active,
        tree: state.document.tree,
        cards: state.document.cards,
        verifyDocs: state.document.verifyDocs,
    }
}

const mapDispatchToProps = {
    ActionQueue,
    selectActiveFileForPreviewPackages,
    setPreviewSizePackages,
    verifyDocClientLinkIdsDocs,
    selectActiveDocumentCard,
    setStateLeftAsideMenu,
    changeDocumentViewMode,
    setModalData,
    setFilesContent,
    selectViewActiveFileForPreview,


    updateUserSettings,
    createDocumentCards,
    changeViewMode,
    mainTableDownloadDocCollection,
    toggleFoldersForTree,
    treeSelectNode,
    PackageAddNodesTree,
    combineMainTableAttributes,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    collectDataForAdditionalSections_Normal,

    toggleIsLoading,
    getDocumentsForTree,
    getDocumentGroupsForTree,
    getDocGroupStructureNodes,
    renderStructureTree,
    setLoaderModalData

}

export default connect(mapStateToProps, mapDispatchToProps)(DocPreview);
