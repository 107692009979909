export type API_REQUEST_TYPES = "post" | "get" | "uploadFiles" | "downloadBlob" | "uploadBlobFiles";

export interface API_AUTH_LOGIN_PARAMS {
    armType : string,
    userName : string,
    password : string
}

export interface API_AUTH_LOGIN_RESPONSE {
    CreationTime : string,
    Token : string
}

export interface API_REQUEST_ERROR {
    errorCode : string,
    message? : API_REQUEST_ERROR_MESSAGE,
    info? : object
}

export interface API_REQUEST_ERROR_MESSAGE {
    ExceptionName: string,
    ExceptionTime : string,
    Message : string
}

export interface API_SETTINGS_GLOBAL_PARAMS {
    armType : string
}

export interface API_SETTINGS_USER_PARAMS {
    armType : string
}

export interface API_USER_UPDATE_PASSWORD {
    idUser : number,
    oldPassword : string,
    newPassword : string
}

export interface API_MANY_RECORDS {
    Records : any[],
    RecordsOffset : number,
    TotalRecordsCount : number
}

export interface API_USER_GET_FILIAL extends API_MANY_RECORDS{
    Records : IFilial[]
}

export interface API_SORTED_REQUEST {
    filter : object | null,
    sorting : object | null
}



export interface IUserSettings {
    ArmType : number,
    Id : number,
    Name : string,
    User : IUser,
    Value : any
}

export interface IGlobalSettings {
    ArmType : number,
    Content : string,
    Description? : string | null,
    Id : string
}

export interface IFilial {
    Id : number
    Name: string,
    Description?: string | null,
    Group?: number | null
}

export interface IDepartment {
    Id: number,
    IdParent?: number | null,
    DepNumber: string,
    IdFilial: number,
    Name: string
}

export interface IUser {
    Id : number,
    AccountExpirationTime : string | null,
    AdDomain : string | null,
    AdLogin : string | null,
    AdminLevel : number,
    Department : IDepartment,
    IsActive: boolean,
    IsBoundToDepartments: boolean,
    Login: string,
    Name: string,
    UserMail: string | null,
    UserPhone: string | null
}

export interface IDocAttributeName {
    Id : number,
    Name : string,
    IdAttributeType : number,
    IsSystem : boolean
}

export interface IAttribute {
    AttributeName : IDocAttributeName,
    AttributeValue : string,
    Id? : number
}

export interface IDocTypeAttribute {
    IdDocType : number,
    AttributeName : IDocAttributeName,
    FieldOrder : number,
    FieldSortOrder : number,
    FieldSortType : FieldSortType,
    IsUnique : boolean,
    IsRequired : boolean,
    IsVisibleInRegistry : boolean,
    IsInvisible : boolean
}

export interface API_DOCUMENTS_TYPE_WITH_RIGHTS {
    Records : IDocTypeAttribute[]
}

export interface IGroupTypeRef {
    IdDocGroup : number,
    IdDocType : number
}

export interface IDocExtended {
    Id : number,
    IdFilial : number,
    IsMarkedForDelete : boolean,
    Name : string,
    RegDate : Date | string,
    RegNumber : string,
    ShortDescription : string,
    SortingAttributeValue : string | null,
    Version : IDocVersion,
    Type : IDocType,
}

export interface API_DOCUMENTS_EXTENDED_RESPONSE {
    Records : IDocExtended[]
}

export interface API_DOCUMENTS_GET_BY_VERSION_RESPONSE {
    Records : IDocFileHeader[]
}


export interface API_DOCUMENTS_GET_ALL_VERSIONS_RESPONSE {
    Records : IDocVersion[]
}

export enum DocFileType {
    Main = 1,
    Attachment = 2
}

// TODO электронная подпись файла
export interface IDocFileSignature {
    Id : number, //Id объекта
    IdDocFile : number, //Id файла документа
    SignatureFileName : string, //Имя файла подписи maxLength: 255
    CertificateInfo? : string | null, //Информация, содержащаяся в сертификате ЭП
    IsValid: boolean, //Флаг, указывающий на действительность ЭП
    SignatureFile : IFileHeader, //Заголовок файла
}

export interface API_SIGNATURES_LOAD_RESPONSE extends API_MANY_RECORDS {
    Records : IDocFileSignature[]
}


export interface IDocFile {
    Id : number,
    IdDoc : number,
    IdDocVersion : number,
    Name : string,
    Description? : string | null,
    Type : DocFileType,
    File : IFileHeader,
    Preview : IFileHeader,
    Text : IFileHeader,
    IsCurrent : boolean,
}

export interface API_DOCUMENTS_GET_FILES extends API_MANY_RECORDS {
    Records : IDocFile[]
}

export interface IFileHeader {
    Size : number,
    PageCount? : number,
    PageFormat? : PrintFileFormat,
    Id : number,
}

export interface IDocFileHeader {
    Id : number,
    IdDoc : number,
    AttributeName : IDocAttributeName,
    Description : string | null,
    Name : string,
    PagesCount? : number,
    PagesFormat? : PrintFileFormat,
    Size : number
}

export enum PrintFileFormat {
    A4 = 1,
    A3 = 2,
    A2 = 3,
    A1 = 4,
    A0 = 5,
    A5 = 6,
    A6 = 7
}

export interface IDocVersion {
    IdDoc : number,
    Version : number,
    VersionTime : Date | string,
    ModifyTime : Date | string,
    VersionComment : string,
    IsIndexed : boolean,
    IsCurrent : boolean,
}

export interface IDocType {
    Id: number,
    Name: string,
    IsNumberNotUnique: boolean,
    IsDateNotUnique: boolean,
}

export interface API_DOCUMENTS_GROUPS_WITH_REFS {
    Records : IGroupTypeRef[]
}

export enum FieldSortType{
    None = 0,
    Asc = 1,
    Desc = 2
}

export enum DocFilePart {
    File = 0,
    Preview = 1,
    Text = 2
}

export interface IDocHeader {
    IdFilial : number,
    Type : IDocType,
    RegDate : Date | string,
    RegNumber : string,
    Name : string
}

export interface API_DOCUMENTS_UPDATE_DOC_ATTRIBUTES_PARAMS {
    docHeader : IDocHeader
    docAttributes : IAttribute[]
}

export interface IDocPackage {
    Type : SystemPackageType,
    Name : string,
    IdFilial? : number,
    CreateDate : Date | string,
    ModifyDate : Date | string,
    Closed : boolean
}

export enum SystemPackageType {
    Unknown = 0,
    Upload = 1,
    Filial = 2,
    Shared = 3
}

export interface API_PACKAGES_LOAD_RESPONSE extends API_MANY_RECORDS {
    Records : IDocPackage[]
}

export interface API_PACKAGES_EXTENDED_LOAD_RESPONSE extends API_MANY_RECORDS {
    Records : IDocPackageExt[]
}

export interface API_PACKAGES_DOC_TYPES extends API_MANY_RECORDS {
    Records : IDocType[]
}

export interface IDocPackageExt extends IDocPackage {
    ContainsChilds : boolean,
    ContainsDocs : boolean,
}

export interface IDocInPackage extends IDocExtended{
    IdPackageDocRef : number,
    IsAuto : boolean,
    IsCurrentVersion : boolean
}

export interface API_PACKAGES_DOC_TYPES_RESPONSE extends API_MANY_RECORDS{
    Records : IDocExtended[]
}

export interface API_PACKAGES_DOC_IN_PACKAGES_RESPONSE extends API_MANY_RECORDS{
    Records : IDocInPackage[]
}

export interface IPackageDocRef {
    IdPackage : number,
    IdDoc : number,
    DocVersion : IDocVersion | null,
    IsAuto : boolean,
}

export interface IPackageDocRefUpdate {
    DocVersion?: IDocVersion | null,
    IdPackage: string,
    IdDoc: string,
    Id: string

}

export interface API_PACKAGES_SAVE_OR_UPDATE_COLLECTION_RESPONSE extends API_MANY_RECORDS{
    Records : IPackageDocRef[]
}

export interface ISearchParams {
    FilialIds : number[],
    IsSearchInVersions? : boolean,
    IsSearchInMarkedForDelete? : boolean,
    IsSearchInAttachments? : boolean,
    TypeIds? : number[] | null,
    Name? : string,
    TypeName? : string,
    RegDateAsString? : string,
    RegPeriodStart? : Date | string | null,
    RegPeriodFinish? : Date | string | null,
    RegNumber? : string,
    VersionNumber? : string,
    VersionTimeAsString? : string,
    VersionTimeStart? : Date  | string,
    VersionTimeFinish? : Date | string,
    AttributesText? : string,
    FullText? : string,
    SearchText? : string,
    PagingFilter : IPagingFilter
}

export interface ISearchParamsInFullText {
    searchParams : ISearchParams,
    idDoc : number,
    idVersion : number
}

export interface IPagingFilter {
    PageOffset : number,
    PageSize : number
}

export interface API_SEARCH_BY_PARAMS extends API_MANY_RECORDS {
    Records : IDocSearchResult[]
}


export interface IDocSearchResult {
    Id : number,
    IdDoc : number,
    IsMarkedForDelete : boolean,
    IsMarkedForDeleteAsInt : boolean,
    Name : number,
    Type : IDocType,
    IdType : number,
    TypeName : string,
    IdFilial : number,
    RegDate? : string,
    FormattedRegDateForClient : string,
    RegNumber : string,
    DocVersion : IDocVersion,
    IdVersion : string,
    Version : string,
    FormattedVersionTime? : string,
    FormattedVersionTimeForClient : string,
    IsCurrent : boolean,
    IsPermissionGranted : boolean,
    AttachmentsTexts : DocFileSearchResult[]
    Attributes : IDocAttribute[],
    DepartmentIds : string
}

export interface DocFileSearchResult {
    Id : number,
    Type : DocFileType,
    IdDoc : number,
    IdDocVersion : number,
    OriginalFileHeader : IFileHeader,
    Name : number,
    Description? : number | null,
    IsCurrent : number,
    FullText : string,
    IsContainingFoundText : boolean
}

export interface IDocAttribute {
    IdDoc : number,
    IdDocVersion : number,
    IsCurrent : boolean
}

export interface IDocClientLinkIds {
    IdDoc : number,
    IdFilial : number,
    IdDocType? : number,
    IdDocGroup? : number
}

export interface IStoredReport {
    Name : string,
    Description : string,
    TemplateFileName : string,
    IdTemplateFile? : number,
    NotCreateParamSheet : boolean
}

export interface API_REPORT_STORED_REPORTS  extends API_MANY_RECORDS{
    Records : IStoredReport[]
}

export interface IStoredReportParam {
    IdReport : number,
    TypeCode : ReportParamTypeCode,
    Name : string,
    Description : number,
    IsList : boolean,
    ParamOrder : number
}

export enum ReportParamTypeCode {
    Integer = 1,
    Float = 2,
    String = 3,
    Boolean = 4,
    DateTime = 5
}

export interface API_REPORT_STORED_REPORTS_PARAMS extends API_MANY_RECORDS {
    Records : IStoredReportParam[]
}

export interface IdStoredReportParamWrapper {
    idStoredReport : number,
    storedReportParams : {
        [key : string] : string[]
    }
}

export interface IEntityReport_Request {
    EntityName : string,
    Filter : any,
    ReportColumns : any[],
    ReportName : string,
    Sorting : any
}


export interface IReportStateInfo {
    ErrorMessage : string | null,
    State : IReportState
}

export enum IReportState {
    None = 0,
    Done = 1,
    Runs = 2,
    Error = 3,
    Stop = 4,
}

export interface API_REPORT_DOWNLOAD_REPORT_RESPONSE {
    name : string,
    file : Blob
}
export interface IEntity {
    Id : number,
    Name: string,
    IdParent:number,
    IdFilial:number,
}
export interface ICollection {
    Id : number
}

export interface API_REQUEST_createNewOnePackage {
    CreateDate : string | null,
    IdFilial : number,
    IdParent : number,
    ModifyDate : string | null,
    Name : string,
    Type : number
}

export interface API_RESPONCE_createNewOnePackage {
    CreateDate : string,
    IdFilial : number,
    IdParent : number,
    ModifyDate : string,
    Name : string,
    Type : number,

    Closed : boolean,
    Id : number,
}

export interface API_REQUEST_saveNew {
    comment : string,
    docAttributes : IAttribute[],
    docHeader : IDocHeader,
    fileInfoList : {
        Size : number,
        PageCount? : number,
        PageFormat? : PrintFileFormat,
        Name : string,
        Description : string
    }[]
}

export interface API_RESPONSE_saveNewComplete {
    Id: number,
    IdDoc: number,
    IsCurrent: boolean,
    ModifyTime: string,
    Version: number,
    VersionComment: string,
    VersionTime: string
}

export interface API_RESPONSE_getUploadFilesProgress {
    IsActive : boolean,
    UploadedBytes : number,
    WholeBytes : number,
}

export enum DataTypesEnum {
    Integer = 1,
    Float = 2,
    String = 3,
    Boolean = 4,
    DataTime = 5,
    RowBlob = 6,
    SearchBlob = 7,
    DocRef = 8,
}
