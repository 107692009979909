import {__} from "../../../tools/HelpFunctions";
import {store} from "../../../index";
import {apiUrl} from "../../../tools/API/apiUrl";
import {apiRequest, apiRequestTest2} from "../../../tools/API/apiRequest";
import {REPORT_STATE} from "../reportActionsList";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {MyXLSX} from "../../../tools/XLSX/MyXLSX";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../globalState/actionCreators/globalState_AppActionCreator";


 function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export const sendReport = ({Next = null, Error = null}) =>{

    return async (dispatch) => {
        try {


            let reportValue = __.deepCopy(store.getState().report.leftMenu.params);

            // let idStoredReport=reportValue[0].IdReport;

            let idStoredReport = store.getState().report.leftMenu.activeRow.Id;

            let storedReportParams={};
           for (let i=0; i<reportValue.length;i++) {
               if(reportValue[i].TypeCode==5){
                   if(reportValue[i]?.value && reportValue[i].value!="") {
                       reportValue[i].value=reportValue[i].value.toString() + "T00:00:00";
                   }
               }
               if(reportValue[i].IsList === true){
                   for (let j=0; j<reportValue[i].value.length;j++) {
                       if(j===0){
                           storedReportParams[reportValue[i].Name]=([reportValue[i].value[j].value])
                       }else{
                           storedReportParams[reportValue[i].Name].push(reportValue[i].value[j].value)
                       }
                   }
               }else{
                   if(reportValue[i]?.value && reportValue[i].value!="") {
                       storedReportParams[reportValue[i].Name]=[reportValue[i].value]
                   }
               }
           }

            let request = new apiRequest(apiUrl.REPORTS.CREATE_QUERY_REPORT, {
                idStoredReport,
                storedReportParams
            });

            request.execute(function(data) {


                dispatch({type : REPORT_STATE.GET_UID, payload : data});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                else if(Error) {Error.action(Error.params);} // если ошибка колбек
                console.log("Ошибка получения списка параметров (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка сохранения параметров  (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }

}

export const getState = ({Next = null, Error = null})=>{
    return async (dispatch) => {
        try {
            let reportGuid = __.deepCopy(store.getState().report.leftMenu.reportGuid);

            let request = new apiRequest(apiUrl.REPORTS.GET_REPORT_STATE, {
                reportGuid
            });

            request.execute(async function (data) {


                dispatch({type: REPORT_STATE.GET_STATE, payload: data});
                if (data.State === 2) {
                    await sleep(5000);
                    dispatch(getState({Next: Next, Error: Error}));
                } else if (data.State === 1) {
                    if (Next) {
                        Next.action(Next.params);
                    }// следующее действие если есть цепочка
                } else {
                    dispatch(setLoaderModalData({keyDeleted: "report_send107"}))
                    dispatch(setModalData({
                            name : ModalTypes.app.info,
                            data : {content : `Ошибка формирования отчета <br /> ${data.ErrorMessage ?? ""}`, type : "fail"}
                        }));

                }
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                else   if(Error) {Error.action(Error.params);} // если ошибка колбек
                console.log("Ошибка получения списка параметров (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка сохранения параметров  (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const downloadReport = ({Next = null, Error = null})=>{
    return async (dispatch) => {
        try {
            // let reportValue = __.deepCopy(store.getState().report.leftMenu.params);
            //let idStoredReport=reportValue[0].IdReport;

            let idStoredReport = store.getState().report.leftMenu.activeRow.Id;

            let reportGuid = __.deepCopy(store.getState().report.leftMenu.reportGuid);


            let request = new apiRequestTest2(apiUrl.REPORTS.DOWNLOAD_REPORT, {}, "", reportGuid);

            // let actionUrl = apiUrl.REPORTS.DOWNLOAD_REPORT.url + reportGuid;


            request.execute(function(data, status, head) {

                if(idStoredReport == 11) {
                   new MyXLSX(data);
               }


                // let contentType = head.getResponseHeader("Content-Type");
                let ContentDisposition = head.getResponseHeader("Content-Disposition");
                let filename = "";

                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(ContentDisposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');

       //          var file = new File([`${data}`], {type : contentType});
                //
                //
                //
                // // let file = new Blob(data, "text/xml");
                if(idStoredReport != 11) {
                    var link = document.createElement("a");
                    link.setAttribute("href", URL.createObjectURL(data));
                    link.setAttribute("download", filename);
                    link.click();
                }



                //dispatch({type : REPORT_STATE.GET_STATE, payload : data});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                else   if(Error) {Error.action(Error.params);} // если ошибка колбек
                console.log("Ошибка получения списка параметров (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка сохранения параметров  (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
