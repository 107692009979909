import React from "react";
import {connect} from "react-redux";
import TrMainTable from "./TrMainTable";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {selectActiveRow} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {ActionQueue} from "../../store/rootReducer";



class Tbody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            curRef: null
        };
        this.setRef = this.setRef.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot){

        if (this.state.curRef)
            if (this.state.curRef.current)
               this.state.curRef.current.focus();
    }

    setRef = (lRef) => {
        //проверка от зацикливания
        if (lRef)
            if (this.state.curRef != lRef)
                this.setState({curRef: lRef})
    }

    selectDocument(event) {
        event.persist();
        let nextActiveRow=null;
        for (let i = 0; i < this.props.body.length; i++) {
            if(this.props.body[i].Info.Id == this.props.activeRow?.Info.Id){
                if(event.keyCode == 40) nextActiveRow = this.props.body[i+1]
                else if(event.keyCode == 38) nextActiveRow = this.props.body[i-1]
            }
        }
        if(nextActiveRow){
            this.props.ActionQueue({
                List : [
                    {action : this.props.selectActiveRow, params : {RowInfo : nextActiveRow}, name : "selectActiveRow"},
                    {action : this.props.resetDocumentPreview, params : {}, name : "resetDocumentPreview"},
                    {action : this.props.createPreviewBySelectDocument, params : {}, name : "createPreviewBySelectDocument"},
                    {action : this.props.collectDataForAdditionalSections_Normal, params : {}, name : "collectDataForAdditionalSections_Normal"},
                ],
                debug : false,
            });
            if(this.props.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
                this.props.changeDocumentViewMode({ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal});
            }
        }
    }

    render() {
        if(!this.props.body || this.props.body.length <= 0) {
            return  null;
        }

        return (
            <tbody  onKeyDown={(event) => {
                 this.selectDocument(event)
            }}>
            {
                this.props.body.map((item, index) => {
                    return (
                        <TrMainTable key={item.Version.IdDoc} data={item} lIndex={index}
                            // Получаем от дочки только сам реф
                                     setRef={(Item) => {
                                         this.setRef(Item)
                                     }}
                        />
                    );
                })
            }
            </tbody>
        );
    }
}


const  mapStateToProps = state => {
    return {
        body : state.document.mainTable.body,
        activeRow: state.document.mainTable.activeRow,
        viewMode : state.document.rootState.viewMode,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    collectDataForAdditionalSections_Normal,
    changeDocumentViewMode
}

export default connect(mapStateToProps,mapDispatchToProps)(Tbody);
