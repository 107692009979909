import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";


// modal.data.content => обязательно
class AlertModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : false,
        };

        this.modalName = ModalTypes.app.alert;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
    }

    // скрывать по кнопке
    hide() {
        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }

    render() {

        let modalStyle = {
            display : "block",
            backgroundColor: "var(--blackout-light)", //#061c3e66, вернуть старое значение если текущее не понравятся
            zIndex : "99999"
        };

        if(this.props.modal.data.fullBackground) {
            modalStyle.backgroundColor = "var(--blackout)"; //rgba(1,4,35,0.98), вернуть старое значение если текущее не понравятся
        }

        let mod = this.props.theme.modString;
        let imageUrl = "";
        if(this.props.modal.data.gif) {
            if(this.props.modal.data.gif === "documents") imageUrl = `doc${mod}.gif`;
            else if(this.props.modal.data.gif === "packages") imageUrl = `pac${mod}.gif`;
            else if(this.props.modal.data.gif === "search") imageUrl = `search${mod}.gif`;
        }
        // switch (themeConfig) {
        //     case "red_theme":
        //         imageUrl += "red.gif"
        //         break;
        //     default :
        //         imageUrl += ".gif"
        //         break;
        // }
        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                     onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                     onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                    <div className="modal-content">
                        <div className="modal-header" style={{borderBottom:"none",justifyContent:"center"}}>
                            <h5 className="modal-title" id="exampleModalLabel"
                                style={{marginRight : "15px"}}>{parse(sanitizeHtml(this.props.modal.data.content))}</h5>
                            {
                                this.props.modal.data.gif &&
                                <img width={50} src={`/images/${imageUrl}`} alt="gif"/>
                            }
                        </div>
                        {
                            !this.props.modal.data.disableButton &&
                            <div className="modal-footer"
                                 style={{borderTop:"none", justifyContent:"center", paddingTop:"0.2em"}}>
                                <button type="button" className="btn btn-primary font-weight-bold" onClick={this.hide}>Окей!</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        modal : state.globalState.app.modal,
        theme: state.globalState.theme
    }
}

const  mapDispatchToProps = {
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
