import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";

import {store} from "../../../index";
import {PACKAGE_SEARCH, PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {PackagesDocument} from "../../../tools/classes/packages/PackagesDocument";
import {__} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {Operators as FilerOperatorsEnum} from "../../../tools/StaticTypes";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";




//TODO aa Задача № 20065 БАГ. Фильтры в Пакетах при открытой доп таблице TableRole

export const updateInputsFieldPackage = ({IdFilial=1,
                                             TableRole = null,
                                             SearchText = null,
                                             IsSearch = false,
                                             AttributesText = null,
                                             FullText = null,
                                             TypeIds = null,
                                             RegDateAsString = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             RegPeriodStart = null,
                                             RegPeriodFinish = null,
                                             Name = {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                             RegNumber = {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                             TypeName = {In: ''},
                                             ModifyDate = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             AppliedParams = {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,},
                                             IsSearchInAttachments = false,
                                             IsSearchInVersions = false,
                                             IsSearchInMarkedForDelete = false,
                                             Next = null,
                                             Error = null}) =>{
    //if(RegDateAsString!==null)RegDateAsString=RegDateAsString.toString().split("T")[0] + "T00:00:00";

    //TODO Задача № 20186

    // 2. В разделе Документы фильтр по данным полям в другом формате - текстовый, что позволяет вводить дату формата 05.2022 для отбора документов за май 2022. Необходимо использовать единый формат фильтров в системе.

    // if(RegPeriodStart!==null && RegPeriodStart !== "")RegPeriodStart=RegPeriodStart.toString().split("T")[0] + "T00:00:00";
    // if(RegPeriodFinish!==null && RegPeriodFinish !== "")RegPeriodFinish=RegPeriodFinish.toString().split("T")[0] + "T00:00:00";

    // if(RegPeriodStart!==null && RegPeriodStart !== "")RegPeriodStart=RegPeriodStart;
    // if(RegPeriodFinish!==null && RegPeriodStart !== "")RegPeriodFinish=RegPeriodFinish;

    //TODO в корректом запросе формат "dd.mm.yyyy" Фильтры в Пакетах. Не работает по "Последнему изменению" Задача № 20069
    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate.toString().split("T")[0] + "T00:00:00";
    //TODO Задача № 20069 3. Формат введения - дата. В документах данное поле имеет текстовый формат ввода, т.к. используется фильтрация формата 05.2022 для фильтрации документов за май 2022, данная практика используется по аналогии со старым архивом
    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate.toString().split("T")[0];

    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate;
    // if(RegDateAsString !== null && RegDateAsString !== "") RegDateAsString = RegDateAsString;

//TODO Делал фильтр "С" "По"
   /// if(TypeIds.length==0)TypeIds=null;
    return async (dispatch) => {
        try {
            if (typeof RegDateAsString === "string") {
                const date = RegDateAsString
                RegDateAsString = {ApproximatelyEqual: date, OnDate: '', DateStart: '', DateEnd: ''}
            }
            if (typeof Name === "string") {
                const name = Name
                Name = {ApproximatelyEqual: name, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof RegNumber === "string") {
                const number = RegNumber
                RegNumber = {ApproximatelyEqual: number, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof TypeName === "string") {
                const typeName = TypeName
                TypeName = {ApproximatelyEqual: typeName, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof ModifyDate === "string") {
                const modDate = ModifyDate
                ModifyDate = {ApproximatelyEqual: modDate, OnDate: '', DateStart: '', DateEnd: ''}
            }
            dispatch({
                type : PACKAGE_SEARCH.UPDATE_INPUTS_FIELD,
                payload : {
                    TableRole,
                    IdFilial,
                    IsSearch,
                    SearchText,
                    AttributesText,
                    FullText,
                    IsSearchInAttachments,
                    IsSearchInVersions,
                    IsSearchInMarkedForDelete,
                    TypeIds,
                    RegDateAsString,
                    RegPeriodStart,
                    RegPeriodFinish,
                    Name,
                    RegNumber,
                    TypeName,
                    ModifyDate,
                    AppliedParams,
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров поиска (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const updateInputsFieldSortingPackage = ({
                                                    TableRole = null,
                                                    SortingParam = null,
                                                    Direction = 0,
                                                    Next = null,
                                                    Error = null}) =>{
    return async (dispatch) => {
        try {
            let SortedParams = {RegDate: 0, Name: 0, RegNumber: 0, TypeName: 0, ModifyDate: 0,}
            if (SortingParam) {
                SortedParams[SortingParam] = Direction
            }
            dispatch({
                type : PACKAGE_SEARCH.UPDATE_INPUTS_FIELD_SORTING,
                payload : {
                    TableRole,
                    SortedParams
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров поиска (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const searchByParamsPackages = ({TableType = "main",
                                           FieldSorting = "",
                                           Direction = 0,
                                           IsClear = false,
                                           Next = null,
                                           Error = null}) => {
    return async dispatch => {
        try {
            //TODO aa 24.03.2023 ??????? зачем из mainTable? переопределил снизу
            let PageSize = store.getState().search.mainTable.paginationPageSize;
            let PageNumber = store.getState().search.mainTable.paginationPageNumber;
            let Filial = store.getState().globalState.filial.Active;
            let Inputs = store.getState().packages.search.searchInputs;
            let IdPackage
            if(TableType === "main"){
                 IdPackage = store.getState().packages.tree.activeNode.Node.Id;
                 PageSize = store.getState().packages.tree.paginationPageSize;
                 PageNumber = store.getState().packages.tree.paginationPageNumber;
            }else if(TableType === "additional"){
                IdPackage = store.getState().packages.treeAdditional.activeNode.Node.Id;
                PageSize = store.getState().packages.treeAdditional.paginationPageSize;
                PageNumber = store.getState().packages.treeAdditional.paginationPageNumber;
            }else if(TableType === "download"){
                IdPackage = store.getState().packages.treeDownload.activeNode.Node.Id;
                PageSize = store.getState().packages.treeDownload.paginationPageSize;
                PageNumber = store.getState().packages.treeDownload.paginationPageNumber;
            }

            ///let TableType = TableType;
            let filter = new Filter(FiltersType.ENTITY)
            if(!IsClear) {
                filter.add(
                    new EntityFilters().setEntityName("DocInPackage")
                        .add({Name: "IdFilial", Value: Filial.Id})
                        .get()
                )
                if (Inputs.Name !== "" && Inputs.Name !== null) {
                    if (typeof Inputs.Name === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.String,
                            Value: Inputs.Name,
                            Name: "Name",
                        }, true);
                        filter.add(entity.get())
                    } else {
                        filter.add(
                            new EntityFilters().setEntityName("DocInPackage")
                                .add({Name: "Name", Value: Inputs.Name, Operator: FilerOperatorsEnum.Like})
                                .get()
                        )
                    }
                }

                if (Inputs.RegNumber !== "" && Inputs.RegNumber !== null) {
                    if (typeof Inputs.RegNumber === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.String,
                            Value: Inputs.RegNumber,
                            Name: "RegNumber",
                        }, true);
                        filter.add(entity.get())
                    } else {
                        filter.add(
                            new EntityFilters().setEntityName("DocInPackage")
                                .add({Name: "RegNumber", Value: Inputs.RegNumber, Operator: FilerOperatorsEnum.Like})
                                .get()
                        )
                    }
                }
                // if (Inputs.RegPeriodStart !== "" && Inputs.RegPeriodStart !== null) {
                //     if (typeof Inputs.RegPeriodStart === "object") {
                //         let entity
                //         entity = __.setEntityFilters({
                //             IdAttributeType: DataTypesEnum.DataTime,
                //             Value: Inputs.RegPeriodStart,
                //             Name: "Name",
                //         }, true);
                //         filter.add(entity.get())
                //     } else {
                //     filter.add(new EntityFilters().setEntityName("DocInPackage")
                //         .add({
                //             Name: "RegDate",
                //             Value: Inputs.RegPeriodStart,
                //             Operator: FilerOperatorsEnum.GreaterOrEqual
                //         })
                //         .get()
                //     )
                // }
                // if (Inputs.RegPeriodFinish !== "" && Inputs.RegPeriodFinish !== null) {
                //     filter.add(new EntityFilters().setEntityName("DocInPackage")
                //         .add({Name: "RegDate", Value: Inputs.RegPeriodFinish, Operator: FilerOperatorsEnum.LessOrEqual})
                //         .get()
                //     )
                // }
                if (Inputs.TypeName !== "" && Inputs.TypeName !== null) {
                    if (typeof Inputs.TypeName === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.String,
                            Value: Inputs.TypeName,
                            Name: "Type.Name",
                        }, true);
                        filter.add(entity.get())
                    } else {
                        filter.add(new EntityFilters().setEntityName("DocInPackage")
                            .add({Name: "Type.Name", Value: Inputs.TypeName, Operator: FilerOperatorsEnum.Like})
                            .get()
                        )
                    }
                }
                if (Inputs.ModifyDate !== "" && Inputs.ModifyDate !== null) {
                    if (typeof Inputs.ModifyDate === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.DataTime,
                            Value: Inputs.ModifyDate,
                            Name: "Version.ModifyTime",
                        }, true);
                        filter.add(entity.get())
                    } else {
                        filter.add(new EntityFilters().setEntityName("DocInPackage")
                            .add({
                                Name: "Version.ModifyTime",
                                Value: Inputs.ModifyDate,
                                Operator: FilerOperatorsEnum.Like
                            })
                            .get()
                        )
                    }
                }
                if (Inputs.RegDateAsString !== "" && Inputs.RegDateAsString !== null) {
                    if (typeof Inputs.RegDateAsString === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.DataTime,
                            Value: Inputs.RegDateAsString,
                            Name: "RegDate",
                        }, true);
                        filter.add(entity.get())
                    } else {
                        filter.add(new EntityFilters().setEntityName("DocInPackage")
                            .add({Name: "RegDate", Value: Inputs.RegDateAsString, Operator: FilerOperatorsEnum.Like})
                            .get()
                        )
                    }
                }
            }
            // if(Inputs.RegDateAsString!=="") {
            //     filter.add(
            //         new EntityFilters().setEntityName("DocInPackage")
            //             .add({Name: "RegDate", Value: Inputs.RegDateAsString, Operator: 2})
            //             .get()
            //     )
            // }
            filter.addAttribute({Name:"IdPackage",Value:IdPackage})
            filter.addAttribute({Name: "PagingFilter", Value: {PageSize : PageSize, PageOffset : (PageNumber - 1) * PageSize}})

            let sorting = new Sorting();
            // sorting.addAttribute({Name: "IdAttributeName", Value: "-1"})

            if(FieldSorting.Name=="Name" || FieldSorting === "Name"){
                sorting.add({PropertyName: "Name", Direction})
            }else if(FieldSorting.Name=="RegDate"|| FieldSorting === "RegDate"){
                sorting.add({PropertyName: "RegDate", Direction})
            }
            else if(FieldSorting.Name=="Version.ModifyTime"|| FieldSorting === "ModifyDate"){
                sorting.add({PropertyName: "Version.ModifyTime", Direction})
            }
            else if(FieldSorting.Name=="TypeName"|| FieldSorting.Value==="Тип"){
                sorting.add({PropertyName: "Type.Name", Direction})
            }
            else if(FieldSorting.Name=="RegNumber"|| FieldSorting === "RegNumber"){
                sorting.add({PropertyName: "RegNumber", Direction})
            } else {
                sorting
                    .add({Direction: 2,PropertyName : "RegDate"})
                    .add({Direction: 1,PropertyName : "RegNumber"})
                    .add({Direction: 1,PropertyName : "Id"})
            }


            filter = filter.get();
            sorting = sorting.get();
            let request = new apiRequest(apiUrl.PACKAGE.DOC_IN_PAC, {
                filter,
                sorting
            });

            request.execute(function(data) {

                let result =  data;

                let documents = [];
                if(result.Records.length > 0) {
                    for(let i = 0; i < result.Records.length; i++) {
                        documents.push(new PackagesDocument(result.Records[i]));
                    }
                }

                dispatch({type : PACKAGES_TREE_ACTIONS.GET_DOC_IN_PAC_SEARCH, payload : {
                        documents,
                        totalRecordsCount : result.TotalRecordsCount,
                        tableType : TableType
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const handlerSearchPackages = ({TableType = "main",Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let activeNode;
            let newChildren;
            if(TableType == "main"){
                 activeNode = __.deepCopy(store.getState().packages.tree.activeNode);
                 newChildren= store.getState().packages.temporaryData.documents;
            }else if(TableType == "additional"){
                activeNode = __.deepCopy(store.getState().packages.treeAdditional.activeNode);
                newChildren= store.getState().packages.temporaryData.documents;
            }
            else if(TableType == "download"){
                activeNode = __.deepCopy(store.getState().packages.treeDownload.activeNode);
                newChildren= store.getState().packages.temporaryData.documents;
            }

             activeNode.Children = activeNode.Children.filter(item => item.Type === "PackagesFolder");

            for(let i = 0; i < newChildren.length; i++) {
                let TreeId=createUniqueIdString(6);
                let Type="PackagesDocument";
                let Children=[];
                activeNode.Children.push(
                    {
                        TreeId,
                        Type,
                        Children,
                        Node: newChildren[i]
                    }
                )
            }

                dispatch({type : PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload : {
                        searchNode : activeNode,
                        tableType : TableType
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const handlerIsSearch = ({TableType = "main", IsSearch = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TREE_ACTIONS.IS_SEARCH, payload : {
                    tableType : TableType,
                    IsSearch
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


