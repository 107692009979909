import React from "react";
import {connect} from "react-redux";
import MainTableSearchPackage from "./MainTableSearchPackage";

import {
    breadcrumbsAdd, breadcrumbsMoveSelect, breadcrumbsSetPage,
    PackageAddNodesTree, SelectActivePackageDocument,
    SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import PackagesTableItem from "../../mainTablePackages/PackagesTableItem";
import {
    ModalTypes,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes, TableTypesEnum,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import Pagination from "../pagination/Pagination";
import stylePagination from "../../../media/styles/pagination.module.css";
import {
    changePaginationPageInPackages,
    PackagesPaginationMovePage, setViewModePreviewPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import PaginationCounterPackages from "./PaginationCounterPackages";
import * as ReactDnD from 'react-drag-and-drop';
import {movingDocumentsInPackageFromPackages} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import TheadPackage from "./TheadPackage";
import TbodyPackage from "./TbodyPackage";
import {history, store} from "../../../index";
import {Routing} from "../../../tools/routing/Routing";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {setDefaultWidthOfColumn} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    handlerSearchPackages,
    searchByParamsPackages
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {
    checkAllRowsOnPage_MainTable,
    checkAllRowsOnPage_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {
    collectDataForAdditionalSections_Normal
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";


class MainTablePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lData: [],
            lHeader: [],
            prevCells: [],
            completeSlice: [],
            completeSliceAdd: []
        };
        this.tableWidthRef = React.createRef();
        //TODO Чтобы ничего не перерендеривалось
        this.memoRef = React.createRef();

        this.selectFolder = this.selectFolder.bind(this);
        this.view_bigCards = this.view_bigCards.bind(this);
        this.view_middleCards = this.view_middleCards.bind(this);
        this.view_table = this.view_table.bind(this);
        this.view_render = this.view_render.bind(this);
        this.changePaginationNumber = this.changePaginationNumber.bind(this);
        this.documentOnDrop = this.documentOnDrop.bind(this);
        this.fitInContainerSize = this.fitInContainerSize.bind(this);
        this.unFitInContainerSize = this.unFitInContainerSize.bind(this);
        this.setPageInBreadcrumbs = this.setPageInBreadcrumbs.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    setWidth(itemSize, lItem) {
        let Cells
        if (this.state.prevCells.length == 0) {
            this.setState({prevCells: __.deepCopy(this.state.lHeader)});
            Cells = __.deepCopy( this.state.lHeader);
        } else {
             Cells = __.deepCopy( this.state.prevCells);
        }

        let lObjCell =  (Cells.find(item => item.Value === lItem.Value))
        if (lObjCell)
            lObjCell.Size = itemSize;
        //TODO использован реф для того, чтобы не перерисовывались дочки, т.к. если делать через стейт, то дочки перерисовываются, это и задержки и ошибки при ресайзе.
        // this.setState({prevCells: Cells});
        this.memoRef = Cells;
    }

    unFitInContainerSize() {
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
        }
    }

    fitInContainerSize() {
        let TableWidth = this.tableWidthRef?.current?.offsetWidth - 100;
        let Cells = __.deepCopy(this.state.lHeader);
        if (this.memoRef && this.memoRef.length)
            this.setState({prevCells: this.memoRef})
        else
            this.setState({prevCells: Cells});
        let RowWidth = 0;
        if (Cells && TableWidth) {
            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
               RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
            }

            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) {
                    let size = (TableWidth / 100) * ((Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70) / RowWidth * 100);
                    Cells[i].Size = size < 100 ? 100 : size;
                // }
            }
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
         }
    }

    documentOnDrop(item) {

        if( this.props.adminLevel < 1) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка наполнения. <br/> Недостаточно прав для выполнения действия`}
            });
            return;
        }


        if(item.document) {
            const doc = JSON.parse(item.document);
            if(doc.IsMarkedForDelete) {
                this.props.setModalData({
                    name : ModalTypes.app.info,
                    data : {content : `Ошибка переноса документа: <br/>Документ помечен на удаление`, type : "fail"}
                });
                return;
            }

            this.props.movingDocumentsInPackageFromPackages({
                IdDoc : doc.IdDoc,
                IdPackage : this.props.activeNode.Node.Id,

                Next : {
                    action : () => {
                        this.props.PackageAddNodesTree({
                            Id : this.props.activeNode.Node.Id,
                            TableType : "main",
                            Next : {
                                action : () => {
                                    this.props.SelectActivePackageNode({
                                        Id : this.props.activeNode.Node.Id,
                                        TableType : "main",
                                    });

                                    this.props.breadcrumbsAdd({
                                        NodeId : this.props.activeNode.Node.Id,
                                        Name : this.props.activeNode.Node.Name,
                                        ParentId : this.props.activeNode.Node.IdParent,
                                        TableType : "main",
                                    });
                                },
                                params : {}
                            }
                        });
                    },
                    params : {}
                }
            });
        }
        else if(item.documents) {
            let documents = JSON.parse(item.documents);
            for (let i = 0; i < documents.length; i++) {
                this.props.movingDocumentsInPackageFromPackages({
                    IdDoc : documents[i],
                    IdPackage : this.props.activeNode.Node.Id,
                    Next : {
                        action : () => {
                            this.props.PackageAddNodesTree({
                                Id : this.props.activeNode.Node.Id,
                                TableType : "main",
                                Next : {
                                    action : () => {
                                        this.props.SelectActivePackageNode({
                                            Id : this.props.activeNode.Node.Id,
                                            TableType : "main",
                                        });

                                        this.props.breadcrumbsAdd({
                                            NodeId : this.props.activeNode.Node.Id,
                                            Name : this.props.activeNode.Node.Name,
                                            ParentId : this.props.activeNode.Node.IdParent,
                                            TableType : "main",
                                        });
                                    },
                                    params : {}
                                }
                            });
                        },
                        params : {}
                    }
                });
            }
        }


    }

    selectFolder(item,role) {
        let IsDownload = this.props.tableRole === "download";
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : false, gif : "packages", key: "MainTablePackage217"}
        }))
        if(item.Type === "PackagesFolder") {
            this.props.PackageAddNodesTree({
                Id : item.Node.Id,
                TableType : this.props.tableRole,
                IsDownload,
                Next : {
                    action : () => {
                        this.props.SelectActivePackageNode({
                            Id : item.Node.Id,
                            TableType : this.props.tableRole,
                            IsDownload
                        });
                        this.props.togglePackagesFolders({
                            IdFolder : item.Node.Id,
                            TableType : this.props.tableRole,
                            IsDownload,
                        });

                        this.props.breadcrumbsAdd({
                            NodeId : item.Node.Id,
                            Name : item.Node.Name,
                            ParentId : item.Node.IdParent,
                            TableType : this.props.tableRole,
                            IsDownload
                        });
                        this.props.breadcrumbsMoveSelect({
                            NodeId: item.Node.Id,
                            TableType: this.props.tableRole,
                            IsDownload
                        });

                        if(this.props.tableRole=="main") {
                            if (this.props.viewModePreview=="none" || this.props.viewModePreview=="normal") {

                                this.props.setViewModePreviewPackages({
                                    ViewMode: "none",
                                });
                            } else {
                                this.props.setViewModePreviewPackages({
                                    ViewMode: this.props.viewModePreview,
                                });
                            }
                        }else if(this.props.tableRole == "download"){
                            if (this.props.viewModePreview == "two_table_download") {
                                this.props.setViewModePreviewPackages({
                                    ViewMode: "two_table_download",
                                });
                            } else {
                                this.props.setViewModePreviewPackages({
                                    ViewMode: "none",
                                });
                            }
                        }
                            this.props.SelectActivePackageDocument({TableType: this.props.tableRole});
                        // }


                        let query = this.props.router.location.query;
                        let queryLink = "";
                        if(this.props.tableRole === "main") {
                           queryLink = Routing.CreateRef({
                              oldQuery : query,
                              newQuery : {p : item.Node.Id},
                              excludedKeys : ["d"]
                           });
                        }
                        else if(this.props.tableRole === "additional") {
                            queryLink = Routing.CreateRef({
                                oldQuery : query,
                                newQuery : {p2 : item.Node.Id},
                                excludedKeys : ["d2"]
                            });
                        }
                        else if(this.props.tableRole === "download") {
                            queryLink = Routing.CreateRef({
                                oldQuery : query,
                                newQuery : {dwp : item.Node.Id},
                                excludedKeys : ["dwd"]
                            });
                        }

                        let link = `${this.props.router.location.pathname}${queryLink}`;
                        // store.dispatch(setModalData({}));
                        //Новый прелоадер
                        store.dispatch(setLoaderModalData({keyDeleted: "MainTablePackage298"}));
                        history.push(link);
                    },
                    params : {}
                }
            });
        }
        else  if(item.Type === "PackagesDocument") {
            this.props.SelectActivePackageDocument({
                Id : item.Node.Id,
                TableType : this.props.tableRole,
                IsDownload,
                Next : {
                    action : ()=> {
                        let activeNode;
                        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
                        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
                        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;


                        let activeDocument;
                        if(this.props.tableRole === "main")  activeDocument = this.props.activeDocument;
                        else if(this.props.tableRole === "additional")  activeDocument = this.props.activeDocumentAdditional;
                        else if(this.props.tableRole === "download")  activeDocument = this.props.activeDocumentDownload;

                        this.props.collectDataForAdditionalSections_Normal({TableRole: this.props.tableRole})

                        let roleTable = role ?? this.props.tableRole;

                        if(roleTable !== "download") {
                            // this.props.setModalData({});
                            //Новый прелоадер
                            this.props.setLoaderModalData({keyDeleted: "MainTablePackage326"});
                            this.props.updateUserSettings({
                                Name: UserSettingsNames.PACKAGE_CONFIG,
                                Action: UserSettingsActionsTypes.Update,
                                Data: {
                                    Id: activeNode.Node.Id,
                                    Name: activeNode.Node.Name
                                }
                            })
                        }else{
                            // this.props.setModalData({});
                            //Новый прелоадер
                            this.props.setLoaderModalData({keyDeleted: "MainTablePackage338"});
                        }

                    },
                    params : {}
                }
            });
            if(this.props.tableRole === "main") {
                if(this.props.viewModePreview === "two_table_none") {

                }
                else if(this.props.viewModePreview === "none") {
                    this.props.setViewModePreviewPackages({
                        ViewMode : "normal"
                    });
                }
            }





            // let link = `${this.props.router.location.pathname}?p=${this.props.activeNode.Node.Id}&d=${item.Node.Id}`;
            // history.push(link);

            let query = this.props.router.location.query;
            let queryLink = "";

            if(this.props.tableRole === "main") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {d : item.Node.Id},
                    excludedKeys : []
                });
            }
            else if(this.props.tableRole === "additional") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {d2 : item.Node.Id},
                    excludedKeys : []
                });
            }
            else if(this.props.tableRole === "download") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {dwd : item.Node.Id},
                    excludedKeys : []
                });
            }

            let link = `${this.props.router.location.pathname}${queryLink}`;
            history.push(link);

        }
    }

    view_render(isReady, viewFilter) {
        let viewMode;
        if(this.props.tableRole === "main") viewMode = this.props.viewModeMainTable;
        else if(this.props.tableRole === "additional") viewMode = this.props.viewModeAdditionalTable;
        else if(this.props.tableRole === "download") viewMode = this.props.viewModeDownloadTable;


        if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_table) {
            return this.view_table(isReady, viewFilter);
        }
        else if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards) {
            return this.view_middleCards(isReady, viewFilter);
        }
        else if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards) {
            return this.view_bigCards(isReady, viewFilter);
        }

        else return null;
    }

    view_bigCards(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        let completeSlice;
        if (!activeNode) {
            return null;
        }
        if(activeNode.Children?.length <= pagination.paginationPageSize && pagination.paginationPageNumber === 1) {
            completeSlice = activeNode.Children;
        }
        else {

            // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
            // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
            // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);


            //TODO aa новый алгоритм пагинации
            //убираем лишние пакеты
            const lCountPack = activeNode?.Children.map(e => e.Type).indexOf('PackagesDocument');

            if (viewFilter === PackagesViewFilters.OnlyDocuments)
            {
                completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument");
            }
                else
            if (lCountPack != -1 && pagination.paginationPageNumber != 1)
            {
                completeSlice = __.dropArrayLeft(activeNode?.Children, activeNode?.Children.length - pagination.paginationPageSize);
                const lPageNumForFolder = (lCountPack) / pagination.paginationPageSize >= 1
                    ? Math.round((lCountPack) / pagination.paginationPageSize) + Math.floor((lCountPack) % pagination.paginationPageSize)
                    : 0;
                if (lPageNumForFolder < pagination.paginationPageNumber)
                    completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument")
                else if(lPageNumForFolder == pagination.paginationPageNumber)
                    completeSlice = ((lCountPack % pagination.paginationPageSize)>0?__.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                        activeNode.Children?.filter(item => item.Type =="PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))
            }
            else
            {
                let leftSlice = __.dropArrayLeft(activeNode?.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
            }

        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
            this.setState({completeSliceAdd: completeSlice})
        }

        if(isReady) {
          //  let i = 0;
            return (
                completeSlice.map((item, index)=> {
                    // i++;
                    // if(i > this.props.treeAdditional.paginationPageNumber * this.props.tree.paginationPageSize) {
                    //     return null;
                    // }
                    // else {
                    //     if(index < (this.props.treeAdditional.paginationPageNumber - 1) * this.props.tree.paginationPageSize) {
                    //         return null;
                    //     }
                    // }



                    if(viewFilter === PackagesViewFilters.All) {
                        return (
                            <PackagesTableItem key={item.TreeId} item={item}
                                               viewMode={"bigCards"}
                                               tableRole={this.props.tableRole}
                                               index={index}
                                               selectFolder={this.selectFolder}/>
                        );
                    }
                    else  if(viewFilter === PackagesViewFilters.OnlyPackages) {
                        if(item.Type === "PackagesFolder") {
                            return (
                                <PackagesTableItem key={item.TreeId} item={item}
                                                   viewMode={"bigCards"}
                                                   tableRole={this.props.tableRole}
                                                   index={index}
                                                   selectFolder={this.selectFolder}/>
                            );
                        } else return null;
                    }
                    else  if(viewFilter === PackagesViewFilters.OnlyDocuments) {
                        if(item.Type === "PackagesDocument") {
                            return (
                                <PackagesTableItem key={item.TreeId} item={item}
                                                   viewMode={"bigCards"}
                                                   tableRole={this.props.tableRole}
                                                   index={index}
                                                   selectFolder={this.selectFolder}/>
                            );
                        } else return null;
                    }
                    else return null;
                })
            );
        }
        else return null;
    }

    view_middleCards(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        let completeSlice;
        if (activeNode) {
            if (activeNode.Children.length <= pagination.paginationPageSize && pagination.paginationPageNumber === 1) {
                completeSlice = activeNode.Children;
            } else {
                // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
                // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);

                //TODO aa новый алгоритм пагинации
                //убираем лишние пакеты
                const lCountPack = activeNode.Children.map(e => e.Type).indexOf('PackagesDocument');
                if (viewFilter === PackagesViewFilters.OnlyDocuments) {
                    completeSlice = activeNode.Children.filter(item => item.Type == "PackagesDocument");
                } else if (lCountPack != -1 && pagination.paginationPageNumber != 1) {
                    completeSlice = __.dropArrayLeft(activeNode.Children, activeNode.Children.length - pagination.paginationPageSize);
                    const lPageNumForFolder = (lCountPack) / pagination.paginationPageSize >= 1
                        ? Math.round((lCountPack) / pagination.paginationPageSize) + Math.floor((lCountPack) % pagination.paginationPageSize)
                        : 0;

                    if (lPageNumForFolder < pagination.paginationPageNumber)
                        completeSlice = activeNode.Children.filter(item => item.Type == "PackagesDocument")
                    else if (lPageNumForFolder == pagination.paginationPageNumber)
                        completeSlice = ((lCountPack % pagination.paginationPageSize) > 0 ? __.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                            activeNode.Children?.filter(item => item.Type == "PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))
                } else {
                    let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                    completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
                }
            }
        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
                this.setState({completeSliceAdd: completeSlice})
        }

        if(isReady) {
            return (
                completeSlice.map((item, index)=> {
                    if(viewFilter === PackagesViewFilters.All) {
                        return (
                                <PackagesTableItem key={item.TreeId} item={item}
                                                   viewMode={"middleCards"}
                                                   tableRole={this.props.tableRole}
                                                   index={index}
                                                   selectFolder={this.selectFolder}/>
                        );
                    }
                    else  if(viewFilter === PackagesViewFilters.OnlyPackages) {
                        if(item.Type === "PackagesFolder") {
                            return (
                                <PackagesTableItem key={item.TreeId} item={item}
                                                   viewMode={"middleCards"}
                                                   tableRole={this.props.tableRole}
                                                   index={index}
                                                   selectFolder={this.selectFolder}/>
                            );
                        }
                    }
                    else  if(viewFilter === PackagesViewFilters.OnlyDocuments) {
                        if(item.Type === "PackagesDocument") {
                            return (
                                <PackagesTableItem key={item.TreeId} item={item}
                                                   viewMode={"middleCards"}
                                                   tableRole={this.props.tableRole}
                                                   index={index}
                                                   selectFolder={this.selectFolder}/>
                            );
                        }
                    }
                    return null;
                })
            );
        }
        else return null;
    }

    view_table(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        if (!activeNode)
            return;

        let completeSlice;
        if(activeNode?.Children.length <= pagination.paginationPageSize && pagination.paginationPageNumber == 1) {
            // if (this.props.isRealSearch) completeSlice = this.props.temporaryData.documents
            // else
                completeSlice = activeNode.Children;
        }
        else {


            //TODO алгоритм пагинации
            // completeSlice = activeNode.Children;

            // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
            // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
            // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);

            //TODO aa новый алгоритм пагинации
            //убираем лишние пакеты
            const lCountPack = activeNode?.Children.map(e => e.Type).indexOf('PackagesDocument');

            if (viewFilter === PackagesViewFilters.OnlyDocuments)
            {
                completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument");
            }
            else
            if (lCountPack != -1 && pagination.paginationPageNumber != 1)
            {
                completeSlice = __.dropArrayLeft(activeNode.Children, activeNode.Children?.length - pagination.paginationPageSize);
                const lPageNumForFolder = ((lCountPack / pagination.paginationPageSize) >= 1)
                    ? (Math.floor((lCountPack) / pagination.paginationPageSize) + ((lCountPack % pagination.paginationPageSize)>0?1:0))
                    :  (lCountPack > 0 ? 1 : 0);

                if (lPageNumForFolder < pagination.paginationPageNumber)
                    completeSlice = activeNode.Children?.filter(item => item.Type =="PackagesDocument")
                else if(lPageNumForFolder == pagination.paginationPageNumber)
                    completeSlice = ((lCountPack % pagination.paginationPageSize)>0?__.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                                activeNode.Children?.filter(item => item.Type =="PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))

            }
            else
            {
                let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
            }
        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
            this.setState({completeSliceAdd: completeSlice})
        }

        let HeaderColumns = [];
        HeaderColumns.push({Name: "Version.Version", Value: "Версия"});
        HeaderColumns.push({Name: "RegDate", Value: "Дата"});
        HeaderColumns.push({Name: "RegNumber", Value: "Номер"});
        HeaderColumns.push({Name: "Name", Value: "Наименование"});
        HeaderColumns.push({Name: "TypeName", Value: "Тип"});
        HeaderColumns.push({Name: "Version.ModifyTime", Value: "Последнее изменение"});


        let BodyColumns = [];
        for(let i = 0; i < completeSlice.length; i++) {
            let BodyColumnsTR = [];
            let item = completeSlice[i].Node;
            if(completeSlice[i].Type === "PackagesDocument") {
                BodyColumnsTR.push({Name: "Version.Version", Value: item.Version.Version});
                BodyColumnsTR.push({Name: "RegDate", Value: item.RegDate});
                BodyColumnsTR.push({Name: "RegNumber", Value: item.RegNumber});
                BodyColumnsTR.push({Name: "Name", Value: item.Name});
                BodyColumnsTR.push({Name: "TypeName", Value: item.Type.Name});
                BodyColumnsTR.push({Name: "Version.ModifyTime", Value: item.Version.ModifyTime});
            }
            else {
                BodyColumnsTR.push({Name: "Version.Version", Value: "-"});
                BodyColumnsTR.push({Name: "RegDate", Value: item.CreateDate});
                BodyColumnsTR.push({Name: "RegNumber", Value: "-"});
                BodyColumnsTR.push({Name: "Name", Value: item.Name});
                BodyColumnsTR.push({Name: "TypeName", Value: "Пакет"});
                BodyColumnsTR.push({Name: "Version.ModifyTime", Value: item.ModifyDate});

            }

            BodyColumnsTR.push({Name: "Item", Value: completeSlice[i]});
            BodyColumns.push(BodyColumnsTR);
        }

        //Оптимизация
        if (JSON.stringify(BodyColumns) != JSON.stringify(this.state.lData))
          this.setState({lData: BodyColumns})
        if (JSON.stringify(HeaderColumns) != JSON.stringify(this.state.lHeader))
            if (this.state.lHeader.length == 0)
              this.setState({lHeader: HeaderColumns})

        let content = (
            <div className={""}>
                <table className={"table table-head-custom table-striped table-hover table-vertical-center"}
                       style={{tableLayout: "fixed"}}>
                    <TheadPackage
                        setWidth = {(itemSize, item) => this.setWidth(itemSize, item)}
                        HeaderColumns={this.state.lHeader}
                        tableRole={this.props.tableRole}
                        body={completeSlice}
                    />
                    <TbodyPackage
                        data={this.state.lData}
                        viewFilter={viewFilter} tableRole={this.props.tableRole} selectFolder={this.selectFolder}/>
                </table>
            </div>
        );

        return isReady ? content : null;
    }

    setPageInBreadcrumbs() {
        let activeNode;
        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;

        this.props.breadcrumbsSetPage({
            TableType: this.props.tableRole,
            IsDownload: this.props.tableRole === "download",
            NodeId: activeNode.Node.Id
        });
    }

        changePaginationNumber(PageNumber) {
            let lActiveNode;
            let lIsSearch

            if (this.props.tableRole === "main") {
                lActiveNode = this.props.activeNode.Node.Id
                lIsSearch = store.getState().packages?.tree?.isSearch
            }
            else if (this.props.tableRole === "additional") {
                lActiveNode = this.props.activeNodeAdditional.Node.Id
                lIsSearch = store.getState().packages?.treeAdditional?.isSearch
            }
            else if (this.props.tableRole === "download") {
                lActiveNode = this.props.activeNodeDownload.Node.Id;
                lIsSearch = store.getState().packages?.treeDownload?.isSearch
            }

            // let ViewMode =
            // this.props.viewMode === "twoTable"
            // ? "two_table_none"
            // : "none"


            //TODO aa 02.02.2023 этот код ломает алгоритм пагинации, делается запрос с паддингом 0 и сдвиг пакетов не работает
            // this.props.setViewModePreviewPackages({
            //     ViewMode,
            //     Next : {
            //         action: () => {
            //             let query = this.props.router.location.query;
            //             let queryLink = "";
            //             queryLink = Routing.CreateRef({
            //                 oldQuery: query,
            //                 newQuery: {p: this.props.activeNode.Node.Id},
            //                 excludedKeys: ["d"]
            //             });
            //             let link = `${this.props.router.location.pathname}${queryLink}`;
            //             history.replace(link);
            //         },
            //         params : {}
            //     }
            // })

        this.props.changePaginationPageInPackages({
            PageNumber,
            TableType : this.props.tableRole
        });
            this.props.setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: true,
                    gif: "packages",
                    key: "MainTablePackage823"
                },
                    Next: {
                    action: () => {
                        this.props.PackagesPaginationMovePage({
                            PageNumber : PageNumber,
                            TableType : this.props.tableRole,
                            IsDownload : this.props.tableRole === "download",
                            Next: {
                                action: () => {
                                    this.props.setLoaderModalData({keyDeleted: "MainTablePackage832"});
                                }
                            }
                        });
                    }
                }
        });


        this.setPageInBreadcrumbs();

        //TODO 24.03.2023 aa при фильтрации не работает пагинация, Костыль для принудительной перерисовки
        if (lIsSearch)
            this.props.searchByParamsPackages({
                TableType: this.props.tableRole,
                Next: {
                    action: () => {
                        this.props.handlerSearchPackages({
                            TableType: this.props.tableRole,
                            Next: {
                                action: () => {
                                    this.props.SelectActivePackageNode({
                                        Id: lActiveNode,
                                        TableType: this.props.tableRole,
                                        Next: { action: () =>{
                                                //Новый прелоадер
                                                // this.props.setModalData({})
                                                this.props.setLoaderModalData({keyDeleted: "MainTablePackage851"});
                                            },
                                            params: {}
                                        }
                                    })
                                },
                                params: {}
                            }
                        })
                    },
                    params: {}
                }
            })
    }

    render() {
        if (!this.props.activeNode) return null
        let activeNode;
        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;


        let viewFilter;
        if(this.props.tableRole === "main") viewFilter = this.props.viewFilter;
        else if(this.props.tableRole === "additional") viewFilter = this.props.viewFilterAdditional;
        else if(this.props.tableRole === "download") viewFilter = this.props.viewFilterDownload;

        let pagination;
        if(this.props.tableRole === "main") {
            this.props.isRealSearch ?
                pagination = {
                    paginationPageNumber : this.props.treePagination.paginationPageNumber,
                    paginationPageSize : this.props.treePagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    foldersTotal : 0,
                } :
            pagination = {
                paginationPageNumber : this.props.treePagination.paginationPageNumber,
                paginationPageSize : this.props.treePagination.paginationPageSize,
                paginationButtonsCount : this.props.treePagination.paginationButtonsCount,

                documentsTotal : this.props.treeLastLoad.documentsTotal,
                foldersTotal : this.props.treeLastLoad.foldersTotal,
            }
        }
        else if(this.props.tableRole === "additional") {

            let documentsTotal = this.props.treeAdditionalLastLoad.documentsTotal
            store.getState().packages.treeAdditional.searchInputs.IsSearch ?
                pagination = {
                    paginationPageNumber : this.props.treeAdditionalPagination.paginationPageNumber,
                    paginationPageSize : this.props.treeAdditionalPagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeAdditionalPagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeAdditionalPagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    foldersTotal : 0,
                } :
            pagination = {
                paginationPageNumber : this.props.treeAdditionalPagination.paginationPageNumber,
                paginationPageSize : this.props.treeAdditionalPagination.paginationPageSize,
                paginationButtonsCount : this.props.treeAdditionalPagination.paginationButtonsCount,

                documentsTotal : documentsTotal,
                foldersTotal : this.props.treeAdditionalLastLoad.foldersTotal,
            }
        }
        else if(this.props.tableRole === "download") {
            let documentsTotal = this.props.treeDownloadLastLoad.documentsTotal
            store.getState().packages.treeDownload.searchInputs.IsSearch ?
                pagination = {
                    paginationPageNumber : this.props.treeDownloadPagination.paginationPageNumber,
                    paginationPageSize : this.props.treeDownloadPagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeDownloadPagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    foldersTotal : 0,
                } :
            pagination = {
                paginationPageNumber : this.props.treeDownloadPagination.paginationPageNumber,
                paginationPageSize : this.props.treeDownloadPagination.paginationPageSize,
                paginationButtonsCount : this.props.treeDownloadPagination.paginationButtonsCount,

                documentsTotal : documentsTotal,
                foldersTotal : this.props.treeDownloadLastLoad.foldersTotal,
            }
        }


        let isReady = false;
        if (activeNode) {
            if (activeNode.Children?.length > 0) {
                isReady = true;
            }
        }
//        let content = this.view_bigCards(isReady);
        let content = this.view_render(isReady, viewFilter);


        let totalItemsCount;
        if(viewFilter === PackagesViewFilters.All) {
            totalItemsCount = pagination.documentsTotal + pagination.foldersTotal;
        }
        else if(viewFilter === PackagesViewFilters.OnlyPackages) {
            totalItemsCount = pagination.foldersTotal;
        }
        else if(viewFilter === PackagesViewFilters.OnlyDocuments) {
            totalItemsCount = pagination.documentsTotal;
        }

        let isEmpty = false;
        if(Math.ceil(totalItemsCount / Number.parseInt(pagination.paginationPageSize))){
            isEmpty=true
        }

        let containerScrollStyle = this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table
            ? "auto"
            : "scroll";

        return (

            <>
                <MainTableSearchPackage
                    fitInContainerSize={this.fitInContainerSize}
                    unFitInContainerSize={this.unFitInContainerSize}
                    viewMode={this.props.viewMode}
                    tableRole={this.props.tableRole}
                    body={this.props.tableRole === TableTypesEnum.Main ? this.state.completeSlice : this.state.completeSliceAdd}/>
                {
                    this.props.tableRole === "main" &&
                    <ReactDnD.Droppable
                        className={"card-body py-0 beautiful-scroll-10 table-responsive"}
                        style={{height: "60vh", overflowY: containerScrollStyle}}
                        types={['document', 'documents']}
                        onDrop={this.documentOnDrop}
                    >
                        <div className="row" style={{}} ref={this.tableWidthRef}>
                            {content}
                        </div>
                    </ReactDnD.Droppable>
                }
                {
                    this.props.tableRole !== "main" &&
                    <div className={"card-body py-0 beautiful-scroll-10"} style={{height: "60vh", overflowY: containerScrollStyle}}>
                        <div className="row" style={{}} ref={this.tableWidthRef}>
                            {content}
                        </div>
                    </div>
                }

                <div className="card-footer py-0">
                    <div className="d-flex justify-content-between align-items-center flex-wrap pt-3 pb-3">
                        {
                            isReady &&
                            <>
                                <Pagination
                                    activePage={pagination.paginationPageNumber} // активная страница
                                    itemsCountPerPage={Number.parseInt(pagination.paginationPageSize)} // элементов на странице
                                    totalItemsCount={totalItemsCount} // всего элементов
                                    pageRangeDisplayed={this.props.viewModePreview === "none" ? pagination.paginationButtonsCount : 1} // сколько кнопок отображать
                                    onChange={this.changePaginationNumber}  // обработчик переключения
                                    itemClass={stylePagination.item} // стили item-ов
                                    linkClass={"btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"} // стиль для ссылок
                                    activeLinkClass={"btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"} // стиль для активных ссылок
                                />

                                {
                                    isEmpty ?
                                        <span className='text-muted'>
                                        Cтр{this.props.viewModePreview === "none" ? "аница" : ""} {pagination.paginationPageNumber} из {Math.ceil(totalItemsCount / Number.parseInt(pagination.paginationPageSize))}
                                    </span>

                                        :
                                        <span className='text-muted'>
                                         Нет данных для отображения
                                    </span>

                                }
                                <PaginationCounterPackages
                                    totalItemsCount={totalItemsCount} // всего элементов
                                    activePage={pagination.paginationPageNumber} // активная страница
                         //           paginationPageSize={pagination.paginationPageSize} // элементов на странице
                                    tableRole={this.props.tableRole} // тип таблицы
                                    isViewModeNone={this.props.viewModePreview === "none"}
                                    setPageInBreadcrumbs={() => this.setPageInBreadcrumbs()} // запись страницы в хлебные крошки
                                />
                            </>
                        }
                    </div>

                </div>
            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeNode : state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        activeNodeDownload : state.packages.treeDownload.activeNode,

        adminLevel : state.globalState.user.AdminLevel,
        header : state.packages.tree.header,
        isRealSearch: state.packages.tree.searchInputs.IsSearch,
        isSearch: state.packages.tree.searchNode,
        router : state.router,
        temporaryData: state.packages.temporaryData,

        treeLastLoad : state.packages.tree.lastLoad,
        treeAdditionalLastLoad : state.packages.treeAdditional.lastLoad,
        treeDownloadLastLoad : state.packages.treeDownload.lastLoad,

        treePagination: {
            paginationPageSize: state.packages.tree.paginationPageSize,
            paginationPageNumber: state.packages.tree.paginationPageNumber,
            paginationButtonsCount: state.packages.tree.paginationButtonsCount,
        },
        treeAdditionalPagination: {
            paginationPageSize: state.packages.treeAdditional.paginationPageSize,
            paginationPageNumber: state.packages.treeAdditional.paginationPageNumber,
            paginationButtonsCount: state.packages.treeAdditional.paginationButtonsCount,
        },
        treeDownloadPagination: {
            paginationPageSize: state.packages.treeDownload.paginationPageSize,
            paginationPageNumber: state.packages.treeDownload.paginationPageNumber,
            paginationButtonsCount: state.packages.treeAdditional.paginationButtonsCount,
        },

        viewFilter : state.packages.tree.viewFilter,
        viewFilterAdditional : state.packages.treeAdditional.viewFilter,
        viewFilterDownload : state.packages.treeDownload.viewFilter,
        viewModeApp : state.packages.rootState.viewMode,
        viewModeMainTable : state.packages.rootState.viewModeMainTable,
        viewModeAdditionalTable : state.packages.rootState.viewModeAdditionalTable,
        viewModeDownloadTable : state.packages.rootState.viewModeDownloadTable,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}


const  mapDispatchToProps = {
    breadcrumbsAdd,
    breadcrumbsMoveSelect,
    breadcrumbsSetPage,
    changePaginationPageInPackages,
    collectDataForAdditionalSections_Normal,
    handlerSearchPackages,
    movingDocumentsInPackageFromPackages,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    searchByParamsPackages,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setViewModePreviewPackages,
    setDefaultWidthOfColumn,
    setLoaderModalData,
    setModalData,
    togglePackagesFolders,
    updateUserSettings,
}

export default connect(mapStateToProps,mapDispatchToProps)(MainTablePackage);




