// import {UserSettingsNames} from "../store/settings/settingsActionCreator";

import {
    DataTypes,
    ModalTypes,
    Operators as FilerOperatorsEnum,
    TableFilterHasValueEnum,
    UserSettingsNames
} from "./StaticTypes";
import {history, store} from "../index";
import {TreeCombine} from "./TreeCombine";
import {cloneDeep, drop, dropRight} from 'lodash';
import {EntityFilters} from "./API_NEW/ApiFilter";
import {DataTypesEnum} from "./API_NEW/ServerTypes";
import {apiRequestNew} from "./API/apiRequest";
import {apiUrl} from "./API/apiUrl";
import {setModalData} from "../store/globalState/actionCreators/globalState_AppActionCreator";
import {API} from "./API_NEW/API";
import {DOCUMENT_VERIFY} from "../store/documents/documentActionsList";

export class HelpFunctions {

    // проверка таблицы -> имеется ли для нее пользовательские настойки
    static CheckUserSettingForTable({userSettings = null, IdDocGroup = null, TypeId = null, IdFilial = null}) {

        if(userSettings == null)  userSettings = store.getState().globalState.userSettings;
        if(IdDocGroup == null)   IdDocGroup = TreeCombine.searchByTreeId({treeId : store.getState().document.tree.activeNode.parent}).info.Id;
        if(TypeId == null)   TypeId = store.getState().document.tree.activeNode.info.Id;
        if(IdFilial == null)  IdFilial = store.getState().globalState.filial.Active.Id;

        let UserSettingsTable = {
            isFind : false,
            data : null
        };

        let findUserSettings = HelpFunctions.FilterLoadSettings({
            name : UserSettingsNames.MAIN_TABLE_CONFIG
        });

        if(findUserSettings) {
            let foundTable = findUserSettings.Value
                .find(Value =>
                    Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(IdDocGroup) &&
                    Number.parseInt(Value.info.TypeId)     === Number.parseInt(TypeId) &&
                    Number.parseInt(Value.info.IdFilial)   === Number.parseInt(IdFilial)
                );

            return foundTable
                ? {isFind : true, data : foundTable}
                : __.deepCopy(UserSettingsTable)
        }
        else return __.deepCopy(UserSettingsTable);
    }

    // проверка таблицы -> имеется ли для нее пользовательские настойки
    static CheckUserSettingForTable_packagesRightDocuments({userSettings = null, IdDocGroup = null, TypeId = null, IdFilial = null}) {

        if(userSettings == null)  userSettings = store.getState().globalState.userSettings;
        if(IdDocGroup == null)   IdDocGroup = TreeCombine.searchByTreeId_packagesRightDocuments({treeId : store.getState().packages.treeForDocuments.activeNode.parent}).info.Id;
        if(TypeId == null)   TypeId = store.getState().packages.treeForDocuments.activeNode.info.Id;
        if(IdFilial == null)  IdFilial = store.getState().globalState.filial.Active.Id;

        let UserSettingsTable = {
            isFind : false,
            data : null
        };

        let findUserSettings = HelpFunctions.FilterLoadSettings({
            name : UserSettingsNames.MAIN_TABLE_CONFIG
        });

        if(findUserSettings) {
            let foundTable = findUserSettings.Value
                .find(Value =>
                    Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(IdDocGroup) &&
                    Number.parseInt(Value.info.TypeId)     === Number.parseInt(TypeId) &&
                    Number.parseInt(Value.info.IdFilial)   === Number.parseInt(IdFilial)
                );

            return foundTable
                ? {isFind : true, data : foundTable}
                : __.deepCopy(UserSettingsTable)
        }
        else return __.deepCopy(UserSettingsTable);
    }

    // поиск пользовательской настройки по имени
    static FilterLoadSettings({name})  {
        let userSettings = __.deepCopy(store.getState().globalState.userSettings)
            .find(userSettings => userSettings.Name === name);
        return userSettings ? userSettings : false;
    }

    // является ли переменная строкой
    static isString(val) {
        return (typeof val === "string" || val instanceof String);
    }

    // функция обрезает текст до нужной длины
    static cutLongString(text, maxCountLitters, dotes = false) {
        // для раздела поиска: приходит строка после парсера с тегами
        if(typeof text !== "string") {
            text = text[0]
        }

        if(text && text.length > maxCountLitters) {
            let cutText = text.substr(0, maxCountLitters);
            if(dotes) {
                return cutText + "...";
            } else {
                return  cutText;
            }
        } else {
            return text;
        }
    }


    // преобразование размеров файла
    // si -> двоичная или десятичная система
    static humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    // переставляет body таблицы по ее заголовкам
    static sortTableBodyByHeaders = ({ColumnDefinition, BodyDefinition}) => {
        let NewBodyDefinition = [];

        for(let k = 0; k < BodyDefinition.length; k++) {

            NewBodyDefinition.push({
                tr : [],
                Version : BodyDefinition[k].Version,
                Type : BodyDefinition[k].Type,
                AllAttributes : BodyDefinition[k].AllAttributes,
                Info : BodyDefinition[k].Info
            });


            for(let i = 0; i < ColumnDefinition.length; i++) { // сопоставляем тело с заголовками
                for(let j = 0; j < BodyDefinition[k].tr.length; j++) {
                    if(ColumnDefinition[i].Name === BodyDefinition[k].tr[j].Name) {
                        if(BodyDefinition[k].tr[j].IsConstant) {
                            NewBodyDefinition[k].tr.push({...BodyDefinition[k].tr[j], ...{UserVisible: ColumnDefinition[i].UserVisible}});
                            break;
                        } else  if(ColumnDefinition[i].Value === BodyDefinition[k].tr[j].ColumnName) {
                            NewBodyDefinition[k].tr.push({...BodyDefinition[k].tr[j], ...{UserVisible: ColumnDefinition[i].UserVisible}});
                            break;
                        }
                    }
                }
            }
        }
        return NewBodyDefinition;
    }

}

// вспомогательный класс
export class __ {

    static createUniqueIdString(len = 6) { // рандомное id строка
        const shuffle = (array) => {
            array.sort(() => Math.random() - 0.5);
            return array.join("");
        }
        let _abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let abc = _abc + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        abc = shuffle(Array.from(abc));
        let rs = "";
        while (rs.length < len) {
            rs += abc[Math.floor(Math.random() * abc.length)];
        }
        return rs;
    }

    static unsecuredCopyToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Сохраняем текущее положение экрана
        const scrollY = window.scrollY;

        // Перемещаем элемент на верхнюю часть страницы
        textArea.style.position = 'absolute';
        textArea.style.top = '0';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);

        // Выполняем копирование
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }

        // Удаляем элемент
        document.body.removeChild(textArea);

        // Возвращаем положение экрана на место
        window.scrollTo(0, scrollY);
    }

    static copyUrl1c(lDoc = null, lPack = null, TableRole = null) {
        let d = "d="
        let p = "p="
        let t = "t="
        let g = "g="
        let indexMod = 2

        if (TableRole === "additional") {
            d = "d2="
            p = "p2="
            t = "t2="
            g = "g2="
            indexMod = 3
        }
        if (TableRole === "download") {
            d = "dwd="
            p = "dwp="
            indexMod = 4
        }
        let link = ""
        if (store.getState().router.location.pathname)
            if (store.getState().router.location.pathname.indexOf('/') != -1)
                link = store.getState().router.location.pathname.slice(store.getState().router.location.pathname.indexOf('/')+1, store.getState().router.location.pathname.length)
            else
                link = store.getState().router.location.pathname;
        link += store.getState().router.location.search;


        if (link.indexOf('search') != -1 && lDoc)
        {
            link = "documents?d="+lDoc
        }

        if (link.indexOf("packages") == -1)
            lPack = null;

        if ((lDoc || lPack) && link.indexOf('search') == -1)
        {
                    let newLink = "";
                    // if (lDoc)
                    // if (this.props.contextMenu.data?.tr?.Info?.Id || (this.props.contextMenu.data?.item?.Type == "PackagesDocument" && this.props.contextMenu.data?.item?.Node?.Id))
                    //  if (link.indexOf(`?${d}`) != -1) {
                    //      if (link.indexOf("&", link.indexOf(`?${d}`) + indexMod + 1) != -1)
                    //          newLink = link.slice(0, link.indexOf(`?${d}`)) + `?${d}` + lDoc + link.slice(link.indexOf("&", link.indexOf(`?${d}`) + indexMod + 1), link.length)
                    //      else
                    //          newLink = link.slice(0, link.indexOf(`?${d}`)) + `?${d}` + lDoc
                    //  } else if (link.indexOf(`&${d}`) != -1) {
                    //      if (link.indexOf("&", link.indexOf(`&${d}`) + indexMod + 1) != -1)
                    //          newLink = link.slice(0, link.indexOf(`&${d}`)) + `&${d}` + lDoc + link.slice(link.indexOf("&", link.indexOf(`&${d}`) + indexMod + 1), link.length)
                    //      else {
                    //          newLink = link.slice(0, link.indexOf(`&${d}`)) + `&${d}` + lDoc
                    //      }
                    //  } else
                    //      newLink = link + "&d=" + lDoc;
                    //
                    // if (lPack) {
                    //         if (newLink.indexOf("&") != -1)
                    //             newLink += "&p=" + lPack
                    //         else
                    //             newLink += "?p=" + lPack;
                    // }

                    //TODO старый формат
                    let oldLink = "";
                    if (link.indexOf('documents') != -1)
                        oldLink = "#d;"
                    else if (link.indexOf('packages') != -1)
                        oldLink = "#p;";

                    if (store.getState().globalState.filial?.Active?.Id) {
                        oldLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
                    }

                    newLink = link
                    if (newLink.indexOf(p) != -1) {

                        if (newLink.indexOf("&", link.indexOf(p)) != -1)
                            oldLink += "pc=" + newLink.slice(newLink.indexOf(p) + indexMod, newLink.indexOf("&", newLink.indexOf(p))) + ";"
                        else
                            oldLink += "pc=" + newLink.slice(newLink.indexOf(p) + indexMod, newLink.length) + ";"
                    }

                    if (newLink.indexOf('g=') != -1) {

                        if (newLink.indexOf("&", newLink.indexOf('g=')) != -1)
                            oldLink += "g=" + newLink.slice(newLink.indexOf('g=') + 2, newLink.indexOf("&", newLink.indexOf('g='))) + ";"
                        else
                            oldLink += "g=" + newLink.slice(newLink.indexOf('g=') + 2, newLink.length) + ";"
                    }

                    if (newLink.indexOf('t=') != -1) {

                        if (newLink.indexOf("&", newLink.indexOf('t=')) != -1)
                            oldLink += "t=" + newLink.slice(newLink.indexOf('t=') + 2, newLink.indexOf("&", newLink.indexOf('t='))) + ";"
                        else
                            oldLink += "t=" + newLink.slice(newLink.indexOf('t=') + 2, newLink.length) + ";"
                    }

                    if (newLink.indexOf(d) != -1) {

                        if (newLink.indexOf("&", newLink.indexOf(d)) != -1)
                            oldLink += "r=" + newLink.slice(newLink.indexOf(d) + indexMod, newLink.indexOf("&", newLink.indexOf(d))) + ";"
                        else
                            oldLink += "r=" + newLink.slice(newLink.indexOf(d) + indexMod, newLink.length) + ";"
                    }

                    if (oldLink.slice(oldLink.length - 1, oldLink.length) == ";")
                        oldLink = oldLink.slice(0, -1);

                    //navigator.clipboard.writeText(newLink);

                    __.unsecuredCopyToClipboard(oldLink);
                    // __.unsecuredCopyToClipboard(newLink);
        }
        else {
            //TODO старый формат
            let newLink = "";
            if (link.indexOf('documents') != -1)
                newLink = "#d;"
            else if (link.indexOf('packages') != -1)
                newLink = "#p;";

            if (store.getState().globalState.filial?.Active?.Id) {
                newLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
            }

            if (link.indexOf('p=') != -1) {

                if (link.indexOf("&", link.indexOf('p=')) != -1)
                    newLink += "pc=" + link.slice(link.indexOf('p=') + 2, link.indexOf("&", link.indexOf('p='))) + ";"
                else
                    newLink += "pc=" + link.slice(link.indexOf('p=') + 2, link.length) + ";"
            }
            if (link.indexOf('g=') != -1) {

                if (link.indexOf("&", link.indexOf('g=')) != -1)
                    newLink += "g=" + link.slice(link.indexOf('g=') + 2, link.indexOf("&", link.indexOf('g='))) + ";"
                else
                    newLink += "g=" + link.slice(link.indexOf('g=') + 2, link.length) + ";"
            }

            if (link.indexOf('t=') != -1) {

                if (link.indexOf("&", link.indexOf('t=')) != -1)
                    newLink += "t=" + link.slice(link.indexOf('t=') + 2, link.indexOf("&", link.indexOf('t='))) + ";"
                else
                    newLink += "t=" + link.slice(link.indexOf('t=') + 2, link.length) + ";"
            }

            if (link.indexOf('d=') != -1) {

                if (link.indexOf("&", link.indexOf('d=')) != -1)
                    newLink += "r=" + link.slice(link.indexOf('d=') + 2, link.indexOf("&", link.indexOf('d='))) + ";"
                else
                    newLink += "r=" + link.slice(link.indexOf('d=') + 2, link.length) + ";"
            }

            if (newLink.slice(newLink.length - 1, newLink.length) == ";")
                newLink = newLink.slice(0, -1);

            __.unsecuredCopyToClipboard(newLink)
            // navigator.clipboard.writeText(link)
        }
    }

    static copyUrl(lDoc = null, lPack = null, isDownload = false) {
        let link = window.location.href;
        let newLink = '';

        if (link.indexOf("search") != -1 && lDoc) {
            newLink = link.split('/search')[0]
            newLink += "/#d;"
            if (store.getState().globalState.filial?.Active?.Id) {
                newLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
            }
            newLink += "r=" + lDoc
            __.unsecuredCopyToClipboard(newLink)
        } else if (lDoc && !lPack) {
            if (link.indexOf("?d=") != -1) {
                if (link.indexOf("&", link.indexOf("?d=") + 3) != -1)
                    newLink = link.slice(0, link.indexOf("?d=")) + "?d=" + lDoc + link.slice(link.indexOf("&", link.indexOf("?d=") + 3), link.length)
                else
                    newLink = link.slice(0, link.indexOf("?d=")) + "?d=" + lDoc

            } else if (link.indexOf("&d=") != -1) {
                if (link.indexOf("&", link.indexOf("&d=") + 3) != -1)
                    newLink = link.slice(0, link.indexOf("&d=")) + "&d=" + lDoc + link.slice(link.indexOf("&", link.indexOf("&d=") + 3), link.length)
                else {
                    newLink = link.slice(0, link.indexOf("&d=")) + "&d=" + lDoc
                }
            } else
                newLink = link + "&d=" + lDoc;

            __.unsecuredCopyToClipboard(newLink);
            // navigator.clipboard.writeText(newLink)
        } else if (link.indexOf("/packages") != -1 && lPack) {
            const lUrl = newLink = link.split('/packages')[0];
            const rootPackageId = store.getState().packages.tree?.render.Node.Id;
            newLink = lUrl + `/packages?p=${isDownload ? rootPackageId : lPack}`;
            if(isDownload) newLink += "&dwp=" + lPack;
            if(lDoc) newLink += `&${isDownload ? 'dwd' : 'd'}=` + lDoc;

            __.unsecuredCopyToClipboard(newLink);
        } else {
            __.unsecuredCopyToClipboard(link);
        }

        // if(isSearch){
            // if (link.indexOf('search') != -1 && lDoc)
            // {
            //     newLink =link.split('/search')[0]
            //     newLink += "/#d;"
            //     if (store.getState().globalState.filial?.Active?.Id)
            //     {
            //         newLink += "f="+store.getState().globalState.filial?.Active?.Id+";";
            //     }
            //     newLink += "r="+lDoc
            //     __.unsecuredCopyToClipboard(newLink)
            // }
        // }else{
        //     __.unsecuredCopyToClipboard(link)
        //     // navigator.clipboard.writeText(link)
        // }

    }

    static showModalCopyComplete(setModalData) {
        setModalData({
            name: ModalTypes.app.alert,
            data: {content: `Скопировано в буфер обмена`, disableButton: true}
        });
        setTimeout(() => {setModalData({})}, 500);
    }


    static dropArrayLeft(array, count) {
        return drop(array, count);
    }

    static dropArrayRight(array, count) {
        return dropRight(array, count);
    }

    static deepCopyJSON(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static deepCopy(obj) {
        return cloneDeep(obj);
    }



    // получаем тип input в зависимости от типа данных
    static inputTypeByDataTypeId(DataTypesId) {
        switch (DataTypesId) {
            case DataTypes.Integer: return "number";
            case DataTypes.Float: return "number";
            case DataTypes.Boolean: return "checkbox";
            case DataTypes.DataTime: return "date";
            case DataTypes.RowBlob: return "file";
            case DataTypes.SearchBlob: return "text";
            case DataTypes.String: return "text";
            default: return "text";
        }
    }

    // проверяем атрибут на уникальность
    static isUniqueAttribute(IdAttributeName) {
        let storeAttrTypes = __.deepCopy(store.getState().document.tree.docs);

        let unityAttributes = [];
        for(let i = 0; i < storeAttrTypes.length; i++) {
            unityAttributes = unityAttributes.concat(storeAttrTypes[i].TypeAttributes);
        }

        let findAttribute = unityAttributes.find(attr => attr.AttributeName.Id === IdAttributeName);
        if(findAttribute) {
            return  findAttribute.IsUnique;
        } else {
            // если не найден, то скорее всего он константный
            return true;
        }
    }

    static checkAttributesInput() {

    }


    // присылаем строку-число
    // в ответ приходит int или float
    static checkOnFloatOrIntType(checkNumber) {
        if(Number.isInteger(Number(checkNumber))) {
            return "int";
        } else {
            return "float";
        }
    }

    static toInt(number) {
        return Number.parseInt(number);
    }

    static toFloat(number, afterDot = 2) {
        return Number.parseFloat(number).toFixed(afterDot);
    }

    static translit(word){
        var answer = '';
        var converter = {
            'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
            'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
            'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
            'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
            'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
            'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
            'э': 'e',    'ю': 'yu',   'я': 'ya',

            'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
            'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
            'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
            'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
            'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
            'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
            'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
        };

        for (var i = 0; i < word.length; ++i ) {
            if (converter[word[i]] == undefined){
                answer += word[i];
            } else {
                answer += converter[word[i]];
            }
        }

        return answer;
    }
    static dateToRussia(Value){

            Value = Value.split('T')[0];
            let  year= Value.split('-')[0];
            let month= Value.split('-')[1];
            let day=Value.split('-')[2];
            Value=day+'.'+month+'.'+year;
        return Value;
    }
    static dateToServer(Value,isEndDay,withoutT){
        if (Value === null) return
        else {
            let year = Value.split('.')[2];
            let month = Value.split('.')[1];
            let day = Value.split('.')[0];
            Value = year + '-' + month + '-' + day;
            if (!withoutT) {
                if (isEndDay) {
                    return Value + 'T23:59:59'
                } else {
                    return Value + 'T00:00:00'
                }
            }
            return Value;
        }
    }
    static reformatDate(dateStr){
        if (!dateStr)
            return "";
        var dArr = dateStr.split("-");  // ex input: "2022-01-18"
        return dArr[2]+ "." +dArr[1]+ "." +dArr[0]; //ex output: "18.01.2022"  .substring(2) если нужно 18.01.22
    }

    static setEntityFilters = (Attributes, fromPackages = false) => { //TODO тип
        let entityName = fromPackages
            ? "DocInPackage"
            : Attributes.isConstant
                ? "DocExtended"
                : "DocAttribute"

        let type = Attributes.IdAttributeType;
        let ValueAttribute = Attributes.Value;
        let NameAttribute = Attributes.Name === "PropertyName" ? "AttributeValue" : Attributes.Name;
        let entity = new EntityFilters().setEntityName(entityName);

        //условие для поиска из фильтров и из строки поиска
        let approxValue = typeof ValueAttribute === "string" ? ValueAttribute : ValueAttribute.ApproximatelyEqual;
        if(type === DataTypesEnum.String) {
            if(ValueAttribute.Equal) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.Equal,
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if(ValueAttribute.In) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.In,
                    Operator: FilerOperatorsEnum.In
                });
            }
            else if(approxValue) {
                entity.add({
                    Name: NameAttribute,
                    Value: approxValue,
                    Operator: FilerOperatorsEnum.Like
                });
            } else {
                if(ValueAttribute.BeginningFrom) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.BeginningFrom,
                        Operator: FilerOperatorsEnum.StartsWith
                    });
                }
                if(ValueAttribute.EndingOn) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.EndingOn,
                        Operator: FilerOperatorsEnum.EndsWith
                    });
                }
            }
            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if (type === DataTypesEnum.Integer || type === DataTypesEnum.Float) {
            if (ValueAttribute.Equal) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.Equal,
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if (ValueAttribute.MoreOrEqual && !ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.MoreOrEqual,
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                });
            } else if (!ValueAttribute.MoreOrEqual && ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.LessOrEqual,
                    Operator: FilerOperatorsEnum.LessOrEqual
                });
            } else if (ValueAttribute.MoreOrEqual && ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.MoreOrEqual,
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                }).add({
                    Name: NameAttribute,
                    Value: ValueAttribute.LessOrEqual,
                    Operator: FilerOperatorsEnum.LessOrEqual
                });
            }
            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if(type === DataTypesEnum.Boolean) {
            if (ValueAttribute.IsChecked === true) {
                entity.add({
                    Name: NameAttribute,
                    Value: "true",
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if (ValueAttribute.IsChecked === false) {
                entity.add({
                    Name: NameAttribute,
                    Value: "false",
                    Operator: FilerOperatorsEnum.Equal
                });
            }

            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if(type === DataTypesEnum.DataTime) {
            if(ValueAttribute.OnDate) {
                if (fromPackages) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.OnDate.split("-").reverse().join("."),
                        Operator: FilerOperatorsEnum.Like
                    })
                } else {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.OnDate + "T00:00:00",
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                }).add({
                    Name: NameAttribute,
                    Value: ValueAttribute.OnDate + "T23:59:59",
                    Operator: FilerOperatorsEnum.Less
                });
                }
            } else if(ValueAttribute.ApproximatelyEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.ApproximatelyEqual,
                    Operator: FilerOperatorsEnum.Like
                });
            } else {
                if(ValueAttribute.DateStart) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.DateStart + "T00:00:00",
                        Operator: FilerOperatorsEnum.GreaterOrEqual
                    });
                }
                if(ValueAttribute.DateEnd) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.DateEnd + "T00:00:00",
                        Operator: FilerOperatorsEnum.LessOrEqual
                    });
                }
            }

            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        return entity;
    }

    // найти группу в многоуровневом дереве документов
    static findGroup = (List, Id, isParent, isGetGroup) => {
        if(isGetGroup) List = List.filter(item => item.type === "FOLDER");

        for (let i = 0; i < List.length; i++) {
            if(isParent && List[i].treeId == Id) return List[i]
            else if (List[i].info.Id == Id) return List[i]
            else {
                if (List[i].children && List[i].children.length > 0) {
                    const foundItem = this.findGroup(List[i].children, Id, isParent, isGetGroup);
                    if (foundItem) return foundItem
                }
            }
        }
    }

    // для открытия папок вложенной ноды дерева (документы с элементами структуры групп)
    static findParents = (List, IdParent, ids) => {
        let foundItem = this.findGroup(List, IdParent, true);
        if(foundItem) {
            ids.push(foundItem.info?.Id);
            if(foundItem.info.StructureNode) this.findParents(List, foundItem.parent, ids);
        }
    }

    //
    static setPreviewFiles = () => {

    }

    static buildDocLink = async (doc, filial) => {

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (store.getState().document.verifyDocs && store.getState().document.verifyDocs[doc]) {
            vFlagVerify = true;
            docRefInfo = store.getState().document.verifyDocs[doc][0];
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: doc,
                    IdFilial: filial
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: doc.IdDoc,
                    data: docRefInfo
                }
            });
        }

        // let docRefInfo = await API.search().verifyDocClientLinkIds({
        //     linkIds: {
        //         IdDoc: item.IdDoc,
        //         IdFilial: item.IdFilial ?? item.filial
        //     }
        // });

        if (docRefInfo && docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                    return;
                }
            }
        } else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;
            if (filial !== store.getState().globalState.filial.Active.Id) {
                link = link.concat(`&f=${filial}`);
            }
            history.replace(link);
        }
    }
}


export async function uploadFilesForDoc(files, idDoc)  {
    for (const item of files) {
        let data = new FormData();
        data.append("description", item.description);
        data.append("upload", item.file, item.name);

        let response = await new apiRequestNew({
            action : apiUrl.DOCUMENTS.LOAD_FILE_FOR_CURRENT_DOC,
            endUrl : idDoc,
            headers : null,
            params : data
        }).isFileUpload(true).execute();

        if(!response.ok) {
            store.dispatch(setModalData({
                name : ModalTypes.app.alert,
                data : {content : "Во время изменения документа произошла ошибка: Загрузка новых файлов", disableButton : false}
            }));
            return;
        }
    }
}

