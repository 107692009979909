import {AttributeName} from "./AttributeName";

export class Attribute {

    // number
    Id;
    // class AttributeName
    AttributeName;
    // string
    AttributeValue;


    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.AttributeName = new AttributeName(DataFromServer.AttributeName);
        this.AttributeValue = DataFromServer.AttributeValue;
    }
}