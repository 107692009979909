import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import moment from 'moment';
import {
    changeVersionDocumentCards,
     toggleAdditionalSectionsOpen_Cards
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {__} from "../../../tools/HelpFunctions";
import {collectDataForAdditionalSectionsSearch_Normal, createPreviewBySelectDocumentSearch, toggleAdditionalSectionsOpenSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";

class VersionSectionSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.sectionName = attributesSectionsTypes.versionSection;

        this.toggle = this.toggle.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpenSearch({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    // выбрать другую версию документа
    selectVersion(index, item) {
        if(!this.props.redactorMode) {
            if(this.props.viewMode === "normal") {
                this.props.createPreviewBySelectDocumentSearch({
                    VersionIndex : index,
                    Next : {
                        action : () => {
                            this.props.collectDataForAdditionalSectionsSearch_Normal({
                                VersionIndex : index
                            });
                        },
                        params : {}
                    }
                });
            }  else if (this.props.viewMode === "card") {
                const {activeTab, searchResults} = this.props;
                const cards = searchResults.find(res => res.Id === activeTab.Id)?.cards;
                this.props.changeVersionDocumentCards({
                    VersionIndex : index,
                    CardId : cards.activeCardId,
                });
            }
        }
    }


    render() {
        const {activeTab, searchResults} = this.props;
        let sectionData;
        let isReady;
        const additionalSectionsData = searchResults.find(res => res.Id === activeTab.Id)?.additionalSections;
        if(this.props.viewMode === "normal") {
            sectionData = additionalSectionsData.state[this.sectionName];
            isReady = additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            const cards = searchResults.find(res => res.Id === activeTab.Id)?.cards;
            if(this.props.redactorMode && cards.redactorData) {
                card = cards.redactorData;
            } else {
                card = cards.collection.find(card => card.Id === cards.activeCardId);
            }
            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = cards.collection
                .find(card => card.Id === cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Версии документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-hover table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-11">Дата</th>
                                <th className="col-lg-1">Номер</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.versions.map((item, index)=>{
                                    let timeMoment = new moment(item.VersionTime);

                                    let trClass = "";
                                    if(index === sectionData.activeVersionIndex) {
                                        trClass = "tr-active";
                                    }

                                    return(
                                        <tr className={trClass} key={item.Version} onClick={()=> {this.selectVersion(index, item)}}>
                                            <td title={timeMoment.format("LTS")}>
                                                <time>{timeMoment.format("L")}</time>
                                            </td>
                                            <td title={item.VersionComment}>
                                                <a href="#" className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    {item.Version}
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,
    }
}

const  mapDispatchToProps = {
    changeVersionDocumentCards,
    collectDataForAdditionalSectionsSearch_Normal,
    createPreviewBySelectDocumentSearch,
    toggleAdditionalSectionsOpenSearch,
    toggleAdditionalSectionsOpen_Cards,
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionSectionSearch);
