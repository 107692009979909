import React from "react";
import {connect} from "react-redux";
import {ActionQueue} from "../../store/rootReducer";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, mainTableSearchDoc,
    resetMainTableForUpdate, selectActiveRow,
    setPaginationPageSize
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import PaginationSelect from "../PaginationSelect";
import {store} from "../../index";
import {AdminLevelEnum} from "../../tools/StaticTypes";


class PaginationCounter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.change = this.change.bind(this);
    }

    change(value) {
        let newSize = value;

        const activeElement = store.getState().document.mainTable.activeRow?.Info.Id
        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents"}
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "PaginationCounter38" }
        }));


        const mainTable = store.getState().document.mainTable
        const index = mainTable.body.findIndex(item => item === mainTable.activeRow)

        let elementsCount = mainTable.paginationPageSize * mainTable.paginationPageNumber
        if (elementsCount > mainTable.collection.totalInServer) elementsCount = mainTable.collection.totalInServer

        let newPage = Math.ceil((elementsCount - (mainTable.body.length - index)) / value)
        if (newPage < 1) newPage = 1

        if (store.getState().document.search.searchInputs.find(item => item.IsApplied)){
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    {action : this.props.resetMainTableForUpdate, params : {lObj: {...this.props, newSize}}, name : "resetMainTableForUpdate"},
                    {action : this.props.setPaginationPageSize, params : {PaginationPageSize: newSize}, name : "setPaginationPageSize"},
                    {action : this.props.mainTableDownloadDocCollection, params : {id: activeElement}, name : "mainTableDownloadDocCollection"},
                    {action : this.props.mainTableSearchDoc, params : {PaginationPageNumber: newPage}, name : "mainTableSearchDoc"},
                    {action : this.props.combineMainTableAttributes, params : {}, name : "combineMainTableAttributes"},
                    {action : () => {
                            if (activeElement) this.props.selectActiveRow({IdDoc: activeElement})
                            this.props.setLoaderModalData({keyDeleted: "PaginationCounter60"});
                        }, params : {}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
        } else {
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    {action : this.props.resetMainTableForUpdate, params : {lObj: {...this.props, newSize}}, name : "resetMainTableForUpdate"},
                    {action : this.props.setPaginationPageSize, params : {PaginationPageSize: newSize}, name : "setPaginationPageSize"},
                    {action : this.props.mainTableDownloadDocCollection, params : {id: activeElement}, name : "mainTableDownloadDocCollection"},
                    {action : this.props.combineMainTableAttributes, params : {}, name : "combineMainTableAttributes"},
                    {action : () => {
                            if (activeElement) this.props.selectActiveRow({IdDoc: activeElement})
                            this.props.setLoaderModalData({keyDeleted: "PaginationCounter74"});
                        }, params : {}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
        }
    }


    render() {
        let {activePage, paginationPageSize, totalItemsCount} = this.props;

        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">Показано {from} - {to} из {totalItemsCount}</span>);
            }
        }

        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
            />
        );
    }
}

const  mapStateToProps = state => {
    return {
        paginationPageSize : state.document.mainTable.paginationPageSize
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    setPaginationPageSize,
    resetMainTableForUpdate,
    mainTableDownloadDocCollection,
    mainTableSearchDoc,
    combineMainTableAttributes,
    selectActiveRow,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounter);
