import React from 'react';
import {connect} from "react-redux";
import HistorySearch from "../history/HistorySearch";

// Компонент приветствующий пользователя при первом запуске приложения
export  class SearchWelcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        let mod = this.props.theme.modString;
        let searchHistory = this.props.userSettings.find((userSetting => userSetting.Name === "searchConfig"));
        return (
            <div className="row pt-4" style={{paddingLeft : "15px", paddingRight : "15px", justifyContent : "center"}}>
                <div className="col-lg-12" style={{padding: "0"}}>
                    <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                         style={{height: "350px",backgroundImage: `url('./images/searchPic${mod}.svg')`}}>
                        <div className="card-body d-flex" style={{padding: "4rem 3.25rem"}}>
                            <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                <div className="flex-grow-1">
                                    <span className="text-welcome font-weight-bolder font-size-h1"><b>Поиск</b></span>
                                    <p className="text-welcome font-weight-bold mt-3">С помощью поисковой системы электронных документов вы можете найти<br/> необходимый вам документ или набор документов
                                    </p>
                                </div>
                                {/*<Link to={"/help"} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                {/*      style={{borderRadius: "0.8em"}}>*/}
                                {/*    Справка*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>

                {
                    searchHistory
                        ?
                        <HistorySearch/>
                        :
                        <div className="col-lg-12 row pt-4 text-center" style={{margin : "0 auto"}}>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex flex-center mt-6 mb-7">
                                            <img src={`/images/parameters${mod}.svg`} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Параметры поиска
                                    </span>
                                        </div>
                                        <p className="mb-12">Вы можете уточнить условия вашего поиска для более точного результата</p>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex flex-center mt-6 mb-7">
                                            <img src={`/images/sorting${mod}.svg`} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Применение фильтров
                                    </span>
                                        </div>
                                        <p className="mb-12">Фильтры по датам и дополнительным параметрам помогут вам удобнее взаимодействовать с результатами умного поиска</p>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex flex-center mt-6 mb-7">
                                            <img src={`/images/history${mod}.svg`} alt=""/>
                                        </div>
                                        <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        История поиска
                                    </span>
                                        </div>
                                        <p className="mb-12">На главной странице программы и на странице поиска вы можете увидеть историю поиска, чтобы вернуться к результату. Это удобнее, чем начинать поиск сначала.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        userSettings: state.globalState.userSettings,
        theme: state.globalState.theme
    }
}

const  mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(SearchWelcome);
