import React from "react";
import stylePagination from "../../media/styles/pagination.module.css";
import {connect} from "react-redux";
import MainTableSearch from "./MainTableSearch";
import Pagination from "./pagination/Pagination";
import PaginationCounter from "./PaginationCounter";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, mainTableSearchDoc,
    resetMainTableForUpdate, setDefaultWidthOfColumn,
    setPaginationPageNumber
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {ActionQueue} from "../../store/rootReducer";
import Thead from "./Thead";
import Tbody from "./Tbody";
import {
    setContextMenuData,
    setLoaderModalData,
    setModalData
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {__} from "../../tools/HelpFunctions";
import {Routing} from "../../tools/routing/Routing";
import {history, store} from "../../index";

class MainTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevCells: []
        };
        this.tableWidthRef = React.createRef();
        this.rowWidthRef = React.createRef();

        //TODO Чтобы ничего не перерендеривалось
        this.memoRef = React.createRef();


        this.handlePageChange = this.handlePageChange.bind(this);
        this.fitInContainerSize = this.fitInContainerSize.bind(this);
        this.unFitInContainerSize = this.unFitInContainerSize.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    componentDidMount() {
    }

    fitInContainerSize() {
        let TableWidth = this.tableWidthRef?.current?.offsetWidth - 100;
        let Cells = __.deepCopy(this.props.header);
        if (this.memoRef && this.memoRef.length)
            this.setState({prevCells: this.memoRef})
        else
            this.setState({prevCells: Cells});
        let RowWidth = 0;
        if (Cells && TableWidth) {
            for (let i = 1; i < Cells.length; i++) {
                if (Cells[i].UserVisible) RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
            }

            for (let i = 1; i < Cells.length; i++) {
                if (Cells[i].UserVisible) {
                    let size = (TableWidth / 100) * ((Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70) / RowWidth * 100);
                    Cells[i].Size = size < 100 ? 100 : size;
                }
            }
            this.props.setDefaultWidthOfColumn({updatedHeaders: Cells})
        }
    }

    unFitInContainerSize() {
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);
            this.props.setDefaultWidthOfColumn({updatedHeaders: Cells})
        }
    }

    setWidth(itemSize, lItem) {
        if (this.state.prevCells.length == 0) {
            this.setState({prevCells: __.deepCopy(this.props.header)});
        }
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);

            let lObjCell =  (Cells.find(item => (item.Value === lItem.Value)))

            if (lObjCell) {
                lObjCell.Size = itemSize;
            }
            //TODO использован реф для того, чтобы не перерисовывались дочки, т.к. если делать через стейт, то дочки перерисовываются, это и задержки и ошибки при ресайзе.
            // this.setState({prevCells: Cells});
            this.memoRef = Cells;
        }
    }

    // переключение страниц пагинации
    handlePageChange(pageNumber) {
        let query = this.props.router.location.query;
        let queryLink = "";


        queryLink = Routing.CreateRef({
            oldQuery : query,
            newQuery : query,
            excludedKeys : ["d"]
        });

        //Новый прелоадер


        //TODO ???? dk
        let Direction="";

            if (this.props.sortDir) {
                Direction = "icon-Filter_up";
            } else {
                Direction = "icon-Filter_down";
            }

        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "MainTable115" }
        }));

        if(this.props.searchInputs.find(item => item.IsApplied)) {
            this.props.ActionQueue({
                List : [
                    {action : this.props.resetMainTableForUpdate, params : {}, name : "resetMainTableForUpdate"},
                    {action : this.props.setPaginationPageNumber, params : {PaginationPageNumber : pageNumber}, name : "setPaginationPageNumber"},
                    {action : this.props.mainTableSearchDoc, params : {
                            PaginationPageNumber : pageNumber,
                            FieldSorting: this.props.FieldSorting,
                            Direction,
                        }, name : "mainTableSearchDoc"},
                    {action : this.props.combineMainTableAttributes, params : {}, name : "combineMainTableAttributes"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "MainTable137"}, name : "setLoaderModalData"},
                    {action : this.props.changeDocumentViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly}, name : "changeDocumentViewMode"},
                ],
                debug : false,
            });
        } else {
            this.props.ActionQueue({
                List : [
                    {action : this.props.resetMainTableForUpdate, params : {}, name : "resetMainTableForUpdate"},
                    {action : this.props.setPaginationPageNumber, params : {PaginationPageNumber : pageNumber}, name : "setPaginationPageNumber"},
                    {action : this.props.mainTableDownloadDocCollection, params : {}, name : "mainTableDownloadDocCollection"},
                    {action : this.props.combineMainTableAttributes, params : {}, name : "combineMainTableAttributes"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "MainTable149"}, name : "setLoaderModalData"},
                    {action : this.props.changeDocumentViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly}, name : "changeDocumentViewMode"},
                ],
                debug : false,
            });
        }
        history.push(queryLink)
    }

    render() {

        let isEmpty = false;
        if(Math.ceil(this.props.mainTable.collection.totalInServer / this.props.mainTable.paginationPageSize)>0){
            isEmpty = true
        }

            return (
                <>
                        <MainTableSearch fitInContainerSize={this.fitInContainerSize} unFitInContainerSize={this.unFitInContainerSize}/>
                    <div className="card-body py-0 table-responsive" style={{height : "70vh"}}  ref={this.tableWidthRef}>

                        <div> {/*прячем контекстное меню при скроле таблицы*/}
                            <div className="" onScroll={()=>
                            {
                                if(this.props.contextMenu.name !== "") {
                                    this.props.setContextMenuData({});
                                }
                            }}>
                                <table className="table table-head-custom table-striped table-hover table-vertical-center" id="mainTable" style={{ tableLayout : "fixed"}}  ref={this.rowWidthRef}>
                                    {<Thead setWidth = {(itemSize, item) => this.setWidth(itemSize, item)}/>}
                                    {<Tbody/> }
                                </table>
                            </div>


                        </div>

                    </div>
                    <div className="card-footer py-0">

                        <div className="d-flex justify-content-between align-items-center flex-wrap pt-3 pb-3">
                            <Pagination
                                activePage={this.props.mainTable.paginationPageNumber} // активная страница
                                itemsCountPerPage={Number.parseInt(this.props.mainTable.paginationPageSize)} // элементов на странице
                                totalItemsCount={this.props.mainTable.collection.totalInServer} // всего элементов
                                pageRangeDisplayed={this.props.mainTable.paginationButtonsCount} // сколько кнопок отображать
                                onChange={this.handlePageChange}  // обработчик переключения
                                itemClass={stylePagination.item} // стили item-ов
                                linkClass={"btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"} // стиль для ссылок
                                activeLinkClass={"btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"} // стиль для активных ссылок
                            />
                            {
                                isEmpty ?
                                    <span className={`text-muted  ${this.props.preview === 'normal' ? 'd-none-1700px' : 'd-none-1450px'  } `}>
                                        Cтраница {this.props.mainTable.paginationPageNumber} из {Math.ceil(this.props.mainTable.collection.totalInServer / this.props.mainTable.paginationPageSize)}
                                    </span>

                                    :
                                    <span className='text-muted'>
                                         нет данных для отображения
                                    </span>

                            }
                            <PaginationCounter
                                totalItemsCount={this.props.mainTable.collection.totalInServer} // всего элементов
                                activePage={this.props.mainTable.paginationPageNumber} // активная страница
                                paginationPageSize={Number.parseInt(this.props.mainTable.paginationPageSize)} // элементов на странице
                            />
                        </div>

                    </div>
                </>
            );
    }
}


const  mapStateToProps = state => {
    return {
        mainTable : state.document.mainTable,
        contextMenu :  state.globalState.app.contextMenu,
        searchInputs : state.document.search.searchInputs,
        header : state.document.mainTable.header,
        router : state.router,
        sortDir:state.document.mainTable.sortDirection,
        FieldSorting: state.document.mainTable.sortField,
        preview: state.document.preview.fixingPreview,
    }
}


const  mapDispatchToProps = {
    ActionQueue,
    setPaginationPageNumber,
    resetMainTableForUpdate,
    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    setContextMenuData,
    mainTableSearchDoc,
    setModalData,
    setLoaderModalData,
    changeDocumentViewMode,
    setDefaultWidthOfColumn
}

export default connect(mapStateToProps,mapDispatchToProps)(MainTable);




