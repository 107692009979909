import React from "react";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {connect} from "react-redux";
import {
    deleteSectionsForTree,
    getSectionsForTree, saveSectionsForTree
} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";

import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {store} from "../../../index";
import Select from "react-select";
import {
    SaveOrUpdateAndDeleteByFilter
} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {DOCUMENT_PREVIEW_ACTIONS} from "../../../store/documents/documentActionsList";
import {setDepartmentsData} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {PACKAGES_TREE_ACTIONS} from "../../../store/packages/packageActionsList";
import {SEARCH_PREVIEW_ACTIONS} from "../../../store/search/searchActionsList";

class ChangeDepartments extends React.Component {
    constructor(props) {
        super(props);

        const sectionName = attributesSectionsTypes.departmentsSection;
        let sectionData = this.props.data.departmentsData
        if (!sectionData) {
            let additionalSectionsData = this.props.data.search ? this.props.additionalSectionsDataSearch : this.props.additionalSectionsData
            sectionData = additionalSectionsData.state[sectionName].departmentsData;
        }

        let departmentRefs = __.deepCopy(this.props.departments)
        departmentRefs.map(item => item.isChecked = !!sectionData.find(dep => item.Id === +dep.IdDepartment))
        const initialDepartmentRefs = departmentRefs

        departmentRefs.forEach(item => {
                if (!item.IdParent || departmentRefs.some(dep => dep.IdParent === item.Id)) {
                    item.expandChildren = true
                }
            }
        )

        this.state = {
            contentOnHover: false,
            searchValue: "",
            selectObject: {
                value: "combined",
                label: "Комбинированный"
            },
            mainCheckbox: !!departmentRefs.every(item => item.isChecked),
            expandAll: true,
            departmentRefs,
            initialDepartmentRefs,
        };

        this.hide = this.hide.bind(this);
        this.sendDepartmentRefs = this.sendDepartmentRefs.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this)
        this.toggleCheckbox = this.toggleCheckbox.bind(this)
        this.hideOutSide = this.hideOutSide.bind(this)
        this.hasChildren = this.hasChildren.bind(this)
        this.expand = this.expand.bind(this)
        this.addParents = this.addParents.bind(this)
        this.toggleMainCheckbox = this.toggleMainCheckbox.bind(this)
    }


    hide() {

        this.props.setModalData({});
    }

    hideOutSide() {
        if (!this.state.contentOnHover) {
            this.hide()
        }
    }

    addParents(arr) {
        let arrChanged = false
        arr.forEach(item => {
            const parent = this.state.initialDepartmentRefs.find(ref => ref.Id === item.IdParent)
            if (parent && !arr.includes(parent)) {
                arr.push(parent)
                arrChanged = true
            }
        })
        if (arrChanged) this.addParents(arr)
    }

    changeSearchText(e) {
        this.setState({searchValue: e.target.value})
        const tempRef = this.state.initialDepartmentRefs.filter(item => item.Name.toLowerCase().includes(e.target.value.toLowerCase()))
        this.addParents(tempRef)
        this.setState({departmentRefs: tempRef})
    }

    toggleMainCheckbox() {
        let items = __.deepCopy(this.state.departmentRefs)

        this.setState({mainCheckbox: !this.state.mainCheckbox})
        items.map(item => item.isChecked = !this.state.mainCheckbox)

        if (this.state.searchValue === "")
            this.setState({initialDepartmentRefs: items})
        else {
            const initialDepartmentRefs = this.state.initialDepartmentRefs
            items.forEach(item => {
                const temp = initialDepartmentRefs.find(elem => elem.Id === item.Id)
                if (temp) temp.isChecked = item.isChecked
            })
            this.setState({initialDepartmentRefs: initialDepartmentRefs})
        }
        this.setState({departmentRefs: items})
    }

    toggleCheckbox(element, value = !element.isChecked, direction = 0) {
        let items = this.state.departmentRefs
        if (!items.includes(element)) items.push(element)
        items.find(item => item.Id === element.Id).isChecked = value

        this.setState({departmentRefs: items})

        if ((this.state.selectObject.value === "combined" || this.state.selectObject.value === "down") && direction !== 1) {
            let children = items.filter(item => item.IdParent === element.Id)
            children.forEach(child => this.toggleCheckbox(child, value, -1))
        }

        if ((this.state.selectObject.value === "combined" || this.state.selectObject.value === "up") && direction !== -1) {
            if (element.IdParent) {
                let parent = items.find(item => item.Id === element.IdParent)
                if (value) this.toggleCheckbox(parent, value, 1)
                else if (items.filter(item => item.IdParent === element.IdParent).every(item => !item.isChecked)) this.toggleCheckbox(parent, false, 1)
            }
        }
    }

    sendDepartmentRefs() {
        let departmentRefs = this.state.departmentRefs
        let collection = []
        let IdDoc

        if (this.props.data?.IdDoc) IdDoc = this.props.data.IdDoc
        if (this.props.activeDocument && !IdDoc) IdDoc = this.props.activeDocument.Info.Version.IdDoc

        if (this.state.searchValue !== "") {
            departmentRefs = [...departmentRefs, ...this.state.initialDepartmentRefs.filter(item => !departmentRefs.find(dep => item === dep))]
        }
        departmentRefs.forEach(item => {
            if (item.isChecked) {
                collection.push({
                    IdDepartment: item.Id.toString(),
                    IdDoc: IdDoc.toString()
                })
            }
        })

        // Отправка новых данных на сервер
        // и диспатч, чтобы не пришлось запрашивать информацию с сервера заново
        this.props.SaveOrUpdateAndDeleteByFilter({IdDoc, collection})

        store.dispatch({
            type: DOCUMENT_PREVIEW_ACTIONS.UPDATE_DATA_FOR_DEPARTMENTS_SECTION,
            payload: { departmentsSection: collection }
        })
        store.dispatch({
            type: SEARCH_PREVIEW_ACTIONS.UPDATE_DATA_FOR_DEPARTMENTS_SECTION,
            payload: { departmentsSection: collection }
        })
        store.dispatch({
            type: DOCUMENT_PREVIEW_ACTIONS.CREATE_DOC_CARDS,
            payload: { departmentsSection: collection }
        })

        if (this.props.data.tableRole){
            store.dispatch({
                type: PACKAGES_TREE_ACTIONS.SET_DEPARTMENTS_DATA,
                payload: {departmentsSection: collection, tableType: this.props.data.tableRole}
            })
        }
        this.hide();
    }

    //проверяет, есть ли потомки
    hasChildren(parent) {
        return !!this.state.initialDepartmentRefs.filter(item => item.IdParent === parent.Id).length
    }

    expand(parent) {
        const departmentRefs = this.state.departmentRefs
        departmentRefs.find(item => (item.Id === parent.Id)).expandChildren = !parent.expandChildren
        this.setState({departmentRefs: departmentRefs})
    }

    render() {
        let modalStyle = {
            display: "block",
            backgroundColor: "#061c3e66",
        };

        const departmentIds = []
        const options = [
            {
                value: "combined",
                label: "Комбинированный"
            },
            {
                value: "independent",
                label: "Независимый"
            },
            {
                value: "up",
                label: "Снизу вверх"
            },
            {
                value: "down",
                label: "Сверху вниз"
            },
        ]


        const parentComponent = ({parent}) => {
            const children = parent.expandChildren ? this.state.departmentRefs.filter(item => item.IdParent === parent.Id) : null
            const hasChildren = this.hasChildren(parent)
            departmentIds.push(parent.Id)
            return(
                <li key={__.createUniqueIdString(6)} className="li-podr">
                    <input type="checkbox"
                           checked={parent.isChecked}
                           onChange={() => this.toggleCheckbox(parent)}
                    />
                    <span><i className="svg-icon text-success svg-icon-xs icon-filial_24 mr-1 ml-1"/>
                        {hasChildren ? <b>{parent.Name}</b> : parent.Name}
                    </span>
                    {hasChildren
                        && <>
                            <span className={`ml-4 ${parent.expandChildren ? "icon-Arrows_2" : "icon-Arrows_3"}`}
                                   onClick={() => this.expand(parent)}
                                   role="button"
                            />

                            {parent.expandChildren &&
                                <ul>
                                    {hasChildren && children.map(item => parentComponent({parent: item}))}
                                    {this.state.departmentRefs.filter(item => item.isChecked).map(item => {
                                        if (item.IdParent === parent.Id && !departmentIds.includes(item.Id)) {
                                            departmentIds.push(item.Id)

                                            {hasChildren && parentComponent({parent:item})}

                                            return (
                                                <li key={__.createUniqueIdString(6)}
                                                    className={`li-podr ${!parent.expandChildren ? "d-none" : ""}`}>
                                                    <input type="checkbox"
                                                           checked={item.isChecked}
                                                           onChange={() => this.toggleCheckbox(parent)}
                                                    />
                                                    <span>
                                                        <i className="svg-icon text-success svg-icon-xs icon-filial_24 mr-1 ml-1"/>
                                                        {item.Name}
                                                    </span>
                                                </li>
                                            )
                                        } else return null
                                    })}
                                </ul>
                            }
                        </>
                    }
                </li>
            )
        }

        return (
            <div className="modal fade show modal-custom" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content"
                         onMouseEnter={() => this.setState({contentOnHover: true})}
                         onMouseLeave={() =>this.setState({contentOnHover: false})}
                         onClick={(e) => e.stopPropagation()}
                    >

                        <div className="modal-header p-4 border-bottom-0">
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Изменение подразделения документа
                            </h5>
                        </div>

                        <div className="row p-4 border-bottom-0">
                            <div className="col-lg-5">
                                <p>Найти подразделение</p>
                                <div className="input-icon h-40px">
                                    <input type="text"
                                           className="form-control form-control-lg h-40px"
                                           placeholder="Поиск"
                                           value={this.state.searchValue}
                                           onChange={(e) => this.changeSearchText(e)}
                                    />
                                    <span>
                                        <span className="svg-icon svg-icon-lg">
                                            <i className="svg-icon svg-icon-lg icon-Search"></i>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-7">
                                <div className="form-group">
                                    <label className="mb-4">Режим выделения</label>

                                    <Select options={options}
                                            value={options.find(item => item.label === this.state.selectObject.label)}
                                            onChange={(value) => this.setState({selectObject: value})}
                                            isSearchable={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'var(--hover-primary)',
                                                    primary25: 'var(--light-primary)',
                                                    primary50: 'var(--hover-primary)',
                                                    primary: 'var(--originaltwo)',
                                                },
                                            })}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="modal-body beautiful-scroll-10 modal-body-departments">
                            {this.state.departmentRefs.length !== 0
                                && <div>
                                    <input type="checkbox" checked={this.state.mainCheckbox}
                                           onChange={() => this.toggleMainCheckbox()}
                                           style={{ accentColor: "var(--originaltwo)"}}
                                    />
                                    <span>
                                        <i className="svg-icon text-success svg-icon-xs icon-department mr-1 ml-1"/>
                                        <b>{store.getState().globalState.filial.Active.Name}</b>
                                    </span>
                                    <span className={`svg-icon text-success svg-icon-xs ${this.state.expandAll ? "icon-Arrows_2" : "icon-Arrows_3"} mr-1 ml-4`}
                                          onClick={() => this.setState({expandAll: !this.state.expandAll})}
                                          role="button"
                                    />

                                    {/*Начало формирования списка*/}
                                    {this.state.expandAll &&
                                        <ul>
                                            {this.state.departmentRefs.filter(item => !item.IdParent).map((parent) => parentComponent({parent}))}
                                        </ul>
                                    }
                                </div>
                            }
                        </div>

                        <div className="modal-footer p-4 border-bottom-0">
                            <button className="btn btn-primary font-weight-bolder" onClick={this.sendDepartmentRefs}>
                                Применить к документу
                            </button>
                            <button className="btn btn-light font-weight-bold" onClick={this.hide}>
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        activeDocument : state.document.mainTable.activeRow,
        activeDocumentSection: state.document.tree.activeSection,
        additionalSectionsData : state.document.additionalSections,
        additionalSectionsDataSearch: state.search.additionalSections,
        allGroups: state.document.tree.group,
        departments: store.getState().globalState.departments,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    setDepartmentsData,
    setModalData,
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    getSectionsForTree,
    deleteSectionsForTree,
    saveSectionsForTree,
    SaveOrUpdateAndDeleteByFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDepartments);
