import {DocumentVersion} from "../documents/DocumentVersion";
import {DocumentType} from "../documents/DocumentType";
import {Attribute} from "../attributes/Attribute";

// документ в пакетах
export class PackagesDocument {

    // number
    Id;
    // number
    IdFilial;
    //number
    IdPackageDocRef;
    // boolean
    IsAuto;
    // boolean
    IsCurrentVersion;
    // boolean
    IsMarkedForDelete;
    // string
    Name;
    // string (преобразовать в DateTime)
    RegDate;
    // string (преобразовать в DateTime)
    RegNumber;
    // obj (обычно приходит null)
    SortingAttributeValue;
    // class Attribute
    Attributes = [];
    // class DocumentType
    Type;
    // class DocumentVersion
    Version;

    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.IdFilial = DataFromServer.IdFilial;
        this.IdPackageDocRef = DataFromServer.IdPackageDocRef;
        this.IsAuto = DataFromServer.IsAuto;
        this.IsCurrentVersion = DataFromServer.IsCurrentVersion;
        this.IsMarkedForDelete = DataFromServer.IsMarkedForDelete;
        this.Name = DataFromServer.Name;
        this.RegDate = DataFromServer.RegDate;
        this.RegNumber = DataFromServer.RegNumber;
        this.SortingAttributeValue = DataFromServer.SortingAttributeValue;
        this.ShortDescription = DataFromServer.ShortDescription;

        this.Version = new DocumentVersion(DataFromServer.Version);
        this.Type = new DocumentType(DataFromServer.Type);

        for(let i = 0; i < DataFromServer.Attributes.length; i++) {
            this.Attributes.push(new Attribute(DataFromServer.Attributes[i]));
        }
    }

}