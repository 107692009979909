import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {
    ModalTypes,
} from "../../tools/StaticTypes";
import {history} from "../../index";

import {setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";


class HistoryPackages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.goToPackage = this.goToPackage.bind(this);
    }

    async goToPackage(item) {
        let link = `/packages?p=${item.Id}`;
        if(item.filial != this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id == item.filial).Name;
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {
                    type: "question",
                    content: `Данный пакет был открыт в другогом филиале: <b>${filialName}</b> <br />Сменить филиал и перейти к пакету?`,
                    disableButton: false,
                    fullBackground: false,
                    onClickContinue: async () => {history.replace(link.concat(`&f=${item.filial}`))}
                }
            });
            return;
        }
        history.replace(link);
    }



    render() {

        let packageHistory = this.props.userSettings.find((userSetting => userSetting.Name === "packageConfig"));






        return (

            <div className="row col-lg-12">


                {
                    packageHistory?.Value &&
                    <div className="col-lg-12">
                        <div className="card card-custom gutter-b">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">Последние пакеты</h3>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="row col-lg-12">

                                    {
                                        packageHistory?.Value &&
                                        packageHistory.Value.map((item, index) => {
                                            let iconColorStyle = {};
                                            if(item.filial != this.props.activeFilialId) {
                                                iconColorStyle = {
                                                    opacity : "50%"
                                                }
                                            }

                                            return (
                                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6"
                                                     key={createUniqueIdString(6)}>
                                                    <div className="packet packet-custom packet-stretch pb-4">
                                                    <span className="link" onClick={() => {
                                                        this.goToPackage(item)
                                                    }}>
                                                        <div className="packet-body text-center pt-1">
                                                            <div className="my-2">
                                                <span className="svg-icon menu-icon icon-xl" style={iconColorStyle}>
                                                    <i className={`icon-pak_on icon-color-primary`}
                                                       style={{fontSize: "4rem"}}/>
                                                </span>
                                                            </div>
                                                            <div className="my-1">
                                                                <p className="text-dark font-weight-bold">{item.Name}</p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }



            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        activeFilialId: state.globalState.filial.Active.Id,
        allFilials : state.globalState.filial.All,
        userSettings: state.globalState.userSettings,
    }
}

const mapDispatchToProps = {
    setModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPackages);
