import React from "react";
import {connect} from "react-redux";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import MobileMenu from "../static/MobileMenu";
import Iframe from "react-iframe";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl, baseUrl} from "../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../tools/Filters";


class HelpApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            lData: []
        };
    }

    async componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose: true
        });
        let request = await new apiRequest(apiUrl.USERS.GET_GUIDES,{
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("UserGuide")
                            .add({Name: "IsActive", Value: "true"}).get()
                    )
                    .add(
                        new EntityFilters().setEntityName("ArmTypeUserGuideRef")
                            .add({Name: "ArmType", Value: "2"})
                            .addAttribute({Name: "ReferenceProperty", Value: "IdUserGuide"})
                            .addAttribute({Name: "RootEntityProperty", Value: "Id"})
                            .get()
                    )
                    .addAttribute({Name: "PagingFilter", Value: {PageSize : 25}})
                    .get()
                ,
                sorting : new Sorting()
                    .add({Direction: 1,PropertyName : "Order"})
                    .get()
            }
        )

        //TODO в приходящих объектах отсутствует поле FileSize, из-за чего на странице Размер файла пишет "NaN Мбайт"
        request.execute((data)=> {
            this.setState({lData: data.Records, id: data.Records[0].Id})
        })
    }

    render() {

        let asideMobileStyle = "";
        // if (!this.props.asideMenuIsClose) {
        //     asideMobileStyle = "aside-on";
        // }
        let lNotData = this.state.lData ? null : " отсутствует";
        let mod = this.props.theme.modString;

        return (
                    <div>
                        <div className="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu">

                            <MobileMenu rootName={"HelpApp"}/>

                            <div className="d-flex flex-column flex-root">
                                <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                                    <LeftMenu rootName="HelpApp"/>
                                </div>

                                <div className="d-flex flex-column flex-row-fluid wrapper">

                                    <div className="content d-flex flex-column flex-column-fluid">
                                        <TopMenu rootName="HelpApp"/>
                                    </div>

                                    <div className="d-flex flex-column-fluid px-4">
                                        <div className="container-new">
                                            <div className="row pt-4">
                                                <div className="col-lg-12" style={{padding: "0"}}>
                                                    <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary" style={{
                                                            height: "350px",
                                                            backgroundImage: `url('./images/help${mod}.svg')`
                                                        }}>
                                                        <div className="card-body d-flex"
                                                             style={{padding: "4rem 3.25rem"}}>
                                                            <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                                                <div className="flex-grow-1">
                                                                    <span
                                                                    className="text-welcome font-weight-bolder font-size-h1">Справка{lNotData}
                                                                    </span>
                                                                    {!lNotData &&
                                                                        <p className="text-welcome font-weight-bold mt-3">Изучите
                                                                            устройство приложения "Электронный
                                                                            Архив",<br/> чтобы
                                                                            воспользоваться полным объемом возможностей.
                                                                        </p>
                                                                    }
                                                                </div>
                                                                {/*<a href={"/media/Справка.pdf"} download={true} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                                                {/*      style={{borderRadius: "0.8em"}}>*/}
                                                                {/*    Скачать*/}
                                                                {/*</a>*/}


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !lNotData &&

                                                <>

                                                <div className="card card-custom col-lg-12 gutter-b py-4">
                                                    <div className="card-body p-0">
                                                        <div className="tab-content">
                                                            <div className="tab-pane fade show active">
                                                                <div className="card card-custom px-2 py-2" style={{border: "none"}}>
                                                                    <div className="card-body py-0"
                                                                         style={{padding: "0"}}>
                                                                        <div className="table-responsive">
                                                                            {/*<MainTable Table={UserGuides.Table}*/}
                                                                            {/*           UpdateCounter={this.state.UpdateCounter}/>*/}
                                                                            <table className="table table-head-custom table-vertical-center table-hover table-striped mb-0
                                                                    unselectable"
                                                                                //    style="grid-template-columns: 50px
                                                                                // 370px 215px 175.125px 226px; display: grid;"
                                                                            >
                                                                                <thead
                                                                                    // style="display: contents;"
                                                                                >
                                                                                <tr
                                                                                    // style="z-index: 100; display: contents;"
                                                                                >
                                                                                    {/*<th></th>*/}
                                                                                    <th className="pl-0"
                                                                                        // style="z-index: 100;
                                                                                    >
                                                                                        <div
                                                                                            // style="display: flex; justify-content: center;"
                                                                                        >
                                                                            <span
                                                                                title="Сортировать &quot;Наименование&quot;"
                                                                                className="ellipsis-custom"
                                                                                // style="cursor: pointer;"
                                                                            >Наименование &nbsp;
                                                                            </span>
                                                                                        </div>
                                                                                        {/*<i className="resize-handle-icon icon-color-primary  svg-icon svg-icon-sm icon-size_height_16-1 px-2"*/}
                                                                                        {/*   style={{background: "white"}}></i>*/}
                                                                                    </th>
                                                                                    <th className="pl-0"
                                                                                        // style="z-index: 100;"
                                                                                    >
                                                                                        <div
                                                                                            // style="display: flex; justify-content: center;"
                                                                                        >
                                                                            <span
                                                                                title="Сортировать &quot;Размер файла&quot;"
                                                                                className="ellipsis-custom"
                                                                                // style="cursor: pointer;"
                                                                            >Размер файла &nbsp;</span>
                                                                                        </div>
                                                                                        {/*/!*<i className="resize-handle-icon icon-color-primary  svg-icon svg-icon-sm icon-size_height_16-1 px-2"*!/*/}
                                                                                        {/*    // style="background: white;"*/}
                                                                                        {/*></i>*/}
                                                                                </th>
                                                                                    <th className="pl-0"
                                                                                        // style="z-index: 100;"
                                                                                    >
                                                                                        <div
                                                                                            // style="display: flex; justify-content: center;"
                                                                                        >
                                                                            <span
                                                                                title="Сортировать &quot;Скачать&quot;"
                                                                                className="ellipsis-custom"
                                                                                // style="cursor: pointer;"
                                                                            >Скачать &nbsp;</span>

                                                                                        </div>
                                                                                        {/*<i className="resize-handle-icon icon-color-primary  svg-icon svg-icon-sm icon-size_height_16-1 px-2"*/}
                                                                                        {/*    // style="background: white;"*!/*/}
                                                                                        {/*></i>*/}
                                                                                    </th>
                                                                                    <th className="pl-0"
                                                                                        // style="z-index: 100;"
                                                                                    >
                                                                                        <div
                                                                                            // style="display: flex; justify-content: center;"
                                                                                        >
                                                                            <span
                                                                                title="Сортировать &quot;Дата изменения&quot;"
                                                                                className="ellipsis-custom"
                                                                                // style="cursor: pointer;"
                                                                            >Дата изменения &nbsp;</span>
                                                                                        </div>
                                                                                        {/*<i className="resize-handle-icon icon-color-primary  svg-icon svg-icon-sm icon-size_height_16-1 px-2"*/}
                                                                                        {/*    // style="background: white;"*/}
                                                                                        {/*></i>*/}
                                                                                    </th>

                                                                                    <th className="pl-0"
                                                                                        // style="z-index: 100;"
                                                                                    >
                                                                                        <div
                                                                                            // style="display: flex; justify-content: center;"
                                                                                        >
                                                                            <span
                                                                                title="Сортировать &quot;Описание&quot;"
                                                                                className="ellipsis-custom"
                                                                                // style="cursor: pointer;"
                                                                            >Описание &nbsp;</span>
                                                                                        </div>
                                                                                        {/*<i className="resize-handle-icon icon-color-primary  svg-icon svg-icon-sm icon-size_height_16-1 px-2"*/}
                                                                                        {/*    // style="background: white;"*/}
                                                                                        {/*></i>*/}
                                                                                    </th>


                                                                                </tr>
                                                                                </thead>
                                                                                <tbody
                                                                                    // style="display: contents;"
                                                                                >


                                                                                {
                                                                                    this.state.lData.map((item, index) => {
                                                                                        if (!item) return null;
                                                                                        return (

                                                                                            <tr
                                                                                                onClick={() => this.setState({id: item.Id})}
                                                                                                key={item.Id}
                                                                                                // style="display: contents;"
                                                                                            >
                                                                                                {/*<td style={this.state.id == item.Id ? {*/}
                                                                                                {/*    backgroundColor: "rgb(27 42 223 / 15%)",*/}
                                                                                                {/*    borderRadius: "0 0 0.42rem 0.42rem",*/}
                                                                                                {/*    width: '150px'*/}
                                                                                                {/*} : {}}*/}
                                                                                                {/*    >*/}
                                                                                                {/*    <i className="fa fa-plus"*/}
                                                                                                {/*       aria-hidden="true"></i><span*/}
                                                                                                {/*    className="text-dark-50 font-weight-bolder d-block font-size-lg"><i*/}
                                                                                                {/*    className="menu-arrow icon-expand_16 pr-5 icon-color-primary"*/}


                                                                                                {/*    // style="color: rgb(32, 75, 160);"*/}
                                                                                                {/*></i></span>*/}
                                                                                                {/*</td>*/}
                                                                                                <td tabIndex="0"
                                                                                                    style={this.state.id == item.Id ? {
                                                                                                        backgroundColor: "var(--light-primary)",
                                                                                                        // borderRadius: "0 0 0.42rem 0.42rem",
                                                                                                        width: '150px'
                                                                                                    } : {}}>
                                                                                                    <div
                                                                                                        className="item">
                                                                                                        <span>{item.Name}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex="1"
                                                                                                    style={this.state.id == item.Id ? {
                                                                                                        backgroundColor: "var(--light-primary)",
                                                                                                        // borderRadius: "0 0 0.42rem 0.42rem",
                                                                                                        width: '150px'
                                                                                                    } : {}}>
                                                                                                    <div
                                                                                                        className="item">
                                                                                                        {item.FileSize === undefined ? <span> — </span> : <span> {(item.FileSize / 1048576).toFixed(2)} Мбайт</span> }
                                                                                                    {/* TODO: MS 28.02*/}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex="2"
                                                                                                    style={this.state.id == item.Id ? {
                                                                                                        backgroundColor: "var(--light-primary)",
                                                                                                        // borderRadius: "0 0 0.42rem 0.42rem",
                                                                                                        width: '150px'
                                                                                                    } : {}}>
                                                                                                    <div
                                                                                                        className="item"><span> <a
                                                                                                        href={`${baseUrl}/UserGuides/DownloadFile/${item.Id}/true`}
                                                                                                        download={true}><i
                                                                                                        className="menu-icon icon-Document_upload1"></i></a></span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex="3"
                                                                                                    style={this.state.id == item.Id ? {
                                                                                                        backgroundColor: "var(--light-primary)",
                                                                                                        // borderRadius: "0 0 0.42rem 0.42rem",
                                                                                                        width: '150px'
                                                                                                    } : {}}>
                                                                                                    <div
                                                                                                        className="item">
                                                                                                        <span>{new Date(item.ModifyTime).toLocaleString()}</span>
                                                                                                    </div>
                                                                                                </td>

                                                                                                <td tabIndex="4"
                                                                                                    style={this.state.id == item.Id ? {
                                                                                                        backgroundColor: "var(--light-primary)",
                                                                                                        // borderRadius: "0 0 0.42rem 0.42rem",
                                                                                                        width: '150px'
                                                                                                    } : {}}>
                                                                                                    <div
                                                                                                        className="item">
                                                                                                        <span> {item.Description}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className={"row"} style={{padding: "0"}}>
                                                <div className={"col-lg-12"} style={{height: "800px", padding: "2px"}}>
                                            {/*<Iframe url={"/media/Справка.pdf"}*/}
                                                <Iframe
                                                src={this.state.id ? `${baseUrl}/UserGuides/DownloadFile/${this.state.id}/false` : ``}
                                                width="100%"
                                                height="100%"
                                                id="helpFile"
                                                display="initial"
                                                position="relative"/>
                                                </div>
                                                </div>
                                                </>
                                            }
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

        );
    }
}


const  mapStateToProps = state => {
    return {
        rootState : state.report.rootState,
        isReady : state.report.leftMenu.isReady,
        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
        Help: state.Help,
        theme: state.globalState.theme,
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
}


export default connect(mapStateToProps, mapDispatchToProps)(HelpApp);
