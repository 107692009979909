import produce from "immer";
import {FILIAL_TREE_ACTIONS} from "./filialActionsList";

const tree = {

    openedFolders: [],
    foundFilial:[],
    isSearch:false,
};

const initialState = {
    tree,
};

export const filialReducer = (state = initialState, action) => {
    return produce(state, newState => {

        // tree
        //получение документов в пакете
        if (action.type === FILIAL_TREE_ACTIONS.TOGGLE_FOLDERS) {
            newState.tree.openedFolders = action.payload.openedFolders;
        }
        else if (action.type === FILIAL_TREE_ACTIONS.FOUND_FILIAL) {
            newState.tree.foundFilial = action.payload.foundFilial;
            newState.tree.isSearch = action.payload.IsSearch;
        }

    })
}
