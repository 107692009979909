import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {AdminLevelEnum, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {history} from "../../index";

import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import {ActionQueue} from "../../store/rootReducer";


class HistorySearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            attributes: false,
            fullText: false,
            date: false,
            additionalFilters: false,
        };

        this.search = this.search.bind(this);
    }

    search(item) {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData, params : {data: {
                            content: "Загрузка...",
                            disableButton: true,
                            fullBackground: false,
                            gif: "search",
                            key: "HistorySearch45",
                        }}, name : "setLoaderModalData"},
                {action : this.props.updateInputsFieldSearch, params : {
                    SearchText: item.SearchText,
                        AttributesText: item.AttributesText,
                        FullText: item.FullText,
                        IsSearchInVersions: item.IsSearchInVersions,
                        IsSearchInMarkedForDelete: item.IsSearchInMarkedForDelete,
                        IsSearchInAttachments: item.IsSearchInAttachments,
                        TypeIds: item.TypeIds,
                        FilialIds: item.FilialIds,
                        RegDateAsString: item.RegDateAsString,
                        RegPeriodStart: item.RegPeriodStart,
                        RegPeriodFinish: item.RegPeriodFinish,
                        RegNumber: item.RegNumber,
                        Name: item.Name
                    }, name : "updateInputsFieldSearch"},
                {action : this.props.searchByParams, params : {}, name : "searchByParams"},
                {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                {action : this.props.changeSearchViewMode, params : {ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly}, name : "changeSearchViewMode"},
                {action : this.props.setLoaderModalData, params : {keyDeleted: "HistorySearch66"}, name : "setLoaderModalData"},
                {action : () => history.replace("/search"), params : {}, name : "history.replace"},
            ],
            debug : false,
        });
    }


    render() {

        let searchHistory = this.props.userSettings.find((userSetting => userSetting.Name === "searchConfig"));


        // let attributes
        // let fullText
        // let date
        // let additionalFilters
        //
        // if (this.state.attributes) {
        //     attributes = "btn-light-success"
        // } else {
        //     attributes = "btn-light"
        // }
        //
        // if (this.state.fullText) {
        //     fullText = "btn-light-success"
        // } else {
        //     fullText = "btn-light"
        // }
        //
        // if (this.state.date) {
        //     date = "btn-light-success"
        // } else {
        //     date = "btn-light"
        // }
        //
        // if (this.state.additionalFilters) {
        //     additionalFilters = "btn-light-success"
        // } else {
        //     additionalFilters = "btn-light"
        // }

        return (
            <>

                {
                    searchHistory?.Value &&
                    <div className="row col-lg-12">
                        <div className="col-lg-12">
                            <div className="card card-custom gutter-b mb-2">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">История поиска</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="row col-lg-12">
                    <div className="col-lg-12">
                        <div className="row">
                            {
                                searchHistory?.Value &&
                                searchHistory.Value.map((item, index) => {
                                    let date = "btn-light";
                                    let attributes = "btn-light";
                                    let fullText = "btn-light";
                                    let additionalFilters = "btn-light";
                                    if (item.RegDateAsString || item.RegPeriodStart || item.RegPeriodFinish) {

                                        date = "btn-light-success";
                                    }
                                    if (item.AttributesText) {
                                        attributes = "btn-light-success";
                                    }
                                    if (item.FullText) {
                                        fullText = "btn-light-success";
                                    }
                                    if (item.FilialIds.length > 0 || item.IsSearchInAttachments || item.IsSearchInVersions || item.IsSearchInMarkedForDelete || item.TypeIds?.length > 0 || item.Name || item.RegNumber) {
                                        additionalFilters = "btn-light-success";
                                    }
                                    return (
                                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6" key={createUniqueIdString(6)}>
                                            <div className="card card-custom gutter-b card-stretch">
                                                <div className="card-body text-left pt-4">
                                                    <div className="my-6">
                                                    <span
                                                        className="text-dark font-weight-bold text-hover-primary font-size-h4"
                                                        style={{cursor: "help"}}
                                                        title={`${item.SearchText ? item.SearchText : "Отсутствует"}`}>
                                                        {`${item.SearchText ? item.SearchText : "Отсутствует"}`}
                                                    </span>
                                                    </div>

                                                    <span
                                                        className={`btn btn-text ${attributes} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`${item.AttributesText ? item.AttributesText : "Нет"}`}>
                                                    Атрибуты
                                                </span>

                                                    <span
                                                        className={`btn btn-text ${fullText} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`${item.FullText ? item.FullText : "Нет"}`}>
                                                    Полнотекст
                                                </span>

                                                    <span
                                                        className={`btn btn-text ${date} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`Дата : ${item.RegDateAsString ? item.RegDateAsString : "Нет"}, C: ${item.RegPeriodStart ? item.RegPeriodStart : "Нет"}, ПО: ${item.RegPeriodFinish ? item.RegPeriodFinish : "Нет"}`}>
                                                    Дата
                                                </span>

                                                    <span
                                                        className={`btn btn-text ${additionalFilters} btn-sm font-weight-bold mt-2 mr-2`}
                                                        style={{cursor: "help"}}
                                                        title={`Поиск в прикрепленных: ${item.IsSearchInAttachments ? "Да" : "Нет"}, Поиск в версиях:  ${item.IsSearchInVersions ? "Да" : "Нет"}, Поиск по удаленным: ${item.IsSearchInMarkedForDelete ? "Да" : "Нет"}, Наименование: ${item.Name ? item.Name : "Нет"}, Номер: ${item.RegNumber ? item.RegNumber : "Нет"}`}>
                                                    Дополнительные фильтры
                                                </span>

                                                    <div className="mt-9" title={"Перейти к результатам поиска"}>
                                                    <span
                                                        className="btn btn-success font-weight-bolder btn-sm py-3 px-6 text-uppercase"
                                                        onClick={() => {
                                                            this.search(item)
                                                        }}>
                                                        <i className="svg-icon svg-icon-xl icon-Search_menu"/>
                                                        Найти
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );

                                })
                            }
                        </div>
                    </div>
                </div>

            </>


        );
    }
}


const mapStateToProps = state => {
    return {
        userSettings: state.globalState.userSettings,
        activeFilialId: state.globalState.filial.Active.Id,
        allFilials : state.globalState.filial.All
    }
}

const mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    updateInputsFieldSearch,
    searchByParams,
    setLoaderModalData,
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistorySearch);
