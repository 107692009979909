import * as XLSX from 'sheetjs-style';
//import * as XLSX from 'xlsx';
import {__} from "../HelpFunctions";

export class MyXLSX {

    constructor(file) {

        const contentFile = new File([file],"Text.xlsx",
            {type : file.type});
        this.test(contentFile);
    }

    async test(file) {


        let wb = XLSX.read(await file.arrayBuffer(), {type : "buffer", cellStyles : true, cellNF : true, cellDates : true});
        let ws = wb.Sheets[wb.SheetNames[0]];

        let lastKey;
        let keys = Object.keys(ws);
        for (let i = 1; i < keys.length; i++) {
            if(keys.at(-i)[0] !== "!") {
                lastKey = keys.at(-i);
                break;
            }
        }

        let ws_json = await XLSX.utils.sheet_to_json(ws, {range : `A2:${lastKey}`, header : ["group", "type", "upload", "download"]});
        let ws_json_headers = await XLSX.utils.sheet_to_json(ws, {header : ["group", "type", "upload", "download"]});

        let stats = {};
        for (let i = 0; i < ws_json.length; i++) {
            if(ws_json[i].group in stats) {
                stats[ws_json[i].group].upload += ws_json[i].upload;
                stats[ws_json[i].group].download += ws_json[i].download;
            }
            else {
                stats[ws_json[i].group] = {
                    upload : ws_json[i].upload,
                    download : ws_json[i].download,
                };
            }
        }

        let stats_array = [];
        let statsKeys = Object.keys(stats);
        for (let i = 0; i < statsKeys.length; i++) {
            let temp = {};
            temp.group = statsKeys[i];
            temp.upload = stats[statsKeys[i]].upload;
            temp.download = stats[statsKeys[i]].download;
            stats_array.push(temp);
        }


        let completeWbJson = __.deepCopy(ws_json);
        completeWbJson.unshift({
            group : stats_array[0].group,
            type : "Количество",
            upload : stats_array[0].upload,
            download : stats_array[0].download,
        });


        let insertCount = 1;
        for (let i = 1; i < completeWbJson.length; i++) {
            if(completeWbJson[i].group !== completeWbJson[i-1].group) {
                completeWbJson.splice(i, 0, {
                    group : stats_array[insertCount].group,
                    type : "Количество",
                    upload : stats_array[insertCount].upload,
                    download : stats_array[insertCount].download,
                })
                insertCount++;
                if(statsKeys.length <= insertCount) {
                    break;
                }
            }
        }




        console.log("completeWbJson", completeWbJson);

        let headerCellsStyle = {
            font : {
                name : "Calibri",
                sz : 12,
                bold : true,
                color : {rgb : "FFFFFFFF"}
            },
            fill : {
                patternType : "solid",
                bgColor : {auto : 1},
                fgColor : {rgb : "FF16365C"}
            },
            alignment : {
                vertical : "center",
                horizontal : "center",
                wrapText : true,
            },
            border : {
                top : {style : "thin", color : "ff000000"},
                bottom : {style : "thin", color : "ff000000"},
                left : {style : "thin", color : "ff000000"},
                right : {style : "thin", color : "ff000000"},
            }
        };

        let bodyCellStyle = {
            border : {
                top : {style : "thin", color : "ff000000"},
                bottom : {style : "thin", color : "ff000000"},
                left : {style : "thin", color : "ff000000"},
                right : {style : "thin", color : "ff000000"},
            }
        };

        let countCellStyle = {
            font : {
                name : "Calibri",
                sz : 12,
                bold : true,
                color : { rgb : "FF0F243E"}
            },
            fill : {
                patternType : "solid",
                bgColor : {auto : 1},
                fgColor : {rgb : "FFC5D9F1"}
            },
            border : {
                top : {style : "thin", color : "ff000000"},
                bottom : {style : "thin", color : "ff000000"},
                left : {style : "thin", color : "ff000000"},
                right : {style : "thin", color : "ff000000"},
            }
        };


        let newWs = await XLSX.utils.json_to_sheet(completeWbJson);
        let range = XLSX.utils.decode_range(newWs["!ref"]);
        console.log("range", range);

        newWs[XLSX.utils.encode_cell({c:0, r: 0})].s = headerCellsStyle;
        newWs[XLSX.utils.encode_cell({c:1, r: 0})].s = headerCellsStyle;
        newWs[XLSX.utils.encode_cell({c:2, r: 0})].s = headerCellsStyle;
        newWs[XLSX.utils.encode_cell({c:3, r: 0})].s = headerCellsStyle;

        let allCountUpload = 0;
        let allCountDownload = 0;
        for (let r = 1; r <= range.e.r; r++) {
            for (let c = 0; c <= range.e.c; c++) {
                if(newWs[XLSX.utils.encode_cell({c,r})].v === "Количество") {
                    newWs[XLSX.utils.encode_cell({c:0, r})].s = countCellStyle;
                    newWs[XLSX.utils.encode_cell({c:1, r})].s = countCellStyle;
                    newWs[XLSX.utils.encode_cell({c:2, r})].s = countCellStyle;
                    newWs[XLSX.utils.encode_cell({c:3, r})].s = countCellStyle;
                    allCountUpload += newWs[XLSX.utils.encode_cell({c:2,r})].v;
                    allCountDownload += newWs[XLSX.utils.encode_cell({c:3,r})].v;
                    break;
                }
                else {
                    newWs[XLSX.utils.encode_cell({c,r})].s = bodyCellStyle;
                }
            }
        }

        console.log("allCountUpload", allCountUpload);
        console.log("allCountDownload", allCountDownload);


        newWs[XLSX.utils.encode_cell({c:0, r: 0})].v = ws_json_headers[0].group;
        newWs[XLSX.utils.encode_cell({c:1, r: 0})].v = ws_json_headers[0].type;
        newWs[XLSX.utils.encode_cell({c:2, r: 0})].v = ws_json_headers[0].upload;
        newWs[XLSX.utils.encode_cell({c:3, r: 0})].v = ws_json_headers[0].download;


        // newWs[XLSX.utils.encode_cell({c:2, r: range.e.r + 1})].v = allCountUpload;
        // newWs[XLSX.utils.encode_cell({c:3, r: range.e.r + 1})].v = allCountDownload;

        XLSX.utils.sheet_add_aoa(newWs, [["Общее Количество"]], {origin : XLSX.utils.encode_cell({c:1, r: range.e.r + 1})});
        XLSX.utils.sheet_add_aoa(newWs, [[allCountUpload]], {origin : XLSX.utils.encode_cell({c:2, r: range.e.r + 1})});
        XLSX.utils.sheet_add_aoa(newWs, [[allCountDownload]], {origin : XLSX.utils.encode_cell({c:3, r: range.e.r + 1})});

        newWs[XLSX.utils.encode_cell({c:1, r: range.e.r + 1})].s = countCellStyle;
        newWs[XLSX.utils.encode_cell({c:2, r: range.e.r + 1})].s = countCellStyle;
        newWs[XLSX.utils.encode_cell({c:3, r: range.e.r + 1})].s = countCellStyle;

        newWs["!cols"] = [
            {width : 50},
            {width : 50},
            {width : 20},
            {width : 20}
        ];





        console.log("newWs", newWs);
        console.log("stats_array", stats_array);

        let newWb = XLSX.utils.book_new();
    //    XLSX.utils.book_append_sheet(wb2, ws, wb.SheetNames[0]);
     //   XLSX.utils.book_append_sheet(wb2, await XLSX.utils.json_to_sheet(stats_array), "Test");
        XLSX.utils.book_append_sheet(newWb, newWs, wb.SheetNames[0]);



        console.log("newWb", newWb);
    //    newWb.Sheets[newWb.SheetNames[0]]["!ref"] = `A1:${lastKey}`;


        let exportFileName = `Report v2.xlsx`;
        XLSX.writeFile(newWb, exportFileName);


        console.log("wb", wb);
        console.log("stats", stats);
        console.log("wb_json2", ws_json);
        console.log("ws_json_headers", ws_json_headers);
        console.log("lastKey", lastKey);

    }
}
