import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import TreePackagesFolderSearch from "../../mainTable/Package/TreePackagesFolderSearch";
import TreePackageSearch from "../../tree/packages/TreePackageSearch";
import {movingDocumentsInPackageFromDocuments, movingDocumentsInPackageFromSearch} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import {setTreeSearchQuery} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {
    collectDataForAdditionalSections_Normal
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";

class ChangeDocumentTypeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : true,
            selectNode : null,
            tooltipShow : false
        };

        this.tooltipRef = React.createRef();

        this.modalName = ModalTypes.documents.mainTable.ChangeDocumentType;

        this.hideOutSide = this.hideOutSide.bind(this);
        this.hide = this.hide.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.moveDocInPack = this.moveDocInPack.bind(this);
    }

    // скрывать при клике вне окна
    hideOutSide() {
        if(!this.state.contentOnHover) {
            this.props.setModalData({});
        }
    }

    // скрывать по кнопке
    hide() {
        this.props.setModalData({});
        this.props.setTreeSearchQuery({})

    }

    selectNode(item) {
        this.setState({selectNode : item});
    }

    moveDocInPack() {
        if(!this.state.selectNode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Необходимо выбрать пакет для переноса`}
            });
            return;
        }

        if(this.props.type.type==="search"){
            let activeRowSearch = this.props.searchResults.find(item => item.Id === this.props.activeTabSearch.Id).mainTable.activeRow
            this.props.movingDocumentsInPackageFromSearch({
                IdPackage: this.state.selectNode.Id,
                ActiveRow: activeRowSearch?.info,
                Next : {
                    action : () => {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type : "success", content : `Документы успешно перенесены`}
                        });
                        this.props.setTreeSearchQuery({})
                    },
                    params : {}
                }
            });
        }else{
            this.props.movingDocumentsInPackageFromDocuments({
                IdPackage: this.state.selectNode.Id,
                ActiveRow: this.props.activeRow?.Info,
                Next: {
                    action: () => {
                        if (this.props.activeRow)
                            this.props.collectDataForAdditionalSections_Normal({
                                Next: {
                                    action: () => {
                                        this.props.setModalData({
                                            name: ModalTypes.app.info,
                                            data: {type: "success", content: `Документы успешно перенесены`}
                                        });
                                        this.props.setTreeSearchQuery({})
                                    }
                                }
                            })
                        else {
                            this.props.setModalData({
                                name: ModalTypes.app.info,
                                data: {type: "success", content: `Документы успешно перенесены`}
                            });
                            this.props.setTreeSearchQuery({})
                        }
                    },
                    params: {}
                }
            });
        }

    }

    render() {
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        let otherFilesStyle = {
            backgroundColor: "red",
        }

        let docSelected;
        if(this.props.type.type === "search") {
            let activeRowSearch = this.props.searchResults.find(item => item.Id === this.props.activeTabSearch.Id).mainTable.activeRow
            let checkedFieldsSearch = this.props.searchResults.find(item => item.Id === this.props.activeTabSearch.Id).mainTable.checkedFields
            let docsCollectionSearch = this.props.searchResults.find(item => item.Id === this.props.activeTabSearch.Id).mainTable.collection.docs
            docSelected = checkedFieldsSearch.length === 0 && activeRowSearch
                ? [activeRowSearch.info]
                : docsCollectionSearch.filter((doc) => checkedFieldsSearch.find(Id => doc.Id === Id))
        } else {
            docSelected = this.props.checkedFields.length === 0 && this.props.activeRow
                ? [this.props.activeRow.Info]
                : this.props.docsCollection.filter((doc) => this.props.checkedFields.find(Id => doc.Id === Id))
        }

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div
                        style={{minWidth: '87vw', minHeight: '85vh'}}
                        className="modal-content"
                         onMouseEnter={()=>{this.setState({contentOnHover : true})}}
                         onMouseLeave={()=>{this.setState({contentOnHover : false})}}>

                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                {/*{*/}
                                {/*    this.state.tooltipShow &&*/}
                                {/*    <MyTooltip target={this.tooltipRef}*/}
                                {/*               title={"Внимание!"}*/}
                                {/*               moreInfo={true}*/}
                                {/*               text={"Документы, <span style='color:red'>расположенные в других филиалах</span>, после добавления в пакет, будут отображены в пакетах только в относящихся к ним филиалах."}*/}
                                {/*               show={this.state.tooltipShow}*/}
                                {/*               placement={"right"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                Выберите пакет и подтвердите перенос документов
                                {/*<span className="btn btn-icon btn-circle btn-sm"*/}
                                {/*      ref={this.tooltipRef}*/}
                                {/*      onMouseEnter={()=>{this.setState({tooltipShow : true})}}*/}
                                {/*      onMouseLeave={()=>{this.setState({tooltipShow : false})}}>*/}
                                {/*        <i className="svg-icon icon-Info icon-color-secondary"/>*/}
                                {/*</span>*/}
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-10" style={{padding:" 0.8rem 1.75rem", minHeight: "20vh"}}>

                            <div className="row">

                                <div className="col-lg-6">
                                    <p>
                                        <span className="label label-inline label-light-success font-weight-bold">
                                            Документы
                                        </span> Выбранные документы для переноса
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: "auto"}}>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Наименование документа</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal"/></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "1em"}}>
                                            {
                                                docSelected.map((item, index)=>{
                                                    if(item.IdFilial!==this.props.idBaseFilial){
                                                        return(
                                                            <tr key={createUniqueIdString(6)}>
                                                                <td className="change_table_text_3" style={otherFilesStyle}
                                                                    title={`${item.Name} ${item.ShortDescription}`}>
                                                                    {parse(sanitizeHtml(item.Name + " " + item.ShortDescription))}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }else{
                                                        return(
                                                            <tr key={createUniqueIdString(6)}>
                                                                <td className="change_table_text_3"
                                                                    title={`${item.Name} ${item.ShortDescription}`}>
                                                                    {parse(sanitizeHtml(item.Name + " " + item.ShortDescription))}
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold mr-2">
                                            Пакеты
                                        </span>
                                        Выберите пакет для переноса документов
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: "max-content"}}>
                                        <TreePackageSearch searchText={(searchText)=>{this.setState({selectNode : null})}} tableRole={"doc"} />
                                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2" style={{height: "max-content"}}>
                                            <div className="aside-menu">
                                                <TreePackagesFolderSearch selectNode={this.selectNode}
                                                                          tableRole={"doc"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button" className="btn btn-primary font-weight-bolder"
                                disabled={this.state.selectNode === null}
                                    onClick={this.moveDocInPack}>
                                Перенести выбранные документы в пакет
                            </button>
                            <button type="button" className="btn btn-light font-weight-bold"
                                    onClick={this.hide}>
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>

    );
    }
}


const  mapStateToProps = state => {
    return {
        checkedFields : state.document.mainTable.checkedFields,
        docsCollection : state.document.mainTable.collection.docs,

        checkedFieldsSearch : state.search.mainTable.checkedFields,
        docsCollectionSearch : state.search.mainTable.collection.docs,

        type : state.globalState.app.modal.data,

        idBaseFilial : state.globalState.filial.Active.Id,
        activeRow : state.document.mainTable.activeRow,
        activeRowSearch: state.search.mainTable.activeRow,
        activeTabSearch: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,

    }
}

const  mapDispatchToProps = {
    collectDataForAdditionalSections_Normal,
    setModalData,
    movingDocumentsInPackageFromDocuments,
    movingDocumentsInPackageFromSearch,
    setTreeSearchQuery

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocumentTypeModal);
