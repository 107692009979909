import React from "react";
import {connect} from "react-redux";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import {setModalData, setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {apiRequest, apiRequestNew} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../tools/Filters";
import AsyncSelect from 'react-select/async';
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {__} from "../../tools/HelpFunctions";
import {AdminLevelEnum, ModalTypes, PrintFileFormat} from "../../tools/StaticTypes";
import {API} from "../../tools/API_NEW/API";
import {encode} from "windows-1251/windows-1251";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import Select from "react-select";
import {treeSearchPackage} from "../../store/packages/actionCreators/package_TreeSearchActionCreator";
import Collapse from "react-bootstrap/Collapse";
import {store} from "../../index";
import {TREE_ACTIONS} from "../../store/documents/documentActionsList";
import makeAnimated from "react-select/animated";
import "../../media/styles/css_dp/icon.css";
import FilesModal from "../overPage/modal/FilesModal";
import DropFileComponent from "../tools/DropFileComponent/DropFileComponent";

const animatedComponents = makeAnimated();
const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: "1rem",
    }),
}

const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}

class MasterApp extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            ActiveAttributes: [],
            ActiveIdFilial: null,
            ActiveType: null,

            AdditionalFileForPrinting: false,

            AttributeError: {Name: "", IsError: false},
            Comment: "WebMaster 1.0",

            ContentFile: null,
            ContentText: "",
            contentTooltipShow: false,

            deleteOtherFilesTooltipShow: false,

            DocName: "",
            DocNameError: false,
            DocNameClear: false,

            FileForPrint: null,

            isCheck: false,
            isShowBanner: true,

            LoadFilial: this.props?.filials,
            LoadGroups: [],
            LoadTypes: [],

            OriginalFile: null,
            OriginalFileError: false,
            OtherFiles: [],

            PreviewFile: null,
            previewInfoTooltipShow: false,

            RegDate: "",
            RegDateError: false,

            RegNumber: "",
            RegNumberClear: false,
            RegNumberError: false,

            SelectedPackages: [],
            SelectedGroups: [],

            showOriginalFilesList: false,
            showOriginalFilesTooltipShow: false,
            showOtherFilesList: false,
            showOtherFilesTooltipShow: false,

            TypeId: null,
            TypeName: null,

            typeValue: {
                value: null,
                label: "Не выбрано",
                isDisabled: true
            },

        };

        this.state = this.defaultState;


        this.contentRef = React.createRef();
        this.originalFileRef = React.createRef();
        this.otherFilesRef = React.createRef();
        this.previewFileRef = React.createRef();
        this.previewInfoRef = React.createRef();
        this.showOriginalFilesRef = React.createRef();
        this.showOtherFilesRef = React.createRef();

        this.checkInput = this.checkInput.bind(this);
        this.docTypesSelectOnChange_loadAttributes = this.docTypesSelectOnChange_loadAttributes.bind(this);
        this.filialSelectOnChange_loadTypes = this.filialSelectOnChange_loadTypes.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.loadPackages = this.loadPackages.bind(this);
        this.showOriginalFilesList = this.showOriginalFilesList.bind(this);
        this.showOtherFilesList = this.showOtherFilesList.bind(this);
        this.uploadDoc = this.uploadDoc.bind(this);
    }

    componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose: true
        });
    }

    checkInput() {
        // проверка заполнения
        if (this.state.DocName === "" || this.state.DocName === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Наименование документа обязательно к заполнению`}
            });
            this.setState({DocNameError: true});
            return;
        }

        if (this.state.RegNumber === "" || this.state.RegNumber === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Номер документа обязателен к заполнению`}
            });
            this.setState({RegNumberError: true});
            return;
        }

        if (this.state.RegDate === "" || this.state.RegDate === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Дата документа обязательна к заполнению`}
            });
            this.setState({RegDateError: true});
            return;
        }

        if (this.state.Comment === "" || this.state.Comment === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/>Комментарий для документа обязателен к заполнению`
                }
            });
            return;
        }

        for (let i = 0; i < this.state.ActiveAttributes.length; i++) {
            if (this.state.ActiveAttributes[i].inputValue === "" && this.state.ActiveAttributes[i].IsRequired) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Атрибут "${this.state.ActiveAttributes[i].AttributeName.Name}" обязателен к заполнению`
                    }
                });
                this.setState({
                    AttributeError: {
                        Name: this.state.ActiveAttributes[i].AttributeName.Name,
                        IsError: true
                    }
                });
                return;
            }
        }


        // проверка файлов
        // заполнение файлов
        if (this.state.OriginalFile == null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/>Необходимо выбрать оригинальный файл документа (.pdf)`
                }
            });
            this.setState({OriginalFileError: true});
            return;
        }

        // размер файла
        if (this.state.OriginalFile.size > 1024 * 1024 * 250) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/>Максимальный размер оригинального файла документа не более 250мб`
                }
            });
            this.setState({OriginalFileError: true});
            return;
        }

        for (let i = 0; i < this.state.OtherFiles.length; i++) {
            if (this.state.OtherFiles[i].size > 1024 * 1024 * 250) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Максимальный размер файла "${this.state.OtherFiles[i].name}" не может превышать 250мб`
                    }
                });
                return;
            }
        }

        // расширение файла
        if (this.state.OriginalFile.name.split('.').pop() !== "pdf") {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/>Оригинальный файл документа должен иметь расширение .pdf`
                }
            });
            this.setState({OriginalFileError: true});
            return;
        }
        if (this.state.PreviewFile)
            if (this.state.PreviewFile.name.split('.').pop() !== "png") {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка заполнения: <br/>Файл документа должен иметь расширение .png`}
                });
                return;
            }

        // проверка типов
        for (let i = 0; i < this.state.ActiveAttributes.length; i++) {
            let idType = this.state.ActiveAttributes[i].AttributeName.IdAttributeType;
            let attrType = __.inputTypeByDataTypeId(idType);

            if (attrType === "datetime-local") {
                this.state.ActiveAttributes[i].inputValue = __.dateToRussia(this.state.ActiveAttributes[i].inputValue);
            }
        }

        this.setState({IsCheck: true});
        this.uploadDoc();
        // TODO очищение формы перенесено в функцию uploadDoc (чтобы очищалась форма после успешной загрузки документа, а не после любой ошибки)
        // this.setState({OriginalFile : null, PreviewFile: null, OtherFiles: []});
    }

    async clearInput(name) {
        if (name === "name") {
            this.setState({DocName: ""});
        } else if (name === "number") {
            this.setState({RegNumber: ""});
        } else if (name === "date") {
            this.setState({RegDate: ""});
        } else if (name === "comment") {
            this.setState({Comment: ""});
        }
    }

    docTypesSelectOnChange_loadAttributes(item) {
        let selectedType = __.deepCopy(this.state.LoadTypes.find(type => type.Id == item.value));
        this.setState({typeValue: item, SelectedGroups: null})

        for (let i = 0; i < selectedType.TypeAttributes.length; i++) {
            selectedType.TypeAttributes[i].uid = createUniqueIdString(6);
            selectedType.TypeAttributes[i].inputValue = "";
        }


        this.setState({
            ActiveType: selectedType.Id,
            ActiveAttributes: selectedType.TypeAttributes,
            isShowBanner: false
        });
    }

    filialSelectOnChange_loadTypes(item) {
        this.setState({
            ActiveIdFilial: item,
            typeValue: {
                value: null,
                label: "Не выбрано",
                isDisabled: true
            },
            SelectedGroups: null,
        }, () => {
            let request_types = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter: {IdFilial: this.state.ActiveIdFilial.value},
                sorting: new Sorting().add({Direction: 1, PropertyName: "Name"}).get()
            });
            let request_groups = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
                filter: {IdFilial: this.state.ActiveIdFilial.value},
                sorting: new Sorting()
                    .add({Direction: 1, PropertyName: "Name"})
                    .add({Direction: 1, PropertyName: "GroupOrder"})
                    .get()
            });
            request_groups.execute((data) => {
                this.setState({
                    LoadGroups: data.Records.sort((a, b) => {
                        return a.GroupOrder - b.GroupOrder
                    }),
                });
            }, (data) => {
                console.log("Ошибка загрузки списка групп документов: ", data)
            });
            request_types.execute((data) => {
                this.setState({
                    LoadTypes: data.Records,
                });
            }, (data) => {
                console.log("Ошибка загрузки списка типов документов: ", data)
            });
        });
    }

    getSelectForBoolAttr({val: value, onChange: onChangeHandler}) {
        let options = [
            {value: "", label: "Не выбрано"},
            {value: "1", label: "Да"},
            {value: "0", label: "Нет"},
        ]
        return (
            <React.Fragment>
                <Select
                    styles={sbSelect}
                    defaultValue={options[0]}
                    onChange={onChangeHandler}
                    options={options}
                    // placeholder="Выберите филиал"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: 'var(--hover-primary)',
                            primary25: 'var(--light-primary)',
                            primary50: 'var(--hover-primary)',
                            primary: 'var(--originaltwo)',
                        },
                    })}
                />

                {/*<div className="input-icon h-40px my-2">*/}
                {/*    <select name="test" className="form-select" value={value} onChange={onChangeHandler}>*/}
                {/*        <option value={""}>Не выбрано</option>*/}
                {/*        <option value={1}>Да</option>*/}
                {/*        <option value={0}>Нет</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </React.Fragment>
        )
    }

    inputHandler(event, item = null) {
        let value = event.target?.value ?? event.value;

        if (item == null) {
            let nameInput = event.target.name;
            if (nameInput === "name") {
                this.setState({DocName: value, DocNameError: value === ""});
            } else if (nameInput === "number") {
                this.setState({RegNumber: value, RegNumberError: value === ""});
            } else if (nameInput === "date") {
                this.setState({RegDate: value, RegDateError: value === ""});
            } else if (nameInput === "comment") {
                this.setState({Comment: value});
            } else if (nameInput === "printCheckbox") {
                this.setState({AdditionalFileForPrinting: event.target.checked});
            }
            // else if (nameInput === "pageCount") {
            //     this.setState({FileForPrint_Pages: value});
            // }
        } else {

            let ActiveAttributes = __.deepCopy(this.state.ActiveAttributes);
            let index = ActiveAttributes.findIndex(attr => attr.uid === item.uid);

            ActiveAttributes[index].inputValue = value;
            this.setState({AttributeError: {...this.state.AttributeError, IsError: value === ""}});
            this.setState({ActiveAttributes});
        }
    }

    async uploadDoc() {

        this.props.setModalData({
            name: ModalTypes.app.alert,
            data: {
                content: "Загрузка документа...",
                disableButton: true,
                fullBackground: false,
                gif: "documents"
            }
        });

        try {

            let type = this.state.LoadTypes.find(type => type.Id === this.state.ActiveType);

            let docHeader = {
                Name: this.state.DocName,
                Type: {
                    Id: type.Id,
                    Name: type.Name
                },
                IdFilial: this.state.ActiveIdFilial.value,
                RegDate: this.state.RegDate + "T00:00:00",
                RegNumber: this.state.RegNumber
            };

            let docAttributes = [];
            for (const attr of this.state.ActiveAttributes) {
                if (attr.AttributeName.IdAttributeType == 5 && attr.inputValue) {
                    attr.inputValue = __.dateToRussia(attr.inputValue)
                }
                docAttributes.push({
                    AttributeName: attr.AttributeName,
                    AttributeValue: attr.inputValue
                });
            }

            let fileInfoList = [];
            fileInfoList.push({
                Name: this.state.OriginalFile.name,
                Type: 3,
                Size: this.state.OriginalFile.size,
                Description: "Основной файл",
                // TODO SS 23692
                PagesCount: 1, //this.state.FileForPrint_Pages,
                PagesFormat: 1, //this.state.FileForPrint_Format
            });

            for (const file of this.state.OtherFiles) {
                fileInfoList.push({
                    Name: file.name,
                    Type: 4,
                    Size: file.size,
                    Description: "---",
                });
            }

            if (this.state.PreviewFile) {
                fileInfoList.push({
                    Name: this.state.PreviewFile.name,
                    Type: 2,
                    Size: this.state.PreviewFile.size,
                    Description: "Файл предпросмотра",
                });
            }


            let body = {
                docHeader,
                docAttributes,
                comment: this.state.Comment,
                fileInfoList
            };

            let DocSaveRequest = await API.documents().saveNew(body);

            if (DocSaveRequest?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${DocSaveRequest.message.Message}`}
                });
                return;
            }

            let lOtherFiles = [];
            let lFile;
            for (let i = 0; i < DocSaveRequest.length; i++) {
                let file;

                if (fileInfoList[i].Type === 2) {
                    file = this.state.PreviewFile;
                }

                if (fileInfoList[i].Type === 3) {
                    file = this.state.OriginalFile;
                }

                if (fileInfoList[i].Type === 4) {
                    if (fileInfoList[i - 1].Type === 4) {
                    } else {
                        let requestOtherFiles = [];
                        const lTempOtherFiles = Array.from([...this.state.OtherFiles]);

                        for (let j = 0; j < this.state.OtherFiles.length; j++) {

                            lTempOtherFiles[j].guid = DocSaveRequest[i + j];
                            requestOtherFiles[j] = await API.documents().uploadDocFiles({
                                file: this.state.OtherFiles[j],
                                Guid: DocSaveRequest[i + j]
                            });

                            if (requestOtherFiles[j]?.errorCode) {
                                this.props.setModalData({
                                    name: ModalTypes.app.info,
                                    data: {
                                        type: "fail",
                                        content: `Ошибка загрузки: <br/>${requestOtherFiles[j].message.Message}`
                                    }
                                });
                                return;
                            }
                        }
                        lOtherFiles = lOtherFiles.concat(lTempOtherFiles);
                    }
                }


                if (fileInfoList[i].Type !== 4) {
                    lFile = __.deepCopy(Array.from([...[file]]));
                    lFile[0].guid = DocSaveRequest[i];
                    lOtherFiles = lOtherFiles.concat(lFile);
                    let request = await API.documents().uploadDocFiles({
                        file: file,
                        Guid: DocSaveRequest[i]
                    });


                    if (request?.errorCode) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Ошибка загрузки: <br/>${request.message.Message}`}
                        });
                        return;
                    }
                }

                let getUploadFilesProgress = await API.documents().getUploadFilesProgress({
                    uploadGuid: DocSaveRequest[i]
                });

                if (getUploadFilesProgress?.errorCode) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            type: "fail",
                            content: `Ошибка загрузки: <br/>${getUploadFilesProgress.message.Message}`
                        }
                    });
                    return;
                }

                if (getUploadFilesProgress.IsActive || getUploadFilesProgress.UploadedBytes !== getUploadFilesProgress.WholeBytes) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка загрузки: <br/> Документ : ${i}`}
                    });
                    return;
                }
            }


            let saveNewCompleteExtended = await API.documents().saveNewCompleteExtended({commit: true});
            let saveNewComplete = saveNewCompleteExtended.DocVersion;

            //TODO для точного соответствия файлов, исключение ситуации файлов с одним именем и размером
            if (saveNewCompleteExtended?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${saveNewCompleteExtended.message.Message}`}
                });
                return;
            }

            //>-------------------------ЭП-----------------------
            if (this.state.SelectedGroups?.length > 0) {
                let collection = [];
                for (let i = 0; i < this.state.SelectedGroups.length; i++) {
                    collection.push(
                        {
                            Id: saveNewComplete.Id,
                            IdDoc: saveNewComplete.IdDoc,
                            IdDocGroup: this.state.SelectedGroups[i].value
                        }
                    )
                }
                let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                    collection: collection
                });
                await request.execute(function (data) {
                }, function (data) {
                    if (data.status === 401) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Ошибка помещенния в реестр: <br/>${data}`}
                        });
                        return;
                    }
                    console.log("Ошибка получения дерева группы документов (post): ", data);
                });
            }
            //<-------------------------ЭП новый метод -----------------------
            const lFilesLoad = await API.documents().getFiles({
                filter: new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocFile")
                            .add({Name: "IdDoc", Value: saveNewComplete.IdDoc})
                            .add({Name: "IdDocVersion", Value: saveNewComplete.Id})
                            .get()
                    ).get(),
                sorting: null
            });

            if (this.state.ContentText?.length > 0) {
                let idFile = lFilesLoad.Records.reverse().find(item => item.Type == 1).Id

                let response = await new apiRequestNew({
                    action: apiUrl.DOCUMENTS.UPDATE_DOC_TEXT,
                    headers: null,
                    params: {
                        id: idFile,
                        text: this.state.ContentText
                    }
                }).execute();

                if (!response.ok) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.alert,
                        data: {
                            content: "Во время сохранения документа произошла ошибка: Обновление содержания",
                            disableButton: false
                        }
                    }));
                    return;
                }
            }


            for (let j = 0; j < lOtherFiles.length; j++) {
                let lFoundFile = lFilesLoad.Records.filter(item =>
                    // item.Type == 2 && item.IsCurrent &&
                    item.Id == saveNewCompleteExtended.GuidToDocFileCollection.find(item => item.Guid == lOtherFiles[j].guid).IdDocFile)
                if (lFoundFile && lFoundFile.length > 0) {
                    if (lOtherFiles[j].signatures)
                        for (let iSignature = 0; iSignature < lOtherFiles[j].signatures.length; iSignature++) {
                            let lData = new FormData();
                            lData.append("description", "Электронная подпись");

                            let files = lOtherFiles[j].signatures[iSignature];
                            files.id = createUniqueIdString(8);
                            files.description = "Электронная подпись";
                            files.new_name = lOtherFiles[j].signatures[iSignature].name;
                            lData.append("signature", files, lOtherFiles[j].signatures[iSignature].name);

                            await API.signatures().attach({
                                idDocFile: lFoundFile[0].Id,
                                FormData: lData,
                            });
                        }
                }
            }

            //>-------------------------ЭП-----------------------


            //добавление документа в выбранные пакеты
            if (this.state.SelectedPackages?.length > 0) {
                let collection = this.state.SelectedPackages.map(item => ({
                    IdDoc: saveNewComplete.IdDoc,
                    IdPackage: item.value
                }));
                let moveInPackages = await API.packages().saveOrUpdateCollection({collection: collection});

                if (moveInPackages?.errorCode) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка загрузки в пакет: <br/>${moveInPackages.message.Message}`}
                    });
                    return;
                }
            }
            // if (this.state.SelectedGroups?.length > 0) {
            //     let collection = [];
            //     for (let i = 0; i < this.state.SelectedGroups.length; i++) {
            //         collection.push(
            //             {
            //                 Id: saveNewComplete.Id,
            //                 IdDoc: saveNewComplete.IdDoc,
            //                 IdDocGroup: this.state.SelectedGroups[i].value
            //             }
            //         )
            //     }
            //     let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
            //         collection: collection
            //     });
            //     await request.execute(function (data) {
            //     }, function (data) {
            //         if (data.status === 401) {
            //             this.props.setModalData({
            //                 name: ModalTypes.app.info,
            //                 data: {type: "fail", content: `Ошибка помещенния в реестр: <br/>${data}`}
            //             });
            //             return;
            //         }
            //         console.log("Ошибка получения дерева группы документов (post): ", data);
            //     });
            // }
            this.setState({OriginalFile: null, PreviewFile: null, OtherFiles: []}, () => {
                // очищение формы с файлами, т.к. было невозможно после ошибки загрузить тот же файл
                this.originalFileRef.current.value = "";
                this.previewFileRef.current.value = "";
                this.otherFilesRef.current.value = "";
            });


            store.dispatch({type: TREE_ACTIONS.RELOAD, payload: {}});


            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "success", content: `Документ успешно загружен`}
            });

            this.setState({
                OriginalFile: null,
                PreviewFile: null,
                ContentFile: "",
            })
        } catch (e) {
            e.message !== undefined ? this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${e.message}`}
                }) :
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${e.responseJSON.Message}`}
                });

        }
    }


    // загрузка пакетов для выбора
    async loadPackages(value) {
        await this.props.treeSearchPackage({Input: value, MasterSearchId: this.state.ActiveIdFilial.value});
        return this.props.packages.map(item => ({label: item.Name, value: item.Id}))
    }

    showOriginalFilesList() {
        this.setState({showOriginalFilesList: true});
    }

    showOtherFilesList() {
        this.setState({showOtherFilesList: true});
    }


    render() {
        const lFlagWithSection = this.props.globalSettings.WithSection === "1";
        let filialOptions = [];
        if (this.state.LoadFilial.length === 0) {
            filialOptions.push({
                value: null,
                label: "Не выбрано",
                isDisabled: true
            });
        }
        for (const filial of this.state.LoadFilial) {
            // if(this.state.LoadFilial[i].Id !== 1) {
            filialOptions.push({
                value: filial.Id,
                label: filial.Name,
                isDisabled: false
            });
            // }

        }


        let typesOptions = [];
        typesOptions.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true
        });

        for (const loadType of this.state.LoadTypes) {
            if (this.state.LoadGroups
                .find(g => g.GroupTypeRefs
                    .find(item => item.IdDocType === loadType.Id && loadType.ModifyAccess))
            )
                typesOptions.push({
                    value: loadType.Id,
                    label: loadType.Name,
                    isDisabled: false
                });
        }

        let groupsOptions = [];
        groupsOptions.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true,
            groupTypes: []
        });
        const groups = this.state.LoadGroups.filter(group => group.Type === 2)
        for (const group of groups) {
            groupsOptions.push({
                value: group.Id,
                label: group.Name,
                isDisabled: false,
                groupTypes: group.GroupTypeRefs,
            });
        }

        // TODO TP №21638,  №21567
        groupsOptions = this.state.ActiveType
            ? groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.state.ActiveType))
            : groupsOptions


        let pageFormatOptions = [];

        for (const item in PrintFileFormat) {
            pageFormatOptions.push({
                value: PrintFileFormat[item],
                label: item,
                isDisabled: false
            });
        }

        let mod = this.props.theme.modString;

        let DocNameIcon = "icon-name_search";
        if (this.state.DocNameClear) { // для отображения красного крестика
            DocNameIcon = "icon-Delete icon-color-red";
        }
        let RegNumberIcon = "icon-name_search";
        if (this.state.RegNumberClear) { // для отображения красного крестика
            RegNumberIcon = "icon-Delete icon-color-red";
        }

        const {showOtherFilesList, OtherFiles, showOriginalFilesList, OriginalFile} = this.state;

        if (filialOptions.length === 1 && this.state.ActiveIdFilial?.value !== filialOptions[0].value)
            this.filialSelectOnChange_loadTypes(filialOptions[0])

        return (
            <div>
                {
                    (showOtherFilesList && OtherFiles.length > 0) &&
                    <FilesModal
                        files={this.state.OtherFiles}
                        onClose={() => this.setState({showOtherFilesList: false})}
                        onSave={(files) => this.setState({OtherFiles: files})}
                    />
                }
                {
                    (showOriginalFilesList && OriginalFile?.name) &&
                    <FilesModal
                        caption="Оригинал (PDF)"
                        files={[this.state.OriginalFile]}
                        onClose={() => this.setState({showOriginalFilesList: false})}
                        onSave={(files) => this.setState({OriginalFile: files[0]})}
                    />
                }
                <div
                    className="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu">
                    <div className="d-flex flex-column flex-root">
                        <div className="aside aside-left d-flex aside-fixed">
                            <LeftMenu rootName="MasterApp"/>
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper">
                            <div className="content d-flex flex-column flex-column-fluid">
                                <TopMenu rootName="MasterApp"/>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper" style={{}}>
                            <div style={{width: "100%"}}></div>
                            <div className="container-new px-8" style={{}}>
                                <div>
                                    <div className="row pt-4" style={{marginBottom: "50px"}}>
                                        <div className="col-lg-12" style={{padding: "0"}}>
                                            <Collapse in={this.state.isShowBanner}>
                                                <div>
                                                    <div
                                                        className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                                                        style={{
                                                            height: "350px",
                                                            backgroundImage: `url('./images/downloadMaster${mod}.svg')`
                                                        }}>
                                                        <div className="card-body d-flex"
                                                             style={{padding: "4rem 3.25rem"}}>
                                                            <div
                                                                className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                                                <div className="flex-grow-1">
                                                                    <span
                                                                        className="text-white font-weight-bolder font-size-h1">Загрузка Документов</span>
                                                                    <p className="text-white font-weight-bold mt-3">Мастер
                                                                        загрузки поможет вам корректно загрузить<br/>
                                                                        новый документ для работы с ним в приложении
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>

                                        <div className="card card-custom container-solid col-xl-12 mb-1"
                                             style={{borderRadius: "20px"}}>
                                            <div className="card-header card-header-colored">
                                                <div className="card-title">
                                                    <span className="card-icon"/>
                                                    <h4 className="card-label text-weight-bolder text-color-primary"
                                                        title="Настройки отчета">
                                                        <b>Основные настройки</b>
                                                    </h4>
                                                </div>
                                            </div>

                                            <form className="form">
                                                <div className="card-body p-5">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div>
                                                                {filialOptions.length !== 1 ?
                                                                    <>
                                                                        <p className="mb-2">Выберите филиал для
                                                                            документа</p>
                                                                        <Select
                                                                            styles={sbSelect}
                                                                            defaultValue={filialOptions[0]}
                                                                            value={this.state.ActiveIdFilial ?? ''}
                                                                            onChange={(item) => {
                                                                                this.filialSelectOnChange_loadTypes(item)
                                                                            }}
                                                                            options={filialOptions}
                                                                            placeholder="Выберите филиал"
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    text: 'var(--hover-primary)',
                                                                                    primary25: 'var(--light-primary)',
                                                                                    primary50: 'var(--hover-primary)',
                                                                                    primary: 'var(--originaltwo)',
                                                                                },
                                                                            })}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p className="mb-2">Текущий филиал</p>
                                                                        <p className="mb-2">{filialOptions[0].label}</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            {
                                                                this.state.ActiveIdFilial &&
                                                                <>
                                                                    <p className="mb-2">Выберите тип документа</p>
                                                                    <Select
                                                                        styles={sbSelect}
                                                                        value={this.state.typeValue}
                                                                        onChange={(item) => {
                                                                            this.docTypesSelectOnChange_loadAttributes(item)
                                                                        }}
                                                                        options={typesOptions}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                text: 'var(--hover-primary)',
                                                                                primary25: 'var(--light-primary)',
                                                                                primary50: 'var(--hover-primary)',
                                                                                primary: 'var(--originaltwo)',
                                                                            },
                                                                        })}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                        {
                                                            lFlagWithSection &&
                                                            <div className="col-lg-3">
                                                                {
                                                                    this.state.ActiveType &&
                                                                    <>
                                                                        <p className="mb-2">Выберите раздел
                                                                            документа</p>
                                                                        <Select
                                                                            styles={sbSelect}
                                                                            placeholder="Выберите раздел документа"
                                                                            title="Выберите раздел документа"
                                                                            closeMenuOnSelect={false}
                                                                            components={animatedComponents}
                                                                            isMulti
                                                                            value={this.state.SelectedGroups}
                                                                            onChange={(value) => this.setState({SelectedGroups: value})}
                                                                            options={groupsOptions}
                                                                            noOptionsMessage={() => "Нет доступных разделов"}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    text: 'var(--hover-primary)',
                                                                                    primary25: 'var(--light-primary)',
                                                                                    primary50: 'var(--hover-primary)',
                                                                                    primary: 'var(--originaltwo)',
                                                                                },
                                                                            })}
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="col-lg-3">
                                                            {
                                                                this.state.ActiveType &&
                                                                <>
                                                                    <p className="mb-2">Выберите пакеты для
                                                                        документа</p>
                                                                    <AsyncSelect
                                                                        key={this.state.ActiveIdFilial.value}
                                                                        title="Выберите пакет"
                                                                        loadOptions={this.loadPackages}
                                                                        onChange={(value) => this.setState({SelectedPackages: value})}
                                                                        isMulti
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        placeholder="Введите название пакета"
                                                                        noOptionsMessage={() => {
                                                                            return "Не найдено пакетов для выбора"
                                                                        }}
                                                                        loadingMessage={() => {
                                                                            return "Загрузка..."
                                                                        }}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                text: 'var(--hover-primary)',
                                                                                primary25: 'var(--light-primary)',
                                                                                primary50: 'var(--hover-primary)',
                                                                                primary: 'var(--originaltwo)',
                                                                            },
                                                                        })}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className={"d-flex col-lg-12 p-0"}>
                                            {
                                                this.state.ActiveType &&
                                                this.state.typeValue.value &&
                                                <>
                                                    <div className="card card-custom container-solid col-lg-6"
                                                         style={{borderRadius: "20px"}}>
                                                        <div className="card-header card-header-colored">
                                                            <div className="card-title">
                                                                <span className="card-icon"/>
                                                                <h4 className="card-label text-weight-bolder text-color-primary"
                                                                    title="Настройки отчета">
                                                                    <b>Настройки атрибутов</b>
                                                                </h4>
                                                            </div>
                                                        </div>

                                                        <div className="card-body beautiful-scroll-10 p-5"
                                                             style={{overflow: "auto"}}>
                                                            <div className="form-group mt-2">
                                                                <h5 className="mb-2">Обязательные атрибуты</h5>
                                                                <p className="mb-2"><b>Наименование</b><sup
                                                                    style={{color: "#dc3545"}}>*</sup></p>
                                                                <div className="input-icon">
                                                                    <input className="form-control" type="text"
                                                                           name="name"
                                                                           value={this.state.DocName}
                                                                           style={this.state.DocNameError ? {borderColor: "var(--danger)"} : {}}
                                                                           onChange={(event) => {
                                                                               this.inputHandler(event)
                                                                           }}
                                                                           placeholder="Наименование документа"/>
                                                                    <span>
                                                                        <span className="svg-icon svg-icon-lg"
                                                                              onMouseEnter={() => {
                                                                                  this.setState({DocNameClear: true})
                                                                              }}
                                                                              onMouseLeave={() => {
                                                                                  this.setState({DocNameClear: false})
                                                                              }}
                                                                              onClick={(event) => this.clearInput("name")}>
                                                                            <i className={`svg-icon svg-icon-lg icon-color-primary ${DocNameIcon}`}/>
                                                                        </span>
                                                                    </span>
                                                                </div>

                                                                <div className={"d-flex"} style={{gap: "2rem"}}>
                                                                    <div className={"w-50"}>
                                                                        <p className="mb-2 mt-2"><b>Номер</b><sup
                                                                            style={{color: "#dc3545"}}>*</sup></p>
                                                                        <div className="input-icon">
                                                                            <input className="form-control" type="text"
                                                                                   name="number"
                                                                                   value={this.state.RegNumber}
                                                                                   style={this.state.RegNumberError ? {borderColor: "var(--danger)"} : {}}
                                                                                   onChange={(event) => {
                                                                                       this.inputHandler(event)
                                                                                   }}
                                                                                   placeholder="Номер документа"/>
                                                                            <span>
                                                                             <span className="svg-icon svg-icon-lg"
                                                                                   onMouseEnter={() => {
                                                                                       this.setState({RegNumberClear: true})
                                                                                   }}
                                                                                   onMouseLeave={() => {
                                                                                       this.setState({RegNumberClear: false})
                                                                                   }}
                                                                                   onClick={(event) => this.clearInput("number")}>
                                                                                 <i className={`svg-icon svg-icon-lg icon-color-primary ${RegNumberIcon}`}/>
                                                                             </span>
                                                                         </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"w-50 position-relative"}>
                                                                        <div>
                                                                            <p className="mb-2 mt-2">
                                                                                <b>Дата</b>
                                                                                <sup style={{color: "#dc3545"}}>*</sup>
                                                                            </p>
                                                                            <input className="form-control"
                                                                                   style={this.state.RegDateError ? {borderColor: "var(--danger)"} : {}}
                                                                                   type="date" name="date"
                                                                                   min="1900-01-01" max="3000-12-31"
                                                                                   onChange={(event) => {
                                                                                       this.inputHandler(event)
                                                                                   }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="separator separator-dashed my-8"/>
                                                                </div>

                                                                <div>
                                                                    <div className="form-group mt-2">
                                                                        <h4>Атрибуты уникальности</h4>
                                                                        {
                                                                            this.state.ActiveAttributes.map((item, index) => {
                                                                                if (item.IsUnique) {
                                                                                    if (item.IsRequired) {
                                                                                        if (__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType) == "date") {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">
                                                                                                            <b>{item.AttributeName.Name}<sup
                                                                                                                style={{color: "#dc3545"}}>*</sup></b>
                                                                                                        </p>
                                                                                                        <div
                                                                                                            className="input-icon">
                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                onChange={(event) => {
                                                                                                                    this.inputHandler(event, item)
                                                                                                                }}
                                                                                                                value={item.inputValue}
                                                                                                                min="1900-01-01"
                                                                                                                max="3000-12-31"
                                                                                                                placeholder={`${item.AttributeName.Name}...`}
                                                                                                            />
                                                                                                            <span>
                                                                                                                <span className="svg-icon svg-icon-lg">
                                                                                                                    <i className="svg-icon svg-icon-lg icon-color-primary icon-barcode"/>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        } else {
                                                                                            const {
                                                                                                AttributeError: {
                                                                                                    Name,
                                                                                                    IsError
                                                                                                }
                                                                                            } = this.state;
                                                                                            return (
                                                                                                <React.Fragment key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">
                                                                                                            <b>{item.AttributeName.Name}
                                                                                                                <sup style={{color: "#dc3545"}}>*</sup>
                                                                                                            </b>
                                                                                                        </p>
                                                                                                        {
                                                                                                            item.AttributeName.IdAttributeType === DataTypesEnum.Boolean ?
                                                                                                                this.getSelectForBoolAttr({
                                                                                                                    val: item.inputValue,
                                                                                                                    onChange: (event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }
                                                                                                                })
                                                                                                                :
                                                                                                                <div
                                                                                                                    className="input-icon">
                                                                                                                    <input
                                                                                                                        className="form-control"
                                                                                                                        type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                        style={(Name === item.AttributeName.Name && IsError) ? {borderColor: "#F64E60"} : {}}
                                                                                                                        onChange={(event) => {
                                                                                                                            this.inputHandler(event, item)
                                                                                                                        }}
                                                                                                                        value={item.inputValue}
                                                                                                                        placeholder={`${item.AttributeName.Name}...`}
                                                                                                                    />
                                                                                                                    <span>
                                                                                                                        <span
                                                                                                                            className="svg-icon svg-icon-lg">
                                                                                                                            <i className="svg-icon svg-icon-lg icon-color-primary icon-barcode"/>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        if (__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType) == "date") {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">{item.AttributeName.Name}</p>
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                            onChange={(event) => {
                                                                                                                this.inputHandler(event, item)
                                                                                                            }}
                                                                                                            value={item.inputValue}
                                                                                                            min="1900-01-01"
                                                                                                            max="3000-12-31"
                                                                                                            placeholder={`${item.AttributeName.Name}...`}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">{item.AttributeName.Name}</p>
                                                                                                        {
                                                                                                            item.AttributeName.IdAttributeType === DataTypesEnum.Boolean ?
                                                                                                                this.getSelectForBoolAttr({
                                                                                                                    val: item.inputValue,
                                                                                                                    onChange: (event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }
                                                                                                                })
                                                                                                                :
                                                                                                                <input
                                                                                                                    className="form-control"
                                                                                                                    type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                    onChange={(event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }}
                                                                                                                    value={item.inputValue}
                                                                                                                    placeholder={`${item.AttributeName.Name}...`}
                                                                                                                />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <h4>Атрибуты по типу документа</h4>
                                                                        {
                                                                            this.state.ActiveAttributes.map((item, index) => {
                                                                                if (!item.IsUnique) {
                                                                                    if (item.IsRequired) {
                                                                                        if (__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType) == "date") {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">
                                                                                                            <b>{item.AttributeName.Name}</b>
                                                                                                            <sup style={{color: "#dc3545"}}>*</sup>
                                                                                                        </p>
                                                                                                        <div
                                                                                                            className="input-icon">
                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                onChange={(event) => {
                                                                                                                    this.inputHandler(event, item)
                                                                                                                }}
                                                                                                                value={item.inputValue}
                                                                                                                min="1900-01-01"
                                                                                                                max="3000-12-31"
                                                                                                                placeholder={`${item.AttributeName.Name}...`}
                                                                                                            />
                                                                                                            <span>
                                                                                                                <span
                                                                                                                    className="svg-icon svg-icon-lg">
                                                                                                                    <i className="svg-icon svg-icon-lg icon-color-primary icon-atributes_search"/>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">
                                                                                                            <b>{item.AttributeName.Name}</b>
                                                                                                            <sup style={{color: "#dc3545"}}>*</sup>
                                                                                                        </p>
                                                                                                        {
                                                                                                            item.AttributeName.IdAttributeType === DataTypesEnum.Boolean ?
                                                                                                                this.getSelectForBoolAttr({
                                                                                                                    val: item.inputValue,
                                                                                                                    onChange: (event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }
                                                                                                                })
                                                                                                                :
                                                                                                                <div
                                                                                                                    className="input-icon">
                                                                                                                    <input
                                                                                                                        className="form-control"
                                                                                                                        type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                        onChange={(event) => {
                                                                                                                            this.inputHandler(event, item)
                                                                                                                        }}
                                                                                                                        value={item.inputValue}
                                                                                                                        placeholder={`${item.AttributeName.Name}...`}
                                                                                                                    />
                                                                                                                    <span>
                                                                                                                        <span
                                                                                                                            className="svg-icon svg-icon-lg">
                                                                                                                            <i className="svg-icon svg-icon-lg icon-color-primary icon-atributes_search"/>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        if (__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType) == "date") {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">{item.AttributeName.Name}</p>
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                            onChange={(event) => {
                                                                                                                this.inputHandler(event, item)
                                                                                                            }}
                                                                                                            value={item.inputValue}
                                                                                                            min="1900-01-01"
                                                                                                            max="3000-12-31"
                                                                                                            placeholder={`${item.AttributeName.Name}...`}/>
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={item.uid}>
                                                                                                    <div>
                                                                                                        <p className="mb-2">{item.AttributeName.Name}</p>
                                                                                                        {
                                                                                                            item.AttributeName.IdAttributeType === DataTypesEnum.Boolean ?
                                                                                                                this.getSelectForBoolAttr({
                                                                                                                    val: item.inputValue,
                                                                                                                    onChange: (event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }
                                                                                                                })
                                                                                                                :
                                                                                                                <input
                                                                                                                    className="form-control"
                                                                                                                    type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                                                                                                    onChange={(event) => {
                                                                                                                        this.inputHandler(event, item)
                                                                                                                    }}
                                                                                                                    value={item.inputValue}
                                                                                                                    placeholder={`${item.AttributeName.Name}...`}
                                                                                                                />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                                return null
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.ActiveType &&
                                                this.state.typeValue.value &&
                                                <div className="card card-custom container-solid col-lg-6"
                                                     style={{borderRadius: "20px"}}>
                                                    <div className="card-header card-header-colored">
                                                        <div className="card-title">
                                                            <span className="card-icon"/>
                                                            <h4 className="card-label text-weight-bolder text-color-primary"
                                                                title="Настройки отчета"
                                                            >
                                                                <b>Загрузка файлов</b>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div className="card-body beautiful-scroll-10 p-5">
                                                        <div className="form-group row">
                                                            <div className="col-lg-6">
                                                                <p className="my-4">
                                                                    <b>Оригинал (PDF)
                                                                        <sup style={{color: "#dc3545"}}>*</sup>
                                                                    </b>
                                                                    {
                                                                        this.state.showOriginalFilesTooltipShow &&
                                                                        <MyTooltip target={this.showOriginalFilesRef}
                                                                                   text={"Просмотр выбранных файлов"}
                                                                                   show={this.state.showOriginalFilesTooltipShow}
                                                                                   placement={"top"} delay={500}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.OriginalFile?.name &&
                                                                        <span
                                                                            className="btn btn-icon btn-sm mx-2 h-auto"
                                                                            ref={this.showOriginalFilesRef}
                                                                            onClick={this.showOriginalFilesList}
                                                                            onMouseEnter={() => {
                                                                                this.setState({showOriginalFilesTooltipShow: true})
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                this.setState({showOriginalFilesTooltipShow: false})
                                                                            }}
                                                                        >
                                                                            <i className="svg-icon icon-Documents icon-color-primary"/>
                                                                        </span>
                                                                    }
                                                                </p>
                                                                <DropFileComponent elemRef={this.originalFileRef}
                                                                                   name={"original"}
                                                                                   accept={"application/pdf"}
                                                                                   multiple={false}
                                                                                   setFiles={(file) => this.setState({OriginalFile: file})}
                                                                                   value={this.state.OriginalFile}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <p className="my-2 text-nowrap">
                                                                    <b>Файл предпросмотра (PNG)</b>
                                                                    {
                                                                        this.state.previewInfoTooltipShow &&
                                                                        <MyTooltip target={this.previewInfoRef}
                                                                                   text={"Файл предпросмотра не является обязательным, при его отсутствии предпросмотр будет сгенерирован автоматически"}
                                                                                   show={this.state.previewInfoTooltipShow}
                                                                                   placement={"top"} delay={500}
                                                                        />
                                                                    }
                                                                    <span className="btn btn-icon btn-circle btn-sm"
                                                                          ref={this.previewInfoRef}
                                                                          onMouseEnter={() => {
                                                                              this.setState({previewInfoTooltipShow: true})
                                                                          }}
                                                                          onMouseLeave={() => {
                                                                              this.setState({previewInfoTooltipShow: false})
                                                                          }}
                                                                    >
                                                                        <i className="svg-icon icon-Info"/>
                                                                    </span>
                                                                </p>
                                                                <DropFileComponent elemRef={this.previewFileRef}
                                                                                   name={"preview"}
                                                                                   accept={"image/png"}
                                                                                   multiple={false}
                                                                                   setFiles={(file) => this.setState({PreviewFile: file})}
                                                                                   value={this.state.PreviewFile}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-lg-6">
                                                                <p className="mb-3">Дополнительные файлы
                                                                    {
                                                                        this.state.showOtherFilesTooltipShow &&
                                                                        <MyTooltip target={this.showOtherFilesRef}
                                                                                   text={"Просмотр выбранных файлов"}
                                                                                   show={this.state.showOtherFilesTooltipShow}
                                                                                   placement={"top"} delay={500}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.OtherFiles.length > 0 &&
                                                                        <span
                                                                            className="btn btn-icon btn-sm mx-2 h-auto"
                                                                            ref={this.showOtherFilesRef}
                                                                            onClick={this.showOtherFilesList}
                                                                            onMouseEnter={() => {
                                                                                this.setState({showOtherFilesTooltipShow: true})
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                this.setState({showOtherFilesTooltipShow: false})
                                                                            }}>
                                                                        <i className="svg-icon icon-Documents icon-color-primary"/>
                                                                    </span>
                                                                    }
                                                                </p>

                                                                <DropFileComponent elemRef={this.otherFilesRef}
                                                                                   name={"other"}
                                                                                   accept={"*"}
                                                                                   multiple
                                                                                   setFiles={(files) => {
                                                                                       this.setState({OtherFiles: files})
                                                                                   }}
                                                                                   value={this.state.OtherFiles}
                                                                />
                                                            </div>
                                                            <div className="separator separator-dashed"/>
                                                        </div>
                                                        <div className="form-group row p-5">
                                                            <div className="d-flex align-items-center mb-3">
                                                                <h4 className="mb-0">Содержание документа</h4>
                                                                {
                                                                    this.state.contentTooltipShow &&
                                                                    <MyTooltip target={this.contentRef}
                                                                               text={"Содержание не является обязательным, при его отсутствии будет сгенерировано автоматически"}
                                                                               show={this.state.contentTooltipShow}
                                                                               placement={"top"} delay={500}
                                                                    />
                                                                }
                                                                <span
                                                                    className="btn btn-icon btn-circle btn-sm icon-info-decor"
                                                                    ref={this.contentRef}
                                                                    onMouseEnter={() => {
                                                                        this.setState({contentTooltipShow: true})
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        this.setState({contentTooltipShow: false})
                                                                    }}
                                                                >
                                                                    <i className="svg-icon icon-Info"/>
                                                                </span>
                                                            </div>
                                                            <textarea className={"form-control beautiful-scroll-10"}
                                                                      id="content" cols="100"
                                                                      name="content"
                                                                      onChange={(e) => {
                                                                          this.setState({ContentText: e.target.value});
                                                                      }}
                                                                      rows="20" style={{resize: "none"}}
                                                            />
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-between">
                                                            <span className="btn btn-primary font-weight-bold"
                                                                  onClick={this.checkInput}
                                                            >
                                                                Сохранить
                                                            </span>
                                                            <span className="btn btn-outline-secondary font-weight-bold"
                                                                  onClick={() => this.setState({...this.defaultState})}
                                                            >
                                                                Сбросить
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,
        filials: state.globalState.filial.All,
        globalSettings: state.globalState.settings.Content,
        packages: state.packages.treeSearch.folders,
        theme: state.globalState.theme,
    }
}

const mapDispatchToProps = {
    setStateLeftAsideMenu,
    setModalData,
    treeSearchPackage,

}


export default connect(mapStateToProps, mapDispatchToProps)(MasterApp);
