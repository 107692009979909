import React from "react";
import {connect} from "react-redux";
import {changePackagesViewMode} from "../../../../store/packages/actionCreators/package_RootStateActionCreator";
import {treeSearchPackage} from "../../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {handlerIsSearch} from "../../../../store/packages/actionCreators/package_InputActionCreator";


class TreeSearchPackagesRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // TreeSearch : "", // текст для поиска
            clearHover : false,
        };

        this.SearchSubmit = this.SearchSubmit.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.clearInput = this.clearInput.bind(this);
    }

    componentDidMount() {
        if (this.props.tableRole === "additional" && this.props.value !== "") {
            this.startSearch(this.props.value)
        }
    }

    SearchSubmit(event) {
        event.preventDefault();
        this.startSearch(this.props.value);
    }

    inputOnChange(event) {
        if(!this.props.isSearch) {
            this.props.handlerIsSearch({TableType: "additional", IsSearch: true});
        }
        this.startSearch(event.target.value);
        this.props.changeValue(event.target.value)
    }

    startSearch(SearchText) {
        if(this.props?.searchText) {
            this.props.searchText(SearchText);
        }
        else {
            if(SearchText.length > 1) {
                this.props.treeSearchPackage({
                    Input : SearchText,
                    TableType: 'additional',

                    // Next : {
                    //     action : () => {
                    //         this.props.changePackagesViewMode({
                    //             ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree_Search
                    //         })
                    //     },
                    //     params : {}
                    // }
                });
            }
            // else{
            //     console.log("пустой поиск2");
            //     this.props.changePackagesViewMode({
            //         ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree
            //     })
            // }
        }



    }

    // сбрасываем поле ввода
    clearInput() {
        this.startSearch("");
        this.props.changeValue("");
        if(this.props.isSearch) {
            this.props.handlerIsSearch({TableType: "additional", IsSearch: false});
        }
    }

    render() {

        let searchIcon = "icon-Search ";
        if(this.state.clearHover) { // для отображения красного крестика
            searchIcon = "icon-Delete icon-color-red";
        }


        return(
            <div style={{position: "sticky", top: -10, backgroundColor: "#ffffff", zIndex: "1200", paddingBottom: 5}}>
                <div className="input-icon h-40px">
                    <form onSubmit={this.SearchSubmit}>
                        <input type="text"
                               className="form-control form-control-lg form-control-solid h-40px"
                               placeholder="Поиск"
                               value={this.props.value}
                               onChange={this.inputOnChange}
                               onKeyDown={(event)=>{
                                   if(event.code  === "Backspace" && this.props.value.length <= 2) {
                                       this.clearInput()
                                   }
                               }}/>
                    </form>
                    <span>
                        <span className="svg-icon svg-icon-lg" style={{cursor : "pointer"}}
                              onMouseEnter={()=>{this.setState({clearHover : true})}}
                              onMouseLeave={()=>{this.setState({clearHover : false})}}
                              onClick={this.clearInput}>
                            <i className={"svg-icon svg-icon-lg " + searchIcon}/>
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree : state.packages.treeForDocuments,
        isSearch: state.packages.treeAdditional.isSearch
    }
}

const  mapDispatchToProps = {
    changePackagesViewMode,
    treeSearchPackage,
    handlerIsSearch
}


export default connect(mapStateToProps, mapDispatchToProps)(TreeSearchPackagesRight);
