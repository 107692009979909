import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {TreeCombine, TreeTypes} from "../../../tools/TreeCombine";
import {PACKAGES_TABLE_ACTIONS, PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {store} from "../../../index";
import {apiRequest} from "../../../tools/API/apiRequest";
import {addToEndUrl, apiUrl} from "../../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {API} from "../../../tools/API_NEW/API";


export const treeSearch_packagesDocumentRight = ({SearchText, tree_render,  Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let tree_render_search = {
                treeId : createUniqueIdString(8), // уникальный id для определения конкретного узла дерева
                parent : null,
                type : TreeTypes.ROOT,
                info : {},
                children : []
            }

            for(let i = 0; i < tree_render.children.length; i++) {
                let docIsFind = false;
                for(let j = 0; j < tree_render.children[i].children.length; j++) {
                    let NameDoc = tree_render.children[i].children[j].info.Name.toLowerCase();
                    if(NameDoc.indexOf(SearchText.toLowerCase()) >= 0) {
                        docIsFind = true;
                        let node = {...tree_render.children[i], ...{
                                parent : tree_render_search.treeId,
                                children : []
                            }};

                        for(let k = 0; k < tree_render.children[i].children.length; k++) {
                            let NameDoc = tree_render.children[i].children[k].info.Name.toLowerCase();
                            if(NameDoc.indexOf(SearchText.toLowerCase()) >= 0) {
                                node.children.push({...tree_render.children[i].children[k], ...{
                                        parent : node.treeId
                                    }});
                            }
                        }
                        node.info = {...node.info, ...{searchType : "folderOpen"}};
                        tree_render_search.children.push(node);
                        break;
                    }
                }

                if(!docIsFind) {
                    let NameFolder = tree_render.children[i].info.Name.toLowerCase();
                    if(NameFolder.indexOf(SearchText.toLowerCase()) >= 0) {
                        let node = {...tree_render.children[i], ...{
                                parent : tree_render_search.treeId,
                                children : []
                            }};

                        for(let k = 0; k < tree_render.children[i].children.length; k++) {
                            node.children.push({...tree_render.children[i].children[k], ...{parent : node.treeId}});
                        }

                        node.info = {...node.info, ...{searchType : "folderNotOpen"}};
                        tree_render_search.children.push(node);
                    }
                }
            }


            dispatch({type : PACKAGES_TABLE_ACTIONS.SEARCH_DOCUMENT_RIGHT, payload : {...tree_render_search}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка поиска по дереву (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const treeSearchToggle_packagesDocumentRight = ({State = false,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SEARCH_TOGGLE_DOCUMENT_RIGHT, payload : State});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка переключения отображения дерева при поиске (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// сохраняем выбранный узел дерева
export const treeSelectNode_packagesDocumentRight = ({Node = null,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SELECT_NODE_DOCUMENT_RIGHT, payload : Node});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения отображения дерева при поиске (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// TODO: переделать под пакеты
export const mainTableDownloadDocCollection_packagesDocumentRight = ({id=null, ActiveNode = null, PaginationPageSize = null, PaginationPageNumber = null, Filial = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"

            let Node = null;
            if(ActiveNode != null) {
                Node = {...ActiveNode};
                Node.parent = TreeCombine.searchByTreeId_packagesRightDocuments({treeId : Node.parent});
            } else {
                Node = {...store.getState().packages.treeForDocuments.activeNode};
                Node.parent = TreeCombine.searchByTreeId_packagesRightDocuments({treeId : Node.parent});
            }

            if(PaginationPageSize == null) {
                PaginationPageSize = store.getState().packages.mainTable.paginationPageSize;
            }

            if(PaginationPageNumber == null) {
                PaginationPageNumber = store.getState().packages.mainTable.paginationPageNumber;
            }

            if(Filial == null) {
                Filial =  {...store.getState().globalState.filial.Active};
            }

            let request;
            if(id){
                request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter : new Filter(FiltersType.ENTITY)
                        .addAttribute({Name: "IdDocGroup", Value: Node.parent.info.Id})
                        .addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Type.Id", Value: Node.info.Id})
                                .add({Name: "IdFilial", Value: Filial.Id}).get()
                        ).get(),
                    id:id,
                    sorting : new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                });
            }
            else {
                request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter : new Filter(FiltersType.ENTITY)
                        .addAttribute({Name: "IdDocGroup", Value: Node.parent.info.Id})
                        .addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Type.Id", Value: Node.info.Id})
                                .add({Name: "IdFilial", Value: Filial.Id}).get()
                        ).get(),
                    sorting : new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                });
            }

            await request.execute(async function(data) {


                if(id) {
                    await dispatch(setPaginationPageNumber_packagesDocumentRight({PaginationPageNumber : Math.ceil((data.RecordsOffset + PaginationPageSize) / PaginationPageSize)}));
                }

                await dispatch({type : PACKAGES_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION_DOCUMENT_RIGHT, payload : {
                        docs : data.Records,
                        totalInServer: data.TotalRecordsCount,
                        recordsOffset:data.RecordsOffset,
                    }});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения коллекции документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения коллекции документов (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// сборка данных для отображения таблицы;
// ActiveNode - выбранный узел дерева;
// Filial - выбранный филиал
export const combineMainTableAttributes_packagesDocumentRight = ({ActiveNode = null, Filial = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"

            let Node = null;
            if(ActiveNode != null) Node = {...ActiveNode};
            else Node = {...store.getState().packages.treeForDocuments.activeNode};
            Node.parent = TreeCombine.searchByTreeId_packagesRightDocuments({treeId : Node.parent});

            if(Filial == null) Filial =  {...store.getState().globalState.filial.Active};

            // вызываем store так как если передавать через очередь, то данные будут не обновлены
            let UserSettings = store.getState().globalState.userSettings;
            let Docs = store.getState().packages.mainTable.collection.docs;

            // проверяем есть ли пользовательские настройки для данной таблицы
            let UserSettingsTable = HelpFunctions.CheckUserSettingForTable_packagesRightDocuments({
                userSettings : UserSettings,
                IdDocGroup: Node.parent.info.id,
                TypeId: Node.info.Id,
                IdFilial: Filial.Id,
            });

            // заголовки таблицы
            let HeaderColumns = [];
            // строки таблицы
            let BodyColumns = [];
            // аттрибуты все которые пришли
            let AllAttributes = [];
            let TypeAttributes = Node.info.TypeAttributes;

            HeaderColumns.push({Name :  "Version.Version", Value : "Версия", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "RegDate", Value : "Дата", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "RegNumber", Value : "Номер", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "Name", Value : "Наименование", IsConstant : true, UserVisible : true});

            // для необязательных атрибутов
            for (let i = 0; i < TypeAttributes.length; i++) {
                if(TypeAttributes[i].IsVisibleInRegistry) {
                    HeaderColumns.push({
                        Name : "PropertyName",
                        Value : TypeAttributes[i].AttributeName.Name,
                        IdAttributeName : TypeAttributes[i].AttributeName.Id,
                        IdAttributeType : TypeAttributes[i].AttributeName.IdAttributeType,
                        AttributeName : TypeAttributes[i].AttributeName,
                        IsConstant : false,
                        UserVisible : true
                    });
                }
                AllAttributes.push({
                    Name : "PropertyName",
                    Value : TypeAttributes[i].AttributeName.Name,
                    IdAttributeName : TypeAttributes[i].AttributeName.Id,
                    AttributeName : TypeAttributes[i].AttributeName,
                    InRegistry : TypeAttributes[i].IsVisibleInRegistry
                });
            }
            HeaderColumns.push({Name :  "Version.ModifyTime", Value : "Последнее изменение", IsConstant : true, UserVisible : true});

            for (let i = 0; i < Docs.length; i++) {
                let BodyColumnsTR = [];

                BodyColumnsTR.push({Name : "Version.Version", Value : Docs[i].Version.Version, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "RegDate", Value : Docs[i].RegDate, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "RegNumber", Value : Docs[i].RegNumber, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "Name", Value : Docs[i].Name, IsConstant : true, UserVisible : true});

                for (let j = 0; j < HeaderColumns.length; j++) {
                    for(let k = 0; k < Docs[i].Attributes.length; k++) {
                        if(!HeaderColumns[j].IsConstant) {
                            if(HeaderColumns[j].IdAttributeName === Docs[i].Attributes[k].AttributeName.Id) {
                                if(HeaderColumns[j].Value === Docs[i].Attributes[k].AttributeName.Name) {
                                    BodyColumnsTR.push({
                                        Name : "PropertyName",
                                        Value : Docs[i].Attributes[k].AttributeValue,
                                        IdAttributeName : Docs[i].Attributes[k].AttributeName.Id,
                                        IdAttributeType : Docs[i].Attributes[k].AttributeName.IdAttributeType,
                                        AttributeName : Docs[i].Attributes[k].AttributeName,
                                        ColumnName : Docs[i].Attributes[k].AttributeName.Name,
                                        IsConstant : false,
                                        UserVisible : HeaderColumns[j].UserVisible
                                    });
                                    break;
                                }
                            }
                        }
                    }
                }
                BodyColumnsTR.push({Name :  "Version.ModifyTime", Value : Docs[i].Version.ModifyTime, IsConstant : true, UserVisible : true});

                // поиск и добавление недостающих данных
                for (let j = 0; j < HeaderColumns.length; j++) {
                    if(!HeaderColumns[j].IsConstant) {
                        if(HeaderColumns[j].Value !== BodyColumnsTR[j].ColumnName) {
                            BodyColumnsTR.splice(j, 0, {
                                Name : "PropertyName",
                                Value : "-",
                                IdAttributeName : HeaderColumns[j].IdAttributeName,
                                AttributeName : HeaderColumns[j],
                                ColumnName : HeaderColumns[j].Value,
                                IsConstant : false,
                                UserVisible : HeaderColumns[j].UserVisible
                            });
                        }
                    }
                }

                // собираем все атрибуты для дополнительных секций
                let AllAttributesValue = [];
                for (let j = 0; j < AllAttributes.length; j++) {
                    for (let k = 0; k < Docs[i].Attributes.length; k++) {
                        if(AllAttributes[j].IdAttributeName === Docs[i].Attributes[k].AttributeName.Id) {
                            if (AllAttributes[j].Value === Docs[i].Attributes[k].AttributeName.Name) {
                                AllAttributesValue.push({
                                    Name : "PropertyName",
                                    Value : Docs[i].Attributes[k].AttributeValue !== null ? Docs[i].Attributes[k].AttributeValue : "-",
                                    IdAttributeName : Docs[i].Attributes[k].AttributeName.Id,
                                    IdAttributeType : Docs[i].Attributes[k].AttributeName.IdAttributeType,
                                    AttributeName : Docs[i].Attributes[k].AttributeName,
                                    ColumnName : Docs[i].Attributes[k].AttributeName.Name,
                                    IsConstant : false
                                });
                                break;
                            }
                        }
                    }
                }

                BodyColumns.push({
                    tr : BodyColumnsTR,
                    Version : Docs[i].Version,
                    Type : Docs[i].Type,
                    AllAttributes : AllAttributesValue,
                    Info: Docs[i],
                });
            }

            // применяем полученные настройки для заголовков
            if(UserSettingsTable.isFind) {
                let SortedHeaderColumns = [];

                let index = 0;
                for(let i = 0; i < UserSettingsTable.data.columns.length; i++) {
                    for(let j = 0; j < HeaderColumns.length; j++) {
                        if(HeaderColumns[j].Name === UserSettingsTable.data.columns[i].Name) {
                            if(HeaderColumns[j].Value === UserSettingsTable.data.columns[i].Value) {
                                SortedHeaderColumns.push(HeaderColumns[j]);
                                SortedHeaderColumns[index].UserVisible = UserSettingsTable.data.columns[i].Visible;
                                index++;
                                break;
                            }
                        }
                    }
                }
                HeaderColumns = SortedHeaderColumns;

                let NewBodyDefinition = HelpFunctions.sortTableBodyByHeaders({
                    ColumnDefinition : HeaderColumns,
                    BodyDefinition : BodyColumns
                });

                dispatch({
                    type : PACKAGES_TABLE_ACTIONS.COMBINE_ATTRIBUTE_DOCUMENT_RIGHT,
                    payload :
                        {
                            Headers : HeaderColumns,
                            Body : NewBodyDefinition,
                        }
                });

            } else {
                dispatch({
                    type : PACKAGES_TABLE_ACTIONS.COMBINE_ATTRIBUTE_DOCUMENT_RIGHT,
                    payload :
                        {
                            Headers : HeaderColumns,
                            Body : BodyColumns,
                        }
                });
            }
            // следующее действие если есть цепочка
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка объединения атрибутов таблицы (catch): ", exception);
            // если ошибка колбек
            if(Error) {Error.action(Error.params);}
        }
    }
}


// установить номер страницы пагинации в таблице
export const setPaginationPageNumber_packagesDocumentRight = ({PaginationPageNumber, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER_DOCUMENT_RIGHT, payload : Number.parseInt(PaginationPageNumber)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения страницы пагинации в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const resetMainTableForUpdate_packagesDocumentRight = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let settings = {...store.getState().packages.mainTable.settings};
            dispatch({type : PACKAGES_TABLE_ACTIONS.RESET_FOR_UPDATE_DOCUMENT_RIGHT, payload : {settings : settings}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса таблицы для обновления (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const checkAllRowsOnPage_packagesDocumentRight = ({Check = null, Body = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let checkedFields = [...store.getState().packages.mainTable.checkedFields];
            let body = Body ? Body : [...store.getState().packages.mainTable.body];


            if(Check == null) {
                // если отмечены все строки
                if(checkedFields.length >= body.length) {
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT, payload : []});
                } else {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        let IdDoc = Body ? Body[i].Node.Version.IdDoc : body[i].Version.IdDoc;
                        newCheckedFields.add(IdDoc);
                    }
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT, payload : Array.from(newCheckedFields)});
                }
            } else {
                if (Check) {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        let IdDoc = Body ? Body[i].Node.Version.IdDoc : body[i].Version.IdDoc;
                        newCheckedFields.add(IdDoc);
                    }
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT, payload : Array.from(newCheckedFields)});
                } else {
                    dispatch({type: PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT, payload: []});
                }
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const checkAllRowsOnPage_MainTable = ({Check = null, Body = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let checkedFields = [...store.getState().packages.mainTable.checkedFieldsMainTable];
            let body = Body ? Body : [...store.getState().packages.mainTable.body];


            if(Check == null) {
                // если отмечены все строки
                if(checkedFields.length >= body.length) {
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE, payload : []});
                } else {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        let IdDoc = Body ? Body[i].Node.Version.IdDoc : body[i].Version.IdDoc;
                        newCheckedFields.add(IdDoc);
                    }
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE, payload : Array.from(newCheckedFields)});
                }
            } else {
                if (Check) {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        let IdDoc = Body ? Body[i].Node.Version.IdDoc : body[i].Version.IdDoc;
                        newCheckedFields.add(IdDoc);
                    }
                    dispatch({type : PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE, payload : Array.from(newCheckedFields)});
                } else {
                    dispatch({type: PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE, payload: []});
                }
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const changeDocumentSearchInputs_packagesDocumentRight = ({
                                                                     isDelete = false,
                                                                     TH=false,
                                                                     Direction=null,
                                                                     DirectionUp="icon-color-gray icon-Filter_up",
                                                                     DirectionDown="icon-color-gray icon-Filter_down",
                                                                     Attribute = null,
                                                                     Value="",
                                                                     IsApplied = false,
                                                                     Name = "",
                                                                     isConstant = false ,
                                                                     IdAttributeType = null,
                                                                     isFocus=false,
                                                                     UserVisible = true,
                                                                     IsShowExtFilters = false,
                                                                     Next = null,
                                                                     Error = null}) => {
    return async dispatch => {
        try {
            let searchInputs;
            if(isDelete) {
                dispatch({type: PACKAGES_TABLE_ACTIONS.CHANGE_INPUTS_DOCUMENT_RIGHT, payload: []});
                if(Next) {Next.action(Next.params);}
                return;
            }
            else {
                searchInputs = __.deepCopy(store.getState().packages.rightTableSearchDoc.searchInputs);
            }

            if(TH){
                Attribute= __.deepCopy(Attribute);
                if(Attribute.Name==="RegNumber"){
                    Attribute="Номер"
                }else if(Attribute.Name==="Name"){
                    Attribute="Наименование"
                }else if(Attribute.Name==="RegDate"){
                    Attribute="Дата"

                }else if(Attribute.Name==="Version.ModifyTime"){
                    Attribute="Последнее изменение"
                }
            }

            //let searchInputs = __.deepCopy(store.getState().packages.search.searchInputs);

            if(Direction!==null){
                if(Direction.indexOf('icon-Filter_up') >= 0){
                    DirectionUp="icon-color-primary icon-Filter_up";
                    DirectionDown="icon-color-gray icon-Filter_down";
                }else {

                    DirectionUp="icon-color-gray icon-Filter_up";
                    DirectionDown="icon-color-primary icon-Filter_down";
                }

            }

            let foundIndex = isConstant
                ? searchInputs.findIndex(s => s.Attribute === Attribute)
                : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);
            // if(TH) {
            //     foundIndex = isConstant
            //         ? searchInputs.findIndex(s => s.Attribute === Attribute)
            //         : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);
            // } else {
            //     foundIndex = isConstant
            //         ? searchInputs.findIndex(s => s.Attribute === Attribute)
            //         : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);
            // }

            if(isFocus) searchInputs = searchInputs.map(item => ({...item, isFocus: false}));

            if(Value === "" || Object.values(Value).every(item => item === "")) {
                if(foundIndex < 0) {
                    for(let i = 0; i < searchInputs.length; i++) {
                        //TODO убраны лишние стрелки
                        //TODO SS протестировать: при первом открытии меню фильтрации - сбрасывалась сортировка
                        if (i !== foundIndex) {
                            // searchInputs[i].DirectionUp = "icon-color-gray icon-Filter_up";
                            // searchInputs[i].DirectionDown = "icon-color-gray icon-Filter_down";
                        }
                    }
                    searchInputs.push({
                        Attribute,
                        Value,
                        Name,
                        isConstant,
                        isFocus,
                        DirectionUp,
                        DirectionDown,
                        UserVisible,
                        IdAttributeType,
                        IsShowExtFilters,
                        IsApplied
                    });
                }
                else {
                    for(let i = 0; i < searchInputs.length; i++) {

                        if(i!==foundIndex){
                            searchInputs[i].DirectionUp = "icon-color-gray icon-Filter_up";
                            searchInputs[i].DirectionDown = "icon-color-gray icon-Filter_down";
                        }else{
                            searchInputs[foundIndex].DirectionUp = DirectionUp;
                            searchInputs[foundIndex].DirectionDown = DirectionDown;
                            searchInputs[foundIndex].Value = Direction === null
                                ? ""
                                : searchInputs[foundIndex].Value;
                            searchInputs[foundIndex].isFocus = isFocus;
                            searchInputs[foundIndex].IsApplied = IsApplied;
                        }

                    }


                }
            }else{
                if(foundIndex < 0) {
                    searchInputs.push({
                        Attribute,
                        Value,
                        Name,
                        isConstant,
                        isFocus,
                        DirectionUp,
                        DirectionDown,
                        UserVisible,
                        IdAttributeType,
                        IsShowExtFilters,
                        IsApplied
                    });
                }
                else {
                    searchInputs[foundIndex].Value = Value;
                    searchInputs[foundIndex].DirectionUp = DirectionUp;
                    searchInputs[foundIndex].DirectionDown = DirectionDown;
                    searchInputs[foundIndex].UserVisible = UserVisible;
                    searchInputs[foundIndex].IsShowExtFilters = IsShowExtFilters;
                    searchInputs[foundIndex].isFocus = isFocus;
                    searchInputs[foundIndex].IsApplied = IsApplied;
                }
            }

            dispatch({type: PACKAGES_TABLE_ACTIONS.CHANGE_INPUTS_DOCUMENT_RIGHT, payload: searchInputs});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



export const mainTableSearchDoc_packagesDocumentRight = ({
                                                             FieldSorting=null,
                                                             Direction=null,
                                                             PaginationPageNumber=null,
                                                             PaginationPageSize=null,
                                                             Filial = null,
                                                             Next = null,
                                                             Error = null}) => {
    return async dispatch => {
        try {
                Direction = (Direction!==null && Direction.includes('icon-Filter_up'))
                    ? 1
                    : 2


            if(Filial == null) {
                Filial =  {...store.getState().globalState.filial.Active};
            }
            if(PaginationPageSize == null) {
                PaginationPageSize = store.getState().packages.mainTable.paginationPageSize;
            }
            if(PaginationPageNumber == null) {
                PaginationPageNumber = store.getState().packages.mainTable.paginationPageNumber;
            }

            let Type_id = store.getState().packages.treeForDocuments.activeNode.info.Id;
            let inputs = store.getState().packages.rightTableSearchDoc.searchInputs;
            let filter = new Filter(FiltersType.ENTITY)
            filter.add(
                new EntityFilters().setEntityName("DocExtended")
                    .add({Name: "Type.Id", Value: Type_id})
                    .add({Name: "IdFilial", Value: Filial.Id})
                    .get()
            )
            for(let i = 0; i < inputs.length; i++) {
                if (typeof inputs[i].Value !== "string") {
                    let entity;
                    entity = __.setEntityFilters(inputs[i]);
                    console.log('entity', entity)
                    if(!inputs[i].isConstant) {
                        entity.add({Name: "AttributeName.Id", Value: inputs[i].Attribute.IdAttributeName})
                            .addAttribute({Name : "ReferenceProperty",Value:"IdDoc"})
                            .addAttribute({Name : "RootEntityProperty",Value:"Id"});
                    }
                    filter.add(entity.get());
                } else {
                    if (inputs[i].isConstant && inputs[i].Value !== "") {
                        if (inputs[i].Attribute === "Наименование" || inputs[i].Attribute === "Name") {
                            filter.add(
                                new EntityFilters().setEntityName("DocExtended")
                                    .add({Name: "Name", Value: inputs[i].Value, Operator: 2})
                                    .get()
                            )
                        } else if (inputs[i].Attribute === "Номер" || inputs[i].Attribute === "RegNumber") {
                            filter.add(
                                new EntityFilters().setEntityName("DocExtended")
                                    .add({Name: "RegNumber", Value: inputs[i].Value, Operator: 2})
                                    .get()
                            )
                        } else if (inputs[i].Attribute === "Дата" || inputs[i].Attribute === "RegDate") {
                            filter.add(
                                new EntityFilters().setEntityName("DocExtended")
                                    .add({Name: "RegDate", Value: inputs[i].Value, Operator: 2})
                                    .get()
                            )
                        } else if (inputs[i].Attribute === "Последнее изменение" || inputs[i].Attribute === "Version.ModifyTime") {
                            filter.add(
                                new EntityFilters().setEntityName("DocExtended")
                                    .add({Name: "Version.ModifyTime", Value: inputs[i].Value, Operator: 2})
                                    .get()
                            )
                        }
                    } else if (!inputs[i].isConstant && inputs[i].Value !== "") {
                        filter.add(
                            new EntityFilters().setEntityName("DocAttribute")
                                .add({Name: "AttributeName.Id", Value: inputs[i].Attribute.IdAttributeName})
                                .add({Name: "AttributeValue", Value: inputs[i].Value, Operator: 2})
                                .addAttribute({Name: "ReferenceProperty", Value: "IdDoc"})
                                .addAttribute({Name: "RootEntityProperty", Value: "Id"})
                                .get()
                        )
                    }
                }

            }
            filter.addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})



            let sorting = new Sorting();

            if(FieldSorting==null){
                sorting.addAttribute({Name: "IdAttributeName", Value: "-1"})
                sorting.add({PropertyName: "RegDate", Direction: 2})
                sorting.add({PropertyName: "RegNumber", Direction: 1})
                sorting.add({PropertyName: "Id", Direction: 1})
            }else {
                if(FieldSorting.isConstant || FieldSorting.IsConstant){
                    sorting.addAttribute({Name: "IdAttributeName", Value: "-1"})
                    if(FieldSorting.Attribute === "Наименование" || FieldSorting.Value === "Наименование"){
                        sorting.add({PropertyName: "Name", Direction})
                    }else if(FieldSorting.Attribute === "Номер" || FieldSorting.Value === "Номер"){
                        sorting.add({PropertyName: "RegNumber", Direction})
                    }else if(FieldSorting.Attribute === "Дата"|| FieldSorting.Value === "Дата"){
                        sorting.add({PropertyName: "RegDate", Direction})
                    }else if(FieldSorting.Attribute === "Последнее изменение"|| FieldSorting.Value === "Последнее изменение"){
                        sorting.add({PropertyName: "Version.ModifyTime", Direction})
                    }

                }else{
                    if(FieldSorting.IdAttributeName){
                        sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.IdAttributeName})
                    }else{
                        sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.Attribute.IdAttributeName})
                    }
                    // sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.Attribute.IdAttributeName})
                    sorting.add({PropertyName: "SortingAttributeValue", Direction})
                }
            }

            filter = filter.get();
            sorting = sorting.get();
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION,{
                filter,
                sorting
            });

            request.execute(function(data) {
                dispatch({type: PACKAGES_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION_DOCUMENT_RIGHT, payload: {
                        docs: data.Records,
                        totalInServer: data.TotalRecordsCount,
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                }
                console.log("Ошибка получения коллекции документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения коллекции документов (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const addRowToChecked_MainTable = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let checkedFields = store.getState().packages.mainTable.checkedFieldsMainTable;
            let result =  Array.from(new Set(checkedFields).add(IdDoc));

            dispatch({type : PACKAGES_TABLE_ACTIONS.ADD_ROW_TO_CHECKED_MAIN_TABLE, payload : result});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка добавления строки к отмеченным (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const addRowToChecked_packagesDocumentRight = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let checkedFields = store.getState().packages.mainTable.checkedFields;
            let result =  Array.from(new Set(checkedFields).add(IdDoc));

            dispatch({type : PACKAGES_TABLE_ACTIONS.ADD_ROW_TO_CHECKED_DOCUMENT_RIGHT, payload : result});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка добавления строки к отмеченным (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const deleteRowFromChecked_MainTable = ({
                                                               IdDoc,
                                                               isClear = false,
                                                               Next = null,
                                                               Error = null}) => {
    return async dispatch => {
        try {
            let checkedFields = store.getState().packages.mainTable.checkedFieldsMainTable;
            let checkedFields_Set = new Set(checkedFields);
            checkedFields_Set.delete(IdDoc);
            let result = Array.from(checkedFields_Set);

            dispatch({type : PACKAGES_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED_MAIN_TABLE, payload : isClear ? [] : result});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const deleteRowFromChecked_packagesDocumentRight = ({
                                                               IdDoc,
                                                               isClear = false,
                                                               Next = null,
                                                               Error = null}) => {
    return async dispatch => {
        try {
            let checkedFields = store.getState().packages.mainTable.checkedFields;
            let checkedFields_Set = new Set(checkedFields);
            checkedFields_Set.delete(IdDoc);
            let result = Array.from(checkedFields_Set);

            dispatch({type : PACKAGES_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED_DOCUMENT_RIGHT, payload : isClear ? [] : result});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const selectActiveRow_packagesDocumentRight = ({RowInfo ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: PACKAGES_TABLE_ACTIONS.SELECT_ACTIVE_ROW_DOCUMENT_RIGHT, payload: RowInfo});
            if(Next) {Next.action(Next.params)} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params)} // если ошибка колбек
        }
    }
}


export const changeDocumentSearchInputsContextMenu_packagesDocumentRight = ({
                                                                                Attribute,
                                                                                Value,
                                                                                isConstant = false,
                                                                                isFocus=false,
                                                                                Next = null,
                                                                                Error = null}) => {
    return async (dispatch) => {
        try {
            let lValue = Value
            let searchInputs = __.deepCopy(store.getState().packages.rightTableSearchDoc.searchInputs);
            if(isConstant){
                if(Attribute.Name=="Name"){
                    Attribute="Наименование"
                    lValue = {ApproximatelyEqual: Value ?? '', BeginningFrom: '', EndingOn: '', Equal: ''}
                }else if(Attribute.Name=="RegNumber"){
                    Attribute="Номер"
                    lValue = {ApproximatelyEqual: Value ?? '', BeginningFrom: '', EndingOn: '', Equal: ''}
                }else if(Attribute.Name=="RegDate"){
                    Attribute="Дата"
                    // Value = Value.split('T')[0];
                    // let  year= Value.split('-')[0];
                    // let month= Value.split('-')[1];
                    // let day=Value.split('-')[2];
                    // Value=day+'.'+month+'.'+year;
                    Value = Value.split("T")[0].split("-").reverse().join(".")
                    lValue = {ApproximatelyEqual: Value ?? '', OnDate: '', DateStart: '', DateEnd: ''}
                }
                else if(Attribute.Name=="Version.ModifyTime"){
                    Attribute="Последнее изменение"
                    // Value = Value.split('T')[0];
                    // let  year= Value.split('-')[0];
                    // let month= Value.split('-')[1];
                    // let day=Value.split('-')[2];
                    // Value=day+'.'+month+'.'+year;
                    Value = Value.split("T")[0].split("-").reverse().join(".")
                    lValue = {ApproximatelyEqual: Value ?? '', OnDate: '', DateStart: '', DateEnd: ''}
                }
            }

            let foundIndex = isConstant
                ? searchInputs.findIndex(s => s.Attribute === Attribute)
                : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);

            if(foundIndex < 0) {
                searchInputs.push({
                    Attribute,
                    Value: lValue,
                    isConstant,
                    isFocus,
                    IsApplied: true
                });
            }
            else {
                searchInputs[foundIndex].Value = lValue;
                searchInputs[foundIndex].IsApplied = true;
            }

            dispatch({type: PACKAGES_TABLE_ACTIONS.CHANGE_INPUTS_DOCUMENT_RIGHT, payload: searchInputs});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setPaginationPageSize_packagesDocumentRight = ({PaginationPageSize, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE_DOCUMENT_RIGHT, payload : Number.parseInt(PaginationPageSize)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения кол-ва строк в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const getDocumentsForTree_packagesDocumentRight = ({filial, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter : {IdFilial : filial.Id},
                sorting :new Sorting().add({Direction: 1,PropertyName : "Name"}).get()
            });

            request.execute(async function (data) {
                await dispatch({type:  PACKAGES_TABLE_ACTIONS.GET_DOCS_DOCUMENT_RIGHT, payload: data.Records});

                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева документов (post): ", data)
            });
        } catch (exception) {
            console.log("Ошибка получения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const getDocumentGroupsForTree_packagesDocumentRight = ({filial, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
                filter : {IdFilial : filial.Id},
                sorting : new Sorting()
                    .add({Direction: 1,PropertyName : "Name"})
                    .add({Direction: 1,PropertyName : "GroupOrder"})
                    .get()
            });

            request.execute(async function (data) {

                await dispatch({type: PACKAGES_TABLE_ACTIONS.GET_GROUP_DOCUMENT_RIGHT, payload: data.Records});

                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const createTree_packagesDocumentRight = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let treeStore = store.getState().packages.treeForDocuments;
            let tree = new TreeCombine({docs : treeStore.docs, group : treeStore.group}).create().sort().get();

            dispatch({type : PACKAGES_TABLE_ACTIONS.CREATE_DOCUMENT_RIGHT, payload : tree});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка построения дерева документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const SelectActivePackageDocument_Right = ({ActiveRow = null, VersionIndex = null, TableType = "main", IsVisibleModal = true, Next = null, Error = null}) => {

    return async dispatch => {
        try {

            if(!ActiveRow) {
                dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT_RIGHT, payload : {
                        activeDocument : null,
                }});
                if(Next) Next.action(Next.params);
                return;
            }

    //        let activeDocument = __.deepCopy(store.getState().packages.mainTable.activeRow);
            let activeDocument = __.deepCopy(ActiveRow);

            activeDocument.additionalSections = {
                isReady : true,
                attributesSection : {
                    attributes : []
                },
                documentPackagesSection : {
                    packages : []
                },
                versionSection : {
                    versions : [],
                    activeVersionIndex : 0
                },
                contentSection : {
                    content : ""
                },
                filesSection : {
                    files : []
                }
            };


            activeDocument.additionalSections.versionSection.versions = await API.documents().getAllVersions({
                filter : new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("DocVersion").add({
                        Name : "IdDoc",
                        Value : activeDocument.Version.IdDoc
                    }).get()
                ).get(),
                sorting : null,
            });
            activeDocument.additionalSections.versionSection.versions.Records = activeDocument.additionalSections.versionSection.versions.Records.reverse();

            let VersionId = 0;
            if(VersionIndex) {
                VersionId = activeDocument.additionalSections.versionSection.versions.Records[VersionIndex].Id;
            } else {
                VersionId = activeDocument.Version.Id;
            }
            activeDocument.additionalSections.versionSection.activeVersionIndex = VersionIndex ? VersionIndex : 0;


            if(VersionIndex) {
                activeDocument.additionalSections.attributesSection.attributes = await new API.documents().getCurrentDocAttributes({
                    filter : new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocAttribute")
                                .add({Name: "IdDoc", Value: activeDocument.Version.IdDoc})
                                .add({Name: "IdDocVersion", Value: VersionId})
                                .get()
                        ).get(),
                    sorting : null
                });
            }
            else {
                activeDocument.additionalSections.attributesSection.attributes.Records = __.deepCopy(activeDocument.AllAttributes);
            }


            activeDocument.additionalSections.contentSection.content = await API.documents().getContentForCurrentFile({
                idDoc : activeDocument.Version.IdDoc,
                idVersion : VersionId
            });


            activeDocument.additionalSections.documentPackagesSection.packages = await API.packages().loadPackages({
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("PackageDocRef")
                            .add({Name: "IdDoc", Value: activeDocument.Version.IdDoc})
                            .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                            .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                            .get()
                    ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                sorting : null
            });

            activeDocument.additionalSections.filesSection.files = await API.documents().getFilesByVersion({
                idDoc : activeDocument.Version.IdDoc,
                idVersion : VersionId
            });

            for(let i = 0; i <  activeDocument.additionalSections.filesSection.files.Records.length; i++) {
                activeDocument.additionalSections.filesSection.files.Records[i].link =
                    addToEndUrl({
                        url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                        collection : [
                            activeDocument.additionalSections.filesSection.files.Records[i].Id,
                            VersionId,
                            "true"
                        ]
                    });
            }


            activeDocument.preview = {
                files : [],
                activeFileIndex : 0,
                activeView: "preview"
            };

            // activeDocument.preview.files.push({
            //     name : "Предпросмотр",
            //     link : addToEndUrl({
            //         url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
            //         collection : [
            //             activeDocument.Version.IdDoc,
            //             VersionId
            //         ]
            //     }),
            //     type : "image",
            //     description : "",
            //     size : null
            // });

            let previewPdf = activeDocument.additionalSections.filesSection.files.Records.find(file => file.AttributeName.Id === 2);

            if(previewPdf) {
                activeDocument.preview.files.push({
                    id : previewPdf.Id,
                    name : previewPdf.Name,
                    originalLink : addToEndUrl({
                        url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                        collection : [
                            previewPdf.Id,
                            VersionId,
                            "false"
                        ]
                    }),
                    previewLink: addToEndUrl({
                        url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                        collection: [
                            activeDocument.Version.IdDoc,
                            VersionId
                        ]
                    }),
                    // type : "pdf",
                    description : "Оригинал",
                    size : previewPdf.Size,
                    AttributeName : previewPdf.AttributeName,
                });
            }


            let otherFiles = activeDocument.additionalSections.filesSection.files.Records.filter(file => file.AttributeName.Id === 4);
            if(otherFiles) {
                for(let i = 0; i < otherFiles.length; i++) {
                    if(otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                        activeDocument.preview.files.push({
                            id : otherFiles[i].Id,
                            name : otherFiles[i].Name,
                            originalLink : addToEndUrl({
                                url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                collection : [
                                    otherFiles[i].Id,
                                    VersionId,
                                    "false"
                                ]
                            }),
                            type : "pdf",
                            description : otherFiles[i].Description,
                            size : otherFiles[i].Size,
                            AttributeName : otherFiles[i].AttributeName,
                        });
                    }
                }
            }


            dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT_RIGHT, payload : {
                activeDocument : activeDocument,
                IsVisibleModal: IsVisibleModal,
            }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка при выборе документа в пакетах (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
