import React from "react";
import {connect} from "react-redux";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {ModalTypes, UserSettingsActionsTypes, UserSettingsNames} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {
    combineMainTableAttributes,
    mainTableSearchDoc
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    changeDocumentSearchInputs,
    changeViewMode
} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {MyTooltip} from "../tooltip/MyTooltip";

class TableSettingModal extends React.Component {
    constructor(props) {
        super(props);

        // проверяем настройки
        let UserSettingsTable = HelpFunctions.CheckUserSettingForTable({});

        // состояние drag and drop
        let dndState = [];
        // если настройки есть для данной таблицы
        if(UserSettingsTable.isFind) {
            for(let i = 0; i < UserSettingsTable.data.columns.length; i++) {
                if(UserSettingsTable.data.columns[i].Name !== "Version.Version") {
                    dndState.push({...UserSettingsTable.data.columns[i], ...{
                            columnId :  createUniqueIdString(6),
                            UserVisible :  UserSettingsTable.data.columns[i].Visible,
                            oldPlace :  i,
                        }});
                } else {
                    this.Version = UserSettingsTable.data.columns[i];
                }
            }
        }
        // если настройки настроек нет
        else {
            for(let i = 0; i < this.props.header.length; i++) {
                if(this.props.header[i].Name !== "Version.Version") {
                    let header = __.deepCopy(this.props.header[i]);
                    header.columnId = createUniqueIdString(6);
                    header.UserVisible = this.props.header[i].UserVisible;
                    header.oldPlace = i;
                    dndState.push(header);
                } else {
                    this.Version = __.deepCopy(this.props.header[i]);
                }
            }
        }

        this.state = {
            visible: true,
            contentOnHover : false,
            dndState,

            limitationTooltipShow : false
        };

        this.modalName = ModalTypes.documents.mainTable.Settings;

        this.limitation = React.createRef();

        this.hide = this.hide.bind(this);
        this.save = this.save.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.resetSettings = this.resetSettings.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    save() {

        let NewColumnDefinition = [...this.state.dndState];
        // версия всегда на первом месте
        NewColumnDefinition.unshift(this.Version);

       this.props.updateUserSettings({
           Name : UserSettingsNames.MAIN_TABLE_CONFIG,
           Action : UserSettingsActionsTypes.Update,
           Data : NewColumnDefinition,
           Next : {
               action : () => {
                   this.props.combineMainTableAttributes({});

                   this.props.setModalData({});
               },
               params : {}
           }
       });

        this.resetFilters();
    }

    resetFilters() {
        this.props.changeDocumentSearchInputs({
            Delete : true,
            Next : {
                action : () => {
                    this.props.changeViewMode({
                        clearFilters : true,
                        Next : {
                            action : () => {
                                this.props.mainTableSearchDoc({
                                    Next : {
                                        action : () => {
                                            this.props.combineMainTableAttributes({});
                                            this.props.changeViewMode({
                                                isSearch : this.props.isSearch
                                            })
                                        },
                                        params : {}
                                    }
                                })
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            }
        });
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }

    // сброс пользовательских настроек
    resetSettings() {
        this.props.updateUserSettings({
            Name : UserSettingsNames.MAIN_TABLE_CONFIG,
            Action : UserSettingsActionsTypes.DeleteOne,
            Data : {},
            Next : {
                action : () => {
                    this.props.combineMainTableAttributes({});

                    this.props.setModalData({});
                },
                params : {}
            }
        });

        this.resetFilters();
    }

    // изменение чекбокса
    changeHandler(index) {
        let items = __.deepCopy(this.state.dndState);

        items[index].UserVisible = !items[index].UserVisible;

        this.setState({
           dndState : items
        });
    }

    // окончание перетаскивания
    // перестановка объектов в нужном порядке
    handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(this.state.dndState);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        this.setState({
            dndState : items
        })
    }

    render() {

        let modalStyle;
        if(this.state.visible) {
            modalStyle = {
                display : "block",
                backgroundColor: "#061c3e66",
            };
        } else {
            modalStyle = {
                display : "none",
                backgroundColor: "#061c3e66",
            };
        }

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content"
                         onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                         onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                        <div className="modal-header">
                            <h5 className="modal-title">Настройка таблицы
                                {
                                    this.state.limitationTooltipShow &&
                                    <MyTooltip target={this.limitation}
                                               title={"Внимание!"}
                                               moreInfo={true}
                                               text={"При изменении настроек таблицы фильтры будут сброшенны"}
                                               show={this.state.limitationTooltipShow}
                                               placement={"right"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-circle btn-sm"
                                      ref={this.limitation}
                                      onMouseEnter={()=>{this.setState({limitationTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({limitationTooltipShow : false})}}>
                                    <i className="svg-icon icon-Info"/>
                                </span>
                            </h5>
                        </div>
                        <DragDropContext onDragEnd={this.handleOnDragEnd}>
                             <div className="modal-body beautiful-scroll-10" style={{height: "auto"}}>
                                <div className="col-lg-12 row my-3">
                                    <div className="col-lg-3">
                                        <span className="d-block font-weight-bold mb-4">Позиция</span>
                                    </div>
                                    <div className="col-lg-6">
                                        <span className="d-block font-weight-bold">Название</span>
                                    </div>
                                    <div className="col-lg-3">
                                        <span className="d-block font-weight-bold mb-4">Показать</span>
                                    </div>
                                </div>

                                 <Droppable droppableId="tableSettingModal">
                                     {(provided) => (
                                         <div {...provided.droppableProps} ref={provided.innerRef}>
                                             {
                                                 this.state.dndState.map((item, index) => {
                                                     if(item.Name === "Version.Version") {
                                                        return null;
                                                     }

                                                     return (
                                                         <Draggable key={item.columnId} draggableId={item.columnId} index={index}>
                                                             {(provided) => (
                                                                 <div className="col-lg-12 row my-3" {...provided.draggableProps} ref={provided.innerRef}
                                                                      key={item.columnId}>
                                                                     <div className="col-lg-3" {...provided.dragHandleProps}>
                                                                         <span className="btn" style={{cursor : "grab"}}><i className="svg-icon-lg icon-Grid_card"> </i></span>
                                                                     </div>
                                                                     <div className="col-lg-6" style={{lineHeight: "37.875px"}} >
                                                                         <span className="btn-text">{item.Value}</span>
                                                                     </div>
                                                                     <div className="col-lg-3">
                                                                     <span className="switch switch-success switch-outline">
                                                                        <label>
                                                                            <input type="checkbox" defaultChecked={item.UserVisible} onChange={() => {this.changeHandler(index)}} name="select"/>
                                                                            <span> </span>
                                                                        </label>
                                                                     </span>
                                                                     </div>
                                                                 </div>
                                                             )}
                                                         </Draggable>
                                                     );
                                                 })
                                             }
                                             {provided.placeholder}
                                         </div>
                                     )}
                                 </Droppable>


                            </div>
                        </DragDropContext>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-success font-weight-bold" onClick={this.resetSettings} style={{marginRight: "auto"}}>
                                По умолчанию
                            </button>

                            <button type="button" className="btn btn-primary font-weight-bold" onClick={this.save}>Сохранить</button>

                            <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>
                            Закрыть
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        header : state.document.mainTable.header,
        isSearch : state.document.search.isSearch,
    }
}

const  mapDispatchToProps = {
    setModalData,
    updateUserSettings,
    combineMainTableAttributes,
    changeDocumentSearchInputs,
    changeViewMode,
    mainTableSearchDoc,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableSettingModal);
