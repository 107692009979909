import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import "moment/locale/ru";
import moment from "moment";
import * as ReactDnD from "../../tools/MyDragAndDrop/index";
import {AdminLevelEnum, contextMenuTypes, PackagesViewFilters, TableTypesEnum} from "../../../tools/StaticTypes";
import {
    addRowToChecked_MainTable,
    addRowToChecked_packagesDocumentRight, deleteRowFromChecked_MainTable, deleteRowFromChecked_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {PackagesDocument} from "../../../tools/classes/packages/PackagesDocument";
import {setContextMenuData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";

class TrMainTablePackage extends React.PureComponent  {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false,
            lId: null,
            refTimeout: null
        };
        this.checkboxOffHover = this.checkboxOffHover.bind(this);
        this.checkboxOnHover = this.checkboxOnHover.bind(this);
        this.checkboxOnChange = this.checkboxOnChange.bind(this);
    }

    componentDidMount() {
        this.setState({lId: createUniqueIdString(6)})
    }

    checkboxOnChange() {
        let {data, checkedFields, checkedFieldsMainTable} = this.props;
        let item = data.find(tr => tr.Name === "Item").Value;
        if(item.Node.Type !== "PackagesFolder") {
            if (item.Node.IsMarkedForDelete) return;

            if (this.props.tableRole !== "main"){
                if (new Set(checkedFields).has(item.Node.Version.IdDoc)) {
                    this.props.deleteRowFromChecked_packagesDocumentRight({
                        IdDoc: item.Node.Version.IdDoc
                    });
                } else {
                    this.props.addRowToChecked_packagesDocumentRight({
                        IdDoc: item.Node.Version.IdDoc
                    });
                }
            } else {
                if (new Set(checkedFieldsMainTable).has(item.Node.Version.IdDoc)) {
                    this.props.deleteRowFromChecked_MainTable({
                        IdDoc: item.Node.Version.IdDoc
                    });
                } else {
                    this.props.addRowToChecked_MainTable({
                        IdDoc: item.Node.Version.IdDoc
                    });
                }
            }
        }
    }
    checkboxOnHover() {
        this.setState({
            checkboxOnHover : true
        });
    }

    checkboxOffHover() {
        this.setState({
            checkboxOnHover : false
        });
    }

    rightClick(event, item, itemData) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTR,
            data : {
                item,
                itemData,
                tr : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }

    render() {
        let data = this.props.data;

        // поиск того где находится версия
        let Version = data.find(tr => tr.Name === "Version.Version").Value;
        let TypeName = data.find(tr => tr.Name === "TypeName").Value;
        let item = data.find(tr => tr.Name === "Item").Value;

        let iconStyle = "";
        if(TypeName === "Пакет") {
            if (item.Node.ContainsDocs && item.Node.ContainsChildren)  iconStyle = "icon-Packet_Full icolor";
            else if (!item.Node.ContainsDocs && !item.Node.ContainsChildren)  iconStyle = "icon-Packet_zero_no icolor";
            else if (item.Node.ContainsDocs && !item.Node.ContainsChildren)  iconStyle = "icon-Packet_full_no icolor";
            else if (!item.Node.ContainsDocs && item.Node.ContainsChildren)  iconStyle = "icon-Packet_zero icolor";
        }
        else {
                if(item.Node.IsMarkedForDelete) {
                    iconStyle = "icon-Doc_view_9 icon-color-red";
                } else {
                    iconStyle = `icon-Documents ${ item.Node.IsAuto ? "icon-color-gold" : 'icolor'} `;
                }
        }
        // проверяем есть ли в отмеченных документах

        // let activeDocument;
        // if(this.props.tableRole === "main") activeDocument = this.props.activeDocument;
        // else if(this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        // else if(this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;

        let activeRow;
        if(this.props.tableRole === "main") activeRow = this.props.activeRow;
        else if(this.props.tableRole === "additional") activeRow = this.props.activeRowAdditional;
        else if(this.props.tableRole === "download") activeRow = this.props.activeRowDownload;
        let checkboxContent;

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

            let isChecked = item.Node instanceof PackagesDocument ?  new Set(checkedFields).has(item.Node.Version.IdDoc) : false;
            if((isChecked || this.state.checkboxOnHover)
                && item.Node instanceof PackagesDocument
                && !item.Node.IsMarkedForDelete
            ) {
                checkboxContent = (
                    <td className="py-6" key={createUniqueIdString(6)} onMouseLeave={this.checkboxOffHover} onMouseEnter={this.checkboxOnHover}>
                        <label className="checkbox checkbox-lg checkbox-inline mb-1" >
                            <input type="checkbox" checked={isChecked} onChange={() => this.checkboxOnChange()}/>
                            {/*<span className={this.props.tableRole === "main" && isChecked ? "main-table-delete" : "row-checked"}/>*/}
                            <span className="row-checked" />
                        </label>
                    </td>
                );
            }
            else {
                /*
                let icon = "icon-Documents icolor";
                if(item.Node instanceof PackagesDocument && item.Node.IsMarkedForDelete) {
                    icon = "icon-Doc_view_9 icon-color-red";
                } else if(item.Node instanceof  PackagesFolder) {
                    icon = "icon-pak_on icolor"
                }

                 */

                checkboxContent = (
                    <td className="pl-0" key={createUniqueIdString(6)}  onMouseEnter={this.checkboxOnHover} >
                    <span className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                        <span className="svg-icon svg-icon-xxl">
                            <i className={`svg-icon ${iconStyle}`}/>
                        </span>
                        <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${Version > 1 && "label-colored"}`}>
                            {Version}
                        </span>
                    </span>
                    </td>
                );
            }

        let superStyle = {
            overflow : "hidden",
            textOverflow: "ellipsis",
            whiteSpace : "nowrap",
        }

        let trStyle = { // чтобы не дергалось при наддвернее на чекбокс
            lineHeight : "min(55px)"
        };

        let timeStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };


        // определяем активная строка или нет
        // let trClass = this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
        //     ? "tablez2 tr-active"
        //     : "tablez2";

        let trClass = "tablez2 noneFocus";
        // if(item.Node.Id === activeDocument?.Node?.Id) {
        //     console.log('activeDocument?.Node?.Id', activeDocument);
        //     console.log('item', item);
        //     trClass = "tablez2 tr-active noneFocus";
        // }
        if(item.Node.Id === activeRow && item.Node.TreeType !== "FOLDER") {

            trClass = "tablez2 tr-active noneFocus";
        }

        let content = (
            <>
                {checkboxContent}
                {
                    data.map((itemData, index) => {
                        if(itemData.Name === "Version.Version" || itemData.Name === "Item") {
                            return null;
                        }
                        else if(itemData.Name === "RegDate" || itemData.Name === "Version.ModifyTime") {
                            let timeMoment = new moment(itemData.Value);
                            return(
                                <td key={createUniqueIdString(6)}
                                    onClick={()=>{this.props.selectFolder(item)}}
                                    //TODO ?????? aa 23.03.2023
                                    onContextMenu={(event) => {this.rightClick(event, item, itemData)}}
                                >
                                    <div className="text-primary font-weight-normal d-block font-size-lg" style={timeStyle}>
                                        <time>{timeMoment.format("L")}</time>
                                    </div>
                                </td>
                            );
                        }
                        else {
                            return(
                                <td key={createUniqueIdString(6)}
                                    onClick={()=>{this.props.selectFolder(item)}}
                                    //TODO ?????? aa 23.03.2023
                                    onContextMenu={(event) => {this.rightClick(event, item, itemData)}}
                                >
                                    <div style={superStyle} title={itemData.Value} className=" text-primary font-weight-normal d-block font-size-lg ">
                                        {itemData.Value}
                                    </div>
                                </td>
                            );
                        }
                    })
                }
            </>
        );

        let viewFilter = this.props.viewFilter;

        if(TypeName === "Пакет") {
            if(viewFilter === PackagesViewFilters.OnlyDocuments) {
                return null;
            }

            return (
                <tr className={trClass}
                    onClick={() => {
                        if(this.props.checkedFields.length > 0) {
                            this.props.deleteRowFromChecked_packagesDocumentRight({isClear: true});
                        }
                        this.props.selectFolder(item)
                    }}
                    style={trStyle}>
                    {content}
                </tr>
            );
        }
        else {
            if(viewFilter === PackagesViewFilters.OnlyPackages) {
                return null;
            }

            let dropData = checkedFields.length > 0
                ? checkedFields
                : {IdDoc: item.Node.Version.IdDoc, IsMarkedForDelete: item.Node.IsMarkedForDelete};

            let dropType = checkedFields.length > 0
                ? "documents"
                : "document";

            return (
                <ReactDnD.Draggable
                    tag={"tr"}
                    type={dropType}
                    data={JSON.stringify(dropData)}
                    className={trClass}
                    onMouseLeave={this.checkboxOffHover}
                    // onClick={()=>{this.props.selectFolder(item)}}
                    style={{cursor : "pointer",  lineHeight : "min(55px)"}}
                    setRef={(Item) => {
                        this.props.setRef(Item)
                    }}
                    activeRow = {activeRow}
                >
                    {content}
                </ReactDnD.Draggable>
            );
        }
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,

        activeRow : state.packages.tree.activeRow,
        activeRowAdditional : state.packages.treeAdditional.activeRow,
        activeRowDownload : state.packages.treeDownload.activeRow,

        adminLevel : state.globalState.user.AdminLevel,

        checkedFields : state.packages.mainTable.checkedFields,
        checkedFieldsMainTable : state.packages.mainTable.checkedFieldsMainTable,
    }
}

const  mapDispatchToProps = {
    addRowToChecked_MainTable,
    addRowToChecked_packagesDocumentRight,
    deleteRowFromChecked_MainTable,
    deleteRowFromChecked_packagesDocumentRight,
    setContextMenuData,
}

export default connect(mapStateToProps,mapDispatchToProps)(TrMainTablePackage);

