import {createUniqueIdString} from "../../CreateUniqueId";

export class PackageTreeItem {

    // string
    TreeId;
    // string -> PackagesFolder or PackagesDocuments or PackageTree
    Type;
    // class -> PackagesFolder or PackagesDocuments
    Node;
    // array -> PackageTreeItem
    Children;


    constructor(Data) {
        this.TreeId = createUniqueIdString(6);
        this.Node = Data.Node;
        this.Type = Data.NodeType;
        this.Children = Data.Children;
    }
}