import React from "react";
import {connect} from "react-redux";
import * as ReactDnD from 'react-drag-and-drop';
import {AdminLevelEnum, contextMenuTypes, TableTypesEnum} from "../../tools/StaticTypes";
import {setContextMenuData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {PackagesDocument} from "../../tools/classes/packages/PackagesDocument";
import {
    addRowToChecked_MainTable,
    addRowToChecked_packagesDocumentRight, deleteRowFromChecked_MainTable,
    deleteRowFromChecked_packagesDocumentRight
} from "../../store/packages/actionCreators/package_DocumentRightActionCreator";


class PackagesTableItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover: false,
        }

        this.viewMode_big = this.viewMode_big.bind(this);
        this.viewMode_middle = this.viewMode_middle.bind(this);
        this.viewMode_table = this.viewMode_table.bind(this);
        this.checkboxOffHover = this.checkboxOffHover.bind(this);
        this.checkboxOnHover = this.checkboxOnHover.bind(this);
        this.checkboxOnChange = this.checkboxOnChange.bind(this);
    }

    checkboxOnChange() {
        let {item, checkedFields, checkedFieldsMainTable} = this.props;
        if(item.Node.Type !== "PackagesFolder") {
            if (item.Node.IsMarkedForDelete) return;

            if (this.props.tableRole !== "main"){
                if (new Set(checkedFields).has(item.Node.Version.IdDoc)) {
                    this.props.deleteRowFromChecked_packagesDocumentRight({
                        IdDoc: item.Node.Version.IdDoc
                    });
                } else {
                    this.props.addRowToChecked_packagesDocumentRight({
                        IdDoc: item.Node.Version.IdDoc
                    });
                }
            } else {
                if (new Set(checkedFieldsMainTable).has(item.Node.Version.IdDoc)) {
                    this.props.deleteRowFromChecked_MainTable({
                        IdDoc: item.Node.Version.IdDoc
                    });
                } else {
                    this.props.addRowToChecked_MainTable({
                        IdDoc: item.Node.Version.IdDoc
                    });
                }
            }
        }
    }

    checkboxOnHover() {
        this.setState({ checkboxOnHover: true });
    }

    checkboxOffHover() {
        this.setState({ checkboxOnHover: false });
    }

    // обработка правого клика
    rightClick(event, item) {

        //TODO ?????? aa 24.03.2023 Почему-то было убрано

        this.props.setContextMenuData({
            name: contextMenuTypes.MainTableTR,
            data: {
                item: item,
                tr: this.props.data
            },
            position: {
                x: event.clientX,
                y: event.clientY
            }
        });
        event.preventDefault();
    }

    viewMode_big() {
        let {item, selectFolder} = this.props;
        const version = item.Node instanceof PackagesDocument && item.Node.Version.Version
        const typeName = item.Type

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

        let iconFolderStyle = "";
        if (typeName === "PackagesFolder") {
            if (item.Node.ContainsDocs && item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_Full";
            else if (!item.Node.ContainsDocs && !item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_zero_no";
            else if (item.Node.ContainsDocs && !item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_full_no";
            else if (!item.Node.ContainsDocs && item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_zero";
        }

        let icon = `icon-Documents  ${item.Node.IsAuto ? "icon-color-gold" : 'icolor'}`;
        if (typeName === "PackagesDocument") {
            if (item.Node.IsMarkedForDelete) {
                icon = "icon-Doc_view_9 icon-color-red";
            }
        }

        let activeDocument;
        if (this.props.tableRole === "main") activeDocument = this.props.activeDocument;
        else if (this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        else if (this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;

        let activeClass = "";
        if (item.Node.Id === activeDocument?.Node?.Id) {
            activeClass = "package-active";
        }
        let checkboxContent

        let isChecked = typeName === "PackagesDocument" ? new Set(checkedFields).has(item.Node.Version.IdDoc) : false;

        if ((isChecked || this.state.checkboxOnHover)
            && item.Node instanceof PackagesDocument
            && !item.Node.IsMarkedForDelete
        ) {
            checkboxContent = (
                <span className="d-flex position-relative"
                      style={{marginBottom: "6px"}}
                      key={createUniqueIdString(6)}
                      onMouseLeave={this.checkboxOffHover}
                >
                    <label className="checkbox checkbox-lg checkbox-inline mb-1">
                        <input type="checkbox" checked={isChecked} onChange={() => this.checkboxOnChange()}/>
                        {/*<span className={this.props.tableRole === TableTypesEnum.Main ? "main-table-delete main-table-head-delete" : "row-checked"}/>*/}
                        <span className="row-checked" />
                    </label>
                    <span
                        onClick={() => { selectFolder(item, this.props.tableRole) }}
                        className="pl-0" key={createUniqueIdString(6)}
                        onMouseEnter={this.checkboxOnHover}
                    >
                        <span className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                            <span className="svg-icon svg-icon-xxl">
                                <i className={`svg-icon ${icon}`} style={{fontSize: "3rem"}}/>
                            </span>
                            <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${version > 1 && "label-colored"}`}
                            >
                                {version}
                            </span>
                        </span>
                    </span>
                </span>
            );
        } else
            checkboxContent = (
                <span className="pl-0" key={createUniqueIdString(6)}
                      onClick={() => { selectFolder(item, this.props.tableRole) }}
                      onMouseEnter={this.checkboxOnHover}
                >
                    <span className="svg-icon menu-icon icon-xl">
                        <i className={`${icon}`} style={{fontSize: "4rem"}}/>
                    </span>
                    <span className={`label label-xl label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 ${version > 1 && "label-colored"}`}
                    >
                        {version}
                    </span>
                </span>
            );

        let content = (
            <span className={`link ${activeClass}`} style={{cursor: "pointer"}} title={` ${item.Node.Name} `}>
                <div className="packet-body text-center pt-1">
                    <div className="my-2">
                        <div className="symbol">
                            {
                                item.Type === "PackagesFolder" &&
                                <span className="svg-icon menu-icon icon-xl">
                                    <i className={`${iconFolderStyle} icon-color-primary`} style={{fontSize: "4rem"}}/>
                                </span>
                            }
                            {
                                item.Type === "PackagesDocument" &&
                                checkboxContent
                            }
                        </div>
                    </div>
                    <div className="my-1"
                         onClick={() => { selectFolder(item, this.props.tableRole) }}
                    >
                        {
                            item.Type === "PackagesFolder" &&
                            <p className="text-dark font-weight-bold m-0 packages_text_2">
                                {item.Node.Name}
                            </p>
                        }
                        {
                            item.Type === "PackagesDocument" &&
                            <p className="text-dark font-weight-bold m-0 packages_text_2">
                                {` ${item.Node.Name} ${item.Node.ShortDescription}`}
                            </p>
                        }
                    </div>
                </div>
            </span>
        );
        let dropData = checkedFields.length > 0
            ? checkedFields
            : {IdDoc: item.Node.Id, IsMarkedForDelete: item.Node.IsMarkedForDelete};

        let dropType = checkedFields.length > 0
            ? "documents"
            : "document";

        if (item.Type === "PackagesDocument" && this.props.tableRole !== "main") {
            return (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"
                     onContextMenu={(event) => {
                         this.rightClick(event, item)
                     }}
                >
                    <ReactDnD.Draggable
                        className="packet packet-custom packet-stretch pb-4"
                        onMouseEnter={this.checkboxOnHover}
                        onMouseLeave={this.checkboxOffHover}
                        type={dropType}
                        data={JSON.stringify(dropData)}
                    >
                        {content}
                    </ReactDnD.Draggable>
                </div>
            );
        } else {
            return (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"
                     onClick={() => { if (item.Type === "PackagesFolder") selectFolder(item, this.props.tableRole) }}
                     onContextMenu={(event) => { this.rightClick(event, item) }}
                >
                    <div className="packet packet-custom packet-stretch pb-4">
                        {content}
                    </div>
                </div>
            );
        }
    }

    viewMode_middle() {
        let {item, selectFolder} = this.props;
        const version = item.Node instanceof PackagesDocument && item.Node.Version.Version
        const typeName = item.Type

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

        let iconFolderStyle = "";
        if (typeName === "PackagesFolder") {
            if (item.Node.ContainsDocs && item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_Full";
            else if (!item.Node.ContainsDocs && !item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_zero_no";
            else if (item.Node.ContainsDocs && !item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_full_no";
            else if (!item.Node.ContainsDocs && item.Node.ContainsChildren) iconFolderStyle = "icon-Packet_zero";
        }

        let icon = `icon-Documents  ${item.Node.IsAuto ? "icon-color-gold" : 'icolor'}`;
        if (typeName === "PackagesDocument") {
            if (item.Node.IsMarkedForDelete) {
                icon = "icon-Doc_view_9 icon-color-red";
            }
        }

        let activeDocument;
        if (this.props.tableRole === "main") activeDocument = this.props.activeDocument;
        else if (this.props.tableRole === "additional") activeDocument = this.props.activeDocumentAdditional;
        else if (this.props.tableRole === "download") activeDocument = this.props.activeDocumentDownload;

        let activeClass = "";
        if (item.Node.Id === activeDocument?.Node?.Id && item.Type === activeDocument?.Type) {
            activeClass = "package-active";
        }

        let checkboxContent

        let isChecked = typeName === "PackagesDocument" ? new Set(checkedFields).has(item.Node.Version.IdDoc) : false;

        if ((isChecked || this.state.checkboxOnHover)
            && item.Node instanceof PackagesDocument
            && !item.Node.IsMarkedForDelete
        ) {
            checkboxContent = (
                <span className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative"
                      key={createUniqueIdString(6)} onMouseLeave={this.checkboxOffHover}
                >
                    <label className="checkbox checkbox-lg checkbox-inline mb-1" style={{width: "2.5rem"}}>
                        <input type="checkbox" checked={isChecked} onChange={() => this.checkboxOnChange()}/>
                        <span className="row-checked" />
                    </label>
                </span>
            );
        } else
            checkboxContent = (
                <>
                    <span className="pl-0" key={createUniqueIdString(6)} onMouseEnter={this.checkboxOnHover}>
                        <span className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                            <span className="svg-icon svg-icon-xxl">
                                <i className={`svg-icon ${icon}`} style={{fontSize: "3rem"}}/>
                            </span>
                            <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${version > 1 && "label-colored"}`}
                            >
                                {version}
                            </span>
                        </span>
                    </span>
                </>
            );

        let content = (
            <span className={`link ${activeClass}`} style={{cursor: "pointer"}} title={item.Node.Name}>
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                        <div className="symbol symbol-lg-75">
                            {
                                item.Type === "PackagesFolder" &&
                                <span className="svg-icon menu-icon icon-xl">
                                    <i className={`${iconFolderStyle} icon-color-primary`} style={{fontSize: "3rem"}}/>
                                </span>
                            }
                            {
                                item.Type === "PackagesDocument" &&
                                checkboxContent
                            }
                        </div>
                    </div>
                    {/* Добавлен стиль minWidth т.к. если нет пробелов в названии и оно длинное переноса не происходило*/}
                    <div className="d-flex flex-column" style={{minWidth: 0}}
                         onClick={() => { selectFolder(item, this.props.tableRole) }}
                    >
                        {
                            item.Type === "PackagesFolder" &&
                            <p className="text-dark font-weight-bold m-0 packages_text_2">
                                {item.Node.Name}
                            </p>
                        }
                        {
                            item.Type === "PackagesDocument" &&
                            <p className="text-dark font-weight-bold m-0 packages_text_2">
                                {` ${item.Node.Name} ${item.Node.ShortDescription}`}
                            </p>
                        }
                    </div>
                </div>
            </span>
        );

        let dropData = checkedFields.length > 0
            ? checkedFields
            : {IdDoc: item.Node.Id, IsMarkedForDelete: item.Node.IsMarkedForDelete};

        let dropType = checkedFields.length > 0
            ? "documents"
            : "document";


        if (item.Type === "PackagesDocument" && this.props.tableRole !== "main") {
            return (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
                     onContextMenu={(event) => { this.rightClick(event, item) }}
                     onMouseLeave={this.checkboxOffHover}
                >
                    <ReactDnD.Draggable
                        style={{cursor: "pointer", lineHeight: "min(1.5rem)"}}
                        className="packet packet-custom packet-stretch pb-4"
                        type={dropType}
                        data={JSON.stringify(dropData)}
                    >
                        {content}
                    </ReactDnD.Draggable>
                </div>
            );
        } else {
            return (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
                     onClick={() => { if (item.Type === "PackagesFolder") selectFolder(item, this.props.tableRole) }}
                     onContextMenu={(event) => { this.rightClick(event, item) }}
                >
                    <div className="packet packet-custom packet-stretch pb-4">
                        {content}
                    </div>
                </div>
            );
        }
    }

    viewMode_table() {
        return null;
    }

    render() {
        let content = null;
        if (this.props.viewMode === "bigCards") {
            content = this.viewMode_big();
        } else if (this.props.viewMode === "middleCards") {
            content = this.viewMode_middle();
        } else if (this.props.viewMode === "table") {
            content = this.viewMode_table();
        }


        return (
            <>
                {content}
            </>
        );


    }
}


const mapStateToProps = state => {
    return {
        activeDocument: state.packages.tree.activeDocument,
        activeDocumentAdditional: state.packages.treeAdditional.activeDocument,
        activeDocumentDownload: state.packages.treeDownload.activeDocument,
        adminLevel : state.globalState.user.AdminLevel,

        checkedFields: state.packages.mainTable.checkedFields,
        checkedFieldsMainTable : state.packages.mainTable.checkedFieldsMainTable,
    }
}


const mapDispatchToProps = {
    setContextMenuData,
    addRowToChecked_packagesDocumentRight,
    deleteRowFromChecked_packagesDocumentRight,
    addRowToChecked_MainTable,
    deleteRowFromChecked_MainTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesTableItem);




