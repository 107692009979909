import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {AdminLevelEnum} from "../../tools/StaticTypes";

type StateType = {
    collapseAllTooltipShow: boolean,
    collapseSubTooltipShow: boolean,
    expandSubTooltipShow: boolean,
    createPackageTooltipShow: boolean
}

type OwnPropsType = {
    onCollapseAll: () => void,
    onCollapseSubTree: () => void,
    onExpandSubTree: () => void
    onCreatePackage: () => void //createPackage??
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = OwnPropsType & PropsFromRedux;

class TreeControlButtons extends React.Component<PropsType, StateType> {
    collapseAllRef: React.RefObject<HTMLButtonElement>;
    collapseSubRef: React.RefObject<HTMLButtonElement>;
    expandSubRef: React.RefObject<HTMLButtonElement>;
    createPackageRef: React.RefObject<HTMLButtonElement>;

    constructor(props: PropsType) {
        super(props);

        this.state = {
            collapseAllTooltipShow: false,
            collapseSubTooltipShow: false,
            expandSubTooltipShow: false,
            createPackageTooltipShow: false
        }

        this.collapseAllRef = React.createRef();
        this.collapseSubRef = React.createRef();
        this.expandSubRef = React.createRef();
        this.createPackageRef = React.createRef();

    }

    render() {
        return(
            <div style={{position: "relative", marginLeft: "25px"}}>
                {
                    this.state.collapseAllTooltipShow &&
                    <MyTooltip target={this.collapseAllRef}
                               text={"Свернуть все"}
                               show={this.state.collapseAllTooltipShow}
                               placement={"bottom"} delay={500}
                    />
                }
                <button className="btn btn-icon btn-circle btn-sm"
                        ref={this.collapseAllRef}
                        onMouseEnter={() => {this.setState({ collapseAllTooltipShow : true})}}
                        onMouseLeave={() => {this.setState({ collapseAllTooltipShow : false})}}
                        onClick={this.props.onCollapseAll}>
                    <i className="svg-icon icon-color-primary icon-Sub" />
                </button>

                {
                    this.state.collapseSubTooltipShow &&
                    <MyTooltip target={this.collapseSubRef}
                               text={"Свернуть поддерево"}
                               show={this.state.collapseSubTooltipShow}
                               placement={"bottom"} delay={500}
                    />
                }
                <button className="btn btn-icon btn-circle btn-sm"
                        ref={this.collapseSubRef}
                        onMouseEnter={() => {this.setState({ collapseSubTooltipShow: true })}}
                        onMouseLeave={() => {this.setState({ collapseSubTooltipShow: false })}}
                        onClick={this.props.onCollapseSubTree}>
                    <i className="svg-icon icon-color-primary icon-collapse_17" />
                </button>

                {/*TODO: MS 02.03*/}
                {/*{*/}
                {/*    this.state.expandSubTooltipShow &&*/}
                {/*    <MyTooltip target={this.expandSubRef}*/}
                {/*               text={"Развернуть поддерево (может занять длительное время)"}*/}
                {/*               show={this.state.expandSubTooltipShow}*/}
                {/*               placement={"bottom"} delay={500}*/}
                {/*    />*/}
                {/*}*/}

                {/*<button className="btn btn-icon btn-circle btn-sm"*/}
                {/*        ref={this.expandSubRef}*/}
                {/*        onMouseEnter={() => {this.setState({ expandSubTooltipShow: true })}}*/}
                {/*        onMouseLeave={() => {this.setState({ expandSubTooltipShow: false })}}*/}
                {/*        onClick={this.props.onExpandSubTree}>*/}
                {/*    <i className="svg-icon icon-color-primary icon-expand_16"/>*/}
                {/*</button>*/}

                {
                    this.state.createPackageTooltipShow &&
                    <MyTooltip target={this.createPackageRef}
                               text={"Создать корневой пакет"}
                               show={this.state.createPackageTooltipShow}
                               placement={"bottom"} delay={500}
                    />
                }
                {
                    this.props.adminLevel > AdminLevelEnum.CommonUser &&
                    <button className="btn btn-icon btn-circle btn-sm"
                            ref={this.createPackageRef}
                            onMouseEnter={() => {this.setState({ createPackageTooltipShow: true })}}
                            onMouseLeave={() => {this.setState({ createPackageTooltipShow: false })}}
                            onClick={this.props.onCreatePackage}>
                        <i className="svg-icon icon-color-primary icon-folder_17"/>
                    </button>
                }
            </div>
        );
    }

}


const  mapStateToProps = (state: any) => {
    return {
        adminLevel : state.globalState.user.AdminLevel,
    }
}

const  mapDispatchToProps = {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(TreeControlButtons);
