import React from "react";
import {connect} from "react-redux";
import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {
    toggleAdditionalSectionsOpen_Cards, updateAttributesRedactorData
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {toggleAdditionalSectionsOpenSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import parse from "html-react-parser";
import {searchByParams, updateInputsFieldSearch} from "../../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../../store/search/actionCreators/search_table";
import sanitizeHtml from "sanitize-html";


class AttributesSectionSearch extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            calendarShow : false,
            calendarItem : null,
            calendarIsHover : false,
        };

        this.sectionName = attributesSectionsTypes.attributesSection;


        this.toggle = this.toggle.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.calendarOnChange = this.calendarOnChange.bind(this);
        this.search = this.search.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpenSearch({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    // обработка изменения атрибутов
    editHandler(event, item) {
        if(item.IsConstant) {
            this.props.updateAttributesRedactorData({
                name : item.AttributeName,
                value : event.target.value,
                isConstant : item.IsConstant
            });
        } else {
            this.props.updateAttributesRedactorData({
                name : item.IdAttributeName,
                value : event.target.value,
                isConstant : item.IsConstant
            });
        }
    }


    calendarOnChange(event, item) {
        this.props.updateAttributesRedactorData({
            name : item.AttributeName,
            value : event.target.value,
            isConstant : item.IsConstant,
        });
    }

    search(value) {
        value = `"${value.split('"').join('').split("'").join('')}"`

        let searchValue = parse(sanitizeHtml(value));
        let searchValueResult="";
        for(let i = 0; i < searchValue.length; i++) {
            if((typeof searchValue[i]) === "string"){
                searchValueResult = searchValueResult + searchValue[i];
            }else {
                searchValueResult = searchValueResult + searchValue[i].props.children
            }
        }

        const idSearch = __.createUniqueIdString(6);

        this.props.updateInputsFieldSearch({
            AttributesText : searchValueResult,
            TypeIds: [],
            Id: idSearch,
            Next : {
                action : () => {
                    this.props.searchByParams({
                        Id: idSearch,
                        Next:{
                            action : () => {
                                this.props.combineMainTableAttributesSearch({})
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            }
        });
    }

    render() {
        const {activeTab, searchResults} = this.props;
        let sectionData;
        let isReady;
        let originalCard = null;
        let originalSectionData = null;
        const additionalSectionsData = searchResults.find(res => res.Id === activeTab.Id)?.additionalSections;
        if(this.props.viewMode === "normal") {
            sectionData = additionalSectionsData.state[this.sectionName];
            isReady = additionalSectionsData.isReady;
        }
        else if(this.props.viewMode === "card") {
            let card;
            const cards = searchResults.find(res => res.Id === activeTab.Id)?.cards;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = cards.redactorData;
                originalCard = cards.collection.find(card => card.Id === cards.activeCardId);
            } else {
                card = cards.collection.find(card => card.Id === cards.activeCardId);
            }

            if(originalCard) {
                originalSectionData = __.deepCopy(originalCard.additionalSections[this.sectionName]);
            }

            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = cards.collection
                .find(card => card.Id === cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }


        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">


                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                {!this.props.redactorMode && <th className="col-lg-1">Найти</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.attributes.map((item, index) => {
                                    if(this.props.redactorMode) {
                                        if(item.AttributeName === "Version.ModifyTime") {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                    <td>
                                                        <input className="form-control"
                                                               type="datetime-local"
                                                               disabled={true}
                                                               value={item.Value}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        else   if(item.AttributeName === "RegDate") {

                                            if(originalSectionData.attributes[index].Value === item.Value) {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        <td style={{verticalAlign: "middle"}}>
                                                            <b>
                                                                {item.ColumnName}
                                                                <sup>*</sup>
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                   type="datetime-local"
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.calendarOnChange(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            else {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        <td style={{verticalAlign: "middle", position : "relative"}}>
                                                            <b>{item.ColumnName}<sup>*</sup></b>
                                                            <span className="btn btn-icon btn-sm"
                                                                  style={{top : "13px", right : "0px", position : "absolute"}}
                                                                  title="Внесены изменения">
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                   type="datetime-local"
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.calendarOnChange(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }


                                        }
                                        else {
                                            if(originalSectionData.attributes[index].Value === item.Value) {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            __.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle"}}><b>{item.ColumnName}<sup>*</sup></b></td>
                                                        }
                                                        {
                                                            !__.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                        }
                                                        <td>
                                                            <input className="form-control"
                                                                   type={__.inputTypeByDataTypeId(item.IdAttributeType)}
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.editHandler(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            else {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            __.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position : "relative"}}>
                                                                <b> {item.ColumnName}<sup>*</sup></b>
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{top : "13px", right : "0px", position : "absolute"}}
                                                                      title="Внесены изменения">
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                            </td>
                                                        }
                                                        {
                                                            !__.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position : "relative"}}>
                                                                {item.ColumnName}
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{top : "13px", right : "0px", position : "absolute"}}
                                                                      title="Внесены изменения">
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                            </td>
                                                        }
                                                        <td>
                                                            <input className="form-control"
                                                                   type={__.inputTypeByDataTypeId(item.IdAttributeType)}
                                                                   value={item.Value}
                                                                   onChange={(event)=>{this.editHandler(event, item)}}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    } else {
                                        let value = item.Value ? item.Value : "-";
                                        return (
                                            <tr key={item.ColumnName}>
                                                <td>{item.ColumnName}</td>
                                                {/*
                                                ///TODO если использовать sanitizeHtml то не будет подсветки но если приложение будет падать вернуть sanitizeHtml Задача № 23042
                                                <td>{parse(sanitizeHtml(value))}</td>
                                                */}
                                                <td>{parse(sanitizeHtml(value, {allowedAttributes: {span: ["class", "className"]}}))}</td>
                                                {/*<td>{parse(value)}</td>*/}


                                                <td >
                                                    {
                                                        value != "-" &&
                                                    <span className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                          onClick={() => {this.search(value)}}
                                                    >
                                                        <i className="svg-icon icon-Search"/>
                                                    </span>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    }
                                })
                            }
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        rootState : state.search.rootState,
        searchResults: state.search.searchState.searchResults,
        activeTab: state.search.rootState.activeTab
    }
}

const  mapDispatchToProps = {
    combineMainTableAttributesSearch,
    searchByParams,
    toggleAdditionalSectionsOpenSearch,
    toggleAdditionalSectionsOpen_Cards,
    updateAttributesRedactorData,
    updateInputsFieldSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSectionSearch);
