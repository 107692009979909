import React  from 'react';
import CreatableSelect from 'react-select/creatable';
import {__} from "../../tools/HelpFunctions";

export class MySelectNew extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            List : [],
            Input : ""
        };

        this.dataList = this.dataList.bind(this);
        this.dataListInputChange = this.dataListInputChange.bind(this);
        this.dataListKeyDown = this.dataListKeyDown.bind(this);
    }

    dataList(list) {
        this.setState({
            List : list
        });
        let returnedValue = [];
        if(list?.length) {
            for (let i = 0; i < list.length; i++) {
                returnedValue.push(list[i].value);
            }
        }
        this.props.returnFunc(returnedValue);
    }

    dataListInputChange(inputValue) {
        this.setState({ Input : inputValue });
        if(this.props.setSinglePackName) this.props.setSinglePackName(inputValue)
    }

    dataListKeyDown(event) {
        const createOption = (label) => ({
            label,
            value: label,
        });

        const inputValue = __.deepCopyJSON(this.state.Input);
        let value = __.deepCopyJSON(this.state.List);

        if(!value) value = [];

        if (!inputValue) return;

        if(event.key === "Enter" || event.key === "Tab") {
            value.push(createOption(inputValue));
            let newValue = [];
            for (let i = 0; i < value.length; i++) {
                newValue.push(JSON.stringify(value[i]));
            }
            newValue = Array.from(new Set(newValue));
            for (let i = 0; i < newValue.length; i++) {
                newValue[i] = JSON.parse(newValue[i]);
            }

            this.setState({
                Input: '',
                List: newValue,
            }, ()=>{
                let returnedValue = [];
                for (let i = 0; i < newValue.length; i++) {
                    returnedValue.push(newValue[i].value);
                }
                this.props.returnFunc(returnedValue);
            });

            event.preventDefault();
        }

    };

    render() {
        return (
            <CreatableSelect
                components={{DropdownIndicator : null}}
                className={"col-lg-12"}
                inputValue={this.state.Input}
                isClearable
                isMulti={this.props.isMulti}
                menuIsOpen={false}
                onChange={this.dataList}
                onInputChange={this.dataListInputChange}
                onKeyDown={this.dataListKeyDown}
                placeholder={this.props.placeholder}
                value={this.state.List}
                styles={this.props.styles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.42rem",
                    colors: {
                        ...theme.colors,
                        primary: 'var(--originaltwo)',
                    }})}
            />
        );
    }
}
