import {cloneDeep} from "lodash";

export class  __ {

    static deepCopyJSON(obj : any) {
        return JSON.parse(JSON.stringify(obj));
    }

    static deepCopy(obj : any) {
        return cloneDeep(obj);
    }

    // является ли переменная строкой
    static isString(val : any) : boolean {
        return (typeof val === "string" || val instanceof String);
    }

    static formatDate (input : any) {
        var datePart = input.match(/\d+/g),
            year = datePart[0].substring(2), // get only two digits
            month = datePart[1], day = datePart[2];

        return day+'/'+month+'/'+year;
    }
    // formatDate ('2010/01/18'); // "18/01/10"


    static reformatDate(dateStr: any)
    {
        if (!dateStr)
            return "";
        var dArr = dateStr.split("-");  // ex input: "2022-01-18"
        return dArr[2]+ "." +dArr[1]+ "." +dArr[0].substring(4); //ex output: "18.01.2022"
    }
}
