import React from "react";
import {connect} from "react-redux";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";


class ContentSectionPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false
        };

        this.toggle = this.toggle.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }


    render() {
        if(!this.props.activeDocument) return null;
        let isReady = this.props.activeDocument.additionalSections.isReady;
        let sectionData = this.props.activeDocument.additionalSections.contentSection.content;


        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        //TODO:24072-Ошибка по содержанию документа,когда происходит перещелкивание файлов
        if(typeof sectionData==='object'&&!Array.isArray(sectionData) && sectionData !== null){
            sectionData=sectionData.content
        }

        let sectionDataText = sanitizeHtml(sectionData, {
            allowedAttributes: {span: ["class", "className"]}
        });

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Содержание
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body content-section p-6">
                        {
                            isReady &&
                            <pre className="content-text">{parse(sectionDataText)}</pre>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSectionPackages);
