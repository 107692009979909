
export const PACKAGES_TREE_ACTIONS = {
    // получение документов для дерева (типов документов)
    GET_DOC_IN_PAC : "PACKAGE | TREE | GET_PAC",

    GET_DOC_IN_PAC_FOR_TYPES : "PACKAGE | TREE | GET_PAC_FOR_TYPES",

    GET_TYPE_DOC : "PACKAGE | TREE | GET_TYPE_DOC",
    // получение групп пакетов (папки в дереве)
    GET_GROUP : "PACKAGE | TREE | GET_GROUP",
    GET_GROUP_SEARCH : "PACKAGE | TREE | GET_GROUP_SEARCH",
    // изменение текста в поле "Фильтр" в пакетах
    SET_TREE_SEARCH_QUERY : "PACKAGE | TREE | SET_SEARCH_QUERY",
    // генерация дерева по полученным данным
    CREATE : "PACKAGE | TREE | CREATE",
    // поиск по дереву
    SEARCH : "DOCUMENT | TREE | SEARCH",
    GET_DOC_IN_PAC_SEARCH : "PACKAGE | TREE | GET_DOC_IN_PAC_SEARCH",
    SEARCH_NODE : "PACKAGE | TREE | SEARCH_NODE",
    IS_SEARCH : "PACKAGE | TREE | IS_SEARCH",
    PATH_TO_NODE : "PACKAGE | TREE | PATH_TO_NODE",
    // переключение между обычным деревом
    // и деревом с отображением поиска
    SEARCH_TOGGLE : "PACKAGE | TREE | SEARCH_TOGGLE",
    // сохраняем выбранный узел дерева
    SELECT_NODE : "PACKAGE | TREE | SELECT_NODE",
    // сохраняем выбранный документа
    SELECT_DOCUMENT : "PACKAGE | TREE | SELECT_DOCUMENT",
    // удаление и обновление подписи
    DELETE_SIGNATURE: "PACKAGE | TREE | DELETE_SIGNATURE",
    UPDATE_SIGNATURE : "PACKAGE | TREE | UPDATE_SIGNATURE",
    //выделить активную строку
    SELECT_ACTIVE_ROW : "PACKAGE | TREE | SELECT_ACTIVE_ROW",
    // переключить открыт/закрыт папки в дереве
    TOGGLE_FOLDERS : "PACKAGE | TREE | TOGGLE_FOLDERS",
    // добавить новую хлебную крошку в конец
    BREADCRUMBS_ADD : "PACKAGE | TREE | BREADCRUMBS_ADD",
    // добавить новую хлебную крошку не в конец
    BREADCRUMBS_FIND_AND_ADD : "PACKAGE | TREE | BREADCRUMBS_FIND_AND_ADD",
    // вернуться в предыдущий пакет по кнопке назад
    BREADCRUMBS_MOVE_PREV : "PACKAGE | TREE | BREADCRUMBS_MOVE_PREV",
    // вернуться в следующий пакет по кнопке вперед
    BREADCRUMBS_MOVE_NEXT : "PACKAGE | TREE | BREADCRUMBS_MOVE_NEXT",
    // перейти к выбранной крошке
    BREADCRUMBS_MOVE_SELECT : "PACKAGE | TREE | BREADCRUMBS_MOVE_SELECT",
    // запись номера страницы, при пагинации
    BREADCRUMBS_SET_PAGE_NUMBER: "PACKAGE | TREE | BREADCRUMBS_SET_PAGE_NUMBER",
    // скопировать основное дерево в дополнительное
    COPY_TO_ADDITIONAL_TREE : "PACKAGE | TREE | COPY_TO_ADDITIONAL_TREE",

    SELECT_DOCUMENT_RIGHT : "PACKAGE | TABLE | SELECT_DOCUMENT_RIGHT",

    CREATE_SEARCH_TREE: "PACKAGE | TREE | CREATE_SEARCH_TREE",
    //выбор активной ноды в модальном окне переноса документов  в пакет
    SELECT_ACTIVE_NODE: "PACKAGE | TREE | SELECT_ACTIVE_NODE",
    // настройка контента в зависимости от выбранного файла
    SET_FILE_CONTENT: "PACKAGE | TREE | SET_FILE_CONTENT",
    // получение данных по подразделениям для документа
    SET_DEPARTMENTS_DATA: "PACKAGE | TREE | SET_DEPARTMENTS_DATA",
    // отображение файла (оригинал / предпросмотр)
    SELECT_VIEW_FOR_ACTIVE_FILE: "PACKAGE | TREE | SELECT_VIEW_FOR_ACTIVE_FILE",
    // Список файлов для просмотра подписей
    SET_ROW_FILE_FOR_SHOW_SIGNATURE : "PACKAGE | TREE | SET_ROW_FILE_FOR_SHOW_SIGNATURE",

    // установить размер пагинации в таблице пакетов
 //   PAGINATION_SET_PAGE_SIZE : "PACKAGE | TREE | PAGINATION_SET_PAGE_SIZE",
};
export const PACKAGES_TABLE_ACTIONS = {
    // переключение вида -> 1 или 2 таблицы, предпросмотр и тд
    CHANGE_VIEW_MODE_PACKAGE_APP : "PACKAGE | TABLE | CHANGE_VIEW_MODE_PACKAGE_APP",
    // переключения вида в таблицах
    CHANGE_VIEW_MODE_PACKAGE_TABLES : "PACKAGE | TABLE | CHANGE_VIEW_MODE_TABLES",
    // переключение страницы пагинации в пакетах
    CHANGE_PAGINATION_PAGE : "PACKAGE | TABLE | CHANGE_PAGINATION_PAGE",
    // изменить фильтрацию просмотра все/пакеты/документы
    CHANGE_VIEW_FILTER : "PACKAGE | TABLE | CHANGE_VIEW_FILTER",
    // изменения кол-ва отображаемых элементов на странице в пакетах
    CHANGE_COUNT_ITEM_ON_PAGE : "PACKAGE | TABLE | CHANGE_COUNT_ITEM_ON_PAGE",
    // изменить способ сортировки: по возрастанию или убыванию
    CHANGE_SORT_DIRECTION : "PACKAGE | TABLE | CHANGE_SORT_DIRECTION",

    SET_VIEW_MODE_PREVIEW : "PACKAGE | TABLE | SET_VIEW_MODE_PREVIEW",
    SET_SIZE_PREVIEW : "PACKAGE | TABLE | SET_SIZE_PREVIEW",

    TOGGLE_ADDITIONAL_SECTIONS : "PACKAGE | TABLE | TOGGLE_ADDITIONAL_SECTIONS",

    SELECT_ACTIVE_PREVIEW_FILE : "PACKAGE | TABLE | SELECT_ACTIVE_PREVIEW_FILE",

    // поиск по дереву
    SEARCH_DOCUMENT_RIGHT : "PACKAGE | TABLE | SEARCH_DOCUMENT_RIGHT",
    // переключение между обычным деревом
    // и деревом с отображением поиска
    SEARCH_TOGGLE_DOCUMENT_RIGHT : "PACKAGE | TABLE | SEARCH_TOGGLE_DOCUMENT_RIGHT",

    SEARCH_DOWNLOAD : "PACKAGE | TABLE | SEARCH_DOWNLOAD",

    SEARCH_TOGGLE_DOWNLOAD_SEARCH : "PACKAGE | TABLE | SEARCH_TOGGLE_DOWNLOAD",

    SELECT_NODE_DOCUMENT_RIGHT : "PACKAGE | TABLE | SELECT_NODE_DOCUMENT_RIGHT",

    DOWNLOAD_DOC_COLLECTION_DOCUMENT_RIGHT : "PACKAGE | TABLE | DOWNLOAD_DOC_COLLECTION_DOCUMENT_RIGHT",

    COMBINE_ATTRIBUTE_DOCUMENT_RIGHT : "PACKAGE | TABLE | COMBINE_ATTRIBUTE_DOCUMENT_RIGHT",

    SET_PAGINATION_PAGE_NUMBER_DOCUMENT_RIGHT : "PACKAGE | TABLE | SET_PAGINATION_PAGE_NUMBER_DOCUMENT_RIGHT",

    RESET_FOR_UPDATE_DOCUMENT_RIGHT : "PACKAGE | TABLE | RESET_FOR_UPDATE_DOCUMENT_RIGHT",

    CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE : "PACKAGE | TABLE | CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE",

    CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT : "PACKAGE | TABLE | CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT",

    CHANGE_INPUTS_DOCUMENT_RIGHT : "PACKAGE | TABLE | CHANGE_INPUTS_DOCUMENT_RIGHT",

    ADD_ROW_TO_CHECKED_MAIN_TABLE : "PACKAGE | TABLE | ADD_ROW_TO_CHECKED_MAIN_TABLE",

    ADD_ROW_TO_CHECKED_DOCUMENT_RIGHT : "PACKAGE | TABLE | ADD_ROW_TO_CHECKED_DOCUMENT_RIGHT",

    DELETE_ROW_FROM_CHECKED_MAIN_TABLE : "PACKAGE | TABLE | DELETE_ROW_FROM_CHECKED_MAIN_TABLE",

    DELETE_ROW_FROM_CHECKED_DOCUMENT_RIGHT : "PACKAGE | TABLE | DELETE_ROW_FROM_CHECKED_DOCUMENT_RIGHT",

    SELECT_ACTIVE_ROW_DOCUMENT_RIGHT : "PACKAGE | TABLE | SELECT_ACTIVE_ROW_DOCUMENT_RIGHT",

    SET_PAGINATION_ON_PAGE_SIZE_DOCUMENT_RIGHT : "PACKAGE | TABLE | SET_PAGINATION_ON_PAGE_SIZE_DOCUMENT_RIGHT",

    GET_DOCS_DOCUMENT_RIGHT  : "PACKAGE | TABLE | GET_DOCS_DOCUMENT_RIGHT",

    GET_GROUP_DOCUMENT_RIGHT : "PACKAGE | TABLE | GET_GROUP_DOCUMENT_RIGHT",

    CREATE_DOCUMENT_RIGHT : "PACKAGE | TABLE | CREATE_DOCUMENT_RIGHT",


    // сортировка активного узла для пагинации
//    SORTING_ACTIVE_NODE_FOR_PAGINATION : "PACKAGE | TABLE | SORTING_ACTIVE_NODE_FOR_PAGINATION",

    // переход к определенной странице пагинации
  //  PAGINATION_MOVE_TO_PAGE : "PACKAGE | TABLE | PAGINATION_MOVE_TO_PAGE",


    COPY_DOCUMENT_TREE : "PACKAGE | TABLE | COPY_DOCUMENT_TREE",
};

// глобальное состояние для компонента DocumentApp
export const PACKAGE_ROOT_STATE_ACTIONS = {
    // изменить режим отображения
    CHANGE_VIEW_MODE : "PACKAGE | ROOT_STATE | CHANGE_VIEW_MODE",
    // изменить режим отображения
    CHANGE_VIEW_MODE_DOWNLOAD : "PACKAGE | ROOT_STATE | CHANGE_VIEW_MODE_DOWNLOAD",
    //переключение видимости asideMenu
    TOGGLE_SHOW_ASIDE_MENU : "PACKAGE | ROOT_STATE | TOGGLE_SHOW_ASIDE_MENU"

};
export const PACKAGE_SEARCH = {
    // изменить режим отображения
    UPDATE_INPUTS_FIELD : "PACKAGE | SEARCH | UPDATE_INPUTS_FIELD",
    // изменить сортируемое поле
    UPDATE_INPUTS_FIELD_SORTING : "PACKAGE | SEARCH | UPDATE_INPUTS_FIELD_SORTING",
};
