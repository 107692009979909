import React from "react";
import {connect} from "react-redux";
import {
    breadcrumbsAdd,
    getDocumentsForTreePackages,
    getPackageGroupsForTree,
    GetPackageTypes, PackageAddNodesTree,
    PackageCreateTree, SelectActivePackageDocument, SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {
    changePaginationPageInPackages,
    changeViewFilter,
    setViewModePreviewPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";
import {treeSearchPackage} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {PACKAGES_TREE_ACTIONS} from "../../../store/packages/packageActionsList";
import {contextMenuTypes, ModalTypes, PackagesViewFilters, TableTypesEnum} from "../../../tools/StaticTypes";
import {
    setContextMenuData,
    setLoaderModalData,
    setModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    handlerIsSearch,
    updateInputsFieldPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import ReactDOM from "react-dom";
// import {__} from "../../../tools/HelpFunctions";


// Компонент создает свои же копии,
// поэтому необходимо передавать ему информацию от первого родителя и вниз по цепочке.
// Для этого был использован prevContext
// (кроме this.props.data и this.props.depth)
class TreePackageFolder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // IsOpen : false,
            selectNode: null,
            curRef: null,
        }


        this.switchFolderState = this.switchFolderState.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.selectAndLoad = this.selectAndLoad.bind(this);
        this.rowRef = React.createRef();
        this.setRef = this.setRef.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
    }

    setActiveRef() {

        // Store a 'this' ref, and
        let _this = this.rowRef.current;
        let rowRef = this.rowRef;
        let props = this.props;
        // wait for a paint before running scrollHeight dependent code.
        window.requestAnimationFrame(function() {
            let node = ReactDOM.findDOMNode(_this);
            if (node !== undefined) {
                // let props1 = props.prevContext
                //     ? props.prevContext
                //     : props;

                // let activeNode;

                // if (props.isSearch) {
                //     activeNode = props1.activeNodeSearch
                // } else if (props1.isAdditional) {
                //     activeNode = props1.activeNodeAdd
                // } else {
                //     activeNode = props1.activeNode
                // }

                if (node) {
                    //TODO SG
                    let p = store.getState().router ? store.getState().router.location.query.p : ""
                    // this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
                    if (p == props.data.Node.Id) {
                        if (props.setRef) props.setRef(rowRef);
                    }
                }
            }
        })
    }

    componentDidMount() {
        this.setActiveRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let lFlagSetRef = false;
        if(this.props.isSearch) {
            if (prevProps.activeNodeSearch != this.props.activeNodeSearch)
                lFlagSetRef = true;
        } else if(this.props.isAdditional) {
            if (prevProps.activeNodeAdd != this.props.activeNodeAdd)
                lFlagSetRef = true;
        } else {
            if (prevProps.activeNode != this.props.activeNode)
                lFlagSetRef = true;
        }

        if (lFlagSetRef)
            this.setActiveRef();

        if (this.props.setLast)
            this.props.setLast();
    }

    setRef = (lRef) => {
        if (lRef)
            this.props.setRef(lRef);
    }

    // открытие или закрытие папки
    switchFolderState() {
        // this.setState({
        //    IsOpen : !this.state.IsOpen,
        // });
    }


    selectAndLoad() {

    }

    // Загрузка пакетов при нажатии на стрелку, без выбора активной ноды
    async load(item) {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        // props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents"}
        // });
        //Новый прелоадер
        if (this.props.tableRole !== "doc")
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "TreePackageFolder146" }
            }));

        if(props.isSearch) {
            await props.treeSearchPackage({Id: item.Node.Id, TableType : "doc", IsSearch: true});
            //Новый прелоадер
            props.setLoaderModalData({keyDeleted: "TreePackageFolder152"});
        } else {
            try {
                let openedFolders;
                let activeNode;
                if(this.props.isSearch) {
                    openedFolders = props.openedFoldersSearch;
                    activeNode = props.activeNodeSearch
                } else if(props.isAdditional) {
                    openedFolders = props.openedFoldersAdd;
                    activeNode = props.activeNodeAdd
                } else {
                    openedFolders = props.openedFolders
                    activeNode = props.activeNode
                }

                const tableRole = props.isAdditional ? "additional" : "main";

                await props.PackageAddNodesTree({
                    Id: item.Node.Id,
                    TableType: tableRole,
                });

                props.togglePackagesFolders({
                    IdFolder: item.Node.Id,
                    TableType: tableRole,
                });

                // если активная нода не добавлена в открытые папки после сворачивания
                // т.к. openedFolders собирается из breadcrumbs, а дерево пересобирается заново без children активной ноды
                if(activeNode?.Node.Id !== 2 && !(openedFolders.includes(activeNode?.Node.Id))) {
                    await props.PackageAddNodesTree({
                        Id: activeNode.Node.Id,
                        TableType: tableRole
                    })
                }
                // props.setModalData({});
                //Новый прелоадер
                if (this.props.tableRole !== "doc")
                    props.setLoaderModalData({keyDeleted: "TreePackageFolder194"});
            } catch (e) {
                props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${e.message}`}
                });
            }
        }
        //TODO aa чтобы не закрывалась модалка, иначе в модалке выходит из div
        if (this.props.backFocus)
            this.props.backFocus();
    }

    //isSearch - property, который означает, что производится поиск по дереву(модальное окно переноса документов)

    async selectNode(item) {

        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        if (!props.isAdditional && !props.isSearch) {
            let query = props.router.location.query;
            let queryLink = "";

            queryLink = Routing.CreateRef({
                oldQuery: query,
                newQuery: {p: item.Node.Id},
                excludedKeys: ["d"]
            });

            let link = `${props.router.location.pathname}${queryLink}`;
            //TODO Задача № 19783 Не открывается Пакет через левую панель после возврата через ссылку главной страницы раздела
            // if (store.getState())
            //   history.replace(link, true)
            // else
            const TableType = props.isAdditional ? "additional" : "main";
            await store.dispatch(changePaginationPageInPackages({PageNumber: 1, TableType}));

            history.push(link);
        }

        if(props.isSearch) {
            store.dispatch({type: PACKAGES_TREE_ACTIONS.SELECT_ACTIVE_NODE, payload: item})
            await props.selectNodeDoc(item.Node);
        } else {
            // store.dispatch(setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages"}
            // }))
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages", key: "TreePackageFolder243"}
            }))
            const tableRole = props.isAdditional ? "additional" : "main";
            if (props.viewModePreview === "welcome") {
                props.setViewModePreviewPackages({
                    ViewMode: "none",
                });
            } else if(props.viewModePreview !== "two_table_none" && props.isAdditional) {
                props.setViewModePreviewPackages({
                    ViewMode: "two_table_none"
                })
            }

            props.PackageAddNodesTree({
                Id: item.Node.Id,
                TableType: tableRole,
                Next: {
                    action: () => {

                        props.SelectActivePackageNode({
                            Id: item.Node.Id,
                            TableType: tableRole,
                        });

                        //TODO поменял местами для синхронизации с breadcrumbs props.breadcrumbsAdd({ сделал первее, чем props.togglePackagesFolders({
                        props.breadcrumbsAdd({
                            NodeId: item.Node.Id,
                            Name: item.Node.Name,
                            ParentId: item.Node.IdParent,
                            TableType: tableRole,
                        });


                        // условие, для того, чтобы при открытии пакета через стрелочку, не должны удаляться дети из view выбранного пакета
                        const openedFolders = tableRole === TableTypesEnum.Main
                            ? props.openedFolders
                            : props.openedFoldersAdd
                        if(!openedFolders.includes(item.Node.Id)) {
                            props.togglePackagesFolders({
                                    IdFolder: item.Node.Id,
                                    TableType: tableRole,
                                }
                            );
                        }

                        props.changeViewFilter({
                            Type: "packages",
                            TableType :  tableRole,
                            lStoreValue : PackagesViewFilters.OnlyDocuments
                        });

                        props.updateInputsFieldPackage({TableRole: tableRole});
                        props.handlerIsSearch({TableType: tableRole});



                        // if(this.props.tableRole === "download") {
                        //     this.props.SelectActivePackageDocument({TableType : this.props.tableRole, IsDownload : true});
                        // }
                        // else {
                        props.SelectActivePackageDocument({TableType: tableRole});
                        // }

                        if (!props.isAdditional) {
                            // let query = props.router.location.query;
                            // let queryLink = "";
                            //
                            // queryLink = Routing.CreateRef({
                            //     oldQuery: query,
                            //     newQuery: {p: item.Node.Id},
                            //     excludedKeys: []
                            // });
                            //
                            // let link = `${props.router.location.pathname}${queryLink}`;
                            // //TODO Задача № 19783 Не открывается Пакет через левую панель после возврата через ссылку главной страницы раздела
                            // // if (store.getState())
                            // //   history.replace(link, true)
                            // // else
                            // history.push(link);
                        } else {
                            let query = props.router.location.query;
                            let queryLink = "";

                            queryLink = Routing.CreateRef({
                                oldQuery : query,
                                newQuery : {p2 : item.Node.Id},
                                excludedKeys : ["d2","dt2","dg2","dd2","dwp","dwd"]
                            });

                            let link = `${props.router.location.pathname}${queryLink}`;
                            //TODO при открытии дополнительной таблицы, пользователь по шагам видит свой предыдущий путь
                            //history.replace(link);
                            history.push(link);

                        }
                        // store.dispatch(setModalData({}))
                        //Новый прелоадер
                        store.dispatch(setLoaderModalData({keyDeleted: "TreePackageFolder333"}));
                        //     TODO: MS 28.02
                    },
                    params: {}
                }
            });
        }
    }

    // Вызов по правому клику контекстного меню для вызова модального окна переноса пакетов
    onRightClick(event, data) {
        if(!this.props.isAdditional) {
            event.stopPropagation();
            let props = this.props.prevContext
                ? this.props.prevContext
                : this.props;
            props.setContextMenuData({
                name : contextMenuTypes.TreePackages,
                data: data,
                position : {
                    x : event.clientX,
                    y : event.clientY + 10}
            });
            event.preventDefault();
        }
    }

    render() {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        let data = this.props.data; // значение текущего узла
        let depth = this.props.depth; // текущая глубина
        let openedFolders = [];
        let activeNode;
        if(this.props.isSearch) {
            openedFolders = props.openedFoldersSearch;
            activeNode = props.activeNodeSearch
        } else if(props.isAdditional) {
            openedFolders = props.openedFoldersAdd;
            activeNode = props.activeNodeAdd
        } else {
            openedFolders = props.openedFolders
            activeNode = props.activeNode
        }

        let subItemStyle = openedFolders.find(item => item === data.Node.Id) ? {} : {display: "none"};

        // выделение активного node
        let activeStyle = {};
        let activeItemStyleText = "";
        let activeItemStyleIcon = "icon-color-grey";
        // TreeId - заменен на Id, т.к. после сворачивания и открытия, дерево пересобирается TreeId меняется и не совпадает
        if(activeNode && (data.Node.Id === activeNode.Node.Id)) {
            activeStyle = {"border": "2px var(--elem-bg) solid"};
            activeStyle.background = "#ffffff82";
            activeStyle.transition = "background-color 0.3s";
            activeStyle.minHeight = "45px";
            activeStyle.borderRadius = "25px";

            activeItemStyleText = "text-color-primary-dark";
            activeItemStyleIcon = "icon-color-primary-dark";
        }
        activeStyle.marginTop =  "3px";
        activeStyle.marginBottom =  "3px";

        let checkedStyle = props.activeNodeSearch && (data.Node.Id === props.activeNodeSearch.Node.Id) ? {
            backgroundColor: "#8bf39d0d",
            border: "1px solid #087c103d",
        } : {}

        let arrowDirection = openedFolders.find(item => item === data.Node.Id) ? "arrow-bottom" : "arrow-right";
        let isContainsChild = props.isSearch ? data.Node.ContainsChilds : data.Node.ContainsChildren;
        let folderIcon = isContainsChild ? "icon-on1" : "icon-off1";
        if (data.Type != "PackagesFolder")
            return <></>

        return(
            <React.Fragment>
                <li className={"menu-link-p menu-item "} onContextMenu={(ev) => {this.onRightClick(ev, data)}}>
                <span className="menu-link menu-toggle" style={props.isSearch ? checkedStyle : activeStyle}>
                    <span className={"d-flex w-100"} onClick={()=>{
                        this.selectNode(data)
                    }}>
                        <span className="svg-icon menu-icon">
                        <i className={folderIcon + " " + activeItemStyleIcon} />
                    </span>
                    <span ref={this.rowRef}
                          tabIndex={this.props.data.Node.Id}
                          className={`menu-text noneFocus ${activeItemStyleText}`}>{data.Node.Name}</span>
                    </span>
                    {
                        isContainsChild &&
                        <i className={`arrow_m ${arrowDirection} ${activeItemStyleIcon} icon-Arrows_1`} onClick={() => {
                            this.load(data)
                        }} />
                    }
                </span>
                    <div className="menu-submenu menu-submenu-p" style={subItemStyle}>
                        <ul className="menu-subnav">
                            {
                                data.Children.map((item, index) => {
                                        if (item.Type === "PackagesFolder") {
                                            return (
                                                <TreePackageFolder
                                                    setRef={(Item) => {
                                                        this.setRef(Item)
                                                    }}
                                                    key={item.TreeId}
                                                    data={item}
                                                    depth={depth + 1}
                                                    isSearch={props.isSearch}
                                                    setLast={props.setLast}
                                                    selectNodeDoc={() => props.selectNodeDoc(item.Node)}
                                                    isAdditional={this.props.isAdditional}
                                                    prevContext={this.props.prevContext ? this.props.prevContext : this.props}
                                                    tableRole={this.props.tableRole}
                                                    rootId={data.Node.Id}
                                                />
                                            );
                                        }
                                        else return  null;
                                    }
                                )
                            }
                        </ul>
                    </div>
                </li>
            </React.Fragment>
        );
    }
    // else return null;
    // }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.packages.tree.activeNode,
        activeNodeSearch: state.packages.treeSearchAdditional.activeNode,
        activeNodeAdd: state.packages.treeAdditional.activeNode,
        openedFolders : state.packages.tree.openedFolders,
        openedFoldersSearch: state.packages.treeSearchAdditional.openedFolders,
        openedFoldersAdd: state.packages.treeAdditional.openedFolders,
        router : state.router,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    GetPackageTypes,
    getPackageGroupsForTree,
    getDocumentsForTreePackages,
    PackageCreateTree,
    PackageAddNodesTree,
    SelectActivePackageNode,
    //  combineMainTableAttributesPackages,
    setViewModePreviewPackages,
    togglePackagesFolders,
    breadcrumbsAdd,
    SelectActivePackageDocument,
    treeSearchPackage,
    setContextMenuData,
    updateInputsFieldPackage,
    handlerIsSearch,
    changeViewFilter,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(TreePackageFolder);
