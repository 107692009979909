import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    changeSortDirection,
    PackagesPaginationMovePage
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    getDocumentsForTreePackages, PackageAddNodesTree,
    PackageCreateTree, SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    handlerSearchPackages, searchByParamsPackages,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";

class ContextMainTablePackageTh extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;

        this.search = this.search.bind(this);
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTablePackageTH) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.contextMenu.data.tableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.contextMenu.data.tableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.contextMenu.data.tableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }

        return searchInputs;
    }

    getAppliedParams(searchInputs) {
        const result = {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,}
        const {RegNumber, Name, TypeName, ModifyDate, RegDateAsString} = searchInputs
        if (Object.values(RegNumber).some(item => item)) {
            result.RegNumber = true
        }
        if (Object.values(Name).some(item => item)) {
            result.Name = true
        }
        if (Object.values(TypeName).some(item => item)) {
            result.TypeName = true
        }
        if (Object.values(ModifyDate).some(item => item)) {
            result.ModifyDate = true
        }
        if (Object.values(RegDateAsString).some(item => item)) {
            result.RegDateAsString = true
        }
        return result
    }



    async search(Value) {
        const {contextMenu} = this.props
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "ThMainTable6963"
            }
        });

        let SortingParam = contextMenu.data.th.Name !== "Version.ModifyTime" ? contextMenu.data.th.Name : "ModifyDate"
        this.props.updateInputsFieldSortingPackage({TableRole: contextMenu.data.tableRole, SortingParam, Direction: Value})
        this.props.changeSortDirection({dir: Value, sortField: contextMenu.data.th})
        await this.props.PackagesPaginationMovePage({
            PageNumber: 1,
            TableType: contextMenu.data.tableRole
        });
        let searchInputs = this.getCurrentSearchInputs()
        const appliedParams = this.getAppliedParams(searchInputs)
        let lActiveNode;
        if (contextMenu.data.tableRole === "main")
            lActiveNode = this.props.activeNode.Node.Id
        else if (contextMenu.data.tableRole === "additional")
            lActiveNode = this.props.activeNodeAdditional.Node.Id
        else if (contextMenu.data.tableRole === "download")
            lActiveNode = this.props.activeNodeDownload.Node.Id;

        let Direction = Value;

        this.props.updateInputsFieldPackage({
            TableRole: contextMenu.data.tableRole,
            RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            TypeName: searchInputs.TypeName ?? {In: ''},
            ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            AppliedParams: appliedParams,
            Next: {
                action: () => {
                    this.props.PackageAddNodesTree({
                        FieldSorting: contextMenu.data.th,
                        Direction,
                        Id: lActiveNode,
                        TableType: contextMenu.data.tableRole,
                        Next: {
                            action: () => {
                                this.props.searchByParamsPackages({
                                    TableType: contextMenu.data.tableRole,
                                    FieldSorting: contextMenu.data.th,
                                    Direction,
                                    Next: {
                                        action: () => {
                                            this.props.SelectActivePackageNode({
                                                Id: lActiveNode,
                                                TableType: contextMenu.data.tableRole,
                                            })
                                            this.props.setContextMenuData({});
                                            this.props.setLoaderModalData({keyDeleted: "ThMainTablePackage123"});},
                                        params: {}
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }


    render() {
        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };

        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onScroll={()=>{ this.props.setContextMenuData({})}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={(event) => {this.search(2)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link up">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_up"> </i>
                            </span>
                            Сортировка А-Я
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={(event) => {this.search(1)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link down">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_down"> </i>
                            </span>
                            Сортировка Я-А
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,

        activeNodeId: state.packages.tree.activeNode.Node.Id,
        pageSize: state.packages.mainTable.paginationPageSize,
        searchInputs: state.packages.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
        sortDir: state.packages.mainTable.sortDirection,
        sortField: state.packages.mainTable.sortField,
        sortedParams: state.packages.search.SortedParams,
        sortedParamsMain: state.packages.tree.SortedParams,
        sortedParamsAdditional: state.packages.treeAdditional.SortedParams,
        sortedParamsDownload: state.packages.treeDownload.SortedParams,

        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,
        changeViewModeSearch: state.document.rootState.viewModeSearch
    }
}

const  mapDispatchToProps = {
    changeSortDirection,
    getDocumentsForTreePackages,
    handlerSearchPackages,
    PackageCreateTree,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    setLoaderModalData,
    searchByParamsPackages,
    SelectActivePackageNode,
    setContextMenuData
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMainTablePackageTh);
// export {ContextMenuMainTablePackageTh}

