import {PACKAGES_TABLE_ACTIONS, PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {store} from "../../../index";
import {PackagesViewFilters, TableTypesEnum} from "../../../tools/StaticTypes";
import {__} from "../../../tools/HelpFunctions";
import {
    getDocumentsForTreePackages,
    getPackageGroupsForTree,
    GetPackageTypes,
    SelectActivePackageNode
} from "./package_TreeActionCreator";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {API} from "../../../tools/API_NEW/API";


// изменить отображение таблиц в пакетах
export const changePackagesTablesViewMode = ({Table = "main", ViewMode, IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_MODE_PACKAGE_TABLES, payload : {
                Table,
                ViewMode,
                IsDownload : IsDownload
            }});
            // следующее действие если есть цепочка
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения отображения таблиц пакетов (catch): ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// переключить страницу пагинации
export const changePaginationPageInPackages = ({PageNumber, TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_PAGINATION_PAGE, payload : {
                PageNumber,
                tableType : TableType,
                IsDownload : IsDownload
            }});
            dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT, payload : {
                    activeDocument : null,
                    tableType : TableType,
                    IsDownload : IsDownload
                }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка переключения страницы пагинации в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// изменить фильтр предпросмотра в пакетах
// Type -> documents/packages
// TableType -> main/additional
export const changeViewFilter = ({Type, TableType = "main", IsDownload = false, Next = null, Error = null, lStoreValue = null}) => {
    return async dispatch => {
        try {
            let storeValue;
            if (lStoreValue)
                storeValue = lStoreValue
            else if(IsDownload || TableType === "download") {
                storeValue = store.getState().packages.treeDownload.viewFilter;
            }
            else {
                storeValue = TableType === "main"
                    ? store.getState().packages.tree.viewFilter
                    : store.getState().packages.treeAdditional.viewFilter;
            }



            let newValue = __.deepCopy(storeValue);
            if(storeValue === PackagesViewFilters.All) {
                newValue = Type === "packages"
                    ? PackagesViewFilters.OnlyDocuments
                    : PackagesViewFilters.OnlyPackages;
            }
            else  if(storeValue === PackagesViewFilters.OnlyPackages) {
                newValue = Type === "packages"
                    ? PackagesViewFilters.OnlyPackages
                    : PackagesViewFilters.All;
            }
            else if(storeValue === PackagesViewFilters.OnlyDocuments) {
                newValue = Type === "documents"
                    ? PackagesViewFilters.OnlyDocuments
                    : PackagesViewFilters.All
            }

            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_FILTER, payload : {
                newValue,
                IsDownload,
                tableType : TableType,

            }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка переключения фильтрации просмотра в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}


// изменить способ сортировки: по возрастанию или убыванию
export const changeSortDirection = ({dir, sortField, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_SORT_DIRECTION, payload : {dir, sortField}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// изменения кол-ва отображаемых элементов на странице в пакетах
export const setPaginationPageSizeInPackages = ({Count, TableType = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_COUNT_ITEM_ON_PAGE, payload : {
                    Count : Number.parseInt(Count),
                    tableType : TableType
            }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// TODO: переделать в норм вид работает хз как вообще
// обновление дерева из полученных данных
export const PackagesPaginationMovePage = ({PageNumber = 1, FieldSorting = "", Direction = 0, TableType = "main", IsDownload = false,fromBreadcrumbs=false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            await dispatch(changePaginationPageInPackages({PageNumber, TableType, IsDownload}));
            let PageSize;
            let ViewFilter;

            if(IsDownload || TableType === "download") {
                PageSize = store.getState().packages.treeDownload.paginationPageSize;
                ViewFilter = store.getState().packages.treeDownload.viewFilter;
            }
            else {
                PageSize =
                    TableType === "main"
                    ? store.getState().packages.tree.paginationPageSize
                    : (TableType === "additional" ? store.getState().packages.treeAdditional.paginationPageSize : store.getState().packages.treeDownload.paginationPageSize);
                ViewFilter =
                    TableType === "main"
                    ? store.getState().packages.tree.viewFilter
                    : (TableType === "additional" ? store.getState().packages.treeAdditional.viewFilter : store.getState().packages.treeDownload.viewFilter);
            }

            let activeNode;
            if(IsDownload || TableType === "download") {
                activeNode = store.getState().packages.treeDownload.activeNode;
            }
            else {
                activeNode = TableType === "main"
                    ? store.getState().packages.tree.activeNode
                    : (TableType === "additional" ? store.getState().packages.treeAdditional.activeNode : store.getState().packages.treeDownload.activeNode);
            }

            if (!activeNode)
                return;

            const params = store.getState().packages.search.SortedParams


            if (!FieldSorting || !Direction )
                for (const [key, value] of Object.entries(params)) {
                    if (value) {
                        FieldSorting = key
                        Direction = value
                    }
                }

            if(IsDownload || TableType === "download"){
                await dispatch(getPackageGroupsForTree({
                    FieldSorting,
                    Direction,
                    IdParent: activeNode.Node.Id,
                    PageSize: PageSize,
                    PageOffset: (PageNumber - 1) * PageSize,
                    IsDownload : IsDownload,
                    IdPackageType : 1,
                    TableType:TableType
                }));
            }else{
                await dispatch(getPackageGroupsForTree({
                    FieldSorting,
                    Direction,
                    IdParent: activeNode.Node.Id,
                    PageSize,
                    PageOffset: (PageNumber - 1) * PageSize,
                    IsDownload : IsDownload,
                    TableType:TableType
                }));
            }



            let lastLoad;
            if(IsDownload || TableType === "download") {
                lastLoad = __.deepCopy(store.getState().packages.treeDownload.lastLoad);

                let test;
                if(PageSize * (PageNumber - 1) === 0) {
                    test = [];
                }
                else {
                    test = __.dropArrayLeft(lastLoad.folders, PageSize * (PageNumber - 1))
                }
                // let test2 = __.dropArrayRight(test, test.length - PageSize);

                // if (PageNumber===1) {
                //     lastLoad.folders.pop(lastLoad.folders.length-PageSize)
                // } else {
                //     lastLoad.folders.shift(PageSize*(PageNumber-1)).pop(lastLoad.folders.length-PageSize)
                // }
               lastLoad = {...lastLoad, folders: test}
            }
            else {
                lastLoad = TableType === "main"
                    ? __.deepCopy(store.getState().packages.tree.lastLoad)
                    : __.deepCopy(store.getState().packages.treeAdditional.lastLoad);

                let test;
                if(PageSize * (PageNumber - 1) === 0) {
                    test = [];
                }
                else {
                    test = __.dropArrayLeft(lastLoad.folders, PageSize * (PageNumber - 1))
                }


                //
                // let test2 = __.dropArrayRight(test, test.length - PageSize);
                //

                // if (PageNumber === 1) {
                //     lastLoad.folders.pop(lastLoad.folders.length - PageSize)
                // } else {
                //     lastLoad.folders.shift(PageSize * (PageNumber - 1)).pop(lastLoad.folders.length - PageSize)
                // }
                lastLoad = {...lastLoad, folders: test}
            }



            // let PageSizeDocs = 0;
            // if(lastLoad.folders.length < PageSize) {
            //     PageSizeDocs = PageSize - lastLoad.folders.length;
            // }
            //
            // let offsetFactor = 0;
            // if(PageSizeDocs === PageSize) {
            //     offsetFactor = Math.floor(lastLoad.foldersTotal % PageSize);
            // }
            //
            // let docsPage = lastLoad.foldersTotal / PageSize >= 1
            //     ? PageNumber - Math.floor(lastLoad.foldersTotal / PageSize)
            //     : PageNumber;
            //
            // let docsOffset = (docsPage - 1) * PageSize - (offsetFactor);
            // if(docsOffset < 0) docsOffset = 0;


            let PageSizeDocs = 0;
            //TODO aa алгоритм пагинации  Задача № 19697 - Пустые страницы в Пакете
            // if(lastLoad.folders.length < PageSize) {
            //     PageSizeDocs = PageSize - lastLoad.folders.length;
            // }
            if(lastLoad.folders.length < PageSize) {
                PageSizeDocs = PageSize - lastLoad.folders.length;
            }

            let offsetFactor = 0;
            if(PageSizeDocs === PageSize) {
                offsetFactor = Math.floor(lastLoad.foldersTotal % PageSize);
            }

            let docsPage = lastLoad.foldersTotal / PageSize >= 1
                ? PageNumber - Math.floor(lastLoad.foldersTotal / PageSize)
                : PageNumber;

            //TODO aa  алгоритм пагинации Задача № 19697 - Пустые страницы в Пакете
            // let docsOffset = (docsPage - 1) * PageSize - (offsetFactor);
            // let docsOffset = docsPage;
            let docsOffset = (docsPage - 1) * PageSize - (offsetFactor);


            //aa 10.10.2022 алгоритм следующий
            //1. Есть число пакетов
            //2. Оределяем для них номер крайней страницы и сколько на ней занято
            //3. Если на этом номере страницы есть свободное место, то получаем нужное число документов

            let lPageNumForFolder = (lastLoad.foldersTotal / PageSize) >= 1
                // ? (Math.round(lastLoad.foldersTotal / PageSize) + Math.floor(lastLoad.foldersTotal % PageSize))
                ? (Math.floor(lastLoad.foldersTotal / PageSize) + ((lastLoad.foldersTotal % PageSize) > 0 ? 1 : 0))
                : (lastLoad.foldersTotal > 0 ? 1 : 0);

            let lFreeForDoc = 0;
            if ( Math.floor(lastLoad.foldersTotal % PageSize) != 0)
                lFreeForDoc = PageSize - Math.floor(lastLoad.foldersTotal % PageSize);
            if (ViewFilter === PackagesViewFilters.OnlyDocuments || lastLoad.foldersTotal == 0) {
                lPageNumForFolder = 1;
                lFreeForDoc = PageSize;
            }

            if (lPageNumForFolder === PageNumber || (PageNumber == 1 && lPageNumForFolder == 0))
            {
                docsOffset = 0;
                PageSizeDocs = lFreeForDoc;
            }
            else
                if (PageNumber > lPageNumForFolder)
                {
                    const lCountPages = PageNumber - lPageNumForFolder;

                    if (lPageNumForFolder == 0)
                        lFreeForDoc = 0;
                    docsOffset =   (lFreeForDoc + (lCountPages - 1) * PageSize);
                    PageSizeDocs = PageSize;
                }
                else
                {
                    docsOffset = -1;
                    // PageSizeDocs = 1;
                    //TODO 02.02.2023 обход Ошибка получения документов в пакете (post):
                    //ExceptionName: 'BusinessLogicException', ExceptionTime: '2023-02-02T11:20:16', Message: 'Смещение страницы не может быть отрицательным'
                    PageSizeDocs = 0;
                }


                // debugger;

            //TODO aa 02.02.2023 новый сервер не дает запрос с -1, см комментарий выше
            if(docsOffset < 0) docsOffset = 0;


            await dispatch(getDocumentsForTreePackages({
                FieldSorting,
                Direction,
                Id: activeNode.Node.Id,
                PageSize: PageSizeDocs,
                PageOffset: docsOffset,
                TableType,
                IsDownload : IsDownload
            }));

            await dispatch(GetPackageTypes({idPackage: activeNode.Node.Id, TableType, IsDownload}));

            let tree;

            //TODO Задача № 20067. Если уже есть бредкрамсы, то дерево заново не строим, иначе получится ситуация, когда в дочерних узлах нет данных
            if (!fromBreadcrumbs) {
                if(TableType === "download") {
                    tree =  __.deepCopy(store.getState().packages.treeDownload);
                }
                else {
                    tree = TableType === "main"
                        ? __.deepCopy(store.getState().packages.tree)
                        : __.deepCopy(store.getState().packages.treeAdditional)
                }
                if (tree?.breadcrumbs?.find(lBreadcrumbs => lBreadcrumbs.PrevId === activeNode.Node.Id))
                    //У этого бредкрамса уже есть загруженные дети и он не подлежит перепостроению!
                    fromBreadcrumbs = true;
            }

            if(fromBreadcrumbs){
                if(IsDownload || (tree = TableType === TableTypesEnum.Download)) {
                    tree = store.getState().packages.treeDownload.render;
                }
                else {
                    tree = TableType === TableTypesEnum.Main
                        ? __.deepCopy(store.getState().packages.tree.render)
                        : __.deepCopy(store.getState().packages.treeAdditional.render)
                }
            }else{
                tree = new TreeCombinePackage(true, TableType, IsDownload).update(activeNode.Node.Id).get();
            }

            await dispatch({
                type: PACKAGES_TREE_ACTIONS.CREATE, payload: {
                    tree: tree,
                    tableType: TableType,
                    IsDownload : IsDownload
                }
            });
            await dispatch(SelectActivePackageNode({Id: activeNode.Node.Id, TableType, IsDownload}));

            if (Next) {
                Next.action(Next.params);
            } // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка обновления дерева документов (catch) ", exception);

            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}


export const setViewModePreviewPackages = ({ViewMode = "none", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_VIEW_MODE_PREVIEW, payload : {ViewMode : ViewMode}});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

export const setPreviewSizePackages = ({Size = "normal", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let stateSize = store.getState().packages.rootState.viewModePreview_Size;

            let saveSize = Size;
            if(stateSize === "normal") {
                saveSize = Size;
            }
            else if(stateSize === "half") {
                if(Size === "half") saveSize = "normal";
                if(Size === "full") saveSize = Size;
            }
            else if(stateSize === "full") {
                if(Size === "half") saveSize = Size;
                if(Size === "full") saveSize = "normal";
            }


            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_SIZE_PREVIEW, payload : {Size : saveSize}});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}


// скопировать данные для дерева документов
export const copyDocumentTreeForPackages = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.COPY_DOCUMENT_TREE, payload : {
                tree : __.deepCopy(store.getState().document.tree)
            }});
            // следующее действие если есть цепочка
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Копирования дерева документов в пакеты (catch): ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}




// сортировки для пагинации
// export const sortingPackagesForPagination = ({TableType = "main", Next = null, Error = null}) => {
//     return async dispatch => {
//         try {
//
//             let activeTreeNode = TableType === "main"
//                 ? __.deepCopy(store.getState().packages.tree.activeNode)
//                 : __.deepCopy(store.getState().packages.treeAdditional.activeNode);
//
//
//             let viewFilter = TableType === "main"
//                 ? store.getState().packages.tree.viewFilter
//                 : store.getState().packages.treeAdditional.viewFilter;
//
//
//
//
//
//             dispatch({type : PACKAGES_TABLE_ACTIONS.SORTING_ACTIVE_NODE_FOR_PAGINATION, payload : {
//                  //   Count : Number.parseInt(Count),
//                     tableType : TableType
//             }});
//             if(Next) Next.action(Next.params);
//         } catch (exception) {
//             if(Error) Error.action(Error.params);
//         }
//     }
// }

export const exportExcel = ({idPackage, isDocuments = false,  Columns = null,  ReportName = "Report", Next = null, Error = null})=>{
        return async dispatch => {
            try {

                let Filial = store.getState().globalState.filial.Active;
                let ValueFilter = new Filter(FiltersType.ENTITY)
                    .addAttribute({Name: "IdPackage", Value: idPackage})
                    .add(
                        new EntityFilters().setEntityName("DocInPackage")
                            .add({Name: "IdFilial", Value: Filial.Id}).get()
                    ).get();
                ValueFilter = JSON.stringify(ValueFilter);

                let filter = new Filter(FiltersType.SIMPLE)
                    .add({Name : "Filter", Value : {
                        TypeName : "DocPackageRestrictFilter",
                        Value : ValueFilter
                    }});


                let sorting = new Sorting()
                    .add({PropertyName: "RegDate", Direction: 2})
                    .add({PropertyName: "RegNumber", Direction: 1})
                    .add({PropertyName: "Id", Direction: 1})
                    .get();

                sorting = JSON.stringify(sorting);

               let ReportColumns = [];
                ReportColumns.push({
                    ColumnName: "Дата",
                    Format: "dd.MM.yyyy",
                    PropertyName: "RegDate"
                });

                ReportColumns.push({
                    ColumnName: "Номер",
                    PropertyName: "RegNumber"
                });

                ReportColumns.push({
                    ColumnName: "Наименование",
                    PropertyName: "Name"
                });

                ReportColumns.push({
                    ColumnName: "Тип документа",
                    PropertyName: "Type.Name"
                });


               let parameters = {
                   EntityName : "DocInPackage",
                   ReportName : ReportName,
                   ReportColumns,
                   Filter : filter.get().Filter,
                   Sorting : {
                       TypeName : "DocSorting",
                       Value : sorting
                   }
               }


                let reportGuid = await API.reports().createEntityReport({parameters});

               if(reportGuid?.errorCode) {
                   return;
               }



            } catch (exception){
                console.log("Ошибка сортировки для пагинации в пакетах", exception);
                 if(Error) Error.action(Error.params);
            }
        }
}





