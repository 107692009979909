import React from 'react';
import HistoryDocuments from "../history/HistoryDocuments";
import {connect} from "react-redux";

// Компонент приветствующий пользователя при первом запуске приложения
export class DocumentsWelcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        let mod = this.props.theme.modString;
        let documentHistory = this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig"));
        return (
            <div className="row pt-4" style={{paddingLeft : "15px", paddingRight : "15px", justifyContent : "center"}}>
                <div className="col-lg-12" style={{padding: "0"}}>
                    <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                         style={{height: "350px",backgroundImage: `url('./images/docWelcome${mod}.svg')`}}>
                        <div className="card-body d-flex" style={{padding: "4rem 3.25rem"}}>
                            <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                <div className="flex-grow-1">
                                    <span className="text-welcome font-weight-bolder font-size-h1"><b>Документы</b></span>
                                    <p className="text-welcome font-weight-bold mt-3">
                                        В нем документы распределены по реестрам и доступны как для просмотра,<br/> так и для детального взаимодействия в режиме "Карточки документа".
                                    </p>
                                </div>
                                {/*<Link to={"/help"} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                {/*   style={{borderRadius: "0.8em"}}>Справка*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    documentHistory ?
                        <HistoryDocuments/>
                        :
                        <>
                            <div className="col-lg-12 row pt-4 text-center" style={{margin : "0 auto"}}>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div className="card card-custom gutter-b card-stretch">
                                        <div className="card-body pt-4">
                                            <div className="d-flex flex-center mt-6 mb-7">
                                                {/*TODO SS исправить отображение иконки для красной темы*/}
                                                <img src={`/images/loadDoc${mod}.svg`} width={'90px'} alt=""/>
                                            </div>
                                            <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Загрузка документов
                                    </span>
                                            </div>
                                            <p className="mb-12">При наличии прав, вы можете добавить новые документы с помощью Мастера загрузки</p>
                                            {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                            {/*      style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                            {/*    Загрузка*/}
                                            {/*</span>*/}
                                            {/*<p className="mb-6 font-size-sm">*/}
                                            {/*    Выберите файл в формате <br/>*/}
                                            {/*    .doc, .docx, .xls, .pdf не более 300 Мб*/}
                                            {/*</p>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div className="card card-custom gutter-b card-stretch">
                                        <div className="card-body pt-4">
                                            <div className="d-flex flex-center mt-6 mb-7">
                                                {/*<img src={`/images/syst_doc_${mod}.svg`} alt=""/>*/}
                                                <img src={`/images/systDoc${mod}.svg`} alt=""/>
                                            </div>
                                            <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Систематизация
                                    </span>
                                            </div>
                                            <p className="mb-12">Документы распределены в реестры для удобного взаимодействия и сортируются по фильтрам</p>
                                            {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                            {/*    style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                            {/*    Документы*/}
                                            {/*</span>*/}
                                            {/*<p className="mb-6 font-size-sm">Функциональная таблица поможет в работе с тысячами документов</p>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div className="card card-custom gutter-b card-stretch">
                                        <div className="card-body pt-4">
                                            <div className="d-flex flex-center mt-6 mb-7">
                                                <img src={`/images/detailDoc${mod}.svg`} alt=""/>
                                            </div>
                                            <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Детализация
                                    </span>
                                            </div>
                                            <p className="mb-12">В "Карточке" вы можете узнать подробности о каждом загруженном документе</p>
                                            {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                            {/*    style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                            {/*    Справка*/}
                                            {/*</span>*/}
                                            {/*<p className="mb-6 font-size-sm">Это не займет более 30 минут и поможет лучше узнать программу</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        userSettings: state.globalState.userSettings,
        theme: state.globalState.theme,

    }
}

const  mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentsWelcome);
