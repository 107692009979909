import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../tools/StaticTypes";
import {setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";


// modal.data.content => обязательно
class SignaturesInfoModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : false,
        };

        this.modalName = ModalTypes.app.info;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
    }

    // скрывать по кнопке
    hide() {
        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {
            this.props.setModalData({});
        }
    }

    render() {
        if (!this.props.modal.data.signature)
          return null;
        const certificateInfo = JSON.parse(this.props.modal.data.signature.CertificateInfo)
        let signatureInfo;

        if(!certificateInfo) return null;
       signatureInfo = [
           {
               name: "Уникальный номер сертификата:",
               value: certificateInfo.SerialNumber
           },
           {
               name: "Данные владельца:",
               value: certificateInfo.Subject
           },
           {
               name: "Данные удостоверяющего центра:",
               value: certificateInfo.Issurer
           },
           {
               name: "Алгоритм электронной подписи:",
               value: certificateInfo.SignatureAlgorithm
           },
           {
               name: "Дата выдачи сертификата:",
               value: new Date(certificateInfo.NotBefore).toLocaleString()
           },
           {
               name: "Дата прекращения действия сертификата:",
               value: new Date(certificateInfo.NotAfter).toLocaleString()
           },
           {
               name: "Дата и время подписания:",
               value: new Date(certificateInfo.SigningTime).toLocaleString()
           },
       ];


        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };


        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                     onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                     onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                    <div className="modal-content" style={{borderRadius:"25px"}}>
                        {
                            this.props.modal.data.type === "success" &&
                            <div>
                                <div className="modal-body align-content-center text-center">
                                    <p className="lead font-weight-bold"><span style={{fontWeight: "bold"}}>Информация об электронной подписи</span></p>
                                    <br/>
                                    {
                                        signatureInfo.map(
                                            (item, index) => {
                                                return (
                                                    <div style={{marginBottom: 15}}>
                                                        <span style={{fontWeight: "bold"}}>{item.name}</span>
                                                        <br/>
                                                        <span style={{wordWrap: "break-word"}}>{item.value}</span>
                                                    </div>
                                                )
                                            }
                                        )
                                    }

                                </div>
                                <div className="modal-footer  align-content-center text-center justify-content-center">
                                    <button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Окей!</button>
                                </div>
                            </div>
                        }
                        {
                            this.props.modal.data.type === "fail" &&
                            <div>

                                <div className="modal-body align-content-center text-center">
                                    <div className="d-flex flex-center mb-5">
                                        <img src="/images/unsuccessful.svg" alt=""/>
                                    </div>
                                    <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                                </div>
                                <div className="modal-footer  align-content-center text-center justify-content-center">
                                    <button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Закрыть</button>
                                </div>
                            </div>
                        }
                        {
                            this.props.modal.data.type === "question" &&
                            <div>

                                <div className="modal-body align-content-center text-center">
                                    <div className="d-flex flex-center mb-5">
                                        <img src="/images/question.svg" alt=""/>
                                    </div>
                                    <p className="lead font-weight-bold">{parse(sanitizeHtml(this.props.modal.data.content))}</p>
                                </div>
                                <div className="modal-footer">
                                    {/*<button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Продолжить</button>*/}
                                    {/*<button type="button" className="btn btn-lg btn-light-success" onClick={this.hide}>Закрыть</button>*/}

                                    <button type="button" className="btn btn-primary font-weight-bolder" onClick={() => this.props.modal.data.onClickContinue()}>Продолжить</button>
                                    <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>Закрыть</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        modal : state.globalState.app.modal
    }
}

const  mapDispatchToProps = {
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesInfoModal);
