import {DOCUMENT_SEARCH_ACTIONS} from "../documentActionsList";
import {store} from "../../../index";
import {__} from "../../../tools/HelpFunctions";


export const changeViewMode = ({clearFilters=null,isSearch=null,Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let inputs
            if(clearFilters){
                inputs= __.deepCopy(store.getState().document.search.searchInputs);

                for(let input of inputs){
                    input.Value=""
                }

                dispatch({type: DOCUMENT_SEARCH_ACTIONS.CHANGE_INPUTS, payload: inputs});
            }

            dispatch({type: DOCUMENT_SEARCH_ACTIONS.CHANGE_VIEW_MODE, payload: isSearch});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const changeDocumentSearchInputs = ({
                                               TH=false,
                                               Direction=null,
                                               DirectionUp="icon-color-gray icon-Filter_up",
                                               Delete = false,
                                               DirectionDown="icon-color-gray icon-Filter_down",
                                               Attribute = null,
                                               Value="",
                                               Name = "",
                                               isConstant = false ,
                                               IdAttributeType = null,
                                               isFocus=false,
                                               UserVisible = true,
                                               IsShowExtFilters = false,
                                               IsApplied = false,
                                               Next = null,
                                               Error = null}) =>
{
    return async dispatch => {
        try {
            let searchInputs;
            if(Delete) {
                dispatch({type: DOCUMENT_SEARCH_ACTIONS.CHANGE_INPUTS, payload: []});
                if(Next) {Next.action(Next.params);}
                return;
            }
            else {
                searchInputs = __.deepCopy(store.getState().document.search.searchInputs);
            }

            // const searchInputsWithSort = store.getState().document.search.searchInputs;


            if(TH){
                //console.log("TH")
                Attribute= __.deepCopy(Attribute);
                //console.log("AttributeTH",Attribute)
                if(Attribute.Name==="RegNumber"){
                    Attribute="Номер"
                }else if(Attribute.Name==="Name"){
                    Attribute="Наименование"
                }else if(Attribute.Name==="RegDate"){
                    Attribute="Дата"
                }else if(Attribute.Name==="Version.ModifyTime"){
                    Attribute="Последнее изменение"
                }
            }

            if(Direction!==null){
                if(Direction.indexOf('icon-Filter_up')>=0){

                    DirectionUp="icon-color-original icon-Filter_up";
                    DirectionDown="icon-color-gray icon-Filter_down";
                }else {

                    DirectionUp="icon-color-gray icon-Filter_up";
                    DirectionDown="icon-color-original icon-Filter_down";
                }

            }

            let foundIndex;
            if(TH) {
                foundIndex = isConstant
                    ? searchInputs.findIndex(s => s.Attribute === Attribute)
                    : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);
                // console.log("foundIndex1",foundIndex)
            } else {

                foundIndex = isConstant
                    ? searchInputs.findIndex(s => s.Attribute === Attribute)
                    : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);
                // console.log("foundIndex2",foundIndex)
            }

            if(isFocus) searchInputs = searchInputs.map(item => ({...item, isFocus: false}));

            if(!Object.values(Value).find(Boolean)) {
                if(foundIndex < 0) {
                    for(let i = 0; i < searchInputs.length; i++) {
                        // const inputWithSort = searchInputsWithSort.find(item => item.Attribute === searchInputs[i].Attribute);
                        //TODO убраны лишние стрелки
                        if (i !== foundIndex) {
                            searchInputs[i].DirectionUp = "icon-color-gray icon-Filter_up";
                            searchInputs[i].DirectionDown = "icon-color-gray icon-Filter_down";
                        }
                    }
                    searchInputs.push({
                        Attribute,
                        Value,
                        Name,
                        isConstant,
                        isFocus,
                        DirectionUp,
                        DirectionDown,
                        UserVisible,
                        IsApplied,
                        IdAttributeType,
                        IsShowExtFilters
                    });
                    //console.log("searchInputs1111",searchInputs)
                }
                else {
                    for(let i = 0; i < searchInputs.length; i++) {
                        if(i !== foundIndex){
                            searchInputs[i].DirectionUp = "icon-color-gray icon-Filter_up";
                            searchInputs[i].DirectionDown = "icon-color-gray icon-Filter_down";
                            searchInputs[i].UserVisible = UserVisible;
                        } else {
                            searchInputs[foundIndex].DirectionUp = DirectionUp;
                            searchInputs[foundIndex].DirectionDown = DirectionDown;
                            searchInputs[foundIndex].UserVisible = UserVisible;
                            searchInputs[foundIndex].Value = Direction === null
                                ? ""
                                : searchInputs[foundIndex].Value;
                            searchInputs[foundIndex].IsShowExtFilters = IsShowExtFilters;
                            searchInputs[foundIndex].IsApplied = IsApplied;
                            searchInputs[foundIndex].isFocus = isFocus;
                        }

                    }


                }
            }else{
                if(foundIndex < 0) {
                    searchInputs.push({
                        Attribute,
                        Value,
                        Name,
                        isConstant,
                        isFocus,
                        DirectionUp,
                        DirectionDown,
                        UserVisible,
                        IdAttributeType,
                        IsShowExtFilters,
                        IsApplied
                    });
                    //console.log("searchInputs222",searchInputs)
                }
                else {

                    // console.log('searchInputs', searchInputs[foundIndex]);
                    searchInputs[foundIndex].Value = Value;
                    searchInputs[foundIndex].DirectionUp = DirectionUp;
                    searchInputs[foundIndex].DirectionDown = DirectionDown;
                    searchInputs[foundIndex].UserVisible = UserVisible;
                    searchInputs[foundIndex].IsShowExtFilters = IsShowExtFilters;
                    searchInputs[foundIndex].IsApplied = IsApplied;
                    searchInputs[foundIndex].isFocus = isFocus;
                }
            }

            dispatch({type: DOCUMENT_SEARCH_ACTIONS.CHANGE_INPUTS, payload: searchInputs});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



export const changeDocumentSearchInputsContextMenu = ({Attribute, Value, isConstant = false ,isFocus=false,Next = null, Error = null}) => {
    return async dispatch => {
        try {


            let lValue
            let searchInputs = __.deepCopy(store.getState().document.search.searchInputs);
            if(isConstant){
                if(Attribute.Name=="Name"){
                    Attribute="Наименование"
                    lValue = {ApproximatelyEqual: Value ?? '', BeginningFrom: '', EndingOn: '', Equal: ''}
                }else if(Attribute.Name=="RegNumber"){
                    Attribute="Номер"
                    lValue = {ApproximatelyEqual: Value ?? '', BeginningFrom: '', EndingOn: '', Equal: ''}
                }else if(Attribute.Name=="RegDate"){
                    Attribute="Дата"
                    // Value = Value.split('T')[0];
                    // let  year= Value.split('-')[0];
                    // let month= Value.split('-')[1];
                    // let day=Value.split('-')[2];
                    // Value=day+'.'+month+'.'+year;
                    Value = Value.split("T")[0].split("-").reverse().join(".")
                    lValue = {ApproximatelyEqual: Value ?? '', OnDate: '', DateStart: '', DateEnd: ''}
                }
                else if(Attribute.Name=="Version.ModifyTime"){
                    Attribute="Последнее изменение"
                    // Value = Value.split('T')[0];
                    // let  year= Value.split('-')[0];
                    // let month= Value.split('-')[1];
                    // let day=Value.split('-')[2];
                    // Value=day+'.'+month+'.'+year;
                    Value = Value.split("T")[0].split("-").reverse().join(".")
                    lValue = {ApproximatelyEqual: Value ?? '', OnDate: '', DateStart: '', DateEnd: ''}
                }
            }

            let foundIndex = isConstant
                ? searchInputs.findIndex(s => s.Attribute === Attribute)
                : searchInputs.findIndex(s => s.Attribute.IdAttributeName === Attribute.IdAttributeName);

            if(foundIndex < 0) {
                searchInputs.push({
                    Attribute,
                    Value: lValue,
                    isConstant,
                    isFocus,
                    IsApplied: true
                });
            }
            else {
                searchInputs[foundIndex].Value = {ApproximatelyEqual: Value ?? '', BeginningFrom: '', EndingOn: '', Equal: ''};
                searchInputs[foundIndex].IsApplied = true
            }

            dispatch({type: DOCUMENT_SEARCH_ACTIONS.CHANGE_INPUTS, payload: searchInputs});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}




