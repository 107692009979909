import React from "react";
import {connect} from "react-redux";
import ContextMenuMainTableTr from "./ContextMenuMainTableTr";
import {AdminLevelEnum, contextMenuTypes} from "../../../tools/StaticTypes";
import ContextMenuMainTableTh from "./ContextMainTableTh";
import ContextMenuMainTableThPackagesDocumentsRight from "./ContextMainTableThPackagesDocumentsRight";
import ContextMenuMainTableTrPackagesDocumentRight from "./ContextMenuMainTableTrPackagesDocumentRight";
import ContextMenuTreePackages from "./ContextMenuTreePackages";
import ContextMainTablePackageTh from "./ContextMainTablePackageTh";
import ContextMenuSearchTabs from "./ContextMenuSearchTabs";

class ContextMenu extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };
    }


    render() {
        switch (this.props.contextMenu.name) {
            case contextMenuTypes.MainTableTR:
                return <ContextMenuMainTableTr/>;
            case  contextMenuTypes.MainTableTH:
                return <ContextMenuMainTableTh/>;
            case  contextMenuTypes.MainTablePackageTH:
                return <ContextMainTablePackageTh/>;
            case  contextMenuTypes.MainTableTH_PackagesDocumentRight:
                return <ContextMenuMainTableThPackagesDocumentsRight/>;
            case  contextMenuTypes.MainTableTR_PackagesDocumentRight:
                return <ContextMenuMainTableTrPackagesDocumentRight/>;
            case contextMenuTypes.TreePackages:
                if(this.props.adminLevel >= AdminLevelEnum.Verifier){
                    return <ContextMenuTreePackages />;
                } else {
                    return null
                }
            case contextMenuTypes.SearchTabs:
                return <ContextMenuSearchTabs/>;
            default: return null;
        }
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu : state.globalState.app.contextMenu,
        adminLevel: state.globalState.user.AdminLevel
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
