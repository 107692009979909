
// Данные об атрибуте документа
export class AttributeName {

    // number
    Id;
    // number
    IdAttributeType;
    // boolean
    IsSystem;
    // string
    Name;

    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.IdAttributeType = DataFromServer.IdAttributeType;
        this.IsSystem = DataFromServer.IsSystem;
        this.Name = DataFromServer.Name;
    }
}