import React from "react";
import {connect} from "react-redux";
class ContextMenuItem extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };
    }

    render() {
        const {Name, Icon, OnClick } = this.props;
        return (
            <li className="context-menu__item" onClick={(ev) => {OnClick(ev)}} style={{cursor: "pointer"}}>
                <span className="context-menu__link">
                    {Icon &&
                        <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-2">
                            <i className={`svg-icon svg-icon-sm text-primary ${Icon}`}> </i>
                        </span>
                    }
                    {Name}
                </span>
            </li>
        );
    }
}

const  mapStateToProps = state => {
    return {
    }
}

const  mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuItem);
