import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {checkAllRowsOnPage_packagesDocumentRight} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import ThMainTableDocumentsRight from "./ThMainTableDocumentsRight";
import {__} from "../../../tools/HelpFunctions";
import {AdminLevelEnum} from "../../../tools/StaticTypes";

class TheadDocumentsRight extends  React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.setItemSize = this.setItemSize.bind(this);
    }

    // выделение или снятие всех галок
    checkboxOnChange() {
        this.props.checkAllRowsOnPage_packagesDocumentRight({});
    }

    setItemSize = (lItem, lSize) => {
        //проверка от зацикливания
        if (lItem)
            lItem.Size = lSize;
    }
    render() {
        let {
            header,
            body,
            checkedFields,
        } = this.props;

        // let header = __.deepCopy(this.props.header)
        if(!header || header.length <= 0) {
            return  null;
        }

        let countIsDelete = body.filter(item => item.Info.IsMarkedForDelete);
        countIsDelete = countIsDelete?.length ? countIsDelete.length : 0;

        let checked = checkedFields.length + countIsDelete >= body.length;

        return (
            <thead>
            <tr className="text-uppercase tablez1" >
                <th className="py-0" style={{width:"35px"}}>
                    {this.props.adminLevel > AdminLevelEnum.CommonUser &&
                        <label className="checkbox checkbox-lg checkbox-inline mr-2" >
                            <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>
                            <span className={"row-checked"}> </span>
                        </label>
                    }
                </th>
                {
                    header.map((item, index) => {
                        item = __.deepCopy(item)
                        return <ThMainTableDocumentsRight
                            setWidth = {(itemSize) => this.props.setWidth(itemSize, item)}
                            setItemSize={(ItemSize) => {
                                this.setItemSize(item, ItemSize)
                            }}
                            key={item.Value + "-" + createUniqueIdString(3)}
                            data={item}/>
                    })
                }
            </tr>
            </thead>
        );
    }
}


const  mapStateToProps = state => {
    return {
        // header : state.packages.mainTable.header,
        adminLevel : state.globalState.user.AdminLevel,
        body :  state.packages.mainTable.body,
        checkedFields : state.packages.mainTable.checkedFields,
    }
}

const  mapDispatchToProps = {
    checkAllRowsOnPage_packagesDocumentRight,
}

export default connect(mapStateToProps,mapDispatchToProps)(TheadDocumentsRight);
