import React from "react";
import {connect} from "react-redux";
import TrMainTableDocumentsRight from "./TrMainTableDocumentsRight";



class TbodyDocumentsRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        if(!this.props.body || this.props.body.length <= 0) {
            return  null;
        }
        return (
            <tbody>
            {
                this.props.body.map((item, index) => {
                    return (
                        <TrMainTableDocumentsRight key={item.Version.IdDoc} data={item}/>
                    );
                })
            }
            </tbody>
        );
    }
}


const  mapStateToProps = state => {
    return {
        body : state.packages.mainTable.body,
        temporaryData: state.packages.temporaryData
    }
}

const  mapDispatchToProps = {
}

export default connect(mapStateToProps,mapDispatchToProps)(TbodyDocumentsRight);
