import {store} from "../index";
import {EntityFilters, Filter, FiltersType, Sorting} from "./Filters";
import {API} from "./API_NEW/API";
import {API_REPORT_DOWNLOAD_REPORT_RESPONSE, IReportState} from "./API_NEW/ServerTypes";
import {__} from "./HelpFunctions";
import {Operators, TableTypesEnum} from "./StaticTypes";


export default class EntityReport {

    idObject : number | null;
    Type : "packages" | "documents" | "download" | "search";
    Columns : any[] | null;
    ReportName : string | "Report";

    ReportGuid : string | null;
    ReadyStatus : IReportState;

    constructor(params : {idObject : number | null, Type : "packages" | "documents" | "download" | "search",  Columns : any[] | null,  ReportName : string | "Report"}) {
        this.Columns = params.Columns;
        this.idObject = params.idObject;
        this.Type = params.Type;
        this.ReportName = params.ReportName;

        this.ReportGuid = null;
        this.ReadyStatus = IReportState.None;
    }

    public async create(TableType = TableTypesEnum.Main) : Promise<string> {
        if(this.Type === "documents") {
            return await this.createForDocuments();
        }
        else if(this.Type === "packages"){
            return await this.createForPackages(TableType);
        }
        else if(this.Type === "download"){
            return await this.createForDownload();
        }
        else{ // search
            return await this.createForSearch();
        }
    }

    private async createForDownload(): Promise<string> {
        let Filial = store.getState().globalState.filial.Active;
        let ValueFilter = new Filter(FiltersType.ENTITY)
            .add(
                new EntityFilters().setEntityName("PackageDocRef")
                    .addAttribute({Name : "ReferenceProperty", Value : "IdDoc"})
                    .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                    // @ts-ignore
                    .add({Name: "IdPackage", Value: this.idObject})
                    .get()
            )
            .add(
                new EntityFilters().setEntityName("DocWithPackageRefs")
                    // @ts-ignore
                    .add({Name: "IdFilial", Value: Filial.Id})
                    .get()
            )
            .get();
        let ValueFilterJson: string = JSON.stringify(ValueFilter);

        let sorting = new Sorting()
            .add({PropertyName: "Type.Name", Direction: 1})
            .add({PropertyName: "RegDate", Direction: 2})
            .add({PropertyName: "RegNumber", Direction: 1})
            .add({PropertyName: "Id", Direction: 1})
            .get();
        let sortingJson = JSON.stringify(sorting);

        let ReportColumns = [];
        ReportColumns.push({
            ColumnName: "Дата",
            Format: "dd.MM.yyyy",
            PropertyName: "RegDate"
        });

        ReportColumns.push({
            ColumnName: "Номер",
            PropertyName: "RegNumber"
        });

        ReportColumns.push({
            ColumnName: "Наименование",
            PropertyName: "Name"
        });

        ReportColumns.push({
            ColumnName: "Тип документа",
            PropertyName: "Type.Name"
        });

        let checkedFieldsFilter = null;
        let checkedFields = store.getState().packages.mainTable.checkedFields;
        if(checkedFields.length > 0) {
            checkedFieldsFilter = new Filter(FiltersType.ENTITY)
                 .addAttribute({Name: "IdPackage", Value: this.idObject});

            let checkedFieldsEntity = new EntityFilters().setEntityName("DocExtended")
                // @ts-ignore
                .add({Name: "Type.Id", Value: this.idObject})
                // @ts-ignore
                .add({Name: "IdFilial", Value: Filial.Id})


            checkedFieldsEntity.addList({
                Name: "Version.IdDoc",
                Operator: 8,
                Value: checkedFields
            });

            checkedFieldsFilter.add(checkedFieldsEntity.get());
            // @ts-ignore
            checkedFieldsFilter = JSON.stringify(checkedFieldsFilter.get());
        }
        let parameters = {
            EntityName: "DocWithPackageRefs",
            ReportName: this.ReportName,
            ReportColumns,
            // @ts-ignore
            Filter: {
                TypeName: "BaseFilter",
                Value: checkedFields.length > 0 ? checkedFieldsFilter : ValueFilterJson
               // Value:  ValueFilterJson
            },
            Sorting: {
                TypeName: "DocSorting",
                Value: sortingJson
            }
        }

        let reportGuid = await API.reports().createEntityReport({parameters});

        if (reportGuid?.errorCode) {
            throw reportGuid.Message;
        }

        this.ReportGuid = reportGuid;
        return reportGuid;
    }

    private async createForSearch(): Promise<string> {
        const activeTab = store.getState().search.rootState.activeTab;
        const state = store.getState().search.searchState.searchInputsList.find(input => input.Id === activeTab.Id);
        // @ts-ignore
        let activeFilialId = store.getState().globalState.filial.Active.Id;

        let searchParams: any = {};
        searchParams.FilialIds = [activeFilialId];
        // @ts-ignore
        searchParams.SearchText = state.SearchText;
        // @ts-ignore
        searchParams.AttributesText = state.AttributesText;
        // @ts-ignore
        searchParams.FullText = state.FullText;
        // @ts-ignore
        searchParams.IsSearchInAttachments = state.IsSearchInAttachments;
        // @ts-ignore
        searchParams.IsSearchInVersions = state.IsSearchInVersions;
        // @ts-ignore
        searchParams.IsSearchInMarkedForDelete = state.IsSearchInMarkedForDelete;
        // @ts-ignore
        searchParams.TypeIds = state.TypeIds;
        // @ts-ignore
        searchParams.RegDateAsString = state.RegDateAsString;
        // @ts-ignore
        searchParams.RegPeriodFinish = state.RegPeriodFinish;
        // @ts-ignore
        searchParams.RegPeriodStart = state.RegPeriodStart;
        // @ts-ignore
        searchParams.Name = state.Name;
        // @ts-ignore
        searchParams.RegNumber = state.RegNumber;
        // @ts-ignore
        searchParams.VersionNumber = state.VersionNumber;

        const mainTable = store.getState().search.searchState.searchResults.find(item => item.Id === activeTab.Id)?.mainTable;

        let checkedFieldsFilter = null;
        let checkedFields = mainTable?.checkedFields;
        if(checkedFields.length > 0) {
            checkedFieldsFilter = new Filter(FiltersType.ENTITY);

            let checkedFieldsEntity = new EntityFilters().setEntityName("DocExtended")
                // @ts-ignore
                // .add({Name: "Type.Id", Value: this.idObject})
                // @ts-ignore
                .add({Name: "IdFilial", Value: activeFilialId})


            checkedFieldsEntity.addList({
                Name: "Version.IdDoc",
                Operator: 8,
                Value: checkedFields
            });

            checkedFieldsFilter.add(checkedFieldsEntity.get());
            // @ts-ignore
            checkedFieldsFilter = JSON.stringify(checkedFieldsFilter.get());
        }

        let reportGuid;
        if(checkedFields.length > 0) {
            let tableHeader = mainTable?.header;
            let ReportColumns = [];

            // @ts-ignore
            if(tableHeader.find(item => item.Name === "RegDate").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Дата",
                    Format: "dd.MM.yyyy",
                    PropertyName: "RegDate"
                });
            }

            // @ts-ignore
            if(tableHeader.find(item => item.Name === "RegNumber").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Номер",
                    PropertyName: "RegNumber"
                });
            }

            // @ts-ignore
            if(tableHeader.find(item => item.Name === "Name").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Наименование",
                    PropertyName: "Name"
                });
            }

            // @ts-ignore
            if(tableHeader.find(item => item.Name === "IdFilial").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Филиал",
                    PropertyName: "IdFilial"
                });
            }

            // @ts-ignore
            if(tableHeader.find(item => item.Name === "TypeName").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Тип",
                    PropertyName: "Type.Name"
                });
            }
            // @ts-ignore
            if(tableHeader.find(item => item.Name === "Version.ModifyTime").UserVisible) {
                ReportColumns.push({
                    ColumnName: "Последнее изменение",
                    PropertyName: "Version.ModifyTime"
                });
            }
            let parameters = {
                EntityName: "DocExtended",
                ReportName: this.ReportName,
                ReportColumns,
                // @ts-ignore
                Filter: {
                    TypeName: "DocGroupRestrictFilter",
                    Value: checkedFieldsFilter
                }
            }
            reportGuid = await API.reports().createEntityReport({parameters});
        }
        else {
            reportGuid = await API.reports().createSearchResultReport({searchParams: searchParams});
        }

        if (reportGuid?.errorCode) {
            throw reportGuid.Message;
        }

        this.ReportGuid = reportGuid;
        return reportGuid;
    }

    private async createForPackages(TableType: string): Promise<string> {
        let Filial = store.getState().globalState?.filial?.Active;
        let UserFilter = {};
        switch (TableType) {
            case "main": UserFilter = store.getState().packages?.tree?.searchInputs; break;
            case "additional": UserFilter = store.getState().packages?.treeAdditional?.searchInputs; break;
            case "download": UserFilter = store.getState().packages?.treeDownload?.searchInputs; break;
        }

        let UserFilterList = []
        for (let [key, value] of Object.entries(UserFilter)) {
            if ((key === ('Name') && value !== '' && value !== null )) {
                UserFilterList.push({
                    key: key,
                    value: value
                })
            }
            if (key === ('RegNumber') && value !== '' && value !== null  ) {
                UserFilterList.push({
                    key: key,
                    value: value
                })
            }
            if (key === ('TypeName') && value !== '' && value !== null  ) {
                UserFilterList.push({
                    key: 'Type.Name',
                    value: value
                })
            }
            if ((key === ('ModifyDate')  && value !== '' && value !== null )) {
                UserFilterList.push({
                    key: 'Version.ModifyTime',
                    value: value
                })
            }
            if ((key === ('RegDateAsString')) && value !== '' && value !== null  ) {
                UserFilterList.push({
                    key: 'RegDate',
                    value: value
                })
            }
        }
        // @ts-ignore
        let entityList = []
        for (const test1Element of UserFilterList) {
           if (typeof test1Element.value === 'string') {
               entityList.push({key: test1Element.key, operator: 2, value: test1Element.value})
           } else {

               // @ts-ignore
               for (let [key, value] of Object.entries(test1Element.value) ) {
                   if (key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                       entityList.push({key: test1Element.key, operator: 2, value: value})
                   } else if (key === 'BeginningFrom' && value !== '' && value !== null) {
                       entityList.push({key: test1Element.key, operator: 12, value: value})
                   } else if (key === 'EndingOn' && value !== '' && value !== null) {
                       entityList.push({key: test1Element.key, operator: 14, value: value})
                   } else if (key === 'Equal' && value !== '' && value !== null) {
                       entityList.push({key: test1Element.key, operator: 0, value: value})
                   }
                   else if (key === 'LessOrEqual' && value !== '' && value !== null) {
                       entityList.push({ key: test1Element.key, operator: 4, value: value})
                   }
                   else if (key === 'MoreOrEqual' && value !== '' && value !== null) {
                       entityList.push({ key: test1Element.key, operator: 5, value: value})
                   }
                   else if (key === 'OnDate' && value !== '' && value !== null) {
                       entityList.push({ key: test1Element.key, operator: 5, value: value + 'T00:00:00'});
                       entityList.push({ key: test1Element.key, operator: 6, value: value + 'T23:59:59'});
                        //TODO Зачем???
                       // entityList.push({ key: test1Element.key, operator: 2, value: __.dateToRussia(value)})
                   }
                   else if (key === 'DateStart' && value !== '' && value !== null) {
                       entityList.push({ key: test1Element.key, operator: 5, value: value + 'T00:00:00'})
                   //     TODO: MS 22.03
                   }
                   else if (key === 'DateEnd' && value !== '' && value !== null) {
                       entityList.push({ key: test1Element.key, operator: 4, value: value + 'T00:00:00'})
                   }
               }
           }
        }

        let checkedFieldsFilter = null;
        let checkedFields = store.getState().packages.mainTable.checkedFields;
        if(checkedFields.length > 0) {
            checkedFieldsFilter = new Filter(FiltersType.ENTITY).addAttribute({Name: "IdPackage", Value: this.idObject + ""});

            let checkedFieldsEntity = new EntityFilters().setEntityName("DocInPackage").add({Name: "IdFilial", Value: Filial.Id, Operator: 0})
                // @ts-ignore
                .add({Name: "IdFilial", Value: Filial.Id})


            checkedFieldsEntity.addList({
                Name: "Version.IdDoc",
                Operator: 8,
                Value: checkedFields
            });

            checkedFieldsFilter.add(checkedFieldsEntity.get());
            // @ts-ignore
            checkedFieldsFilter = JSON.stringify(checkedFieldsFilter.get());
        }

        // @ts-ignore
        let finalRequest;
        let ValueFilter = new Filter(FiltersType.ENTITY)
        ValueFilter.addAttribute({Name: "IdPackage", Value: this.idObject + ""})

        finalRequest =  new EntityFilters().setEntityName("DocInPackage").add({Name: "IdFilial", Value: Filial.Id, Operator: 0})
        for (let i = 0; i < entityList.length; i++) {
            finalRequest.add({Name: entityList[i].key, Value: entityList[i].value, Operator: entityList[i].operator})
        }
        ValueFilter.add(finalRequest.get())
        ValueFilter.get();
        console.log('ValueFilter',ValueFilter)

        let ValueFilterJson: string = JSON.stringify(ValueFilter._filter);
        let filter = new Filter(FiltersType.SIMPLE)
            .add({
                Name: "Filter", Value: {
                    TypeName: "DocPackageRestrictFilter",
                    Value: checkedFields.length > 0 ? checkedFieldsFilter : ValueFilterJson
                }
            }).get();
        let sorting = new Sorting()
            .add({PropertyName: "RegDate", Direction: 2})
            .add({PropertyName: "RegNumber", Direction: 1})
            .add({PropertyName: "Id", Direction: 1})
            .get();
        let sortingJson = JSON.stringify(sorting);

        let ReportColumns = [];
        ReportColumns.push({
            ColumnName: "Дата",
            Format: "dd.MM.yyyy",
            PropertyName: "RegDate"
        });

        ReportColumns.push({
            ColumnName: "Номер",
            PropertyName: "RegNumber"
        });

        ReportColumns.push({
            ColumnName: "Наименование",
            PropertyName: "Name"
        });

        ReportColumns.push({
            ColumnName: "Тип документа",
            PropertyName: "Type.Name"
        });


        let parameters = {
            EntityName: "DocInPackage",
            ReportName: this.ReportName,
            ReportColumns,
            // @ts-ignore
            Filter: filter.Filter,
            Sorting: {
                TypeName: "DocSorting",
                Value: sortingJson
            }
        }

        let reportGuid = await API.reports().createEntityReport({parameters});

        if (reportGuid?.errorCode) {
            return "";
        }

        this.ReportGuid = reportGuid;
        return reportGuid;
    }

    private async createForDocuments() : Promise<string> {
        let Filial = store.getState().globalState.filial.Active;
        let IdDocGroup = store.getState().router?.location?.query?.g
        if(!IdDocGroup){
            // @ts-ignore
            IdDocGroup = store.getState().document.tree.group.find(ground => ground.GroupTypeRefs.findIndex(item => item.IdDocType === this.idObject) !== -1).Id;
        }
        let tableHeader = store.getState().document.mainTable.header;
        let Filters = store.getState().document.search.searchInputs;
        let ValueFilterJson = '';

        let ValueFilter = new Filter(FiltersType.ENTITY)
            .addAttribute({Name: "IdDocGroup", Value: IdDocGroup})
            .add(
                new EntityFilters().setEntityName("DocExtended")
                    // @ts-ignore
                    .add({Name: "Type.Id", Value: this.idObject})
                    // @ts-ignore
                    .add({Name: "IdFilial", Value: Filial.Id})
                    .get()
            );
        if (Filters && Filters.length > 0) {
            // @ts-ignore
            if (tableHeader.find(item => item.Name === "RegDate").UserVisible) {
                // @ts-ignore
                let filterValue = Filters.find(item => item.Attribute === "Дата");
                // @ts-ignore
                let complexFilter = [];
                if (typeof filterValue.Value !== "string") {
                    for (let [key, value] of Object.entries(filterValue.Value)) {
                        if (key === 'OnDate' && value !== '' && value !== null) {
                            complexFilter.push({operator: 0, value: value})

                        } else if (key === 'DateStart' && value !== '' && value !== null) {
                            complexFilter.push({operator: 5, value: value})

                        } else if (key === 'DateEnd' && value !== '' && value !== null) {
                            complexFilter.push({operator: 4, value: value})
                        }
                        // todo tp № 22889
                        else if(key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                            complexFilter.push({operator: Operators.Like, value: value})
                        }
                    }

                    if (complexFilter.length > 0) {
                        let buf = new EntityFilters().setEntityName("DocExtended")
                        for (let i = 0; i < complexFilter.length; i++) {
                            if(complexFilter[i].operator === Operators.Like) {
                                buf.add({
                                    Name: "RegDate",
                                    Value: complexFilter[i].value,
                                    Operator: complexFilter[i].operator
                                })
                            } else {
                                buf.add({
                                    Name: "RegDate",
                                    Value: complexFilter[i].value + 'T00:00:00',
                                    Operator: complexFilter[i].operator
                                })
                            }

                        }
                        // @ts-ignore
                        ValueFilter.add(buf.get());
                    }
                } else {
                    if (filterValue && filterValue?.Value && filterValue?.Value !== "") {
                        ValueFilter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                // @ts-ignore
                                .add({Name: "RegDate", Value: filterValue.Value, Operator: 2})
                                .get()
                        );
                    }
                }

            }

        // @ts-ignore
            if (tableHeader.find(item => item.Name === "RegNumber").UserVisible) {
                // @ts-ignore
                let filterValue = Filters.find(item => item.Attribute === "Номер");
                // @ts-ignore
                let complexFilter = [];
                if (typeof filterValue.Value !== "string") {
                    for (let [key, value] of Object.entries(filterValue.Value)) {
                        if (key === 'Equal' && value !== '' && value !== null) {
                            complexFilter.push({operator: 0, value: value})

                        } else if (key === 'BeginningFrom' && value !== '' && value !== null) {
                            complexFilter.push({operator: 12, value: value})

                        } else if (key === 'EndingOn' && value !== '' && value !== null) {
                            complexFilter.push({operator: 14, value: value})

                        } else if (key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                            complexFilter.push({operator: 2, value: value})

                        }
                    }

                    if (complexFilter.length > 0) {
                        let buf = new EntityFilters().setEntityName("DocExtended")
                        for (let i = 0; i < complexFilter.length; i++) {
                            buf.add({
                                Name: "RegNumber",
                                Value: complexFilter[i].value,
                                Operator: complexFilter[i].operator
                            })
                        }
                        // @ts-ignore
                        ValueFilter.add(buf.get());
                    }
                } else {
                    if (filterValue && filterValue?.Value && filterValue?.Value !== "") {
                        ValueFilter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                // @ts-ignore
                                .add({Name: "RegNumber", Value: filterValue.Value, Operator: 2})
                                .get()
                        );
                    }
                }

            }

            // @ts-ignore
            if (tableHeader.find(item => item.Name === "Name").UserVisible) {
                // @ts-ignore
                let filterValue = Filters.find(item => item.Attribute === "Наименование");
                let complexFilter = [];
                if (typeof filterValue.Value !== "string") {
                    for (let [key, value] of Object.entries(filterValue.Value)) {
                        if (key === 'Equal' && value !== '' && value !== null) {
                            complexFilter.push({operator: 0, value: value})

                        } else if (key === 'BeginningFrom' && value !== '' && value !== null) {
                            complexFilter.push({operator: 12, value: value})

                        } else if (key === 'EndingOn' && value !== '' && value !== null) {
                            complexFilter.push({operator: 14, value: value})

                        } else if (key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                            complexFilter.push({operator: 2, value: value})

                        }
                    }

                    if (complexFilter.length > 0) {
                        let buf = new EntityFilters().setEntityName("DocExtended")
                        for (let i = 0; i < complexFilter.length; i++) {
                            buf.add({Name: "Name", Value: complexFilter[i].value, Operator: complexFilter[i].operator})
                        }
                        // @ts-ignore
                        ValueFilter.add(buf.get());
                    }
                } else {
                    if (filterValue && filterValue?.Value && filterValue?.Value !== "") {
                        ValueFilter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                // @ts-ignore
                                .add({Name: "Name", Value: filterValue.Value, Operator: 2})
                                .get()
                        );
                    }
                }

            }

            // @ts-ignore
            if (tableHeader.find(item => item.Name === "Version.ModifyTime").UserVisible) {
                // @ts-ignore
                let filterValue = Filters.find(item => item.Attribute === "Последнее изменение");
                let complexFilter = [];
                if (typeof filterValue.Value !== "string") {
                    // TODO: MS 22.03: протестировал, все работает.
                    for (let [key, value] of Object.entries(filterValue.Value)) {
                        if (key === 'Equal' && value !== '' && value !== null) {
                            complexFilter.push({operator: 0, value: value})

                        } else if (key === 'BeginningFrom' && value !== '' && value !== null) {
                            complexFilter.push({operator: 12, value: value})

                        } else if (key === 'EndingOn' && value !== '' && value !== null) {
                            complexFilter.push({operator: 14, value: value})

                        } else if (key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                            complexFilter.push({operator: 2, value: value})

                        } else if (key === 'OnDate' && value !== '' && value !== null) {
                            complexFilter.push({operator: 5, value:  __.dateToServer(__.dateToRussia(value))})

                        } else if (key === 'DateStart' && value !== '' && value !== null) {
                            complexFilter.push({operator: 5, value:  __.dateToServer(__.dateToRussia(value))})

                        } else if (key === 'DateEnd' && value !== '' && value !== null) {
                            complexFilter.push({operator: 6, value: __.dateToServer(__.dateToRussia(value))})
                        }
                    }

                    if (complexFilter.length > 0) {
                        let buf = new EntityFilters().setEntityName("DocExtended")
                        for (let i = 0; i < complexFilter.length; i++) {
                            buf.add({
                                Name: "Version.ModifyTime",
                                Value: complexFilter[i].value,
                                Operator: complexFilter[i].operator
                            })
                        }
                        // @ts-ignore
                        ValueFilter.add(buf.get());
                    }
                } else {
                    if (filterValue && filterValue?.Value && filterValue?.Value !== "") {
                        ValueFilter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                // @ts-ignore
                                .add({Name: "Version.ModifyTime", Value: filterValue.Value, Operator: 2})
                                .get()
                        );
                    }
                }

            }




        for (let i = 0; i < Filters.length; i++) {
            // @ts-ignore
            if (!Filters[i].isConstant) {
                // @ts-ignore
                if (Filters[i].Attribute.UserVisible) {

                    let complexFilter = []
                    if (typeof Filters[i].Value !== 'string') {
                        // @ts-ignore
                        for (let [key, value] of Object.entries(Filters[i].Value)) {
                            if (key === 'ApproximatelyEqual' && value !== '' && value !== null) {
                                complexFilter.push({operator: 2, value: value})

                            } else if (key === 'BeginningFrom' && value !== '' && value !== null) {
                                complexFilter.push({operator: 12, value: value})

                            } else if (key === 'EndingOn' && value !== '' && value !== null) {
                                complexFilter.push({operator: 14, value: value})

                            } else if (key === 'Equal' && value !== '' && value !== null) {
                                complexFilter.push({operator: 0, value: value})

                            } else if (key === 'LessOrEqual' && value !== '' && value !== null) {
                                complexFilter.push({operator: 4, value: value})

                            } else if (key === 'MoreOrEqual' && value !== '' && value !== null) {
                                complexFilter.push({operator: 5, value: value})

                            } else if (key === 'OnDate' && value !== '' && value !== null) {
                                complexFilter.push({operator: 0, value: value})

                            } else if (key === 'DateStart' && value !== '' && value !== null) {
                                complexFilter.push({operator: 5, value: value})

                            } else if (key === 'DateEnd' && value !== '' && value !== null) {
                                complexFilter.push({operator: 4, value: value})

                            }
                        }

                        // todo tp №22889 зачем добавлять атрибуты, если значения пустые??
                        if(complexFilter.length > 0) {
                            let buf = new EntityFilters().setEntityName("DocAttribute")
                            buf.addAttribute({Name: "ReferenceProperty", Value: "IdDoc"})
                            buf.addAttribute({Name: "RootEntityProperty", Value: "Id"})
                            // @ts-ignore
                            // buf.add({Name: "AttributeName.Id", Value: Filters[i].Attribute.IdAttributeName})
                            buf.add({Name: "AttributeName.Id", Value: Filters[i].Attribute.IdAttributeName})
                            for (let j = 0; j < complexFilter.length; j++) {
                                buf.add({
                                    Name: "AttributeValue",
                                    Value: complexFilter[j].value,
                                    Operator: complexFilter[j].operator
                                })
                            }
                            ValueFilter.add(buf.get())
                        }
                    } else {
                        // @ts-ignore
                        if (Filters[i].Value && Filters[i].Value !== "") {
                            ValueFilter.add(
                                new EntityFilters().setEntityName("DocAttribute")
                                    // @ts-ignore
                                    .addAttribute({Name: "ReferenceProperty", Value: "IdDoc"})
                                    .addAttribute({Name: "RootEntityProperty", Value: "Id"})
                                    // @ts-ignore
                                    .add({
                                        Name: "AttributeName.Id",
                                        Value: Filters[i].Attribute.IdAttributeName
                                    })
                                    // @ts-ignore
                                    .add({Name: "AttributeValue", Value: Filters[i].Value, Operator: 2})
                                    .get()
                            );
                        }
                    }

                }

            }
        }

     }
        ValueFilterJson = JSON.stringify(ValueFilter.get());
        let sorting = new Sorting()
            .add({PropertyName: "RegDate", Direction: 2})
            .add({PropertyName: "RegNumber", Direction: 1})
            .add({PropertyName: "Id", Direction: 1})
            // @ts-ignore
            .addAttribute({Name : "IdAttributeName", Value : -1})
            .get();
        let sortingJson = JSON.stringify(sorting);

        let ReportColumns = [];

        // @ts-ignore
        if(tableHeader.find(item => item.Name === "RegDate").UserVisible) {
            ReportColumns.push({
                ColumnName: "Дата",
                Format: "dd.MM.yyyy",
                PropertyName: "RegDate"
            });
        }

        // @ts-ignore
        if(tableHeader.find(item => item.Name === "RegNumber").UserVisible) {
            ReportColumns.push({
                ColumnName: "Номер",
                PropertyName: "RegNumber"
            });
        }

        // @ts-ignore
        if(tableHeader.find(item => item.Name === "Name").UserVisible) {
            ReportColumns.push({
                ColumnName: "Наименование",
                PropertyName: "Name"
            });
        }

        ReportColumns.push({
            ColumnName: "Тип документа",
            PropertyName: "Type.Name"
        });

        // @ts-ignore
        for (let i = 0; i < tableHeader.length; i++) {
            // @ts-ignore
            if(!tableHeader[i].IsConstant) {
                // @ts-ignore
                if(tableHeader[i].UserVisible) {
                    ReportColumns.push({
                        // @ts-ignore
                        ColumnName: tableHeader[i].Value,
                        PropertyName: "Attributes",
                        CollectionElementPropertyName : "Value",
                        CollectionElementKeyName : "AttributeName.Id",
                        // @ts-ignore
                        CollectionElementKeyValue : tableHeader[i].IdAttributeName.toString(),
                        // @ts-ignore
                        Format: tableHeader[i].IdAttributeType === 5 ?  "dd.MM.yyyy" : undefined,
                    });
                }
            }
        }


        let checkedFieldsFilter = null;
        let checkedFields = store.getState().document.mainTable.checkedFields;
        if(checkedFields.length > 0) {
            checkedFieldsFilter = new Filter(FiltersType.ENTITY).addAttribute({Name: "IdDocGroup", Value: IdDocGroup});

            let checkedFieldsEntity = new EntityFilters().setEntityName("DocExtended")
                // @ts-ignore
                .add({Name: "Type.Id", Value: this.idObject})
                // @ts-ignore
                .add({Name: "IdFilial", Value: Filial.Id})


            checkedFieldsEntity.addList({
                Name: "Version.IdDoc",
                Operator: 8,
                Value: checkedFields
            });

            checkedFieldsFilter.add(checkedFieldsEntity.get());
            // @ts-ignore
            checkedFieldsFilter = JSON.stringify(checkedFieldsFilter.get());
        }



        let parameters = {
            EntityName: "DocExtended",
            ReportName: this.ReportName,
            ReportColumns,
            // @ts-ignore
            Filter: {
                TypeName: "DocGroupRestrictFilter",
                Value: checkedFields.length > 0 ? checkedFieldsFilter : ValueFilterJson
            },
            Sorting: {
                TypeName: "DocSorting",
                Value: sortingJson
            }
        }

        let reportGuid = await API.reports().createEntityReport({parameters});

        if (reportGuid?.errorCode) {
            throw reportGuid.Message;
        }

        this.ReportGuid = reportGuid;
        return reportGuid;
    }

    public async getState() : Promise<number> {
        if(this.ReportGuid) {
            let reportState = await API.reports().getReportState({
                reportGuid : this.ReportGuid
            });
            if(reportState?.errorCode) {
                throw reportState.Message;
            }

            if(reportState.State == IReportState.Error || reportState.State == IReportState.Stop) {
                throw new Error("Ошибка во время создания отчета")
            }

            this.ReadyStatus = reportState.State;
            return reportState.State;
        }
        else throw new Error("Сначала необходимо создать репорт")
    }

    public async getReportFile() : Promise<API_REPORT_DOWNLOAD_REPORT_RESPONSE> {
        if(this.ReportGuid == null || this.ReadyStatus == null || this.ReadyStatus != IReportState.Done) {
            throw new Error("Произведены не все действия для загрузки репорта")
        }

        let report = await API.reports().downloadReport({
            reportGuid : this.ReportGuid
        });

        if(report?.errorCode) {
            throw report.Message;
        }

        return report;
    }


}
