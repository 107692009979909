import React, {createRef} from "react";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {MyTooltip} from "../../overPage/tooltip/MyTooltip";
import {
    breadcrumbsAdd,
    breadcrumbsMoveNext,
    breadcrumbsMovePrev,
    PackageAddNodesTree,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    breadcrumbsMoveSelect,
    togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    changePackagesTablesViewMode,
    changeViewFilter, exportExcel, PackagesPaginationMovePage, setViewModePreviewPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    AdminLevelEnum,
    ModalTypes,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    TableTypesEnum,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import {changePackagesViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";
import {
    handlerIsSearch,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import EntityReport from "../../../tools/EntityReport";
import {IReportState} from "../../../tools/API_NEW/ServerTypes";
import {__} from "../../../tools/HelpFunctions";
import {history, store} from "../../../index";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {Routing} from "../../../tools/routing/Routing";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import DropdownItem from "react-bootstrap/DropdownItem";
import {ActionQueue} from "../../../store/rootReducer";
import {
    checkAllRowsOnPage_MainTable,
    checkAllRowsOnPage_packagesDocumentRight, deleteRowFromChecked_MainTable,
    deleteRowFromChecked_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {apiRequestNew} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {AUTH_ACTIONS} from "../../../store/globalState/globalStateActionsList";

class MainTableSearchPackage extends React.Component {
    resizeObserver = null;
    resizeElement = createRef();
    constructor(props) {
        super(props);

        this.lFlagCopyUrl1C = this.props.globalSettings.WithCopyUrl1C === "1";

        this.fitInContainerSize = React.createRef();
        this.state = {
            applyFilterTooltipShow : false,
            cancelFilterTooltipShow : false,
            timeFilterTooltipShow : false,
            exportXlsTooltipShow : false,
            deleteDocTooltipShow : false,
            updateTableTooltipShow : false,
            tableViewTooltipShow : false,
            bigViewTooltipShow : false,
            middleViewTooltipShow : false,
            additionalViewTooltipShow : false,
            movePrevViewTooltipShow : false,
            moveNextViewTooltipShow : false,
            plusPackagesTooltipShow : false,

            fitInContainerSizeTooltipShow : false,

            packagesViewFilterTooltipShow : false,
            documentsViewFilterTooltipShow : false,
            isFitInContainerSize : false,

            deleteDocumentTooltipShow : false,

            //для дропдауна(скрыть/показать) при изменении ширины
            CurrentWidth: 1_000_000,
            UpdateCounter: 0,
            Menu: {
                isShowRight: true,
                isShowCenter: true,
                isShowLeft: true,
                DeletingSizes: {
                    Center: null,
                    CenterBlock: null,
                    Left: null,
                    LeftBlock: null,
                    Right: null,
                    RightBlock: null
                }
            }
        }

        this.applyFilterRef = React.createRef();
        this.cancelFilterRef = React.createRef();
        this.timeFilterRef = React.createRef();
        this.exportXlsRef = React.createRef();
        this.deleteDocumentRef = React.createRef();
        this.deleteDocRef = React.createRef();
        this.updateTableRef = React.createRef();
        this.tableViewRef = React.createRef();
        this.bigViewRef = React.createRef();
        this.middleViewRef = React.createRef();
        this.additionalViewRef = React.createRef();
        this.movePrevViewRef = React.createRef();
        this.moveNextViewRef = React.createRef();
        this.plusPackagesRef = React.createRef();

        this.packagesViewFilterRef = React.createRef();
        this.documentsViewFilterRef = React.createRef();

        this.copyUrlRef = React.createRef();
        this.copyUrl1CRef = React.createRef();

        this.movePrev = this.movePrev.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.changeViewMode = this.changeViewMode.bind(this);
        this.changeViewFilter = this.changeViewFilter.bind(this);
        this.toggleTwoTables = this.toggleTwoTables.bind(this);
        this.search = this.search.bind(this);
        this.PlusPackage = this.PlusPackage.bind(this);
        this.deletePackage = this.deletePackage.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.updateNamePackage = this.updateNamePackage.bind(this);
        this.exportXLS = this.exportXLS.bind(this);

        this.viewModeBtns = this.viewModeBtns.bind(this);
        this.filtersBtns = this.filtersBtns.bind(this);
        this.togglePacketsAndDocs = this.togglePacketsAndDocs.bind(this);
        this.setButtons = this.setButtons.bind(this);
        this.hideNextItem = this.hideNextItem.bind(this);
        this.showNextItem = this.showNextItem.bind(this);
        this.addDropdownItems = this.addDropdownItems.bind(this);

        this.loadPackages = this.loadPackages.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.copyUrl = this.copyUrl.bind(this);
        this.getCheckedFieldsView = this.getCheckedFieldsView.bind(this);
        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.getActiveRowPageNumber = this.getActiveRowPageNumber.bind(this);
        this.getSearchInputs = this.getSearchInputs.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tableRole === "main" && this.props.activeNode !== prevProps.activeNode)
            store.dispatch(deleteRowFromChecked_MainTable({isClear: true}))

        if (this.props.tableRole !== prevProps.tableRole
        || (this.props.tableRole === "download" && this.props.activeNodeDownload !== prevProps.activeNodeDownload)
        || (this.props.tableRole === "additional" && this.props.activeNodeAdditional?.Node.Id !== prevProps.activeNodeAdditional?.Node.Id)
        )
            store.dispatch(deleteRowFromChecked_packagesDocumentRight({isClear: true}))
    }

    componentWillUnmount() {
        store.dispatch(deleteRowFromChecked_packagesDocumentRight({isClear: true}))
        store.dispatch(deleteRowFromChecked_MainTable({isClear: true}))
    }

    checkboxOnChange() {
        let docs = this.props.body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
        let viewFilter
        if (this.props.tableRole === TableTypesEnum.Main)
            viewFilter = this.props.viewFilter
        else if (this.props.tableRole === TableTypesEnum.Additional)
            viewFilter = this.props.viewFilterAdditional
        else if (this.props.tableRole === TableTypesEnum.Download)
            viewFilter = this.props.viewFilterDownload

        if (viewFilter !== "OnlyPackages"){
            if (this.props.tableRole === TableTypesEnum.Main) this.props.checkAllRowsOnPage_MainTable({Body: docs})
            else this.props.checkAllRowsOnPage_packagesDocumentRight({Body: docs});
        }
    }

    hideNextItem(Entry, DeletingSize, callback) {
        //types -  Entry:  ResizeObserverEntry, DeletingSize: number, callback: ()=>void
        let L = Entry.contentRect.width;

        let l1 = Entry.target.children[0].clientWidth ?? 0;
        let l2 = Entry.target.children[1] ? Entry.target.children[1].clientWidth : 0;
        let l3 = Entry.target.children[2] ? Entry.target.children[2].clientWidth : 0;
        let l4 = Entry.target.children[3] ? Entry.target.children[3].clientWidth : 0;
        let l5 = Entry.target.children[4] ? Entry.target.children[4].clientWidth : 0;

        if((L < (l1 + l2 + l3 + l4 + l5) * 1.1) && this.state.Menu.DeletingSizes.Right === null) {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowRight: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Right: DeletingSize, RightBlock: l4}}
            });
            callback();
        }
        if((L < (l1 + l2 + l3 + l4) * 1.1) && this.state.Menu.DeletingSizes.Center === null)  {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowCenter: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Center: DeletingSize, CenterBlock: l3}}
            });
           callback();
        }
        if ((L < (l1 + l2 + l3) * 1.1 ) && this.state.Menu.DeletingSizes.Left === null) {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowLeft: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Left: DeletingSize, LeftBlock: l2}}
            });

            callback();
        }
    }

    showNextItem(CurrentSize, containers, Entry, callback) {
        //types CurrentSize: number, containers:  Element[], Entry:  ResizeObserverEntry, callback: (isAll:boolean)=>void

        let L = Entry.contentRect.width;

        let l1 = Entry.target.children[0].clientWidth ?? 0;
        let l2 = Entry.target.children[1] ? Entry.target.children[1].clientWidth : 0;
        let l3 = Entry.target.children[2] ? Entry.target.children[2].clientWidth : 0;
        let l4 = Entry.target.children[3] ? Entry.target.children[3].clientWidth : 0;
        let l5 = Entry.target.children[4] ? Entry.target.children[4].clientWidth : 0;



        const {Menu: {DeletingSizes} } = this.state;

        if (L >= ((DeletingSizes.LeftBlock ?? l2) + 100) * 1.1 && DeletingSizes.Left !== null) {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowLeft: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Left: null}}
            });
            callback(false);
        }
        if(L >= (l1 + (DeletingSizes.LeftBlock ?? l2) +  (DeletingSizes.CenterBlock ?? l3) + 100) * 1.1 && DeletingSizes.Center !== null)  {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowCenter: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Center: null}}
            });
            callback(false);
        }
        if(L >= (l1 + (DeletingSizes.LeftBlock ?? l2) + (DeletingSizes.CenterBlock ?? l3) + (DeletingSizes.RightBlock ?? 100)) * 1.1 && DeletingSizes.Right !== null) {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowRight: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Right: null}}
            });
            callback(true);
        }
    }

    getActiveRowPageNumber() {
        let tree
        if (this.props.tableRole === "main")
            tree = store.getState().packages.tree
        else if (this.props.tableRole === "additional")
            tree = store.getState().packages.treeAdditional
        else if (this.props.tableRole === "download")
            tree = store.getState().packages.treeDownload

        const index = tree.lastLoad.documentsForTypes.map(item => item.Id == tree.activeRow).indexOf(true)
        return index > -1 ? Math.ceil((index + tree.lastLoad.foldersTotal) / tree.paginationPageSize) : null
    }
    componentDidMount() {
        //для дропдауна(скрыть/показать) при изменении ширины
        const Exec = (entries) => {
            for (let entry of entries) {

                let CurrentWidth = this.state.CurrentWidth;

                this.setState({ CurrentWidth: entry.contentRect.width}, ()=>{
                    // let isMinHeight = entry.contentRect.height > 50;
                    let containers = Array.from(entry.target.children);

                    let lastDeleteSize = 0;
                    if( this.state.UpdateCounter === 0) {
                        lastDeleteSize = 1_000_000;
                    } else {
                        if(this.state.Menu?.DeletingSizes.Right && !this.state.Menu.DeletingSizes.Center) {

                            lastDeleteSize = this.state.Menu?.DeletingSizes.Right;
                        }
                        else if(this.state.Menu?.DeletingSizes.Right && this.state.Menu.DeletingSizes.Center && !this.state.Menu.DeletingSizes.Left) {
                            lastDeleteSize = this.state.Menu?.DeletingSizes.Center;
                        }
                        else if(this.state.Menu?.DeletingSizes.Right && this.state.Menu.DeletingSizes.Center && this.state.Menu.DeletingSizes.Left){
                            lastDeleteSize = this.state.Menu?.DeletingSizes.Left;
                        }
                        else {
                            lastDeleteSize = 1_000_000;
                        }
                    }

                    let delta = Math.abs(lastDeleteSize -  entry.contentRect.width) / entry.contentRect.width;
                    if(delta > 0.03 || this.state.UpdateCounter === 0) {
                        if (CurrentWidth >= entry.contentRect.width) this.hideNextItem(entry, entry.contentRect.width, () => {
                            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
                        });

                        else if (CurrentWidth < entry.contentRect.width) this.showNextItem(entry.contentRect.width, containers, entry, (isAll) => {
                            if (isAll) {
                                this.setState({UpdateCounter: 0});
                            } else {
                                this.setState({UpdateCounter: this.state.UpdateCounter + 1});
                            }
                        });
                    }

                });
            }
        }

        if(this.state.Menu) {
            this.resizeObserver = new ResizeObserver((entries) => {
                Exec(entries);
            });
            this.resizeObserver.observe(this.resizeElement.current);

        }

       let index = this.props.userSettings.findIndex(allUserSettings => allUserSettings.Name === "packageTypeConfig")
       let indexAdditions = this.props.userSettings.findIndex(allUserSettings => allUserSettings.Name === "packageTypeConfigAdditional")
       if(index >= 0) {
           this.props.changePackagesTablesViewMode({
               Table: this.props.userSettings[index].Value[0].Table,
               ViewMode:this.props.userSettings[index].Value[0].ViewMode,
               IsDownload : this.props.tableRole === "download"
           });
       }
       if (indexAdditions >= 0){
           this.props.changePackagesTablesViewMode({
               Table: this.props.userSettings[indexAdditions].Value[0].Table,
               ViewMode:this.props.userSettings[indexAdditions].Value[0].ViewMode,
               IsDownload : this.props.tableRole === "download"
           });
       }
    }

    async search(event) {
        //TODO aa 20.03.2023 Иначе при втором вызове окно поиска закрывается
        //TODO DK 05.04.2023 если разкоментить то вот этот баг № 22828
        // store.dispatch({type : PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload : {
        //         searchNode : [],
        //         tableType : "main"
        //     }});

        //TODO 1. Открытие фильтров в доп таблице не должно открывать левую панель дерева пакетов (если до этого она была закрыта).
        // if (this.props.tableRole === "main")
            // if(this.props.asideMenuIsClose) {
            //     this.props.setStateLeftAsideMenu({ IsClose: false });
            // }
        if (event.target.id === "show") {
            if(this.props.tableRole === "main") {
                this.changeViewFilter("packages", PackagesViewFilters.All);
                if(this.props.asideMenuIsClose) {
                    this.props.setStateLeftAsideMenu({IsClose: false});
                }
            } else {

                if (this.props.userModalShow && this.props.userModalType === "treePackagesSearch") {
                    // this.changeViewFilter("packages", PackagesViewFilters.OnlyDocuments)
                    this.props.setUserModalShow({
                        isShow: false
                    });
                    //TODO - отжатие кнопки Не показывать пакеты
                    let lFlagSearch = false;

                    let lTree = store.getState().packages.treeAdditional;
                    if (this.props.userModalTableRole === "additional")
                        lTree = store.getState().packages.treeAdditional
                    else if (this.props.userModalTableRole === "download")
                        lTree = store.getState().packages.treeDownload

                    lFlagSearch = lTree.isSearch;
                    if (!lFlagSearch)
                        this.props.changeViewFilter({
                            Type: "packages",
                            TableType: this.props.userModalTableRole,
                            lStoreValue: PackagesViewFilters.OnlyDocuments,
                            IsDownload: this.props.userModalTableRole === "download"
                        });
                    return;
                } else {
                    this.changeViewFilter("packages", PackagesViewFilters.All)
                }
            }
        }
        else {
            //TODO 3. Кнопка "сбросить фильтр" выключает пакеты, необходима логика как в основной таблице
            // let lFlagSearch = false;
            // if (this.props.tableRole === "main")
            //         lFlagSearch = store.getState().packages.tree.isSearch
            //     else if (this.props.tableRole === "additional")
            //         lFlagSearch = store.getState().packages.treeAdditional.isSearch
            //     else if (this.props.tableRole === "download")
            //         lFlagSearch = store.getState().packages.treeDownload.isSearch
            //
            // if (lFlagSearch)
            this.changeViewFilter("packages", PackagesViewFilters.OnlyDocuments)
        }


        if (this.props.tableRole === "main") {
            if (event.target.id === "show") {
                let viewMode;
                if (this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Search && !this.props.asideMenuIsClose) {
                    viewMode = RootComponentsStateViewModeTypes.PackagesApp.Tree
                } else {
                    viewMode = RootComponentsStateViewModeTypes.PackagesApp.Search
                }

                this.props.changePackagesViewMode({
                    ViewMode: viewMode
                })
            } else {
                const searchInputs = this.getSearchInputs({TableRole: this.props.tableRole})

                let activeDocId
                if (this.props.tableRole === "main")
                    activeDocId = this.props.activeDocument?.Node.Id
                else if (this.props.tableRole === "additional")
                    activeDocId = this.props.activeDocumentAdd?.Node.Id
                else if (this.props.tableRole === "download")
                    activeDocId = this.props.activeDocumentDownload?.Node.Id

                const lPageNumber = this.getActiveRowPageNumber();

                if (Object.values(searchInputs.AppliedParams).filter(Boolean).length){
                    this.props.setLoaderModalData({
                        data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages", key: "MainTablePackage445"},
                        Next: {
                            action: () => {
                                this.props.changePackagesViewMode({
                                    ViewMode: RootComponentsStateViewModeTypes.PackagesApp.Tree,
                                    Next: {
                                        action: () => {
                                            this.props.PackageAddNodesTree({
                                                Id: this.props.activeNode.Node.Id,
                                                TableType: this.props.tableRole,
                                                Next: {
                                                    action: () => {
                                                        this.props.handlerIsSearch({
                                                            TableType: this.props.tableRole,
                                                            Next: {
                                                                action: () => {
                                                                    this.props.updateInputsFieldPackage({
                                                                        TableRole: this.props.tableRole,
                                                                        Next: {
                                                                            action: () => {
                                                                                this.props.updateInputsFieldSortingPackage({
                                                                                    TableRole: this.props.tableRole,
                                                                                    Next: {
                                                                                        action: () => {
                                                                                            this.props.SelectActivePackageNode({
                                                                                                Id: this.props.activeNode.Node.Id,
                                                                                                TableType: this.props.tableRole,
                                                                                                Next: {
                                                                                                    action:  async () => {
                                                                                                        await this.props.PackagesPaginationMovePage({PageNumber: lPageNumber ?? 1})
                                                                                                        if (activeDocId) this.props.SelectActivePackageDocument({Id: activeDocId})
                                                                                                        this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage479"});
                                                                                                    },
                                                                                                    params: {}
                                                                                                }
                                                                                            })
                                                                                        },
                                                                                        params: {}
                                                                                    }
                                                                                })
                                                                            },
                                                                            params: {}
                                                                        }
                                                                    })
                                                                },
                                                                params: {}
                                                            }
                                                        })
                                                    },
                                                    params: {}
                                                }
                                            })
                                        },
                                        params: {}
                                    }
                                })
                            },
                            params: {}
                        }
                    })
                } else {
                    this.props.changePackagesViewMode({ ViewMode: RootComponentsStateViewModeTypes.PackagesApp.Tree })
                }
            }
        } else {
            if (event.target.id === "show") {
                //TODO БАГ. Фильтры в Пакетах при открытой доп таблице

                // this.props.changePackagesViewMode({
                //     ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Search_Right
                //
                // })

                this.props.setUserModalShow({
                    isShow: true,
                    Type: "treePackagesSearch",
                    // для скрытия пакетов при закрытии модального окна с фильтрами в таблице пакетов загрузки
                    TableRole: this.props.tableRole
                })
            } else {

                this.props.setUserModalShow({
                    isShow: false,
                    Type: "treePackagesSearch",
                    TableRole: this.props.tableRole
                });
                this.props.PackageAddNodesTree({
                    Id: this.props.activeNode.Node.Id,
                    TableType: this.props.tableRole
                });
                this.props.handlerIsSearch({TableType: this.props.tableRole});
                this.props.updateInputsFieldPackage({TableRole: this.props.tableRole});


                //TODO aa Задача № 20065 БАГ. Фильтры в Пакетах при открытой доп таблице
                //Убран не нужный код, т.к. сменилась технология
                // this.props.SelectActivePackageNode({
                //     Id: this.props.activeNode.Node.Id,
                //     TableType: this.props.tableRole,
                // });


                // this.props.changePackagesViewMode({
                //     ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree,
                //     Next : {
                //         action : () => {
                //             this.props.PackageAddNodesTree({
                //                 Id: this.props.activeNode.Node.Id,
                //                 TableType: this.props.tableRole
                //             });
                //             this.props.handlerIsSearch({TableType: this.props.tableRole});
                //             this.props.updateInputsFieldPackage({});
                //             this.props.SelectActivePackageNode({
                //                 Id: this.props.activeNode.Node.Id,
                //                 TableType: this.props.tableRole,
                //             });
                //         },
                //         params : {}
                //     }
                // })
            }
        }

        if (store.getState().packages.tree.activeRow) {
            this.props.SelectActivePackageDocument({
                Id: store.getState().packages.tree.activeRow,
                TableType: this.props.tableRole
            })
        }
    }


    loadPackages(isChangeViewMode) {

        this.props.ActionQueue({
            List : [
                //Новый прелоадер
                {action: this.props.SelectActivePackageNode, params : {
                        Id : this.props.activeNodeAdditional.Node.Id ?? 2,
                        TableType : "additional"
                    }, name: "SelectActivePackageNode"},
                {action: this.props.SelectActivePackageDocument, params : {
                        TableType : "additional"
                    }, name: "SelectActivePackageDocument"},
                {action: this.props.setViewModePreviewPackages, params : {
                        ViewMode : isChangeViewMode ? "two_table_none" : this.props.viewModePreview
                    }, name: "setViewModePreviewPackages"},
                {action: this.props.breadcrumbsAdd, params : {
                        NodeId : this.props.activeNodeAdditional.Node.Id ?? 2,
                        Name : this.props.activeNodeAdditional.Node.Name ?? "Пакеты",
                        TableType : "additional"
                    }, name: "breadcrumbsAdd"},

            ],
            debug : true
        });

        // this.props.SelectActivePackageNode({
        //     Id : this.props.activeNodeAdditional.Node.Id ?? 2,
        //     TableType : "additional",
        //     Next : {
        //         action : ()=> {
        //             this.props.SelectActivePackageDocument({
        //                 TableType : "additional",
        //                 Next : {
        //                     action : ()=> {
        //                         this.props.setViewModePreviewPackages({
        //                             ViewMode : isChangeViewMode ? "two_table_none" : this.props.viewModePreview,
        //                             Next : {
        //                                 action : () => {
        //                                     this.props.breadcrumbsAdd({
        //                                         NodeId : this.props.activeNodeAdditional.Node.Id ?? 2,
        //                                         Name : this.props.activeNodeAdditional.Node.Name ?? "Пакеты",
        //                                         TableType : "additional",
        //                                     });
        //                                 },
        //                                 params : {}
        //                             }
        //                         });
        //                     },
        //                     params : {}
        //                 }
        //             });
        //         },
        //         params : {}
        //     }
        // });
    }


    toggleTwoTables() {
        if(this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") {

            let activeDocument= this.props.activeDocument;
            let query = this.props.router.location.query;
            let queryLink = "";

            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {},
                excludedKeys : ["p2","d2","dt2","dg2","dd2","dwp","dwd"]
            });

            let link = `${this.props.router.location.pathname}${queryLink}`;
            history.push(link);
            if(activeDocument){
                this.props.setViewModePreviewPackages({
                    ViewMode : "normal",
                });
            }else{
                this.props.setViewModePreviewPackages({
                    ViewMode : "none",
                });
            }

        }
        else {

            let query = this.props.router.location.query;
            let queryLink = "";

            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {p2 : 2},
                excludedKeys : ["d2","dt2","dg2","dd2","dwp","dwd"]
            });

            let link = `${this.props.router.location.pathname}${queryLink}`;
            //TODO при открытии дополнительной таблицы, пользователь по шагам видит свой предыдущий путь
            //history.replace(link);
            history.push(link);

            this.loadPackages(true);
        }
    }

    movePrev() {
        let breadcrumbsArrowPrev;
        if(this.props.tableRole === "main") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrev;
        else if(this.props.tableRole === "additional") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevDownload;

        if(breadcrumbsArrowPrev) {
            if(this.props.tableRole === "download") {
                this.props.breadcrumbsMovePrev({TableType : this.props.tableRole, IsDownload : true});
            } else {
                this.props.breadcrumbsMovePrev({TableType : this.props.tableRole});
            }
        }
        if(this.props.viewModePreview=="none" || this.props.viewModePreview=="normal"){

            this.props.setViewModePreviewPackages({
                ViewMode : "none",
            });
        }else{
            this.props.setViewModePreviewPackages({
                ViewMode : this.props.viewModePreview,
            });
        }
        this.props.SelectActivePackageDocument({TableType: this.props.tableRole});
    }

    moveNext() {
        let breadcrumbsArrowNext;
        if(this.props.tableRole === "main") breadcrumbsArrowNext = this.props.breadcrumbsArrowNext;
        else if(this.props.tableRole === "additional") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextDownload;


        if(breadcrumbsArrowNext) {
            if(this.props.tableRole === "download") {
                this.props.breadcrumbsMoveNext({TableType : this.props.tableRole, IsDownload : true});
            } else {
                this.props.breadcrumbsMoveNext({TableType : this.props.tableRole});
            }
        }
        if(this.props.viewModePreview=="none" || this.props.viewModePreview=="normal"){

            this.props.setViewModePreviewPackages({
                ViewMode : "none",
            });
        }else{
            this.props.setViewModePreviewPackages({
                ViewMode : this.props.viewModePreview,
            });
        }
        this.props.SelectActivePackageDocument({TableType: this.props.tableRole});
    }

    changeViewMode(Table, ViewMode) {
        if(this.props.tableRole === "download") {
            // this.props.changePackagesTablesViewMode({
            //     Table,
            //     ViewMode,
            //     IsDownload : true
            // });
            this.props.updateUserSettings({
                Name : UserSettingsNames.PACKAGE_TYPE_CONFIG,
                Action : UserSettingsActionsTypes.Update,
                Data: {
                    Table,
                    ViewMode,
                    IsDownload : true
                },
                Next:{
                    action:()=>{
                        this.props.changePackagesTablesViewMode({
                            Table,
                            ViewMode,
                            IsDownload : true
                        });
                    },
                    params:{}
                }
            })
        }
        else {
            // console.log("changeViewMode2", Table, ViewMode);
            if (Table === "main") {
                this.props.updateUserSettings({
                    Name : UserSettingsNames.PACKAGE_TYPE_CONFIG,
                    Action : UserSettingsActionsTypes.Update,
                    Data: {
                        Table,
                        ViewMode,
                    },
                    Next:{
                        action:()=>{
                            this.props.changePackagesTablesViewMode({
                                Table,
                                ViewMode,
                            });
                        },
                        params:{}
                    }
                })
            }else{
                this.props.updateUserSettings({
                    Name : UserSettingsNames.PACKAGE_TYPE_CONFIG_ADDITIONAL,
                    Action : UserSettingsActionsTypes.Update,
                    Data: {
                        Table,
                        ViewMode,
                    },
                    Next:{
                        action:()=>{
                            this.props.changePackagesTablesViewMode({
                                Table,
                                ViewMode,
                            });
                        },
                        params:{}
                    }
                })
            }


        }
    }

    // Type - documents/packages
    changeViewFilter(Type, lStoreValue) {
        let lPageNum;
        let lPaginationPageSize;
        if(this.props.tableRole === "download") {
           this.props.changeViewFilter({
               Type,
               TableType :  this.props.tableRole,
               IsDownload : true,
               lStoreValue : lStoreValue
           });
       }
       else {
            this.props.changeViewFilter({
               Type,
               TableType :  this.props.tableRole,
               lStoreValue : lStoreValue
           });
       }

        if(this.props.tableRole === "main") {
            lPaginationPageSize = Math.ceil(store.getState().packages.tree.lastLoad.documentsTotal / store.getState().packages.treeDownload.paginationPageSize);
            lPageNum = store.getState().packages.tree.paginationPageNumber;
        }
        else if(this.props.tableRole === "additional") {
            lPaginationPageSize =  Math.ceil(store.getState().packages.treeAdditional.lastLoad.documentsTotal / store.getState().packages.treeDownload.paginationPageSize);
            lPageNum = store.getState().packages.treeAdditional.paginationPageNumber;
        }
        else if(this.props.tableRole === "download") {
            lPaginationPageSize =  Math.ceil(store.getState().packages.treeDownload.lastLoad.documentsTotal / store.getState().packages.treeDownload.paginationPageSize);
            lPageNum = store.getState().packages.treeDownload.paginationPageNumber;
        }

        if (lPaginationPageSize < lPageNum)
            // lPageNum = lPaginationPageSize;
            lPageNum = lPaginationPageSize !== 0 ? lPaginationPageSize : 1;
        this.props.PackagesPaginationMovePage({
            PageNumber :  lPageNum,
            TableType : this.props.tableRole
        });

    }

    PlusPackage(inLevel = false, isAdditional = false) {
        let activeNode = isAdditional ? this.props.activeNodeAdditional : this.props.activeNode

        if(inLevel) {
            let IdParent = this.props.activeNode.Node.Id === 2
                ? activeNode.Node.Id
                : activeNode.Node.IdParent;

            this.props.setModalData({
                name: ModalTypes.packages.crud,
                data: {
                    type : "createNewPackages",
                    inLevel,
                    idParent : IdParent,
                    activeNode : activeNode
                }
            });
        } else {
            this.props.setModalData({
                name: ModalTypes.packages.crud,
                data: {
                    type : "createNewPackages",
                    inLevel : inLevel,
                    idParent : activeNode.Node.Id,
                    activeNode : activeNode
                }
            });
        }
    }

    deletePackage() {
        const { tableRole, activeNode, activeNodeAdditional, setModalData} = this.props;
        const currentNode = tableRole === "main" ? activeNode : activeNodeAdditional;

        setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "deletePackage",
                tableRole: tableRole,
                idParent : currentNode,
                activeNode : new TreeCombinePackage(true, tableRole, false).searchById(currentNode.Node.IdParent)
            }
        });
    }

    updateNamePackage(){
        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "updateNamePackage",
                activeNode : this.props.activeNode
            }
        });
    }

    async exportXLS(){
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false}
        // });

        //Новый прелоадер
        this.props.setModalData({
            data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false, key: "MainTableSearchPackage799"}
        });

        try {
            let reportType =
                // (this.props.tableRole === TableTypesEnum.Main || this.props.tableRole === TableTypesEnum.Additional) ?
                    "packages"
                // : "download";

            let activeNode;
            if(this.props.tableRole === TableTypesEnum.Main) activeNode = this.props.activeNode;
            else if(this.props.tableRole === TableTypesEnum.Additional) activeNode = this.props.activeNodeAdditional;
            else if(this.props.tableRole === TableTypesEnum.Download) activeNode = this.props.activeNodeDownload;


            let entityReport = new EntityReport({
                Type : reportType,
                idObject : activeNode.Node.Id,
                Columns : [],
                ReportName : __.translit(activeNode.Node.Name)
            });

            let reportGuid = await entityReport.create(this.props.tableRole);


            while(true) {
                let status = await entityReport.getState();
                if (status == IReportState.Done) break;
                await sleep(5000);
            }

            let report = await entityReport.getReportFile();

            var link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(report.file));
            link.setAttribute("download", report.name);
            link.click();
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage829"});
        }
        catch(e) {
            //Новый прелоадер
            // this.props.setModalData({});
            this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage834"});
        }
    }

    deleteDocument() {
        let checkedFields = this.props.tableRole === TableTypesEnum.Main
            ? this.props.checkedFieldsMainTable
            : this.props.checkedFields
        let activeNode
        if (this.props.tableRole === TableTypesEnum.Main) activeNode = this.props.activeNode
        else if (this.props.tableRole === TableTypesEnum.Additional) activeNode = this.props.activeNodeAdditional
        const collection = []

        for (let child of activeNode?.Children) {
            if (checkedFields.includes(child.Node.Version?.IdDoc)) {
                collection.push({Id: child.Node.IdPackageDocRef})
            }
        }

        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type: "deleteDocument",
                collection,
                activeNode,
            }
        });
    }
    updateTable() {
        let IsDownload = this.props.tableRole === "download";
        let activeNode;
        if(this.props.tableRole === "main") activeNode = this.props.activeNode;
        else if(this.props.tableRole === "additional") activeNode = this.props.activeNodeAdditional;
        else if(this.props.tableRole === "download") activeNode = this.props.activeNodeDownload;
        // store.dispatch(setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages"}
        // }))
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : true, gif : "packages", key: "MainTablePackage850"}
        }))

        this.props.PackageAddNodesTree({
            Id : activeNode.Node.Id,
            TableType : this.props.tableRole,
            IsDownload : IsDownload,
            Next : {
                action : () => {
                    this.props.SelectActivePackageNode({
                        Id : activeNode.Node.Id,
                        TableType : this.props.tableRole,
                        IsDownload : IsDownload
                    });
                    this.props.togglePackagesFolders({
                        IdFolder : activeNode.Node.Id,
                        TableType : this.props.tableRole,
                        IsDownload : IsDownload,
                    });

                    this.props.breadcrumbsAdd({
                        NodeId : activeNode.Node.Id,
                        Name : activeNode.Node.Name,
                        ParentId : activeNode.Node.IdParent,
                        TableType : this.props.tableRole,
                        IsDownload : IsDownload
                    });
                    this.props.breadcrumbsMoveSelect({
                        NodeId: activeNode.Node.Id,
                        TableType: this.props.tableRole,
                        IsDownload:IsDownload
                    });

                    if(this.props.tableRole=="main") {
                        if (this.props.viewModePreview=="none" || this.props.viewModePreview=="normal") {

                            this.props.setViewModePreviewPackages({
                                ViewMode: "none",
                            });
                        } else {
                            this.props.setViewModePreviewPackages({
                                ViewMode: this.props.viewModePreview,
                            });
                        }
                    }else if(this.props.tableRole=="download"){
                        if (this.props.viewModePreview == "two_table_download") {
                            this.props.setViewModePreviewPackages({
                                ViewMode: "two_table_download",
                            });
                        } else {
                            this.props.setViewModePreviewPackages({
                                ViewMode: "none",
                            });
                        }
                    }
                    this.props.SelectActivePackageDocument({TableType: this.props.tableRole});
                    // }


                    let query = this.props.router.location.query;
                    let queryLink = "";
                    if(this.props.tableRole === "main") {
                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {p : activeNode.Node.Id},
                            excludedKeys : ["d"]
                        });
                    }
                    else if(this.props.tableRole === "additional") {
                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {p2 : activeNode.Node.Id},
                            excludedKeys : ["d2"]
                        });
                    }
                    else if(this.props.tableRole === "download") {
                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {dwp : activeNode.Node.Id},
                            excludedKeys : ["dwd"]
                        });
                    }

                    let link = `${this.props.router.location.pathname}${queryLink}`;
                    history.push(link);
                    // store.dispatch(setModalData({}));
                    //Новый прелоадер
                    store.dispatch(setLoaderModalData({keyDeleted: "MainTableSearchPackage932"}));
                },
                params : {}
            }
        });
    }

    copyUrl(for1C = false) {
        // if(for1C) {
        //     __.copyUrl1c();
        // } else {
            let idPack, idDoc;
            const {tableRole, activeNode, activeDocument, activeNodeAdditional, activeDocumentAdd, activeNodeDownload, activeDocumentDownload} = this.props;
            switch (tableRole) {
                case TableTypesEnum.Main: {
                    idPack = activeNode.Node.Id;
                    idDoc = activeDocument?.Node.Id;
                    break;
                }
                case TableTypesEnum.Additional: {
                    idPack = activeNodeAdditional.Node.Id;
                    idDoc = activeDocumentAdd?.Node.Id;
                    break;
                }
                case TableTypesEnum.Download: {
                    idPack = activeNodeDownload.Node.Id;
                    idDoc = activeDocumentDownload?.Node.Id;
                    break;
                }

                default: return
            }
        if (for1C) {
            __.copyUrl1c(idDoc ?? null, idPack, tableRole);
        } else
            __.copyUrl(idDoc ?? null, idPack, tableRole === TableTypesEnum.Download);
        // }
        __.showModalCopyComplete(this.props.setModalData);
    }

    //рендер кнопок, которые переключают viewMode
    viewModeBtns(tableClass = "", middleCardsClass = "", bigCardsClass = "", toggleButtonClass = "") {
        if(this.props.tableRole !== "main") {
            return (
                <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                    {
                        this.state.tableViewTooltipShow &&
                        <MyTooltip target={this.tableViewRef}
                                   text={"Таблица"}
                                   show={this.state.tableViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={tableClass}
                          ref={this.tableViewRef}
                          onMouseEnter={() => {this.setState({tableViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({tableViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_table)}}>
                          <i className="svg-icon icon-Table_grid"/>
                     </span>

                    {
                        this.state.middleViewTooltipShow &&
                        <MyTooltip target={this.middleViewRef}
                                   text={"Средние значки"}
                                   show={this.state.middleViewTooltipShow}
                                   placement={"top"} delay={500}
                        />

                    }
                    <span className={middleCardsClass}
                          ref={this.middleViewRef}
                          onMouseEnter={() => {this.setState({middleViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({middleViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)}}>
                          <i className="svg-icon icon-Grid_list"/>
                     </span>


                    {
                        this.state.bigViewTooltipShow &&
                        <MyTooltip target={this.bigViewRef}
                                   text={"Крупные значки"}
                                   show={this.state.bigViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={bigCardsClass}
                          ref={this.bigViewRef}
                          onMouseEnter={() => {this.setState({bigViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({bigViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)}}>
                          <i className="svg-icon icon-Grid_card"/>
                     </span>
                    {
                        this.state.additionalViewTooltipShow &&
                        <MyTooltip target={this.additionalViewRef}
                                   text={"Показать/Скрыть дополнительную таблицу"}
                                   show={this.state.additionalViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    {/*{*/}
                    {/*    this.props.tableRole === "main" &&*/}
                    {/*    <span className={toggleButtonClass}*/}
                    {/*          ref={this.additionalViewRef}*/}
                    {/*          onMouseEnter={() => {this.setState({additionalViewTooltipShow: true})}}*/}
                    {/*          onMouseLeave={() => {this.setState({additionalViewTooltipShow: false})}}*/}
                    {/*          onClick={() => {this.toggleTwoTables()}}>*/}
                    {/*        {*/}
                    {/*            (this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") &&*/}
                    {/*            <i className="svg-icon icon-Remove_table"/>*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            (this.props.viewModePreview === "none" || this.props.viewModePreview === "normal") &&*/}
                    {/*            <i className="svg-icon icon-Add_table"/>*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*}*/}

                </div>
            )
        } else {
            return (
                <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                    {
                        this.state.tableViewTooltipShow &&
                        <MyTooltip target={this.tableViewRef}
                                   text={"Таблица"}
                                   show={this.state.tableViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={tableClass}
                          ref={this.tableViewRef}
                          onMouseEnter={() => {
                              this.setState({tableViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({tableViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                          }}>
                          <i className="svg-icon icon-Table_grid"/>
                     </span>

                    {
                        this.state.middleViewTooltipShow &&
                        <MyTooltip target={this.middleViewRef}
                                   text={"Средние значки"}
                                   show={this.state.middleViewTooltipShow}
                                   placement={"top"} delay={500}
                        />

                    }
                    <span className={middleCardsClass}
                          ref={this.middleViewRef}
                          onMouseEnter={() => {
                              this.setState({middleViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({middleViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                          }}>
                          <i className="svg-icon icon-Grid_list"/>
                     </span>


                    {
                        this.state.bigViewTooltipShow &&
                        <MyTooltip target={this.bigViewRef}
                                   text={"Крупные значки"}
                                   show={this.state.bigViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={bigCardsClass}
                          ref={this.bigViewRef}
                          onMouseEnter={() => {
                              this.setState({bigViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({bigViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                          }}>
                          <i className="svg-icon icon-Grid_card"/>
                     </span>
                    {
                        this.state.additionalViewTooltipShow &&
                        <MyTooltip target={this.additionalViewRef}
                                   text={"Показать/Скрыть дополнительную таблицу"}
                                   show={this.state.additionalViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    {
                        this.props.tableRole === "main" &&
                        <span className={toggleButtonClass}
                              ref={this.additionalViewRef}
                              onMouseEnter={() => {
                                  this.setState({additionalViewTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({additionalViewTooltipShow: false})
                              }}
                              onClick={() => {
                                  this.toggleTwoTables()
                              }}>
                            {
                                (this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") &&
                                <i className="svg-icon icon-Remove_table"/>
                            }
                            {
                                (this.props.viewModePreview === "none" || this.props.viewModePreview === "normal") &&
                                <i className="svg-icon icon-Add_table"/>
                            }
                        </span>
                    }
                </div>
            )
        }
    }

    getSearchInputs({TableRole = "main"}) {
        if (this.props.tableRole == "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional)
            {
                return searchInputsAdditional
            }

        } else if (this.props.tableRole == "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload)
            {
                return searchInputsDownload
            }
        } else {
            const {searchInputsMain} = this.props;
            return searchInputsMain
        }
    }
    //рендер кнопок фильтров
    filtersBtns() {
        let filterStyle = "icon-color-primary";

        //TODO aa Задача № 20065 БАГ. Фильтры в Пакетах при открытой доп таблице
        // const {searchInputs} = this.props;
        let searchInputs = this.getSearchInputs({TableRole: this.props.tableRole});

        if(searchInputs.AppliedParams) {
            // todo: ms 03.04
            let isFilter = Object.values(searchInputs.AppliedParams).some(item => item)
            if(isFilter) {
                filterStyle = "icon-color-secondary";
            }
        }
        return (
            <div className="d-flex btn-toolbar card-toolbar">
            {
                this.state.applyFilterTooltipShow &&
                <MyTooltip target={this.applyFilterRef}
                           text={"Показать/скрыть фильтр"}
                           show={this.state.applyFilterTooltipShow}
                           placement={"top"} delay={500}
                />
            }
            <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.applyFilterRef}
                  onMouseEnter={()=>{this.setState({applyFilterTooltipShow : true})}}
                  onMouseLeave={()=>{this.setState({applyFilterTooltipShow : false})}}
                  onClick={(event)=>{this.search(event)}}>
                        <i id="show" className={`svg-icon icon-Table_edit_7 ${filterStyle}`}/>
                    </span>

            {
                this.state.cancelFilterTooltipShow &&
                <MyTooltip target={this.cancelFilterRef}
                           text={"Сбросить и закрыть фильтр"}
                           show={this.state.cancelFilterTooltipShow}
                           placement={"top"} delay={500}
                />
            }
            <span className="btn btn-icon btn-circle btn-sm" ref={this.cancelFilterRef}
                  onMouseEnter={()=>{this.setState({cancelFilterTooltipShow : true})}}
                  onMouseLeave={()=>{this.setState({cancelFilterTooltipShow : false})}}
                  onClick={(event)=>{this.search(event)}}>
                        <i id="disabled" className="svg-icon icon-Table_edit_8 icon-color-primary"/>
                    </span>

        </div>
        )
    }

    //рендер кнопок переключения видимости пакетов и документов
    togglePacketsAndDocs(packClass = "", docClass = "") {
        return (
            <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                {
                    this.state.packagesViewFilterTooltipShow &&
                    <MyTooltip target={this.packagesViewFilterRef}
                               text={"Показать/Скрыть Пакеты"}
                               show={this.state.packagesViewFilterTooltipShow}
                               placement={"top"} delay={500}
                    />
                }
                <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.packagesViewFilterRef}
                      onMouseEnter={()=>{this.setState({packagesViewFilterTooltipShow : true})}}
                      onMouseLeave={()=>{this.setState({packagesViewFilterTooltipShow : false})}}
                      onClick={()=>{this.changeViewFilter("packages")}}
                >
                    <i className={`svg-icon icon-pak_on ${packClass}`}/>
                </span>

                {
                    this.state.documentsViewFilterTooltipShow &&
                    <MyTooltip target={this.documentsViewFilterRef}
                               text={"Показать/Скрыть Документы"}
                               show={this.state.documentsViewFilterTooltipShow}
                               placement={"top"} delay={500}
                    />
                }
                <span className="btn btn-icon btn-circle btn-sm" ref={this.documentsViewFilterRef}
                      onMouseEnter={()=>{this.setState({documentsViewFilterTooltipShow : true})}}
                      onMouseLeave={()=>{this.setState({documentsViewFilterTooltipShow : false})}}
                      onClick={()=>{this.changeViewFilter("documents")}}>
                        <i className={`svg-icon icon-Documents ${docClass}`}/>
                    </span>

            </div>
        )
    }

    //настройка видимости блоков кнопок
    setButtons() {
        let viewMode;
        if(this.props.tableRole === "main")  viewMode = this.props.viewModeMainTable;
        else if(this.props.tableRole === "additional")  viewMode = this.props.viewModeAdditionalTable;
        else if(this.props.tableRole === "download")  viewMode = this.props.viewModeDownloadTable;

        let changeViewModeBottomClass_table = viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_table
            ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
            : "btn btn-icon btn-sm btn-circle mr-1";

        let changeViewModeBottomClass_middleCards = viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards
            ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
            : "btn btn-icon btn-sm btn-circle mr-1";

        let changeViewModeBottomClass_bigCards = viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards
            ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
            : "btn btn-icon btn-sm btn-circle mr-1";

        let toggleButtonClass = "btn btn-icon btn-sm btn-circle mr-1"
        if(this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") {
            toggleButtonClass = "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
        }

        let viewFilter;
        if(this.props.tableRole === "main") viewFilter =  this.props.viewFilter;
        else if(this.props.tableRole === "additional") viewFilter =  this.props.viewFilterAdditional;
        else if(this.props.tableRole === "download") viewFilter =  this.props.viewFilterDownload;


        let viewFilterPackagesClass = "icon-color-primary";
        let viewFilterDocumentsClass = "icon-color-primary";

        if(viewFilter === PackagesViewFilters.OnlyPackages) {
            viewFilterDocumentsClass = "";
        } else if(viewFilter === PackagesViewFilters.OnlyDocuments) {
            viewFilterPackagesClass = "";
        }
        // let viewFilterPackagesClass = "";
        // let viewFilterDocumentsClass = "";
        // if(viewFilter === PackagesViewFilters.All) {
        //     viewFilterPackagesClass = "icon-color-primary";
        //     viewFilterDocumentsClass = "icon-color-primary";
        // }
        // else if(viewFilter === PackagesViewFilters.OnlyPackages) {
        //     viewFilterPackagesClass = "icon-color-primary";
        // }
        // else if(viewFilter === PackagesViewFilters.OnlyDocuments) {
        //     viewFilterDocumentsClass = "icon-color-primary";
        // }

        let breadcrumbsArrowPrev;
        if(this.props.tableRole === "main") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrev;
        else if(this.props.tableRole === "additional") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevDownload;


        let breadcrumbsArrowNext;
        if(this.props.tableRole === "main") breadcrumbsArrowNext = this.props.breadcrumbsArrowNext;
        else if(this.props.tableRole === "additional") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextDownload;

        let prevStyle = breadcrumbsArrowPrev ? {} : {backgroundColor: "#b5b5c3", borderColor: "#b5b5c3"};
        let nextStyle = breadcrumbsArrowNext ? {} : {backgroundColor: "#b5b5c3", borderColor: "#b5b5c3"};

        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;

        return (
            <>
                <div className="d-flex btn-toolbar card-toolbar justify-content-start">
                    {
                        this.state.movePrevViewTooltipShow &&
                        <MyTooltip target={this.movePrevViewRef}
                                   text={"К предыдущему пакету"}
                                   show={this.state.movePrevViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={`btn btn-icon btn-sm btn-primary btn- mr-2 table-menu-arrow-btn ${!breadcrumbsArrowPrev && "disabled"}`} onClick={this.movePrev} style={prevStyle}
                          ref={this.movePrevViewRef}
                          onMouseEnter={()=>{this.setState({movePrevViewTooltipShow : true})}}
                          onMouseLeave={()=>{this.setState({movePrevViewTooltipShow : false})}}>
                          <i className="svg-icon icon-Arrows_4"/>
                     </span>
                    {
                        this.state.moveNextViewTooltipShow &&
                        <MyTooltip target={this.moveNextViewRef}
                                   text={"К следующему пакету"}
                                   show={this.state.moveNextViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={`btn btn-icon btn-sm btn-primary btn- mr-2 table-menu-arrow-btn ${!breadcrumbsArrowNext && "disabled"}`} onClick={this.moveNext} style={nextStyle}
                          ref={this.moveNextViewRef}
                          onMouseEnter={()=>{this.setState({moveNextViewTooltipShow : true})}}
                          onMouseLeave={()=>{this.setState({moveNextViewTooltipShow : false})}}>
                          <i className="svg-icon icon-Arrows_3"/>
                     </span>

                </div>

                {isShowLeft &&
                    //блок кнопок переключения viewMode
                    this.viewModeBtns(changeViewModeBottomClass_table,
                        changeViewModeBottomClass_middleCards,
                        changeViewModeBottomClass_bigCards,
                        toggleButtonClass)
                }

                {isShowCenter &&
                    this.filtersBtns()
                }

                {isShowRight &&
                    //блок кнопок переключения видимости пакетов и документов
                    this.togglePacketsAndDocs(viewFilterPackagesClass, viewFilterDocumentsClass)
                }
            </>

        )
    }

    //добавляем кнопки, которые были скрыты в дропдаун
    addDropdownItems() {
        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;
        let viewFilter;
        if(this.props.tableRole === "main") viewFilter =  this.props.viewFilter;
        else if(this.props.tableRole === "additional") viewFilter =  this.props.viewFilterAdditional;
        else if(this.props.tableRole === "download") viewFilter =  this.props.viewFilterDownload;

        const isViewModePreviewNone = this.props.viewModePreview === "none" || this.props.viewModePreview === "normal"

        let icon = "icon-Table_edit_7"
        if (this.props.tableRole === TableTypesEnum.Main) {
            if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        if (this.props.tableRole === TableTypesEnum.Additional) {
            if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        else if (this.props.tableRole === TableTypesEnum.Download) {
            if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        return (
            <>
                {!isShowLeft &&
                    <>
                        <Dropdown>
                            <Dropdown.Toggle className={"dropdown-toggle"}>
                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                    <i className={`svg-icon icon-color-white ${icon}`}/>
                                </span>
                                Сменить вид отображения
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_table)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_grid"/>
                                    </span>
                                    Таблица
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Grid_list"/>
                                    </span>
                                    Средние значки
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Grid_card"/>
                                    </span>
                                    Крупные значки
                                </Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                        {
                            this.props.tableRole === "main" &&
                            <Dropdown.Item href="#" onClick={() => {this.toggleTwoTables()}}>
                                <span className={"btn btn-icon btn-circle btn-sm mr-1"}>
                                    {
                                        (this.props.viewModePreview === "two_table_none"
                                            || this.props.viewModePreview === "right_document_table"
                                            || this.props.viewModePreview === "two_table_download")
                                        &&
                                        <i className="dropdown-item svg-icon icon-Remove_table"/>
                                    }
                                    {
                                        isViewModePreviewNone &&
                                        <i className="dropdown-item svg-icon icon-Add_table"/>
                                    }
                                </span>
                                {`${isViewModePreviewNone ? "Показать " : "Скрыть"} дополнительную таблицу`}
                            </Dropdown.Item>
                        }
                    </>
                }

                {!isShowCenter &&
                    <>
                        <Dropdown.Item href="#" id="show" onClick={(event) => {this.search(event)}}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_7"/>
                            </span>
                            Показать/скрыть фильтр
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={(event) => {this.search(event)}}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_8"/>
                            </span>
                            Сбросить фильтр
                        </Dropdown.Item>
                    </>
                }

                {!isShowRight &&
                    <>
                        <Dropdown.Item href="#" disabled={viewFilter === "OnlyDocuments"} onClick={()=>{this.changeViewFilter("documents")}}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Documents"/>
                            </span>
                            {`${viewFilter === "OnlyPackages" ? "Показать " : "Скрыть "}документы`}
                        </Dropdown.Item>
                        <Dropdown.Item href="#" disabled={viewFilter === "OnlyPackages"} onClick={()=>{this.changeViewFilter("packages")}}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-pak_on"/>
                            </span>
                            {`${viewFilter === "OnlyDocuments" ? "Показать " : "Скрыть "}пакеты`}
                        </Dropdown.Item>
                    </>
                }
            </>
        )

    }


    getCheckedFieldsView() {
        if(this.props.tableRole === "main" && this.props.checkedFieldsMainTable.length !== 0)
            return <div>Выбрано файлов: {this.props.checkedFieldsMainTable.length}</div>
        else if (this.props.tableRole !== "main" && this.props.checkedFields.length !== 0 )
            return <div>Выбрано файлов: {this.props.checkedFields.length}</div>

    }
    render() {
        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;

        let {
            body,
        } = this.props;

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

        let docs = body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
        let checked = (checkedFields.length >= docs.length) && docs.length > 0;

        return (
            <>
            <div className="card-header border-0 py-2 mb-3" ref={this.resizeElement}>
                {<this.setButtons />}
                {
                    this.props.tableRole !== "main" ?
                        <div className="card-toolbar col-lg-4 justify-content-end" style={{flexWrap: "nowrap"}}>

                            {/*Дропдаун для доп таблицы */}
                            {!isShowRight || !isShowCenter || !isShowLeft ?
                                <>
                                {
                                    this.state.fitInContainerSizeTooltipShow &&
                                        <MyTooltip target={this.fitInContainerSize}
                                                   text={"Вписать"}
                                                   show={this.state.fitInContainerSizeTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                }
                                <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                      onClick={
                                          () => {
                                              if (!this.state.isFitInContainerSize)
                                                  this.props.fitInContainerSize()
                                              else
                                                  this.props.unFitInContainerSize();
                                              this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                              // this.props.fitInContainerSize
                                          }
                                      }
                                      onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}>
                                    <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                    </span>
                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={this.exportXLS}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                            Экспорт документов в Excel
                                        </Dropdown.Item>
                                        {
                                            this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                            this.props.tableRole !== TableTypesEnum.Download &&
                                            <DropdownItem href="#" onClick={this.deleteDocument}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                                </span>
                                                Удалить документ(ы)
                                            </DropdownItem>
                                        }
                                        <DropdownItem href="#" onClick={this.updateTable}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                            </span>
                                            Обновить пакет
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                            </span>
                                            Скопировать ссылку
                                        </DropdownItem>
                                        {this.lFlagCopyUrl1C &&
                                            <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                </span>
                                                Скопировать ссылку для 1С
                                            </DropdownItem>
                                        }
                                        {
                                            this.props.tableRole !== TableTypesEnum.Download &&
                                            this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                            <>
                                                <DropdownItem href="#" onClick={() => this.PlusPackage(false, true)}><span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Plus" />
                                                </span>
                                                    Создать новый пакет в текущем
                                                </DropdownItem>
                                                <DropdownItem href="#" onClick={()=>{this.deletePackage()}}><span className="btn btn-icon btn-circle btn-sm mr-1" >
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12" />
                                                </span>
                                                    Удалить текущий пакет
                                                </DropdownItem>
                                            </>
                                        }
                                        <this.addDropdownItems />
                                    </Dropdown.Menu>
                                </Dropdown>
                                </>
                                :
                                <>
                                    {
                                        this.state.deleteDocumentTooltipShow &&
                                        <MyTooltip target={this.deleteDocumentRef}
                                                   text={"Исключить документ(ы) из пакета"}
                                                   show={this.state.deleteDocumentTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    {
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        this.props.tableRole === TableTypesEnum.Additional &&
                                        <span className={`btn btn-icon btn-sm ${this.props.checkedFields.length === 0 && "invisible"}`}
                                              ref={this.deleteDocumentRef}
                                              onClick={this.deleteDocument}
                                              onMouseEnter={()=>{this.setState({deleteDocumentTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({deleteDocumentTooltipShow : false})}}
                                        >
                                            <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                        </span>
                                    }
                                            {
                                                this.state.updateTableTooltipShow &&
                                                <MyTooltip target={this.updateTableRef}
                                                           text={"Обновить пакет"}
                                                           show={this.state.updateTableTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            <span className="btn btn-icon btn-sm ml-2" ref={this.updateTableRef}
                                                  onClick={this.updateTable}
                                                  onMouseEnter={()=>{this.setState({updateTableTooltipShow : true})}}
                                                  onMouseLeave={()=>{this.setState({updateTableTooltipShow : false})}}>
                                                <i className="svg-icon icon-Reload icon-color-primary"/>
                                            </span>
                                            {
                                                this.state.copyUrlTooltipShow &&
                                                <MyTooltip target={this.copyUrlRef}
                                                           text={"Скопировать ссылку"}
                                                           show={this.state.copyUrlTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrlRef}
                                                  disabled={
                                                      window.location.href.indexOf("d=") == -1 && false
                                                  }
                                                  onClick={(event) => {
                                                      this.copyUrl(false);
                                                      // __.copyUrl();
                                                      // __.showModalCopyComplete(this.props.setModalData);
                                                  }}
                                                  onMouseEnter={() => {this.setState({copyUrlTooltipShow: true})}}
                                                  onMouseLeave={() => {this.setState({copyUrlTooltipShow: false})}}
                                            >
                                                <i className={`svg-icon svg-icon-sm ${((window.location.href.indexOf("d=") == -1) && false) ? "disabled" : "text-primary"} icon-Doc_view_14`}/>
                                            </span>
                                            {this.lFlagCopyUrl1C &&
                                                <>
                                                    {
                                                        this.state.copyUrl1CTooltipShow &&
                                                        <MyTooltip target={this.copyUrl1CRef}
                                                                   text={"Скопировать ссылку для 1С"}
                                                                   show={this.state.copyUrl1CTooltipShow}
                                                                   placement={"top"} delay={500}
                                                        />
                                                    }
                                                    <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                                          disabled={
                                                              store.getState().router?.location?.search?.indexOf("d=") == -1 && false
                                                          }
                                                          onClick={(event) => {
                                                              this.copyUrl(true)
                                                              // __.copyUrl1c(lDoc, lPack);
                                                              __.showModalCopyComplete(this.props.setModalData);
                                                          }}
                                                          onMouseEnter={() => {
                                                              this.setState({copyUrl1CTooltipShow: true})
                                                          }}
                                                          onMouseLeave={() => {
                                                              this.setState({copyUrl1CTooltipShow: false})
                                                          }}>
                                                        <i className={`svg-icon svg-icon-sm ${((store.getState().router?.location?.search?.indexOf("d=") == -1) && false) ? "disabled" : "text-primary"} icon-c_16`}/>
                                                    </span>
                                                </>
                                            }
                                            {
                                                this.state.fitInContainerSizeTooltipShow &&
                                                <MyTooltip target={this.fitInContainerSize}
                                                           text={"Вписать"}
                                                           show={this.state.fitInContainerSizeTooltipShow}
                                                           placement={"top"}
                                                           delay={500}
                                                />
                                            }
                                            <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                                  onClick={
                                                      () => {
                                                          if (!this.state.isFitInContainerSize)
                                                              this.props.fitInContainerSize()
                                                          else
                                                              this.props.unFitInContainerSize();
                                                          this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                                          // this.props.fitInContainerSize
                                                      }
                                                  }
                                                  onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                                  onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                                            >
                                                <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                            </span>

                                            {
                                                this.state.exportXlsTooltipShow &&
                                                <MyTooltip target={this.exportXlsRef}
                                                           text={"Экспорт документов в Excel"}
                                                           show={this.state.exportXlsTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.exportXlsRef}
                                                  onClick={this.exportXLS}
                                                  onMouseEnter={()=>{this.setState({exportXlsTooltipShow : true})}}
                                                  onMouseLeave={()=>{this.setState({exportXlsTooltipShow : false})}}>
                                                <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                    {(this.props.tableRole !== "download" && this.props.tableRole !== "main") &&
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        <>
                                            {
                                                this.state.plusPackagesTooltipShow &&
                                                <MyTooltip target={this.plusPackagesRef}
                                                           text={"Создать новый пакет в текущем"}
                                                           show={this.state.plusPackagesTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                                <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.plusPackagesRef}
                                                      onMouseEnter={()=>{this.setState({plusPackagesTooltipShow : true})}}
                                                      onMouseLeave={()=>{this.setState({plusPackagesTooltipShow : false})}}>
                                                    <i className="svg-icon icon-color-primary icon-Plus" onClick={() => this.PlusPackage(false, true)}/>
                                                </span>
                                            {
                                                this.state.deleteDocTooltipShow &&
                                                <MyTooltip target={this.deleteDocRef}
                                                           text={"Удалить текущий пакет"}
                                                           show={this.state.deleteDocTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                                <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.deleteDocRef}
                                                      onMouseEnter={()=>{this.setState({deleteDocTooltipShow : true})}}
                                                      onMouseLeave={()=>{this.setState({deleteDocTooltipShow : false})}}>
                                                    <i className="svg-icon icon-color-primary icon-Table_edit_12" onClick={()=>{this.deletePackage()}}/>
                                                </span>
                                        </>
                                    }
                                </>
                            }
                            {
                                this.props.tableRole === "main" &&
                                <Dropdown className={"mx-1 zindex-dropdown"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDownload" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Filter_data"/>
                                            </span>
                                            Дерево Пакетов Загрузки
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={this.exportXLS}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                            Экспорт документов в Excel
                                        </Dropdown.Item>
                                        <DropdownItem href="#" onClick={this.updateTable}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                            </span>
                                            Обновить пакет
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                            </span>
                                            Скопировать ссылку
                                        </DropdownItem>
                                        {this.lFlagCopyUrl1C &&
                                            <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                </span>
                                                Скопировать ссылку для 1С
                                            </DropdownItem>
                                        }
                                        {
                                            this.props?.adminLevel > AdminLevelEnum.CommonUser &&
                                            <>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(false)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет в текущем
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(true)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет на уровне с текущим
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.updateNamePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-name_search"/>
                                                    </span>
                                                    Переименовать текущий пакет
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.deletePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12"/>
                                                    </span>
                                                    Удалить текущий пакет
                                                </Dropdown.Item>
                                            </>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        :
                        <div className="card-toolbar justify-content-end">
                            {
                                this.state.deleteDocumentTooltipShow &&
                                <MyTooltip target={this.deleteDocumentRef}
                                           text={"Исключить документ(ы) из пакета"}
                                           show={this.state.deleteDocumentTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            { isShowRight &&
                                <>
                                    {
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        this.props.tableRole !== TableTypesEnum.Download &&
                                        <span className={`btn btn-icon btn-sm ${this.props.checkedFieldsMainTable.length === 0 && "invisible"}`}
                                              ref={this.deleteDocumentRef}
                                              onClick={this.deleteDocument}
                                              onMouseEnter={()=>{this.setState({deleteDocumentTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({deleteDocumentTooltipShow : false})}}
                                        >
                                            <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                        </span>
                                    }
                            {
                                this.state.updateTableTooltipShow &&
                                <MyTooltip target={this.updateTableRef}
                                           text={"Обновить пакет"}
                                           show={this.state.updateTableTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm mx-2" ref={this.updateTableRef}
                                  onClick={this.updateTable}
                                  onMouseEnter={()=>{this.setState({updateTableTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({updateTableTooltipShow : false})}}>
                                <i className="svg-icon icon-Reload icon-color-primary"/>
                            </span>


                            {
                                this.state.copyUrlTooltipShow &&
                                <MyTooltip target={this.copyUrlRef}
                                           text={"Скопировать ссылку"}
                                           show={this.state.copyUrlTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrlRef}
                                  disabled={
                                      window.location.href.indexOf("d=") == -1  && false
                                  }
                                  onClick={(event) => {
                                      this.copyUrl();
                                      // __.copyUrl();
                                      // __.showModalCopyComplete(this.props.setModalData);
                                  }

                                  }
                                  onMouseEnter={() => {
                                      this.setState({copyUrlTooltipShow: true})
                                  }}
                                  onMouseLeave={() => {
                                      this.setState({copyUrlTooltipShow: false})
                                  }}
                            >
                                <i className={`svg-icon svg-icon-sm ${window.location.href.indexOf("d=") == -1  && false ? "disabled" : "text-primary"} icon-Doc_view_14`}/>
                            </span>

                            {this.lFlagCopyUrl1C &&
                                <>
                                    {
                                        this.state.copyUrl1CTooltipShow &&
                                        <MyTooltip target={this.copyUrl1CRef}
                                                   text={"Скопировать ссылку для 1С"}
                                                   show={this.state.copyUrl1CTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                          disabled={
                                              store.getState().router?.location?.search?.indexOf("d=") == -1  && false
                                          }
                                          onClick={(event) => {
                                              __.copyUrl1c();
                                              __.showModalCopyComplete(this.props.setModalData);
                                          }}
                                          onMouseEnter={() => {
                                              this.setState({copyUrl1CTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({copyUrl1CTooltipShow: false})
                                          }}
                                    >
                                        <i className={`svg-icon svg-icon-sm ${store.getState().router?.location?.search?.indexOf("d=") == -1  && false ? "disabled" : "text-primary"} icon-c_16`}/>
                                    </span>
                                    </>
                            }
                                </>
                            }

                            {
                                this.props.tableRole !== "main" &&
                                <>
                                    {
                                        this.state.fitInContainerSizeTooltipShow &&
                                        <MyTooltip target={this.fitInContainerSize}
                                                   text={"Вписать"}
                                                   show={this.state.fitInContainerSizeTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                          onClick={
                                              () => {
                                                  if (!this.state.isFitInContainerSize)
                                                      this.props.fitInContainerSize()
                                                  else
                                                      this.props.unFitInContainerSize();
                                                  this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                                  // this.props.fitInContainerSize
                                              }
                                          }
                                          onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                                    >
                                          <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                    </span>

                                    {

                                        this.state.exportXlsTooltipShow &&
                                        <MyTooltip target={this.exportXlsRef}
                                                   text={"Экспорт документов в Excel"}
                                                   show={this.state.exportXlsTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.exportXlsRef}
                                          onClick={this.exportXLS}
                                          onMouseEnter={() => {
                                              this.setState({exportXlsTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({exportXlsTooltipShow: false})
                                          }}
                                    >
                                         <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                                    </span>
                                </>
                            }
                            {(this.props.tableRole !== "download" && this.props.tableRole !== "main") &&
                                this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                <>
                                    {
                                        this.state.plusPackagesTooltipShow &&
                                        <MyTooltip target={this.plusPackagesRef}
                                                   text={"Создать новый пакет в текущем"}
                                                   show={this.state.plusPackagesTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.plusPackagesRef}
                                          onMouseEnter={()=>{this.setState({plusPackagesTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({plusPackagesTooltipShow : false})}}
                                    >
                                        <i className="svg-icon icon-color-primary icon-Plus" onClick={this.PlusPackage}/>
                                    </span>
                                    {
                                        this.state.deleteDocTooltipShow &&
                                        <MyTooltip target={this.deleteDocRef}
                                                   text={"Удалить текущий пакет"}
                                                   show={this.state.deleteDocTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.deleteDocRef}
                                          onMouseEnter={()=>{this.setState({deleteDocTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({deleteDocTooltipShow : false})}}
                                    >
                                        <i className="svg-icon icon-color-primary icon-Table_edit_12" onClick={()=>{this.deletePackage()}}/>
                                    </span>
                                </>
                            }
                            {
                                this.state.fitInContainerSizeTooltipShow &&
                                <MyTooltip target={this.fitInContainerSize}
                                           text={"Вписать"}
                                           show={this.state.fitInContainerSizeTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                  onClick={
                                      () => {
                                          if (!this.state.isFitInContainerSize)
                                              this.props.fitInContainerSize()
                                          else
                                              this.props.unFitInContainerSize();
                                          this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                          // this.props.fitInContainerSize
                                      }
                                  }
                                  onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                            >
                                <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                            </span>


                            {/*Дропдаун для доп таблицы */}
                            {this.props.tableRole !== "main" && !isShowRight &&
                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            {
                                this.props.tableRole === "main" &&
                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{
                                            this.loadPackages(false);
                                            this.props.setUserModalShow({ isShow : true, Type : "treePackages" })})}
                                        >
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Packet"/>
                                            </span>Дерево пакетов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDownload" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Filter_data"/>
                                            </span>
                                            Дерево Пакетов Загрузки
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={this.exportXLS}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                            Экспорт документов в Excel
                                        </Dropdown.Item>
                                        { !isShowRight &&
                                            <>
                                                {
                                                    this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                                    this.props.tableRole !== TableTypesEnum.Download &&
                                                    <DropdownItem href="#" onClick={this.deleteDocument} className={`${this.props.checkedFieldsMainTable.length === 0 && "d-none"}`}>
                                                        <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                            <i className="dropdown-item svg-icon icon-color-red icon-Doc_view_8" />
                                                        </span>
                                                       Исключить документ(ы) из пакета
                                                    </DropdownItem>
                                                }
                                                <DropdownItem href="#" onClick={this.updateTable}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                                    </span>
                                                    Обновить пакет
                                                </DropdownItem>
                                                <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                                    </span>
                                                    Скопировать ссылку
                                                </DropdownItem>
                                                {this.lFlagCopyUrl1C &&
                                                    <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                        <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                            <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                        </span>
                                                        Скопировать ссылку для 1С
                                                    </DropdownItem>
                                                }
                                            </>
                                        }
                                        {
                                            this.props?.adminLevel > AdminLevelEnum.CommonUser &&
                                            <>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(false)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет в текущем
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(true)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет на уровне с текущим
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.updateNamePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-name_search"/>
                                                    </span>
                                                    Переименовать текущий пакет
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.deletePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12"/>
                                                    </span>
                                                    Удалить текущий пакет
                                                </Dropdown.Item>
                                                <this.addDropdownItems />
                                            </>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                }
            </div>
                <div className={"d-flex"} style={{marginLeft: "2rem", height: "3rem"}}>
                    {
                        ((this.props.tableRole === TableTypesEnum.Main && this.props.viewModeMainTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table) ||
                        (this.props.tableRole === TableTypesEnum.Additional && this.props.viewModeAdditionalTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table) ||
                        (this.props.tableRole === TableTypesEnum.Download && this.props.viewModeDownloadTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table)) &&
                        <div style={{width:"35px"}}>
                            {(this.props.adminLevel > AdminLevelEnum.CommonUser
                                || this.props.tableRole !== TableTypesEnum.Main) &&
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                    <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>
                                    {/*<span className={this.props.tableRole === "main" ? "main-table-delete main-table-head-delete" : "row-checked"} />*/}
                                    <span className="row-checked" />
                                </label>
                            }
                        </div>
                    }
                    {this.getCheckedFieldsView()}
                </div>
            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdd : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload: state.packages.treeDownload.activeDocument,

        activeNode : state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        activeNodeDownload : state.packages.treeDownload.activeNode,

        adminLevel : state.globalState.user.AdminLevel,
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,

        breadcrumbsArrowNext : state.packages.tree.breadcrumbsArrowNext,
        breadcrumbsArrowPrev : state.packages.tree.breadcrumbsArrowPrev,
        breadcrumbsArrowNextAdditional : state.packages.treeAdditional.breadcrumbsArrowNext,
        breadcrumbsArrowPrevAdditional : state.packages.treeAdditional.breadcrumbsArrowPrev,
        breadcrumbsArrowNextDownload : state.packages.treeDownload.breadcrumbsArrowNext,
        breadcrumbsArrowPrevDownload : state.packages.treeDownload.breadcrumbsArrowPrev,

        checkedFields : state.packages.mainTable.checkedFields,
        checkedFieldsMainTable : state.packages.mainTable.checkedFieldsMainTable,
        globalSettings: state.globalState.settings.Content,

        router : state.router,
        searchInputs: state.packages.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,

        userModalShow : state.globalState.app.userModalShow,
        userModalTableRole : state.globalState.app.userModalTableRole,
        userModalType : state.globalState.app.userModalType,
        userSettings : state.globalState.userSettings,

        viewFilter : state.packages.tree.viewFilter,
        viewFilterAdditional : state.packages.treeAdditional.viewFilter,
        viewFilterDownload : state.packages.treeDownload.viewFilter,
        viewMode : state.packages.rootState.viewMode,
        viewModeMainTable : state.packages.rootState.viewModeMainTable,
        viewModeAdditionalTable : state.packages.rootState.viewModeAdditionalTable,
        viewModeDownloadTable : state.packages.rootState.viewModeDownloadTable,
        viewModePreview : state.packages.rootState.viewModePreview,

    }
}

const  mapDispatchToProps = {
    ActionQueue,
    breadcrumbsAdd,
    breadcrumbsMoveNext,
    breadcrumbsMovePrev,
    breadcrumbsMoveSelect,
    changePackagesTablesViewMode,
    changePackagesViewMode,
    changeViewFilter,
    checkAllRowsOnPage_packagesDocumentRight,
    checkAllRowsOnPage_MainTable,
    deleteRowFromChecked_packagesDocumentRight,
    exportExcel,
    handlerIsSearch,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setLoaderModalData,
    setModalData,
    setUserModalShow,
    setViewModePreviewPackages,
    setStateLeftAsideMenu,
    togglePackagesFolders,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    updateUserSettings,
}



export default connect(mapStateToProps, mapDispatchToProps)(MainTableSearchPackage);
