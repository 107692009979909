
// auth - авторизация
export const AUTH_ACTIONS = {
    // вход в аккаунт
    LOGIN : "AUTH | LOGIN",
    // проверка войден ли
    IS_AUTH : "AUTH | IS_AUTH",
    // выход из аккаунта
    LOGUOT : "AUTH | LOGUOT",
};

// user - пользователь
export const USER_ACTIONS = {
    // получение информации о текущем пользователе
    GET_CURRENT : "USER | GET_CURRENT",
    // получение доступных для текущего пользователя филиалов
    GET_FILIAL : "USER | GET_FILIAL",
    //получение групп филиалов
    GET_FILIAL_GROUPS : "USER | GET_FILIAL_GROUPS",
    //смена филиала
    SET_FILIAL : "USER | SET_FILIAL",
    //собранное дерево
    RENDER_FILIAL_TREE : "USER | RENDER_FILIAL_TREE",
    // сброс информации о пользователе
    RESET_USER : "USER | RESET",
};

// settings - глобальные и пользовательские настройки
export const SETTINGS_ACTIONS = {
    // получение глобальных настроек для пользователя
    GET_GLOBAL_CLIENT_SETTINGS : "SETTING | GET_GLOBAL_CLIENT_SETTINGS",
    // получение настроек относящихся к конкретному пользователю
    GET_USER_SETTINGS : "SETTING | GET_USER_SETTINGS",
    // обновление пользовательских настроек
    UPDATE_USER_SETTINGS : "SETTING | UPDATE_USER_SETTINGS",
    UPDATE_USER_SETTINGS_SEARCH : "SETTING | UPDATE_USER_SETTINGS_SEARCH",
    UPDATE_USER_SETTINGS_DOC : "SETTING | UPDATE_USER_SETTINGS_SEARCH",
    UPDATE_USER_SETTINGS_PACKAGE : "SETTING | UPDATE_USER_SETTINGS_PACKAGE",
    UPDATE_USER_SETTINGS_PACKAGE_TYPE_ADDITIONAL : "SETTING | UPDATE_USER_SETTINGS_PACKAGE_TYPE_ADDITIONAL",
    UPDATE_USER_SETTINGS_PACKAGE_TYPE : "SETTING | UPDATE_USER_SETTINGS_PACKAGE_TYPE",
    UPDATE_USER_SETTINGS_GENERAL: "SETTING | UPDATE_USER_SETTINGS_GENERAL",
    // сброс до изначального состояния
    RESET_SETTINGS : "SETTING | RESET",
    //смена филиала
    SET_FILIAL : "SETTING | SET_FILIAL",
    CHANGE_WRAP : "SETTING | CHANGE_WRAP",
    SET_THEME : "SETTING | SET_THEME",

    //получить массив подразделений --- departmentsRef
    GET_DEPARTMENTS: "SETTING | GET_DEPARTMENTS"
};

// действия относящиеся ко всем страницам приложения
export const APP_ACTIONS = {
    // установить состояние бокового меню с деревом (открыто/закрыто)
    SET_ASIDE_MENU_STATE : "APP | SET_ASIDE_MENU_STATE",
    // открытие или закрытие модального окна, а так же передача
    // в него необходимых произвольных данных
    SET_MODAL_DATA : "APP | SET_MODAL_DATA",
    // открытие или закрытие модального окна, а так же передача
    // в него необходимых произвольных данных
    SET_LOADER_MODAL_DATA : "APP | SET_LOADER_MODAL_DATA",
    // открытие или закрытие контекстного меню, а так же передача
    // в него необходимых произвольных данных
    SET_CONTEXT_MENU_DATA : "APP | SET_CONTEXT_MENU_DATA",

    SET_SHOW_USER_MODAL : "APP | SET_SHOW_USER_MODAL"
}

