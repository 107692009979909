import React from "react";
import {connect} from "react-redux";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch, selectActiveRowSearch} from "../../store/search/actionCreators/search_table";
import {__} from "../../tools/HelpFunctions";
import Select from "react-select";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {Sorting} from "../../tools/Filters";
import {history, store} from "../../index";
import makeAnimated from 'react-select/animated';
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {
    AdminLevelEnum,
    ModalTypes,
    RootComponentsStateViewModeTypes,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../tools/StaticTypes";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

const animatedComponents = makeAnimated();

class InputSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover: false,
            accordionShow1: true,
            accordionShow2: true,
            accordionShow3: true,
            accordionShow4: true,
            arrowDopDate: false,

            LoadTypes : [],
            TypeIds: [],
            FilialIds: [],

            limitationTooltipShow: false,

            clearHoverSearchText: false,
            clearHoverAttributesText: false,
            clearHoverFullText: false,
            clearHoverDate: false,
            clearHoverNumber: false,
            clearHoverName: false,
            clearHoverVersionNumber: false,
            clearHoverStartDate: false,
            clearHoverEndDate: false,

            RegPeriodStart: "",
            RegPeriodFinish: "",

            SearchText: "",
            AttributesText: "",
            FullText: "",
            RegDateAsString: "",
            RegNumber: "",
            Name: "",
            VersionNumber: "",

            IsSearchInAttachments: false,
            IsSearchInVersions: false,
            IsSearchInMarkedForDelete: false,

            //           test : [{isDisabled: false, label: "test", value: 6}]

            typesOptions: [],
            typesOptions2: [],


        };
        this.limitation = React.createRef();


        this.search = this.search.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.accordion1 = this.accordion1.bind(this);
        this.accordion2 = this.accordion2.bind(this);
        this.accordion3 = this.accordion3.bind(this);
        this.accordion4 = this.accordion4.bind(this);
        this.docTypesSelectOnChange = this.docTypesSelectOnChange.bind(this);
        this.filialSelectOnChange = this.filialSelectOnChange.bind(this);
        this.dopDate = this.dopDate.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.clearInputAll = this.clearInputAll.bind(this);
        this.setFields = this.setFields.bind(this);
    }

    componentDidMount() {
        const {searchInputFields, activeTab} = this.props;
        if(searchInputFields) {
            const searchFieldsIndex = searchInputFields.findIndex(field => field.Id === activeTab.Id);
            if(searchFieldsIndex !== -1) {
            const inputFields = searchInputFields[searchFieldsIndex];
            this.setFields(inputFields);
            }
        }

    }

    componentWillMount() {

        this.setState(() => {
            let request_types = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter: {IdFilial: store.getState().globalState.filial.Active.Id},
                sorting: new Sorting().add({Direction: 1, PropertyName: "Name"}).get()
            });

            request_types.execute((data) => {
                this.setState({
                    LoadTypes: data.Records,
                }, () => {
                    let typesOptions = [];
                    typesOptions.push({
                        value: null,
                        label: "Не выбрано",
                        isDisabled: true
                    });
                    for (let i = 0; i < this.state.LoadTypes.length; i++) {
                        if (this.state.LoadTypes[i].ContainsDocs) {
                            typesOptions.push({
                                value: this.state.LoadTypes[i].Id,
                                label: this.state.LoadTypes[i].Name,
                                isDisabled: false
                            });
                        }
                    }

                    this.setState({
                        typesOptions: typesOptions
                    })


                    let typesOptions2 = [];
                    typesOptions2.push({
                        value: null,
                        label: "Не выбрано",
                        isDisabled: true
                    });

                    for (let i = 0; i < this.props.allFilial.length; i++) {
                        typesOptions2.push({
                            value: this.props.allFilial[i].Id,
                            label: this.props.allFilial[i].Name,
                            isDisabled: false
                        });
                    }

                    this.setState({
                        typesOptions2: typesOptions2
                    })

                    let newTypesIds = [];
                    if (this.state.TypeIds.length > 0 && !this.state.TypeIds[0]?.value) {
                        for (let i = 0; i < this.state.TypeIds.length; i++) {
                            let foundType = typesOptions.find(type => type.value == this.state.TypeIds[i]);

                            if (foundType) {
                                newTypesIds.push(foundType);
                            }
                        }

                        this.setState({
                            TypeIds: newTypesIds
                        });
                    }


                    let newFilialIds = [];
                    if (this.state.FilialIds.length > 0 && !this.state.FilialIds[0]?.value) {
                        for (let i = 0; i < this.state.FilialIds.length; i++) {
                            let foundFilial = typesOptions2.find(filial => filial.value == this.state.FilialIds[i]);

                            if (foundFilial) {
                                newFilialIds.push(foundFilial);
                            }
                        }

                        this.setState({
                            FilialIds: newFilialIds
                        });
                    }

                });
            }, (data) => {
                console.log("Ошибка загрузки списка типов документов: ", data)
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((JSON.stringify(this.props.searchInputFields) !== JSON.stringify(prevProps.searchInputFields)) ||
            this.props.activeTab.Id !== prevProps.activeTab.Id) {
            const {searchInputFields, activeTab} = this.props;
            if(searchInputFields) {
                const searchFieldsIndex = searchInputFields.findIndex(field => field.Id === activeTab.Id);
                if(searchFieldsIndex !== -1) {
                    const inputFields = searchInputFields[searchFieldsIndex];
                    this.setFields(inputFields);
                }
            }
        }
    }

    setFields(inputFields) {
        let typesIds = [];
        if(inputFields?.TypeIds?.length > 0 && this.state.typesOptions.length > 0) {
            inputFields?.TypeIds.map(id => {
                const type = this.state.typesOptions.find(type => type.value === id);
                if(type) {
                    typesIds.push(type);
                }
            })
        } else {
            typesIds = inputFields?.TypeIds ? inputFields?.TypeIds : [];
        }

        this.setState({
                TypeIds: typesIds,
                // TypeIds: inputFields?.TypeIds ? typesIds : [],
                FilialIds: inputFields?.FilialIds ? inputFields?.FilialIds : [],
                RegPeriodStart: inputFields?.RegPeriodStart ? inputFields?.RegPeriodStart : "",
                RegPeriodFinish: inputFields?.RegPeriodFinish ? inputFields?.RegPeriodFinish : "",

                SearchText: inputFields?.SearchText ? inputFields?.SearchText : "",
                AttributesText: inputFields?.AttributesText ? inputFields?.AttributesText : "",
                FullText: inputFields?.FullText ? inputFields?.FullText : "",
                RegDateAsString: inputFields?.RegDateAsString ? inputFields?.RegDateAsString : "",
                RegNumber: inputFields?.RegNumber ? inputFields?.RegNumber : "",
                Name: inputFields?.Name ? inputFields?.Name : "",
                VersionNumber: inputFields?.VersionNumber ? inputFields?.VersionNumber : "",

                IsSearchInAttachments:  inputFields?.IsSearchInAttachments ? inputFields?.IsSearchInAttachments : false,
                IsSearchInVersions:  inputFields?.IsSearchInVersions ? inputFields?.IsSearchInVersions : false,
                IsSearchInMarkedForDelete: inputFields?.IsSearchInMarkedForDelete ? inputFields?.IsSearchInMarkedForDelete : false,
            })
        }

    dopDate() {
        this.setState({
            arrowDopDate: !this.state.arrowDopDate
        });
    }

    accordion1() {
        this.setState({
            accordionShow1: !this.state.accordionShow1
        });
    }

    accordion4() {
        this.setState({
            accordionShow4: !this.state.accordionShow4
        });
    }


    accordion2() {
        this.setState({
            accordionShow2: !this.state.accordionShow2
        });
    }

    accordion3() {
        this.setState({
            accordionShow3: !this.state.accordionShow3
        });
    }

    inputHandler(event, item = null) {
        let value = event.target.value;

        if (item == null) {
            let nameInput = event.target.name;
            if (nameInput === "SearchText") {
                this.setState({SearchText: value});
            } else if (nameInput === "AttributesText") {
                this.setState({AttributesText: value});
            } else if (nameInput === "FullText") {
                this.setState({FullText: value});
            } else if (nameInput === "versionsCheckbox") {
                this.setState({IsSearchInVersions: event.target.checked});
            } else if (nameInput === "deletedCheckbox") {
                this.setState({IsSearchInMarkedForDelete: event.target.checked});
            } else if (nameInput === "attributesCheckbox") {
                this.setState({IsSearchInAttachments: event.target.checked});
            } else if (nameInput === "date") {
                this.setState({RegDateAsString: value});
            } else if (nameInput === "dateStart") {
                this.setState({RegPeriodStart: value});
            } else if (nameInput === "dateFinish") {
                this.setState({RegPeriodFinish: value});
            } else if (nameInput === "Number") {
                this.setState({RegNumber: value});
            } else if (nameInput === "Name") {
                this.setState({Name: value});
            } else if (nameInput === "VersionNumber") {
                this.setState({VersionNumber: value});
            }
        } else {
            let activeAttributes = __.deepCopy(this.state.ActiveAttributes);
            let index = activeAttributes.findIndex(attr => attr.uid === item.uid);
            activeAttributes[index].inputValue = value;
            this.setState({
                ActiveAttributes: activeAttributes
            });
        }
    }

    areFieldsEmpty() {
        return !this.state.SearchText
            && !this.state.AttributesText
            && !this.state.FullText
            && !this.state.RegDateAsString
            && !this.state.RegPeriodStart
            && !this.state.RegPeriodFinish
            && !this.state.RegNumber
            && !this.state.Name
            && !this.state.VersionNumber

    }

    search(e, openModal = false) {
        e.preventDefault()
        if(this.areFieldsEmpty() && openModal){
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Нужно заполнить хотя бы один атрибут`}
            });
            return
        }
        history.push("/search?s=true");
        let filialIds = [];
        if(this.state.FilialIds.length > 0 && this.state.FilialIds[0]?.value) {
            for (let i = 0; i < this.state.FilialIds.length; i++) {
                filialIds.push(this.state.FilialIds[i].value);
            }
        } else filialIds = this.state.FilialIds;


        let typeIds = [];
        if(this.state.TypeIds.length > 0 && this.state.TypeIds[0]?.value) {
            for (let i = 0; i < this.state.TypeIds.length; i++) {
                typeIds.push(this.state.TypeIds[i]);
            }
        }
        else typeIds = this.state.TypeIds;

        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {
        //         content: "Загрузка...",
        //         disableButton: true,
        //         fullBackground: false,
        //         gif: "search"
        //     }
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: false,
                gif: "search",
                key: "inputSearch328"
            }
        }));

        const idSearch = this.props.activeTab ? this.props.activeTab.Id : __.createUniqueIdString(6);

        this.props.updateInputsFieldSearch({
            SearchText: this.state.SearchText,
            AttributesText: this.state.AttributesText,
            FullText: this.state.FullText,
            IsSearchInVersions: this.state.IsSearchInVersions,
            IsSearchInMarkedForDelete: this.state.IsSearchInMarkedForDelete,
            IsSearchInAttachments: this.state.IsSearchInAttachments,
            TypeIds: typeIds,
            FilialIds: filialIds,
            RegDateAsString: this.state.RegDateAsString,
            RegPeriodStart: this.state.RegPeriodStart,
            RegPeriodFinish: this.state.RegPeriodFinish,
            RegNumber: this.state.RegNumber,
            Name: this.state.Name,
            VersionNumber: this.state.VersionNumber,
            Id: idSearch,

            Next: {
                action: () => {
                    this.props.searchByParams({
                        Id: idSearch,
                        PaginationPageNumber: 1,
                        Next: {
                            action: () => {
                                this.props.combineMainTableAttributesSearch({
                                    Next: {
                                        action: () => {
                                            this.props.updateUserSettings({
                                                Name: UserSettingsNames.SEARCH_CONFIG,
                                                Action: UserSettingsActionsTypes.Update,
                                                Data: {
                                                    FilialIds: this.state.FilialIds,
                                                    TypeIds: this.state.TypeIds,
                                                },
                                                Next: {
                                                    action: () => {
                                                        this.props.changeSearchViewMode({
                                                            ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly,
                                                            Next: {
                                                                action: () => {
                                                                    this.props.selectActiveRowSearch({})
                                                                    // this.props.setModalData({});
                                                                    //Новый прелоадер
                                                                    this.props.setLoaderModalData({keyDeleted: "InputSearch364"});
                                                                }
                                                            }
                                                        });
                                                    },
                                                    params: {}
                                                }
                                            })
                                        },
                                        params: {}
                                    }
                                })
                            },
                            params: {}
                        }
                    })
                },
                params: {}
            }
        });
    }


    docTypesSelectOnChange(item) {
        this.setState({TypeIds: item});
    }

    filialSelectOnChange(item) {
        // let tempArr=new Set();
        // for (let i = 0; i < item.length;i++){
        //     tempArr.add(item[i].value);
        // }
        // tempArr.delete(null);
        this.setState({FilialIds: item});
    }


    clearInput(event) {

        if (event.target.id == "SearchText") {
            this.setState({SearchText: ""});
        } else if (event.target.id == "AttributesText") {
            this.setState({AttributesText: ""});
        } else if (event.target.id == "FullText") {
            this.setState({FullText: ""});
        } else if (event.target.id == "date") {
            this.setState({RegDateAsString: ""});
        } else if (event.target.id == "Number") {
            this.setState({RegNumber: ""});
        } else if (event.target.id == "Name") {
            this.setState({Name: ""});
        } else if (event.target.id == "StartDate") {
            this.setState({RegPeriodStart: ""});
        } else if (event.target.id == "EndDate") {
            this.setState({RegPeriodFinish: ""});
        } else if (event.target.id == "VersionNumber") {
            this.setState({VersionNumber: ""});
        }
    }

    clearInputAll() {
        this.setState({SearchText: ""});
        this.setState({AttributesText: ""});
        this.setState({FullText: ""});
        this.setState({RegDateAsString: ""});
        this.setState({RegNumber: ""});
        this.setState({Name: ""});
        this.setState({RegPeriodStart: ""});
        this.setState({RegPeriodFinish: ""});
        this.setState({VersionNumber: ""});
        this.setState({IsSearchInVersions: false});
        this.setState({IsSearchInMarkedForDelete: false});
        this.setState({IsSearchInAttachments: false});
        this.setState({TypeIds: []});
    }

    render() {
        let accordionShow1 = "";
        let accordionShowArrow1 = "";
        if (this.state.accordionShow1) {
            accordionShow1 = "show"
            accordionShowArrow1 = "active"
        }
        // let accordionShow4 = "";
        // let accordionShowArrow4="";
        // if(this.state.accordionShow4){
        //     accordionShow4 ="show"
        //     accordionShowArrow4 ="active"
        // }
        let accordionShow2 = "";
        let accordionShowArrow2 = "";
        if (this.state.accordionShow2) {
            accordionShow2 = "show"
            accordionShowArrow2 = "active"
        }

        let accordionShow3 = "";
        let accordionShowArrow3 = "";
        if (this.state.accordionShow3) {
            accordionShow3 = "show"
            accordionShowArrow3 = "active"
        }

        // let arrowDopDate="right";
        // if(this.state.arrowDopDate){
        //     arrowDopDate="bottom"
        // }

        let searchIconSearchText = "icon-Search ";
        if (this.state.clearHoverSearchText) { // для отображения красного крестика
            searchIconSearchText = "icon-Delete icon-color-red";
        }
        let searchIconAttributesText = "icon-atributes_search ";
        if (this.state.clearHoverAttributesText) { // для отображения красного крестика
            searchIconAttributesText = "icon-Delete icon-color-red";
        }
        let searchIconFullText = "icon-fulltext_search ";
        if (this.state.clearHoverFullText) { // для отображения красного крестика
            searchIconFullText = "icon-Delete icon-color-red";
        }
        let searchIconDate = "icon-calendar_search ";
        if (this.state.clearHoverDate) { // для отображения красного крестика
            searchIconDate = "icon-Delete icon-color-red";
        }
        let searchIconNumber = "icon-number_search ";
        if (this.state.clearHoverNumber) { // для отображения красного крестика
            searchIconNumber = "icon-Delete icon-color-red";
        }
        let searchIconName = "icon-name_search ";
        if (this.state.clearHoverName) { // для отображения красного крестика
            searchIconName = "icon-Delete icon-color-red";
        }
        let searchIconVersionNumber = "icon-groups";
        if (this.state.clearHoverVersionNumber) { // для отображения красного крестика
            searchIconVersionNumber = "icon-Delete icon-color-red";
        }
        let searchIconStartDate = "icon-up_calendar ";
        if (this.state.clearHoverStartDate) { // для отображения красного крестика
            searchIconStartDate = "icon-Delete icon-color-red";
        }

        let searchIconEndDate = "icon-down_calendar ";
        if (this.state.clearHoverEndDate) { // для отображения красного крестика
            searchIconEndDate = "icon-Delete icon-color-red";
        }

        if (this.props.asideMenuIsClose) return null;

        const styles = {
            menuList: (base) => ({
                ...base,
                "::-webkit-scrollbar": {
                    width: "5px",
                    backgroundColor: "rgba(255, 255, 155, 0.3)"
                },
                "::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: "var(--light-primary)"
                },
                "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    backgroundColor: "var(--background-colorBS)"
                },
            })
        }

        return (
            <div>
                <form onSubmit={e => this.search(e)} className="form mb-4">
                    {/*TODO добавить событие на форму*/}
                    <div className="d-flex">
                        <div className="p-2 p-lg-2 my-lg-2">
                            <h3 className="text-color-primary font-weight-bolder"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    this.props.changeSearchViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.SearchApp.None,
                                        Next: {
                                            action: () => {
                                                history.push("/search");
                                            },
                                            params: {}
                                        }
                                    });
                                }}>
                                <span title={"Главная"}>Поиск</span>
                                {
                                    this.state.limitationTooltipShow &&
                                    <MyTooltip target={this.limitation}
                                               title={"Внимание!"}
                                               moreInfo={true}
                                               text={"Результаты поиска ограничены <span style='color:red'>2000</span> документами. Если искомого документа нет среди найденных, попробуйте уточнить условия поиска"}
                                               show={this.state.limitationTooltipShow}
                                               placement={"right"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-circle btn-sm icon-info-decor"
                                      ref={this.limitation}
                                      onMouseEnter={() => {
                                          this.setState({limitationTooltipShow: true})
                                      }}
                                      onMouseLeave={() => {
                                          this.setState({limitationTooltipShow: false})
                                      }}>
                                    <i className="svg-icon icon-Info"/>
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="input-icon h-40px my-4">
                        <input
                            type="text" className="form-control form-control-lg h-40px"
                            placeholder="Поиск"
                            onChange={(event) => {
                                this.inputHandler(event)
                            }}
                            value={this.state.SearchText}
                            name="SearchText"
                            onKeyDown={(event) => {
                                if (event.code === "Enter" || event.code === "NumpadEnter") {
                                    this.search(event)
                                }
                            }}
                        />
                        <span
                            role="button"
                            className="svg-icon svg-icon-lg icon-na"
                            onMouseEnter={() => {
                                this.setState({clearHoverSearchText: true})
                            }}
                            onMouseLeave={() => {
                                this.setState({clearHoverSearchText: false})
                            }}
                            onClick={(event) => this.clearInput(event)}
                        >
                            <i className={"svg-icon svg-icon-lg " + searchIconSearchText} id="SearchText"/>
                        </span>
                    </div>

                    <div className="accordion accordion-light  accordion-toggle-arrow">
                        <div className="card">
                            <div className="card-header">
                                <div className={"card-title " + accordionShowArrow1} onClick={this.accordion1}>
                                    Поиск по типам документов
                                </div>
                                <div className={"collapse " + accordionShow1}>
                                    <div className="card-body">
                                        {
                                            <label style={{width: "250px", margin: "1px"}}>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    styles={styles}
                                                    components={animatedComponents}
                                                    value={this.state.TypeIds}
                                                    isMulti
                                                    onChange={(item) => {
                                                        this.docTypesSelectOnChange(item)
                                                    }}
                                                    options={this.state.typesOptions}
                                                    placeholder="Выберите тип документа"
                                                    title="Выберите тип документа"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'var(--hover-primary)',
                                                            primary25: 'var(--light-primary)',
                                                            primary50: 'var(--hover-primary)',
                                                            primary: 'var(--originaltwo)',
                                                        },
                                                    })}
                                                />
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="accordion accordion-light  accordion-toggle-arrow">*/}
                    {/*    <div className="card">*/}
                    {/*        <div className="card-header" >*/}
                    {/*            <div className={"card-title "+ accordionShowArrow4} onClick={this.accordion4}>*/}
                    {/*                ЛФО*/}
                    {/*            </div>*/}


                    {/*            <div className={"collapse "+ accordionShow4}>*/}
                    {/*                <div className="card-body">*/}
                    {/*                    {*/}
                    {/*                        <label style={{width:"250px"}}>*/}
                    {/*                            <Select*/}
                    {/*                                closeMenuOnSelect={false}*/}
                    {/*                                components={animatedComponents}*/}
                    {/*                                value={this.state.FilialIds}*/}
                    {/*                                isMulti*/}
                    {/*                                onChange={(item)=>{this.filialSelectOnChange(item)}}*/}
                    {/*                         //       onChange={(item)=>{this.setState({test : item})}}*/}
                    {/*                                options={this.state.typesOptions2}*/}
                    {/*                                placeholder="Выберите ЛФО"*/}
                    {/*                                title="Выберите ЛФО"*/}
                    {/*                            />*/}
                    {/*                        </label>*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="accordion accordion-light accordion-toggle-arrow">
                        <div className="card">
                            <div className="card-header">
                                <div className={"card-title " + accordionShowArrow2} onClick={this.accordion2}>
                                    Дополнительные параметры
                                </div>
                                <div className={"collapse " + accordionShow2}>
                                    <div className="card-body">
                                        <div className="input-icon h-40px my-2">
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   title={"Поиск по атрибутам"}
                                                   placeholder="Поиск по атрибутам"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.AttributesText}
                                                   name="AttributesText"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverAttributesText: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverAttributesText: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconAttributesText}
                                                   id="AttributesText"/>
                                            </span>
                                        </div>

                                        <div className="input-icon h-40px my-4">
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   title={"Поиск по содержанию"}
                                                   placeholder="Поиск по содержанию"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.FullText}
                                                   name="FullText"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverFullText: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverFullText: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconFullText}
                                                   id="FullText"/>
                                                </span>
                                        </div>

                                        <div className="input-icon h-40px my-4" style={{position: "relative"}}>
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   title={"Поиск по дате"}
                                                   placeholder="Поиск по дате"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.RegDateAsString}
                                                   name="date"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverDate: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverDate: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconDate}
                                                   id="date"/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="row align-items-center">
                                            <label className="col-9 col-form-label">Поиск в версиях</label>
                                            <div className="col-3">
                                            <span className="switch switch-sm">
                                                <label>
                                                    <input type="checkbox"
                                                           checked={this.state.IsSearchInVersions}
                                                           name="versionsCheckbox"
                                                           onChange={(event) => {
                                                               this.inputHandler(event, null)
                                                           }}/>
                                                    <span/>
                                                </label>
                                            </span>
                                            </div>
                                        </div>
                                        {
                                            this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                            <div className="row align-items-center">
                                                <label className="col-9 col-form-label">Поиск в удаленных</label>
                                                <div className="col-3">
                                                    <span className="switch switch-sm">
                                                        <label>
                                                            <input type="checkbox"
                                                                   checked={this.props.adminLevel > AdminLevelEnum.CommonUser && this.state.IsSearchInMarkedForDelete}
                                                                   name="deletedCheckbox"
                                                                   onChange={(event) => {
                                                                       this.inputHandler(event, null)
                                                                   }}
                                                            />
                                                               <span/>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        <div className="row align-items-center">
                                            <label className="col-9 col-form-label">
                                                Поиск в тексте прикрепленных файлов
                                            </label>
                                            <div className="col-3">
                                                <span className="switch switch-sm">
                                                    <label>
                                                        <input type="checkbox"
                                                               checked={this.state.IsSearchInAttachments}
                                                               name="attributesCheckbox"
                                                               onChange={(event) => {
                                                                   this.inputHandler(event, null)
                                                               }}
                                                        />
                                                        <span/>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion accordion-light accordion-toggle-arrow">
                        <div className="card">
                            <div className="card-header">
                                <div className={"card-title " + accordionShowArrow3} onClick={this.accordion3}>
                                    Фильтры
                                </div>
                                <div className={"collapse " + accordionShow3}>
                                    <div className="card-body">
                                        <div>
                                            <div className="input-icon h-40px my-2">
                                                <span className="svg-icon svg-icon-lg"
                                                   onMouseEnter={() => {
                                                       this.setState({clearHoverStartDate: true})
                                                   }}
                                                   onMouseLeave={() => {
                                                       this.setState({clearHoverStartDate: false})
                                                   }}
                                                   onClick={(event) => this.clearInput(event)}>
                                                    <i id="StartDate"
                                                       className={`svg-icon svg-icon-lg icon-color-primary ${searchIconStartDate}`}
                                                    />
                                                </span>
                                                <input type="date" className="form-control form-control-lg h-40px"
                                                       min="1900-01-01"
                                                       max="3000-12-31"
                                                       placeholder="Дата 'С'"
                                                       title="Дата 'С'"
                                                       value={this.state.RegPeriodStart.split("T")[0]}
                                                       onChange={(event) => {
                                                           this.inputHandler(event)
                                                       }}
                                                       name="dateStart"
                                                />
                                            </div>

                                            <div className="input-icon h-40px my-2">
                                                <span className="svg-icon svg-icon-lg"
                                                   onMouseEnter={() => {
                                                       this.setState({clearHoverEndDate: true})
                                                   }}
                                                   onMouseLeave={() => {
                                                       this.setState({clearHoverEndDate: false})
                                                   }}
                                                   onClick={(event) => this.clearInput(event)}
                                                >
                                                    <i id="EndDate"
                                                       className={`svg-icon svg-icon-lg icon-color-primary ${searchIconEndDate}`}
                                                    />
                                                </span>
                                                <input type="date" className="form-control form-control-lg h-40px"
                                                       min="1900-01-01"
                                                       max="3000-12-31"
                                                       placeholder="Дата 'ПО'"
                                                       title="Дата 'ПО'"
                                                       value={this.state.RegPeriodFinish.split("T")[0]}
                                                       onChange={(event) => {
                                                           this.inputHandler(event)
                                                       }}
                                                       name="dateFinish"
                                                />
                                            </div>
                                        </div>
                                        <div className="input-icon h-40px my-4">
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   placeholder="Фильтр по номеру"
                                                   title="Фильтр по номеру"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.RegNumber}
                                                   name="Number"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverNumber: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverNumber: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i id="Number"
                                                   className={"svg-icon svg-icon-lg icon-color-primary " + searchIconNumber}
                                                />
                                            </span>
                                        </div>
                                        <div className="input-icon h-40px my-4">
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   placeholder="Фильтр по наименованию"
                                                   title="Фильтр по наименованию"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.Name}
                                                   name="Name"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverName: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverName: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i id="Name"
                                                   className={"svg-icon svg-icon-lg icon-color-primary " + searchIconName}
                                                />
                                            </span>
                                        </div>
                                        <div className="input-icon h-40px my-4">
                                            <input type="text" className="form-control form-control-lg h-40px"
                                                   placeholder="Фильтр по версии"
                                                   title="Фильтр по версии"
                                                   onChange={(event) => {
                                                       this.inputHandler(event)
                                                   }}
                                                   value={this.state.VersionNumber}
                                                   name="VersionNumber"
                                                   onKeyDown={(event) => {
                                                       if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                           this.search(event)
                                                       }
                                                   }}
                                            />
                                            <span className="svg-icon svg-icon-lg"
                                                  onMouseEnter={() => {
                                                      this.setState({clearHoverVersionNumber: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({clearHoverVersionNumber: false})
                                                  }}
                                                  onClick={(event) => this.clearInput(event)}
                                            >
                                                <i id="VersionNumber"
                                                   className={"svg-icon svg-icon-lg icon-color-primary " + searchIconVersionNumber}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <button
                            style={{marginRight: '22px'}}
                            className="col-lg-8 btn btn-primary btn-lg btn-block my-3"
                            onClick={e => this.search(e, true)}
                        >
                            Найти
                        </button>
                        <button className="col-lg-3 btn btn-outline-primary btn-icon btn-lg btn-block my-3"
                                title={"Очистить текущий фильтр"}
                                onClick={() => this.clearInputAll()}>
                            <i id="disabled" className="svg-icon icon-Table_edit_8 fsic4-i"/>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        adminLevel: state.globalState.user.AdminLevel,
        allFilial: state.globalState.filial.All,
        isOpenAsideMenu : state.search.rootState.isOpenAsideMenu,
        viewMode:state.search.rootState.viewMode,
        activeTab: state.search.rootState.activeTab,
        searchInputFields: state.search.searchState.searchInputsList
    }
}

const mapDispatchToProps = {
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    searchByParams,
    selectActiveRowSearch,
    setLoaderModalData,
    setModalData,
    updateInputsFieldSearch,
    updateUserSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSearch);
