import React from "react";
import {connect} from "react-redux";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    setStateLeftAsideMenu,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {AppRouter} from "../../index";
import {history} from "../../index";
import {createRedactorData} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import LanguageString from "../../tools/LanguageStrings";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";

// Левое меню. Основное меню навигации между корневыми компонентами.
class LeftMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            homeTooltipShow : false,
            docTooltipShow : false,
            packetTooltipShow : false,
            searchTooltipShow : false,
            helpTooltipShow : false,
            reportTooltipShow : false,
            settingsTooltipShow : false,
            masterTooltipShow : false,


            asideMenuButtonOnHover : false,
        };

        this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
        this.onClickMenuLink = this.onClickMenuLink.bind(this);

        this.homeRef = React.createRef();
        this.docRef = React.createRef();
        this.packetRef= React.createRef();
        this.searchRef= React.createRef();
        this.helpRef= React.createRef();
        this.reportRef= React.createRef();
        this.settingsRef= React.createRef();
        this.treeRef= React.createRef();
        this.masterRef= React.createRef();
    }

    toggleLeftMenu() {
        let rootName = !this.props.isSearch ? this.props.rootName : null
        this.props.setStateLeftAsideMenu({ rootName: rootName });
    }

    onClickMenuLink(link) {
        let isContinue = true;

        if(this.props.redactorData) {
            isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");

        }

        if(!isContinue) {
            return;
        }

        if(this.props.userModalShow) {
            this.props.setUserModalShow({
                isShow : false
            });
        }

        this.props.createRedactorData({CardId : null});

        // TODO: MS 30.03 — без этой строчки приложение падает при принудительном выходе из Redactor mode.
        this.props.changeDocumentViewMode({ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.None})

        window.onbeforeunload = null;
        window.addEventListener("popstate", null);

        //TODO aa 28.03.2023 Документы встать на документ на 7 странице и удалить его, без replace d из роутинга уходит
        history.replace(link);
        // TODO: MS 28.03 - replace меняет текущую запись истории на новую,
        //  так что пользователь не может вернуться к предыдущей странице, используя кнопку "Назад" в браузере,
        // если replace — необходим, то рождает баг #22703. необходимо будет разобраться и обсудить перед тем, как вернуть обратно.
        // TODO aa 28.03.2023 разобрался исправил роутинг 22703
        history.push(link);
    }

    render() {

        let asideMenuButtonStyle = {opacity : "100", transition : "300ms"};
        if(this.props.asideMenuOnHover && !this.state.asideMenuButtonOnHover) {
            asideMenuButtonStyle = {opacity : "0", transition : "600ms"}
        }



        let homeLink = "/";
        let documentsLink = `/documents`;
        let packagesLink = "/packages";
        let searchLink = "/search";
        let reportLink = "/report";
        let helpLink = "/help";
        let masterLink = "/master";


        let router = AppRouter.stored;

        if(router.documents?.g && router.documents?.t) {
            documentsLink += `?g=${router.documents.g}&t=${router.documents.t}`;
            if(router.documents?.d) {
                documentsLink += `&d=${router.documents.d}`;
                if(router.documents?.c) {
                    documentsLink += `&c=${router.documents.c}`;
                }
            }
        }

        // TODO: MS 29.03. Почему то не было сохранения ссылки для пакетов, тайный смысл был?
        if (router.packages?.p) packagesLink += `?p=${router.packages.p}`;
        for (const [key, value] of Object.entries(router.packages)) {
            if (key === "p") continue
            if(value) packagesLink += `&${key}=${value}`
        }


        if(router.search?.s) {
            searchLink += `?s=true`;
        }


        if(router.report?.r) {
            reportLink += `?r=${router.report.r}`;
        }

        const logoColor = this.props.modString === "yellow" ? "_graphite" :""

        let visible = true;
        if(this.props.types?.length==0) visible =false;
        return (
            <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
                    <div>
                        <img src={`/images/logo/07.png`}  className="max-h-50px w-100" alt=""/>
                    </div>

                </div>

                <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                    <ul className="nav flex-column">

                        {
                            this.state.homeTooltipShow && visible &&
                            <MyTooltip target={this.homeRef} text={"Домашняя страница"} show={this.state.homeTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            visible &&
                            <li className="nav-item mb-3" ref={this.homeRef}
                                onMouseEnter={()=>{this.setState({homeTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({homeTooltipShow : false})}}>
                            <span onClick={()=>{this.onClickMenuLink(homeLink)}}
                                  className={this.props.rootName === "HomeApp"
                                      ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                      : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon "}>
                                <i className="svg-icon svg-icon-xl icon-Home"/>
                            </span>
                            </li>
                        }
                        {
                            this.state.docTooltipShow && visible &&
                            <MyTooltip target={this.docRef} text={"Документы"} show={this.state.docTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            visible &&
                            <li className="nav-item mb-3" ref={this.docRef}
                                onMouseEnter={()=>{this.setState({docTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({docTooltipShow : false})}}>
                            <span onClick={()=>{this.onClickMenuLink(documentsLink)}}
                                  className={this.props.rootName === "DocumentApp"
                                      ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                      : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>
                                <i className="svg-icon svg-icon-xl icon-Documents"/>
                            </span>
                            </li>
                        }
                        {
                            this.state.packetTooltipShow && visible &&
                            <MyTooltip target={this.packetRef} text={"Пакеты"} show={this.state.packetTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            visible &&
                            <li className="nav-item mb-3" ref={this.packetRef}
                                onMouseEnter={()=>{this.setState({packetTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({packetTooltipShow : false})}}>
                            <span onClick={()=>{this.onClickMenuLink(packagesLink)}}
                                  className={this.props.rootName === "PackageApp"
                                      ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                      : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>
                                <i className="svg-icon svg-icon-xl icon-Menu_pack"/>
                            </span>
                            </li>

                        }

                        {
                            this.state.searchTooltipShow && visible &&
                            <MyTooltip target={this.searchRef} text={"Поиск"} show={this.state.searchTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            visible &&
                            <li className="nav-item mb-3" ref={this.searchRef}
                                onMouseEnter={()=>{this.setState({searchTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({searchTooltipShow : false})}}>
                            <span onClick={()=>{this.onClickMenuLink(searchLink)}}
                                  className={this.props.rootName === "SearchApp"
                                      ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                      : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>
                                <i className="svg-icon svg-icon-xl icon-Search_menu"/>
                            </span>
                            </li>
                        }
                        {
                            this.state.reportTooltipShow && visible &&
                            <MyTooltip target={this.reportRef} text={"Отчеты"} show={this.state.reportTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            visible &&
                            <li className="nav-item mb-3" ref={this.reportRef}
                                onMouseEnter={()=>{this.setState({reportTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({reportTooltipShow : false})}}>
                            <span onClick={()=>{this.onClickMenuLink(reportLink)}}
                                  className={this.props.rootName === "ReportsApp"
                                      ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                      : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>
                                <i className="svg-icon svg-icon-xl icon-reports"/>
                            </span>
                            </li>
                        }



                        {
                            this.state.masterTooltipShow && visible &&
                            <MyTooltip target={this.masterRef} text={"Мастер Загрузки Документов"} show={this.state.masterTooltipShow} placement={"right"} delay={400}/>
                        }
                        {
                            this.props.adminLevel >= 1 && visible &&
                            <li className="nav-item mb-3" ref={this.masterRef}
                                onMouseEnter={()=>{this.setState({masterTooltipShow : true})}}
                                onMouseLeave={()=>{this.setState({masterTooltipShow : false})}}>
                                <span onClick={()=>{this.onClickMenuLink(masterLink)}}
                                      className={this.props.rootName === "MasterApp"
                                          ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"
                                          : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>
                                    <i className="svg-icon svg-icon-xl icon-Upload_document"/>
                                </span>
                            </li>
                        }


                        {/*{*/}
                        {/*    this.state.helpTooltipShow &&*/}
                        {/*    <MyTooltip target={this.helpRef} text={"Справка"} show={this.state.helpTooltipShow} placement={"right"} delay={400}/>*/}
                        {/*}*/}
                        {/*<li className="nav-item mb-3" ref={this.helpRef}*/}
                        {/*    onMouseEnter={()=>{this.setState({helpTooltipShow : true})}}*/}
                        {/*    onMouseLeave={()=>{this.setState({helpTooltipShow : false})}}>*/}
                        {/*    <span onClick={()=>{this.onClickMenuLink(helpLink)}}*/}
                        {/*          className={this.props.rootName === "HelpApp"*/}
                        {/*              ? "nav-link btn btn-icon btn-clean btn-lg left-menu-icon active"*/}
                        {/*              : "nav-link btn btn-icon btn-clean btn-lg left-menu-icon"}>*/}
                        {/*        <i className="svg-icon svg-icon-xl icon-Doc_view_6"/>*/}
                        {/*    </span>*/}
                        {/*</li>*/}

                    </ul>

                </div>
                {
                    this.state.asideMenuButtonOnHover &&
                    <MyTooltip target={this.treeRef} text={!this.props.asideMenuIsClose ? "Свернуть" : "Развернуть"} show={this.state.asideMenuButtonOnHover} placement={"right"}  delay={400}/>
                }
                <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                    {
                        (this.props.rootName !== "HomeApp" && this.props.rootName !== "ReportsApp" && this.props.rootName !== "MasterApp" && this.props.rootName !== "HelpApp") &&
                        <span className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm "
                              onMouseEnter={()=>{this.setState({asideMenuButtonOnHover : true})}}
                              onMouseLeave={()=>{this.setState({asideMenuButtonOnHover : false})}}
                              style={asideMenuButtonStyle} onClick={this.toggleLeftMenu}
                              ref={this.treeRef}
                        >
                            <i className="svg-icon svg-icon-xl icon-Menu_arrow"/>
                        </span>
                    }


                    {
                         // this.state.settingsTooltipShow &&
                         // <MyTooltip target={this.settingsRef} text={"Настройки"} show={this.state.settingsTooltipShow} placement={"right"}  delay={400}/>
                    }
                    <span
                        style={{maxWidth: "60px", textAlign: "center", lineHeight: "1.25"}}
                       // onMouseEnter={()=>{this.setState({settingsTooltipShow : true})}}
                       // onMouseLeave={()=>{this.setState({settingsTooltipShow : false})}}
                        >
                            <span className="symbol-30 symbol-lg-40" style={{color: "white", fontSize: "10px"}}>

                                {/*<i className="svg-icon svg-icon-xl icon-Settings"/>*/}
                                {LanguageString.Version.Client}
                            </span>
                    </span>

                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,
        redactorData: state.document.cards.redactorData,
        adminLevel: state.globalState.user.AdminLevel,
        userModalShow: state.globalState.app.userModalShow,
        types: state.document.tree.docs,
        modString: state.globalState.theme.modString,
    }
}

const  mapDispatchToProps = {
    setStateLeftAsideMenu,
    createRedactorData,
    setUserModalShow,
    changeDocumentViewMode
}


export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
