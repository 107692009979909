
import {
    API_AUTH_LOGIN_PARAMS,
    API_AUTH_LOGIN_RESPONSE,
    API_REQUEST_ERROR,
    API_REQUEST_TYPES,
    API_SETTINGS_GLOBAL_PARAMS,
    IGlobalSettings,
    API_SETTINGS_USER_PARAMS,
    IUserSettings,
    IUser,
    API_USER_UPDATE_PASSWORD,
    API_USER_GET_FILIAL,
    API_SORTED_REQUEST,
    API_DOCUMENTS_TYPE_WITH_RIGHTS,
    API_DOCUMENTS_GROUPS_WITH_REFS,
    API_DOCUMENTS_EXTENDED_RESPONSE,
    API_DOCUMENTS_GET_BY_VERSION_RESPONSE,
    API_DOCUMENTS_GET_ALL_VERSIONS_RESPONSE,
    API_DOCUMENTS_GET_FILES,
    DocFilePart,
    API_DOCUMENTS_UPDATE_DOC_ATTRIBUTES_PARAMS,
    API_PACKAGES_LOAD_RESPONSE,
    API_PACKAGES_EXTENDED_LOAD_RESPONSE,
    API_PACKAGES_DOC_TYPES,
    API_PACKAGES_SAVE_OR_UPDATE_COLLECTION_RESPONSE,
    API_PACKAGES_DOC_IN_PACKAGES_RESPONSE,
    ISearchParams,
    API_SEARCH_BY_PARAMS,
    ISearchParamsInFullText,
    DocFileSearchResult,
    IAttribute,
    IDocClientLinkIds,
    API_REPORT_STORED_REPORTS,
    API_REPORT_STORED_REPORTS_PARAMS,
    IdStoredReportParamWrapper,
    IReportStateInfo,
    API_REPORT_DOWNLOAD_REPORT_RESPONSE,
    IEntity,
    ICollection,
    IEntityReport_Request,
    API_REQUEST_createNewOnePackage,
    API_RESPONCE_createNewOnePackage,
    API_REQUEST_saveNew,
    API_RESPONSE_saveNewComplete,
    API_RESPONSE_getUploadFilesProgress,
    API_MANY_RECORDS,
    IDocFileSignature,
    API_SIGNATURES_LOAD_RESPONSE, IPackageDocRefUpdate
    // @ts-ignore
} from "./ServerTypes.ts";
import {DocGroupStructureNode, IDocGroupStructureNode} from "../classes/documents/DocGroupStructureNode";

export const siteUrl : string = "http://archive4.test.itb.local";
export const baseUrl : string = "/cit-api";


export const API_URL = {
    AUTH : {
        LOGIN : "/",
        LOG_UOT : "/Logoff",
        IS_AUTH : "/IsAuthenticated"
    },
    SETTINGS : {
        GLOBAL : "/GlobalClientSettings/Get",
        USER : "/UserSettings/GetSettings",
        UPDATE_USER : "/UserSettings/SaveOrUpdateCollection"
    },
    USERS : {
        GET_CORRECT : "/Users/GetCurrent",
        UPDATE_PASSWORD : "/Users/ChangePassword",
        GET_FILIAL : "/Filials/Load"
    },

    // TODO электронная подпись файла
    SIGNATURES: {
        GET_BY_ID : "/DocFileSignatures/GetById", //"Получение электронной подписи файла документа по ID"
        LOAD : "/DocFileSignatures/Load", //"Загрузка электронных подписей файлов документов по фильтру"
        ATTACH : "/DocFileSignatures/Attach", //Прикрепление электронной подписи к файлу документа
        ATTACH_COLLECTION : "/DocFileSignatures/AttachCollection", //Прикрепление нескольких электронных подписей к файлу документа
        UPDATE : "/DocFileSignatures/Update", //Обновление электронной подписи файла документа
        DOWNLOAD_GET : "/DocFileSignatures/Download", //Скачка электронной подписи файла документа
        DOWNLOAD_HEAD : "/DocFileSignatures/Download", //Скачка электронной подписи файла документа
        DELETE : "/DocFileSignatures/Delete", //Удаление электронной подписи файла документа по Id
    },


    DOCUMENTS : {
        DOCS_SAVE : "/Docs/Save",
        DOCS_UPLOAD : "/DocFiles/Upload",
        DOCS_SAVE_COMPLETE : "/Docs/SaveComplete",
        DOCS_SAVE_COMPLETE_EXTENDED : "/Docs/SaveCompleteExtended", //Производит завершение процесса сохранения документа архива
        DOCS_GET_UPLOAD_PROGRESS_BY_GUID : "/DocFiles/GetUploadProgressByGuid",
        DOC_TYPES_WITH_RIGHTS__DOC_TREE : "/DocTypesWithRights/Load",
        DOC_GROUPS_WITH_REFS__DOC_TREE : "/DocGroupsWithRefs/Load",
        DOC_COLLECTION : "/DocsExtended/Load",
        GET_BY_VERSION : "/DocFileHeaders/GetByVersion",
        DOWNLOAD_PREVIEW : "/DocFiles/DownloadPreview",
        DOWNLOAD_FILE : "/DocFiles/Download/",
        GET_VERSIONS : "/DocVersions/Load",
        GET_ATTRIBUTES : "/DocAttributes/Load",
        GET_CONTENT : "/DocFiles/DownloadContent",
        GET_FILES : "/DocFiles/Load",
        UPLOAD_ATTACHED_FILE : "/DocFiles/UploadAttachedFile/",
        CREATE_NEW_VERSION : "/Docs/CreateNewVersion/",
        UPDATE_ATTRIBUTES : "/Docs/Update",
        UPDATE_FILE_CONTENT : "/DocFiles/UpdateText",
        SET_MAIN_FILE : "/DocFiles/SetMain",
        DELETE_ATTACHED_FILE : "/DocFiles/DeleteAttachedFile",
        RESET_PREVIEW_FILE : "/DocFiles/ResetPreview",
        RENAME_FILE : "/DocFiles/Rename",
        MARK_FOR_DELETE : "/Docs/MarkForDelete",
        DOC_GROUP_STRUCTURE_NODES : "/DocGroupStructureNodes/Load",
        GET_FILES_BY_ID: "/DocFiles/GetById",
        DOWNLOAD_CONTENT_FILES: ""
    },
    PACKAGES : {
        LOAD : "/Packages/Load",
        UPDATE_DOC_REF: "/PackageDocRefs/Update",
        LOAD_EXTENDED : "/PackagesExt/Load",
        DOCS_TYPES : "/DocTypes/GetPackageTypes",
        DOCS_IN_PACKAGES : "/DocsInPackage/Load",
        SAVE_OR_UPDATE_COLLECTION : "/PackageDocRefs/SaveOrUpdateCollection",
        DELETE_COLLECTION : "/PackageDocRefs/DeleteCollection",
        CREATE_NEW_PACKAGES : "/Packages/CreateSubTree",
        CREATE_NEW_PACKAGE_ONE : "/Packages/Save",
        DELETE_PACKAGES : "/Packages/Delete",
        UPDATE_NAME_PACKAGES : "/Packages/Update"
    },
    SEARCH : {
        SEARCH_BY_PARAMS : "/Searcher/SearchByParams",
        SEARCH_BY_PARAMS_IN_FULLTEXT : "/Searcher/SearchByParamsInFullText",
        SEARCH_BY_PARAMS_IN_ATTRIBUTES : "/Searcher/SearchByParamsInAttributes",
        VERIFY_DOC_CLIENT_LINK_IDS : "/Docs/VerifyDocClientLinkIds",
    },
    REPORTS : {
        STORED_REPORT : "/StoredReports/Load",
        STORED_REPORT_PARAMS : "/StoredReportParams/Load",
        CREATE_QUERY_REPORT : "/Reports/CreateQueryReport",
        CREATE_ENTITY_REPORT : "/Reports/CreateEntityReport",
        CREATE_SEARCH_RESULT_REPORT : "/Reports/CreateSearchResultReport",
        GET_REPORT_STATE : "/Reports/GetReportState",
        DOWNLOAD_REPORT : "/Reports",
    },
};

export enum API_ERROR_NAMES {
    DOC_GROUP_STRUCTURE_NODES_GET = "documents: getDocGroupStructureNodes",
}



export class API {
    static auth = () => {
        return {
            login: async (body: API_AUTH_LOGIN_PARAMS): Promise<API_AUTH_LOGIN_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.AUTH.LOGIN, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            logout: async () : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.AUTH.LOG_UOT, "post").execute();
                if (!response.ok) {
                    if(response.status === 401) {
                        return false;
                    }
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            isAuth : async () : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.AUTH.IS_AUTH, "post").execute();
                if (!response.ok) {
                    if(response.status === 401) {
                        return false;
                    }
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            }
        };
    }
    static settings = () => {
       return {
           getGlobal : async (body : API_SETTINGS_GLOBAL_PARAMS) : Promise<IGlobalSettings | API_REQUEST_ERROR> => {
               let response = await new API_REQUEST(API_URL.SETTINGS.GLOBAL, "post", body).execute();
               let result = await response.json();
               if (!response.ok) {
                   return {
                       errorCode: response.status,
                       info: response,
                       message: result
                   }
               }
               return result;
           },
           getUser : async (body : API_SETTINGS_USER_PARAMS) : Promise<IUserSettings[] | API_REQUEST_ERROR> => {
               let response = await new API_REQUEST(API_URL.SETTINGS.USER, "post", body).execute();
               let result = await response.json();
               if (!response.ok) {
                   return {
                       errorCode: response.status,
                       info: response,
                       message: result
                   }
               }
               return result;
           },
           updateUser : async (body: { collection: IUserSettings[]}) : Promise<IUserSettings[] | API_REQUEST_ERROR> => {
               let response = await new API_REQUEST(API_URL.SETTINGS.UPDATE_USER, "post", body).execute();
               let result = await response.json();
               if (!response.ok) {
                   return {
                       errorCode: response.status,
                       info: response,
                       message: result
                   }
               }
               return result;
           }
       };
    }
    static user = () => {
        return {
            getCurrent : async () : Promise<IUser | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.USERS.GET_CORRECT, "post", {}).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            updatePassword : async (body : API_USER_UPDATE_PASSWORD) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.USERS.UPDATE_PASSWORD, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            getFilial : async () : Promise<API_USER_GET_FILIAL | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.USERS.GET_FILIAL, "post", {}).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
        };
    }


    // TODO электронная подпись файла
    // SIGNATURES: {
    //     GET_BY_ID : "DocFileSignatures/GetById", //"Получение электронной подписи файла документа по ID"
    //     LOAD : "DocFileSignatures/Load", //"Загрузка электронных подписей файлов документов по фильтру"
    //     ATTACH : "DocFileSignatures/Attach/{idDocFile}", //Прикрепление электронной подписи к файлу документа
    //     ATTACH_COLLECTION : "DocFileSignatures/AttachCollection/{idDocFile}", //Прикрепление нескольких электронных подписей к файлу документа
    //     UPDATE : "DocFileSignatures/Update/{idSignature}", //Обновление электронной подписи файла документа
    //     DOWNLOAD_GET : "DocFileSignatures/Download", //Скачка электронной подписи файла документа
    //     DOWNLOAD_HEAD : "DocFileSignatures/Download", //Скачка электронной подписи файла документа
    //     DELETE : "DocFileSignatures/Delete", //Удаление электронной подписи файла документа по Id
    // },

    static signatures = () => {
        return {
            getById: async (body: API_REQUEST_saveNew): Promise<IDocFileSignature | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SIGNATURES.GET_BY_ID, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },

            load: async (body : API_SORTED_REQUEST) : Promise<API_SIGNATURES_LOAD_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SIGNATURES.LOAD, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            attach: async (body: { idDocFile : number, FormData : FormData}): Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(`${API_URL.SIGNATURES.ATTACH}/${body.idDocFile}`, "uploadFilesWithoutHeaders", body.FormData).execute();

                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
            },
            update: async (body: { idSignature : number, FormData : FormData}): Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(`${API_URL.SIGNATURES.UPDATE}/${body.idSignature}`, "uploadFilesWithoutHeaders", body.FormData).execute();

                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
            },
            delete: async (body : {id : number}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SIGNATURES.DELETE, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
        }
    }

    static documents = () => {
        return {
            saveNew : async (body : API_REQUEST_saveNew) : Promise<string[] | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOCS_SAVE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            uploadDocFiles :async (body : {file : File, Guid : string}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(`${API_URL.DOCUMENTS.DOCS_UPLOAD}/${body.Guid}`, "uploadBlobFiles", body.file).execute();
                // //TODO aa 30.03.2023 нужен ответ с сервера для загрузки под этот файл электронной подписи
                // let result = await response.json();

                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            saveNewComplete : async (body : {commit : boolean}) : Promise<API_RESPONSE_saveNewComplete | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOCS_SAVE_COMPLETE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            saveNewCompleteExtended : async (body : {commit : boolean}) : Promise<API_RESPONSE_saveNewComplete | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOCS_SAVE_COMPLETE_EXTENDED, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getUploadFilesProgress : async (body : {uploadGuid : string}) : Promise<API_RESPONSE_getUploadFilesProgress | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOCS_GET_UPLOAD_PROGRESS_BY_GUID, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getTypesWithRights : async (body : API_SORTED_REQUEST) : Promise<API_DOCUMENTS_TYPE_WITH_RIGHTS | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOC_TYPES_WITH_RIGHTS__DOC_TREE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getGroupsWithRefs : async (body : API_SORTED_REQUEST) : Promise<API_DOCUMENTS_GROUPS_WITH_REFS | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOC_GROUPS_WITH_REFS__DOC_TREE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getDocsExtended: async (body : API_SORTED_REQUEST) : Promise<API_DOCUMENTS_EXTENDED_RESPONSE | API_REQUEST_ERROR> => {
                let filterObj = {
                    filter: body.filter ? body.filter.get() : null,
                    sorting: body.sorting ? body.sorting.get() : null
                };
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOC_COLLECTION, "post", filterObj).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            // TODO: deprecated
            getFilesByVersion : async (body : {idDoc: number, idVersion: number}) : Promise<API_DOCUMENTS_GET_BY_VERSION_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_BY_VERSION, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getAllVersions : async (body : API_SORTED_REQUEST) : Promise<API_DOCUMENTS_GET_ALL_VERSIONS_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_VERSIONS, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getCurrentDocAttributes : async (body : API_SORTED_REQUEST) : Promise<API_DOCUMENTS_GET_ALL_VERSIONS_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_ATTRIBUTES, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            // TODO: deprecated
            getContentForCurrentFile : async (body : {idDoc: number, idVersion: number}) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_CONTENT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            // инфа о файлах
            getFiles : async (body : API_SORTED_REQUEST) :  Promise<API_DOCUMENTS_GET_FILES | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_FILES, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            // сами файлы
            getCurrentFile : async (body : {id : number, part : DocFilePart, isAttachment : boolean}) : Promise<string | API_REQUEST_ERROR> => {
                if(body.part === DocFilePart.File || body.part === DocFilePart.Preview) {
                    return API_GET_LINK.documents().linkToFile(body);
                }

                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_FILES, "get", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getCurrentFileText : async (body : {id : number, part : DocFilePart, isAttachment : boolean}) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_GET_LINK.documents().linkToFile(body), "getFiles").execute();
                let result = await response.arrayBuffer();
                const decoder = new TextDecoder('windows-1251');
                const text = decoder.decode(result);
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return text;
            },
            uploadAttachedFile : async (body : {IdDoc : number, FormData : FormData}) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(`${API_URL.DOCUMENTS.UPLOAD_ATTACHED_FILE}/${body.IdDoc}`, "uploadFiles", body.FormData).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            createNewDocVersion : () => {

            },
            updateDocAttributes: async (body: API_DOCUMENTS_UPDATE_DOC_ATTRIBUTES_PARAMS): Promise<any | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.UPDATE_ATTRIBUTES, "post", body).execute();
                if (response.status === 200) {
                    return true;
                } else {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                // return true;
            },
            updateDocFileContent : async (body : {id : number, text : string}) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.UPDATE_FILE_CONTENT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            setMainDocFile : async (body : {id : number}) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.SET_MAIN_FILE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            deleteDocFile : async (body : {id : number}) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DELETE_ATTACHED_FILE, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            renameDocFile : async (body : {id : number, newDescription : string, newName : string}) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.RENAME_FILE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            resetDocPreviewFile : async (body : {id : number, isAvailable : boolean}) : Promise<boolean | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.RESET_PREVIEW_FILE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return true;
            },
            markForDelete : async (body : {id : number, isMarked : boolean}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.MARK_FOR_DELETE, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            getDocGroupStructureNodes: async (body: API_SORTED_REQUEST): Promise<Omit<API_MANY_RECORDS, "Records"> & {Records: IDocGroupStructureNode[]}> => {
                let filterObj = {
                    filter: body.filter ? body.filter : null,
                    sorting: body.sorting ? body.sorting: null
                };
                let response = await new API_REQUEST(API_URL.DOCUMENTS.DOC_GROUP_STRUCTURE_NODES, "post", filterObj).execute();
                if (!response.ok) {
                    throw new API_REQUEST(API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET,{
                        errorCode: response.status,
                        info: response,
                        ApiMessage: response.status !== 404 ? await response.json() : `Url Not Found: ${API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET}`
                    });
                }
                let result: Omit<API_MANY_RECORDS, "Records"> & {Records: IDocGroupStructureNode[]} = await response.json();
                result.Records = result.Records.map(item => {
                    return new DocGroupStructureNode(item);
                });
                return result;
            },
            getFilesById: async (body: {id: string}): Promise<Omit<API_MANY_RECORDS, "Records"> & {Records: any}> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_FILES_BY_ID, "post", body).execute();
                if (!response.ok) {
                    throw new API_REQUEST(API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET,{
                        errorCode: response.status,
                        info: response,
                        ApiMessage: response.status !== 404 ? await response.json() : `Url Not Found: ${API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET}`
                    });
                }
                return response.json();
            },
            downloadContentFiles: async (body: {id: string}): Promise<Omit<API_MANY_RECORDS, "Records"> & {Records: any}> => {
                let response = await new API_REQUEST(API_URL.DOCUMENTS.GET_FILES_BY_ID, "post", body).execute();
                if (!response.ok) {
                    throw new API_REQUEST(API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET,{
                        errorCode: response.status,
                        info: response,
                        ApiMessage: response.status !== 404 ? await response.json() : `Url Not Found: ${API_ERROR_NAMES.DOC_GROUP_STRUCTURE_NODES_GET}`
                    });
                }
                return response.json();
            },
        };
    }
    static packages = () => {
        return {
            loadPackages : async (body : API_SORTED_REQUEST) : Promise<API_PACKAGES_LOAD_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.LOAD, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getPackagesExtended : async (body : API_SORTED_REQUEST) : Promise<API_PACKAGES_EXTENDED_LOAD_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.LOAD_EXTENDED, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getPackageTypes : async (body : {idPackage : number}) : Promise<API_PACKAGES_DOC_TYPES | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.DOCS_TYPES, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getDocsInPackage : async (body : API_SORTED_REQUEST) : Promise<API_PACKAGES_DOC_IN_PACKAGES_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.DOCS_IN_PACKAGES, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            saveOrUpdateCollection  : async (body : {collection: {IdDoc : number, IdPackage : number}[]} ) : Promise<API_PACKAGES_SAVE_OR_UPDATE_COLLECTION_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.SAVE_OR_UPDATE_COLLECTION, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            deleteCollection  : async (body : {collection : [ICollection]}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.DELETE_COLLECTION, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            updatePackageDocRef: async (body : {entity: IPackageDocRefUpdate} ) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.UPDATE_DOC_REF, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            createNewPackages : async (body : {childPackages : [], idRootPackage : number, setLastVersion : string}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.CREATE_NEW_PACKAGES, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            createNewOnePackage : async (body : {entity : API_REQUEST_createNewOnePackage}) : Promise<API_RESPONCE_createNewOnePackage | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.CREATE_NEW_PACKAGE_ONE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            deletePackage : async (body : {id : number}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.DELETE_PACKAGES, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            },
            updateNamePackage : async (body : {entity: IEntity}) : Promise<void | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.PACKAGES.UPDATE_NAME_PACKAGES, "post", body).execute();
                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return;
            }
        };
    }

    static search = () => {
        return {
            searchByParams : async (body : {searchParams : ISearchParams}) : Promise<API_SEARCH_BY_PARAMS | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SEARCH.SEARCH_BY_PARAMS, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            searchByParamsInFullText : async (body : ISearchParamsInFullText) : Promise<DocFileSearchResult[] | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SEARCH.SEARCH_BY_PARAMS_IN_FULLTEXT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            searchByParamsInAttributes : async (body : ISearchParamsInFullText) : Promise<IAttribute[] | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SEARCH.SEARCH_BY_PARAMS_IN_FULLTEXT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            verifyDocClientLinkIds : async (body : {linkIds : IDocClientLinkIds}) : Promise<IDocClientLinkIds | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.SEARCH.VERIFY_DOC_CLIENT_LINK_IDS, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
        };
    }
    static reports = () => {
        return {
            storedReport : async (body : API_SORTED_REQUEST) : Promise<API_REPORT_STORED_REPORTS | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.STORED_REPORT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            storedReportParams : async (body : API_SORTED_REQUEST) : Promise<API_REPORT_STORED_REPORTS_PARAMS | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.STORED_REPORT_PARAMS, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            createQueryReport : async (body : IdStoredReportParamWrapper) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.CREATE_QUERY_REPORT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            createEntityReport : async (body : {parameters : IEntityReport_Request}) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.CREATE_ENTITY_REPORT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            createSearchResultReport : async (body : {searchParams : any}) : Promise<string | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.CREATE_SEARCH_RESULT_REPORT, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            getReportState : async (body : {reportGuid : string}) : Promise<IReportStateInfo | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(API_URL.REPORTS.GET_REPORT_STATE, "post", body).execute();
                let result = await response.json();
                if (!response.ok) {
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return result;
            },
            downloadReport  : async (body : {reportGuid : string}) : Promise<API_REPORT_DOWNLOAD_REPORT_RESPONSE | API_REQUEST_ERROR> => {
                let response = await new API_REQUEST(`${API_URL.REPORTS.DOWNLOAD_REPORT}/${body.reportGuid}`, "downloadBlob", body).execute();

                var filename = "file.xlsx";
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(response.headers.get('content-disposition'));
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                let result = await response.blob();

                if (!response.ok) {
                    let result = await response.json();
                    return {
                        errorCode: response.status,
                        info: response,
                        message: result
                    }
                }
                return {
                    name : filename,
                    file : result
                };
            }
        };
    }
}

export class API_REQUEST {
    url : string;
    type : API_REQUEST_TYPES;
    params? : any;

    constructor(url : string, type : API_REQUEST_TYPES, params? : any) {
        this.url = url;
        this.type = type;
        this.params = params ? params : null;
    }

    async execute() : Promise<any> {
        let self = this;

    if(this.type === "getFiles") {
            return new Promise((resolve, reject) => {
                try {
                    let response;
                    response = fetch(`${baseUrl}${self.url}`, {
                        method: "GET",
                        headers: {
                            'Content-Type': 'text/plain; charset=iso-8859-1',
                            'credentials': "same-origin",
                        },
                    });
                    resolve(response);
                }
                catch (exception) {
                    reject(exception);
                }
            });
        }
        else if(this.type === "post" || this.type === "get") {
            return new Promise(async (resolve, reject) => {
                try {
                    let response;
                    response = await fetch(`${baseUrl}${self.url}`, {
                        method: this.type === "post" ? "POST" : "GET",
                        body: self.params ? JSON.stringify(self.params) : JSON.stringify({}),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'credentials': "same-origin",
                        },
                    });
                    resolve(response);
                } catch (exception) {
                    reject(exception);
                }
            });
        }
        else if(this.type === "uploadFiles") {
            return new Promise((resolve, reject) => {
                try {
                    let response;
                    response = fetch(`${baseUrl}${self.url}`, {
                        method: "POST",
                        // принимает FormData
                        body : self.params,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    resolve(response);
                }
                catch (exception) {
                    reject(exception);
                }
            });
        }
        else if(this.type === "uploadFilesWithoutHeaders") {
            return new Promise((resolve, reject) => {
                try {
                    let response;
                    response = fetch(`${baseUrl}${self.url}`, {
                        method: "POST",
                        // принимает FormData
                        body : self.params,
                        // headers: {
                        //     'Content-Type': 'multipart/form-data',
                        // },
                    });
                    resolve(response);
                }
                catch (exception) {
                    reject(exception);
                }
            });
        }
        else if(this.type === "uploadBlobFiles") {
            return new Promise((resolve, reject) => {
                try {
                    let response;
                    response = fetch(`${baseUrl}${self.url}`, {
                        method: "POST",
                        // принимает FormData
                        body : self.params,
                        headers: {
                 //           'Content-Type': 'text/plain;charset=windows-1251',
                            'Content-Type': 'text/plain',
                        },
                        credentials: "same-origin",
                        cache: "no-cache",
                        //          contentLength : self.params.size,
                    });
                    resolve(response);
                }
                catch (exception) {
                    reject(exception);
                }
            });
        }
        else if(this.type === "downloadBlob") {
            return new Promise((resolve, reject) => {
                try {
                    let response;
                    response = fetch(`${baseUrl}${self.url}`, {
                        method: "POST",
                        body: self.params ? JSON.stringify(self.params) : JSON.stringify({}),
                        headers: {
                            'responseType': 'blob',
                            'dataType': 'binary',
                        },
                        cache : "no-cache",
                        credentials : "same-origin"
                    });
                    resolve(response);
                }
                catch (exception) {
                    reject(exception);
                }
            });
        }
    }
}


export class API_GET_LINK {
    static documents = () => {
        return {
            // TODO : deprecated
            downloadPreview : (body : {idDoc : number, idVersion : number}) : string => {
                return `${baseUrl}${API_URL.DOCUMENTS.DOWNLOAD_PREVIEW}/${body.idDoc}/${body.idVersion}`;
            },
            // TODO : deprecated
            downloadFile : (body : {idFile : number, idVersion : number, isAttachment : boolean}) : string => {
                return `${baseUrl}${API_URL.DOCUMENTS.DOWNLOAD_FILE}/${body.idFile}/${body.idVersion}/${body.isAttachment}`;
            },
            linkToFile : (body : {id : number, part : DocFilePart, isAttachment : boolean, time?: number}) => {
                return `${API_URL.DOCUMENTS.DOWNLOAD_FILE}?id=${body.id}&part=${body.part}&isAttachment=${body.isAttachment}${body.time ? `&time=${body.time}`: ""}`;
            }
        };
    }
}
