import React from "react";
import {connect} from "react-redux";
import TabItem from "./TabItem";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    addTabSearch,
    closeTabSearch,
    setActiveTabSearch,
    updateInputsFieldSearch
} from "../../store/search/actionCreators/search_Inputs";
import {__} from "../../tools/HelpFunctions";
import {emptySearchFields} from "../../tools/consts";
import {contextMenuTypes, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {setContextMenuData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {store} from "../../index";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";

class SearchTabs extends React.Component {
    // private closeAllTabsRef;

    constructor(props) {
        super(props);

        this.state = {
            closeAllTabsTooltipShow: false,
            show: false,
            contentOnHover: true,
            addTabTooltipShow: false
        }

        this.getIconByType = this.getIconByType.bind(this);
        this.OnTabClick = this.OnTabClick.bind(this);
        this.OnTabCloseClick = this.OnTabCloseClick.bind(this);
        this.closeAllTabs = this.closeAllTabs.bind(this)
        this.hideOutSide = this.hideOutSide.bind(this)
        this.addTab = this.addTab.bind(this);
        this.OnRightClick = this.OnRightClick.bind(this);

        this.closeAllTabsRef = React.createRef();
        this.addTabRef = React.createRef();
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {
            this.setState({show: false})
            document.removeEventListener('click', this.hideOutSide)
        }
    }

    OnTabClick(tab) {
        this.props.setActiveTabSearch({Id: tab.Id, name: tab.Name });
    }

    async OnTabCloseClick(tab) {
        this.props.closeTabSearch({Ids: [tab.Id]});
        if(tab.Id === this.props.activeTab.Id) {
            if(store.getState().search.rootState.tabs.length > 0) {
                this.props.setActiveTabSearch({Id: this.props.tabsList[0].Id, name: this.props.tabsList[0].name});
            } else {
                const id = __.createUniqueIdString(6)
                this.props.setActiveTabSearch({Id: id, name: "Поиск"});
                this.props.updateInputsFieldSearch({...emptySearchFields, Id: id});
            }
        }
    }


    getIconByType() {
        const {tabsList, activeTab} = this.props;

        return tabsList.map(tab => {
            return {
                Type: tab,
                Name: tab,
                Icon: "icon-Search",
                IsActive: tab === activeTab
            };
        });
    }

    async closeAllTabs() {

    }

    addTab() {
        const id = __.createUniqueIdString(6);
        this.props.setActiveTabSearch({Id: id, name: "Поиск"});
        this.props.updateInputsFieldSearch({...emptySearchFields, Id: id});
        if(this.props.viewMode !== RootComponentsStateViewModeTypes.SearchApp.MainTableOnly) {
            this.props.changeSearchViewMode({ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly});
        }
    }

    OnRightClick(event, tab) {
        this.props.setContextMenuData({
            name: contextMenuTypes.SearchTabs,
            data: tab,
            position: {
                x: event.clientX,
                y: event.clientY + 10
            }
        });
        event.preventDefault();
    }

    render() {
        const {tabsList, activeTab} = this.props;
        let style = {borderBottom: "none", visibility: this.props.hidden ? "hidden" : "inherit"};

        const tabs = tabsList.map(tab => tab = {
                Id: tab.Id,
                Name: tab.name,
                Icon: "icon-Search",
                IsActive: tab.Id === activeTab.Id
        });

        return (
            <div className="card-header card-header-tabs-line" style={style}>
                <div className="card-toolbar">
                    <ul className="nav nav-tabs nav-bold nav-tabs-line">
                        {tabs.map((item, index) => {
                                return (
                                    <TabItem info={item}
                                             key={createUniqueIdString(6)}
                                             OnTabClick={() => this.OnTabClick(item)}
                                             OnTabCloseClick={() => this.OnTabCloseClick(item)}
                                             onRightClick={(ev) => this.OnRightClick(ev, item)}
                                    />
                                )
                            })
                        }
                        <li>
                            {
                                this.state.addTabTooltipShow &&
                                <MyTooltip target={this.addTabRef}
                                           text={"Добавить новую вкладку"}
                                           show={this.state.addTabTooltipShow}
                                           placement={"bottom"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm"
                                  ref={this.addTabRef}
                                  onMouseEnter={()=>{this.setState({addTabTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({addTabTooltipShow : false})}}
                                  onClick={this.addTab}>
                                <i className={`svg-icon icon-add_16 ${this.state.addTabTooltipShow ? 'icon-color-primary' : ''}`}/>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        tabsList: state.search.rootState.tabs,
        activeTab: state.search.rootState.activeTab,
    }
}

const mapDispatchToProps = {
    setActiveTabSearch,
    closeTabSearch,
    addTabSearch,
    updateInputsFieldSearch,
    setContextMenuData,
    changeSearchViewMode
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(SearchTabs);
