
// типы документов в пакете
export class PackagesTypes {

    // number
    Id;
    // string
    Name;

    constructor(DataFromServer) {
        this.Id = DataFromServer.Id;
        this.Name = DataFromServer.Name;
    }
}