import React from "react";
import {connect} from "react-redux";
import {TableFilterHasValueEnum} from "../../../tools/StaticTypes";
import {changeDocumentSearchInputs} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    combineMainTableAttributes,
    mainTableSearchDoc
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {
    changeDocumentSearchInputs_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {updateInputsFieldPackage} from "../../../store/packages/actionCreators/package_InputActionCreator";

// type StateType = {
//     HasValue: number;
//     OnDate: string | null,
//     DateStart: string | null,
//     DateEnd: string | null,
// }
//
// type OwnPropsType = {
//     rootLink: RootLinkEnum
// }
//
// type PropsFromRedux = ConnectedProps<typeof connector>;
// type PropsType = OwnPropsType & PropsFromRedux;

class FiltersDate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            HasValue: TableFilterHasValueEnum.None,
            ApproximatelyEqual: null,
            OnDate: null,
            DateStart: null,
            DateEnd: null,

            onClearContainHover: false,
            onClearOnDateHover: false,
            onClearDateStartHover: false,
            onClearDateEndHover: false
        };

        this.CheckConflictedFields = this.CheckConflictedFields.bind(this);
        this.ApplyFilter = this.ApplyFilter.bind(this);
        this.setDate = this.setDate.bind(this);
        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this)
    }

    componentDidMount() {
        let { filterInfo: { Value } } = this.props;
        if (!Value) Value = this.props.filterInfo.value
        if(Value) {
            this.setState({
                ApproximatelyEqual: Value.ApproximatelyEqual ?? null,
                OnDate: Value.OnDate ?? null,
                DateStart: Value.DateStart ?? null,
                DateEnd: Value.DateEnd ?? null,
            });
        }
    }

    getCurrentSearchInputs() {
        let searchInputs;
        if (this.props.filterInfo.TableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.filterInfo.TableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.filterInfo.TableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }
        return searchInputs;
    }

    CheckConflictedFields(inputName) {
        if(inputName === "ApproximatelyEqual") {
            if(this.state.ApproximatelyEqual !== null) {
                if(this.state.DateStart) {
                    this.setState({DateStart: null});
                }
                if(this.state.DateEnd) {
                    this.setState({DateEnd: null});
                }
                if(this.state.OnDate) {
                    this.setState({OnDate: null});
                }
            }
        }
        else if(inputName === "OnDate") {
            if(this.state.OnDate !== null) {
                if(this.state.DateStart) {
                    this.setState({DateStart: null});
                }
                if(this.state.DateEnd) {
                    this.setState({DateEnd: null});
                }
                if(this.state.ApproximatelyEqual) {
                    this.setState({ApproximatelyEqual: null});
                }
            }
        }
        else {
            if(this.state.ApproximatelyEqual !== null || this.state.OnDate !== null) {
                if(this.state.DateStart) {
                    this.setState({ApproximatelyEqual: null, OnDate: null});
                }
                if(this.state.DateEnd) {
                    this.setState({ApproximatelyEqual: null, OnDate: null});
                }

            }
        }
    }

    async ApplyFilter(isClear = false) {
        const filterData = {
            ApproximatelyEqual: isClear ? null : this.state.ApproximatelyEqual,
            OnDate: isClear ? null : this.state.OnDate,
            DateStart: isClear ? null : this.state.DateStart,
            DateEnd: isClear ? null : this.state.DateEnd
        }
        if (this.props.searchRight) {
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                ...this.props.filterInfo,
                Value : filterData
            });
        } else if (this.props.packageSearch) {
            if (this.props.filterInfo.Attribute === "Дата"){
                this.props.updateInputsFieldPackage({
                    ...this.getCurrentSearchInputs(),
                    RegDateAsString: filterData
                });
            } else if (this.props.filterInfo.Attribute === "Последнее изменение"){
                this.props.updateInputsFieldPackage({
                    ...this.getCurrentSearchInputs(),
                    ModifyDate: filterData
                });
            }
        } else {
            this.props.changeDocumentSearchInputs({
                ...this.props.filterInfo,
                Value: filterData
            });
        }
        if(isClear) {
            this.setState({
                ApproximatelyEqual: null,
                OnDate: null,
                DateStart: null,
                DateEnd: null
            });
            return
        }

        if (this.props.onApply) this.props.onApply()
    }

    setDate(date, name) {
        if(name === "ApproximatelyEqual") this.setState({ApproximatelyEqual: date})
        else if(name === "OnDate") this.setState({OnDate: date})
        else if(name === "DateStart") this.setState({DateStart: date})
        else if(name === "DateEnd") this.setState({DateEnd: date})
        this.CheckConflictedFields(name);
    }

    render() {
        const { filterInfo } = this.props;

        const filterName = typeof filterInfo.Attribute === "string"
            ? filterInfo.Attribute
            : filterInfo.Attribute.AttributeName.Name

        return (
            <>
                <h5 className="text-dark my-3">Фильтр (<b>{filterName}</b>)</h5>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Содержит</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  onMouseEnter={() => {this.setState({ onClearContainHover: true})}}
                                  onMouseLeave={() => {this.setState({ onClearContainHover : false})}}
                                  onClick={() => {if(this.state.onClearContainHover) {
                                      this.setDate("", "ApproximatelyEqual");
                                  }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearContainHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-calendar_search"}`}/>
                            </span>
                        </div>
                        <input type="text" className="form-control form-control-lg h-40px br-right-5"
                               name={"Contain"}
                               onKeyDown={(event)=>{
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setDate(event.target.value.trim(), "ApproximatelyEqual");
                               }}
                               placeholder="Задайте значение" value={this.state.ApproximatelyEqual ? this.state.ApproximatelyEqual : ""}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>На дату</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  onMouseEnter={() => {this.setState({ onClearOnDateHover: true})}}
                                  onMouseLeave={() => {this.setState({ onClearOnDateHover : false})}}
                                  onClick={() => {if(this.state.onClearOnDateHover) {
                                      this.setDate("", "OnDate");
                                  }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearOnDateHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-calendar_search"}`}/>
                            </span>
                        </div>
                        <input type="date" className="form-control form-control-lg h-40px br-right-5"
                               name={"OnDate"}
                               min="1900-01-01"
                               max="3000-12-31"
                               onKeyDown={(event)=>{
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setDate(event.target.value.trim(), "OnDate");
                               }}
                               placeholder="Задайте значение" value={this.state.OnDate ? this.state.OnDate : ""}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>С даты</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  onMouseEnter={() => {this.setState({ onClearDateStartHover: true})}}
                                  onMouseLeave={() => {this.setState({ onClearDateStartHover : false})}}
                                  onClick={() => {if(this.state.onClearDateStartHover) {
                                      this.setDate("", "DateStart");
                                      // this.ApplyFilter();
                                  }}}>
                            <i className={`svg-icon svg-icon-lg ${this.state.onClearDateStartHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-down_calendar"}`}/>
                        </span>
                        </div>
                        <input type="date" className="form-control form-control-lg h-40px br-right-5"
                               name={"DateStart"}
                               min="1900-01-01"
                               max="3000-12-31"
                               onKeyDown={(event)=>{
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setDate(event.target.value.trim(), "DateStart");
                               }}
                               placeholder="Задайте значение"
                               value={this.state.DateStart ? this.state.DateStart : ""}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>По дату</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  onMouseEnter={() => {this.setState({ onClearDateEndHover: true})}}
                                  onMouseLeave={() => {this.setState({ onClearDateEndHover : false})}}
                                  onClick={() => {if(this.state.onClearDateEndHover) {
                                      this.setDate("", "DateEnd");
                                      // this.ApplyFilter();
                                  }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearDateEndHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-up_calendar"}`}/>
                            </span>
                        </div>
                        <input type="date" className="form-control form-control-lg h-40px br-right-5"
                               name={"DateEnd"}
                               min="1900-01-01"
                               max="3000-12-31"
                               onKeyDown={(event) => {
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setDate(event.target.value.trim(), "DateEnd");
                               }}
                               placeholder="Задайте значение" value={this.state.DateEnd ? this.state.DateEnd : ""}/>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-5" onClick={() => {this.ApplyFilter()}}>
                        <a href="#" className="btn btn-primary font-weight-bold">Применить</a>
                    </div>
                    <div className="col-lg-4"
                         onClick={() => {if (this.props.onApply) this.props.onApply()}}>
                        <a href="#" className="btn btn-outline-secondary font-weight-bold">Отменить</a>
                    </div>
                    <div className="col-lg-2" onClick={() => {this.ApplyFilter(true)}}>
                        <span className="btn btn-icon btn-lg">
                            <i className="svg-icon icon-Table_edit_8 icon-color-primary text-success" />
                        </span>
                    </div>
                </div>

                {/*<p className="mb-3">Наличие значения</p>*/}
                {/*<div className="input-icon h-40px my-2">*/}
                {/*    <select className="form-select" value={this.state.HasValue}*/}
                {/*            onChange={(event)=>{*/}
                {/*                this.setState({HasValue: Number.parseInt(event.target.value)});*/}
                {/*                this.onInputHandler();*/}
                {/*            }}>*/}
                {/*        <option value={0}>Не выбрано</option>*/}
                {/*        <option value={1}>Нет значения</option>*/}
                {/*        <option value={2}>Есть значение</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        packageSearchInputs: state.packages.search.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
        searchInputsMain: state.packages.tree.searchInputs,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs,
    changeDocumentSearchInputs_packagesDocumentRight,
    changeDocumentViewMode,
    combineMainTableAttributes,
    mainTableSearchDoc,
    updateInputsFieldPackage,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FiltersDate);
