import {store} from "../index";
import {__} from "./HelpFunctions";
import {PackageTree} from "./classes/packages/PackageTree";
import {PackageTreeItem} from "./classes/packages/PackageTreeItem";

export const TreeTypes = {
    ROOT : "ROOT",
    FOLDER : "FOLDER",
    DOC : "DOCUMENT"
};



// создает дерево пакетов
// возвращает класс PackageTree

export class TreeCombinePackage {
constructor(treeExits = false, TableType = "main", IsDownload = false, IsSearch = false, ForFilter = false) {

    //ForFilter нужен, чтобы не подменялось основное дерево и служит только для проверки узла при фильтрации

    let data;
    if(IsDownload || TableType === "download") {
        data = store.getState().packages.treeDownload.lastLoad;
    }
    else {
        if(IsSearch) {
            data = store.getState().packages.treeSearchAdditional;
        } else if(TableType === "main")
            data = store.getState().packages.tree.lastLoad
        else if(TableType === "additional") {
            data = store.getState().packages.treeAdditional.lastLoad
        }
        else data = store.getState().packages.treeDownload.lastLoad;
    }


    this.folders = data.folders;
    this.documents = data.documents;
    this.types = data.types;

    if(IsDownload || TableType === "download") {

        if(treeExits) {
            this._tree = __.deepCopy(store.getState().packages.treeDownload.render);
        }
        else {
            // if (store.getState().packages.treeDownload.isSearch || IsSearch) this._tree = store.getState().packages.treeSearchDownload.folders;
            this._tree = null;
        }
        // if ((store.getState().packages.treeDownload.isSearch || IsSearch) && ForFilter)
            // this._tree = store.getState().packages.treeDownload.searchNode;
    }
    else {
        if(treeExits) {
            if(IsSearch) {
                this._tree = store.getState().packages.treeSearchAdditional.tree;
            }
            else if(TableType === "main"){
                this._tree = __.deepCopy(store.getState().packages.tree.render)
                if ((store.getState().packages.tree.isSearch || IsSearch) && ForFilter)
                    this._tree = store.getState().packages.tree.searchNode;
            }
            else {
                this._tree = __.deepCopy(store.getState().packages.treeAdditional.render);
            }
        }
        else {
            this._tree = null;
        }
    }
}


//  TODO ЗАПАСНОЙ ВАРИАНТ
// export class TreeCombinePackage {
//     constructor(treeExist = false, TableType = "main", IsDownload = false, IsSearch = false) {
//
//         let data;
//         if(IsDownload || TableType === "download") {
//             data = store.getState().packages.treeDownload.lastLoad;
//         }
//         else {
//             if(IsSearch) {
//                 data = store.getState().packages.treeSearchAdditional;
//             } else if(TableType === "main") {
//                 data = store.getState().packages.tree.lastLoad
//             }
//             else if(TableType === "additional") {
//                 data = store.getState().packages.treeAdditional.lastLoad
//             }
//             else data = store.getState().packages.treeDownload.lastLoad;
//         }
//
//
//         this.folders = data.folders;
//         this.documents = data.documents;
//         this.types = data.types;
//
//         if(IsDownload || TableType === "download") {
//             if(treeExist) {
//                 this._tree = __.deepCopy(store.getState().packages.treeDownload.render);
//             }
//             else {
//                 if (store.getState().packages.treeDownload.isSearch || IsSearch) this.folders = store.getState().packages.treeSearchDownload.folders;
//                 if (store.getState().packages.treeDownload.isSearch || IsSearch) this._tree = store.getState().packages.treeSearchDownload.folders;
//                 // this._tree = null;
//             }
//         }
//         else {
//             if(treeExist) {
//                 if(IsSearch) {
//                     this._tree = store.getState().packages.treeSearchAdditional.tree;
//                 }
//                 else if(TableType === "main")
//                     this._tree = __.deepCopy(store.getState().packages.tree.render)
//                 else if(TableType === "download")
//                     this._tree = __.deepCopy(store.getState().packages.treeDownload.render)
//                 else {
//                     this._tree = __.deepCopy(store.getState().packages.treeAdditional.render);
//                 }
//             }
//             else {
//                 this._tree = null;
//             }
//         }
//     }


    create(IsDownload) {
        let children = [];
        for(let i = 0; i < this.folders.length; i++) {
            children.push(new PackageTreeItem({
                Node : this.folders[i],
                Children : [],
                NodeType : "PackagesFolder"
            }));
        }

        //TODO БАГ. Корневой каталог пакетов - отличается основная и доп таблица
        if(this.documents && this.documents.length > 0 && !IsDownload) {
            for(let i = 0; i < this.documents.length; i++) {
                children.push(new PackageTreeItem({
                    Node : this.documents[i],
                    Children : [],
                    NodeType : "PackagesDocument"
                }));
            }
        }

        let rootId = IsDownload ? 1 : 2;

        this._tree = new PackageTree({
            // id корневого узла
            TreeType : this.folders[0]?.IdParent,
            // у корневого элемента Node только Id
            Node : {Id : rootId},
            NodeType :  "PackageTree",
            Children : children,
        });


        return this;
    }

    rebuild() {
        this._tree = __.deepCopy(this._tree);
    }

    update(Id) {
        let foundItem = this.searchById(Id);
        //console.log("foundItem",__.deepCopy(foundItem))
        //console.log("this.folders",__.deepCopy(this.folders))

        if(foundItem) {
            // if(foundItem.Children.length === 0) {
                let children = [];
                for(let i = 0; i < this.folders.length; i++) {
                    children.push(new PackageTreeItem({
                        Node : this.folders[i],
                        Children : [],
                        NodeType : "PackagesFolder"
                    }));
                }

                if(this.documents && this.documents.length > 0) {
                    for(let i = 0; i < this.documents.length; i++) {
                        children.push(new PackageTreeItem({
                            Node : this.documents[i],
                            Children : [],
                            NodeType : "PackagesDocument"
                        }));
                    }
                }



          //      this.#rebuild();
                foundItem.Children = children;


            // }


        }

        return this;
    }


    get() {
        return this._tree;
    }


    searchById (Id, isPackage, treeTypeForFound) {
        //console.log("this._tree", this._tree, store.getState().packages.treeSearchAdditional.tree)
        // console.log("this._tree", Id, this._tree)

        const recursiveSearch = (item) => {
            let lIsSearch = true;
            if (treeTypeForFound)
            {
                if (item.Type != treeTypeForFound) //"PackagesDocument"
                {
                    lIsSearch = false;
                }
            }
            if (lIsSearch)
                if(item.Node?.Id == Id) {
                    return item;
                }

            // фильтр для поиска только по пакетам без документов
            if(isPackage) {
                item.Children = item.Children.filter(item => item.Type === "PackagesFolder");
            }

            if(item.Children?.length > 0) {
                for(let i = 0; i < item.Children.length; i++) {
                    let found = recursiveSearch(item.Children[i]);
                    if(found) return found;
                }
            }

            return null;
        }
        return recursiveSearch(this._tree);
    }
}


export class ItemTree {
    constructor({treeId, parent, children, type, info}) {
        // уникальный id для определения конкретного узла дерева
        this.treeId = treeId;
        this.parent = parent;
        this.children = children;
        this.type = type;
        this.info = info;
    }
}
