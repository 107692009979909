import React from "react";
import {connect} from "react-redux";
import {ActionQueue} from "../../../store/rootReducer";
import {combineMainTableAttributesSearch, setPaginationPageSizeSearch} from "../../../store/search/actionCreators/search_table";
import {searchByParams} from "../../../store/search/actionCreators/search_Inputs";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import PaginationSelect from "../../PaginationSelect";
import {store} from "../../../index";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";


class PaginationCounterSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.change = this.change.bind(this);
    }

    change(value) {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData,
                    params : {data : {
                        content : "Загрузка...",
                        disableButton : true,
                        fullBackground : false,
                        gif : "documents",
                        key : "PaginationCounterSearch33"
                    }},
                    name : "setLoaderModalData"},
                {action : this.props.setPaginationPageSizeSearch, params : {PaginationPageSize : value}, name : "setPaginationPageSizeSearch"},
                {action : this.props.searchByParams, params : {}, name : "searchByParams"},
                {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                {action : this.props.setLoaderModalData, params : {keyDeleted: "PaginationCounterSearch46"}, name : "setLoaderModalData"},
            ],
            debug : false,
        });
    }

    render() {
        let {activePage, paginationPageSize, totalItemsCount} = this.props;

        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">Показано {from} - {to} из {totalItemsCount}</span>);
            }
        }

        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
            />
        );
    }
}

const  mapStateToProps = state => {
    return {
        paginationPageSize : state.search.mainTable.paginationPageSize
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    combineMainTableAttributesSearch,
    searchByParams,
    setLoaderModalData,
    setModalData,
    setPaginationPageSizeSearch,
}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounterSearch);
