import React from "react";
import {connect} from "react-redux";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes} from "../../../tools/StaticTypes";
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";

class FilesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filesList: [],
            openedFilesList: []
        };

        this.hide = this.hide.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        this.setState({
            filesList: Array.from([...this.props.files])
        });
    }

    // скрывать модалку
    hide() {
        this.props.onClose();
    }


    openFiles(lFileItem) {
        let newFilesList = __.deepCopy(this.state.filesList);
        let foundItemIndex = this.state.filesList.findIndex(item => item == lFileItem);
        if (foundItemIndex != -1)
        {
            newFilesList[foundItemIndex].showSignatures = !newFilesList[foundItemIndex].showSignatures

            this.setState({
                filesList: newFilesList
            });
        }
    }


    deleteFile(file) {
        this.setState({
            filesList: this.state.filesList.filter(item => (item.name !== file.name && item.size !== file.size))
        });
    }

    deleteSignature(lFileItem, iSignature) {
        let newFilesList = __.deepCopy(this.state.filesList);
        let foundItemIndex = this.state.filesList.findIndex(item => item == lFileItem);
        if (foundItemIndex != -1)
        {
            let foundSignIndex = this.state.filesList[foundItemIndex].signatures.findIndex(item => item == iSignature);

            if (foundSignIndex > -1) {
                newFilesList[foundItemIndex].signatures.splice(foundSignIndex, 1);
            }
            this.setState({
                filesList: newFilesList
            });
        }
    }

    // выбор файлов
    async selectFiles(event, lFileItem) {
        this.props.setModalData({
            name : ModalTypes.app.alert,
            data : {content : "Загрузка файлов...", disableButton : true, fullBackground : false, gif : "documents"}
        })
        this.setState({lFile : event.target.files[0], lFileError: event.target.files.length <= 0});

        let files = Array.from(event.target.files) ;
        let newFilesList = __.deepCopy(this.state.filesList);
        let foundItemIndex = this.state.filesList.findIndex(item => item == lFileItem);
        if (foundItemIndex != -1)
        {
            if (newFilesList[foundItemIndex].signatures)
                newFilesList[foundItemIndex].signatures = newFilesList[foundItemIndex].signatures.concat(files)
            else
                newFilesList[foundItemIndex].signatures = files

            this.setState({
                filesList: newFilesList
            });
        }


        this.props.setModalData({});
    }



    saveChanges() {
        this.props.onSave(this.state.filesList);
        this.props.onClose();
    }

    render() {
        const modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        const { filesList } = this.state;
        let arrRefs = [];
        const lFlagSignature =  filesList.findIndex(item => item.signatures && item.signatures.length != 0)!= -1;

        let lFlagWithSignature = this.props.globalSettings.WithSignature === "1";

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                {this.props.caption ?? "Выбранные дополнительные файлы"}
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-5" style={{height: "20vw", padding:" 0.8rem 1.75rem"}}>
                            <div className="table-responsive" style={{overflow: 'hidden'}}>
                                <table
                                    className="table table-hover table-striped table-borderless table-vertical-center">
                                    <thead className="thead-light">
                                    <tr className="text-uppercase">
                                        <th style={{borderRadius: "1em 0 0 0"}}><p
                                            className="text-success m-0 font-weight-normal">Наименование</p></th>
                                        {
                                            lFlagWithSignature &&
                                            <th style={{borderRadius: "0 0 0 0"}}><p
                                                className="text-success m-0 font-weight-normal"
                                                title={"Прикрепить электронную подпись"}>Прикрепить подпись</p>
                                            </th>
                                        }
                                        <th style={{borderRadius: "0 1em  0 0", wordWrap: "break-word", minWidth: 150, maxWidth: 150}}><p
                                            className="text-success m-0 font-weight-normal">Удалить</p></th>
                                    </tr>
                                    </thead>
                                    <tbody style={{fontSize: "0.75em"}}>
                                    {
                                        filesList.length > 0 && filesList.map((item, index)=> {
                                            arrRefs[index] = React.createRef();
                                            return (
                                                <React.Fragment key={createUniqueIdString(6)}>
                                            <tr key={__.createUniqueIdString(6)}>
                                                <td style={lFlagSignature ? {backgroundColor: "var(--light-primary)"} : {}}>
                                                    <div style={{display: 'flex'}} >
                                                    {
                                                        lFlagWithSignature &&
                                                        item.signatures &&
                                                        item.signatures.length != 0 &&
                                                        <span
                                                            className="text-dark-50 font-weight-bolder d-block font-size-lg">
                                                               <i onClick={(event) => {

                                                                   this.openFiles(item);

                                                               }}
                                                                  className={`menu-arrow ${item.showSignatures ? 'icon-expand_16' : 'icon-collapse_16'} pr-5 icon-color-primary`}
                                                                  style={{color: '#204ba0'}}
                                                               />
                                                       </span>
                                                    }
                                                    {HelpFunctions.cutLongString(item.name, 20, true)}
                                                    </div>
                                                </td>
                                                {
                                                    lFlagWithSignature &&
                                                    <td style={lFlagSignature ? {backgroundColor: "var(--light-primary)"} : {}}>
                                                        <span className="btn btn-icon btn-sm" onClick={() => {
                                                            arrRefs[index].current.click()
                                                        }}>
                                                        <i className="svg-icon icon-Signed1 icon-color-primary"/>
                                                        <input type="file" name={"original"}
                                                               accept=".p7s, .sign, .sig, .sgn"
                                                               multiple={true}
                                                               onChange={(e) => {
                                                                   this.selectFiles(e, item)
                                                               }}
                                                               ref={arrRefs[index]}
                                                               style={{display: "none"}}/>
                                                    </span>
                                                    </td>
                                                }
                                                <td style={lFlagSignature ? {backgroundColor: "var(--light-primary)"} : {}}>
                                                    <span className="btn btn-icon btn-sm" onClick={() => this.deleteFile(item)}>
                                                        <i className="svg-icon icon-Delete icon-color-red"/>
                                                    </span>
                                                </td>
                                            </tr>
                                                {
                                                    lFlagWithSignature &&
                                                    item.showSignatures &&
                                                    item.signatures &&
                                                    item.signatures.length > 0 &&
                                                    <>
                                                        <tr
                                                            key={__.createUniqueIdString(6)}>
                                                            <td colSpan="12" className="bg-white"
                                                                style={{textAlign: "left"}}><b>Электронная
                                                                подпись</b></td>
                                                        </tr>

                                                        {
                                                            item.signatures.map((iSignature, index) => {
                                                                return (
                                                                    <tr key={__.createUniqueIdString(6)}>
                                                                        <td colSpan={2} className="bg-white"> {/*className="bg-white"*/}
                                                                            <p style={{
                                                                                textAlign: 'left',
                                                                               // fontSize: 15,
                                                                                marginLeft: 10
                                                                            }}>
                                                                                <span style={{cursor: "help"}} title={iSignature.name}>
                                                                                    {HelpFunctions.cutLongString(iSignature.name, 25, true)}
                                                                                    <br/>

                                                                                    {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor" title={iSignature.name}>*/}
                                                                                    {/*    <i className="svg-icon icon-Info"/>*/}
                                                                                    {/*</span>*/}
                                                                                </span>
                                                                            </p>
                                                                        </td>
                                                                        {/*<td className="bg-white" />*/}
                                                                        <td className="bg-white">
                                                                            <span className="btn btn-icon btn-sm"
                                                                                  onClick={() => this.deleteSignature(item, iSignature)}>
                                                                                <i className="svg-icon icon-Delete icon-color-red"/>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </>
                                                }

                                                </React.Fragment>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button"
                                    className="btn btn-primary font-weight-bolder"
                                    onClick={this.saveChanges}>
                                Сохранить
                            </button>
                            <button type="button"
                                    className="btn btn-light font-weight-bold"
                                    onClick={this.hide}>
                                Отменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const  mapStateToProps = state => {
    return {
        modal: state.globalState.app.modal,
        globalSettings : state.globalState.settings.Content,
    }
}

const  mapDispatchToProps = {
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesModal);
