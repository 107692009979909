import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router'
import {globalStateReducer} from "./globalState/globalStateReducer";
import {documentReducer} from "./documents/documentReducer";
import {packagesReducer} from "./packages/packageReducer";
import {searchReducer} from "./search/searchReducer";
import {reportReducer} from "./reports/reportReducer";
import {filialReducer} from "./filials/filialReducer";

export  const  rootReducer = (history) => combineReducers({
    // для маршрутизации
    router: connectRouter(history),
    // auth, user, settings, filial, userSettings...
    globalState : globalStateReducer,
    // tree, mainTable...
    document : documentReducer,

    packages : packagesReducer,

    search : searchReducer,

    report : reportReducer,

    filial : filialReducer,
});


/**
 * Вызывает к выполнению очередь для действий идущих в строгом порядке
 * (например для асинхронных запросов).
 * Так же может быть создана очередь из очередей.
 *
 * @param {Array} List, список функций (action), параметров (params), имен функций (name)
 * @param {callback} Next, вызван после завершения работы функции {Next : {action : {}, params : {}}}
 * @param {callback} Error, вызван в случаи ошибки {Error : {action : {}, params : {}}}
 * @param {boolean} debug, вывод в консоль отладочной информацию true/false
 */
export const ActionQueue = ({List, Next = null, Error = null, debug = false}) => { // очередь подряд идущих запросов (действий)
    return async dispatch => {

        try {
            for(let i = 0; i < List.length; i++) {
                if(i+1 < List.length) {
                    List[i].params.Next = {
                        action : List[i+1].action,
                        params : List[i+1].params
                    };
                    if(List[i].name && List[i+1].name) {
                        List[i].params.NameCorrect = List[i].name
                        List[i].params.NameNext = List[i+1].name
                    }
                }
            }
            if(debug) {
                console.log(List[0]);
            }

            await List[0].action(List[0].params);

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка в очереди запросов (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

