import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../../tools/CreateUniqueId";
import TreeDocsDocumentPackagesRight from "./TreeDocsDocumentPackagesRight";

class TreeDocsFolderPackagesRight extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.tree_isSearch) {
            this.state = {
                IsOpen: this.props.data.info.searchType === "folderOpen",  // открыта ли папка при поиске
            }
        }
        else {
            this.state = {
                IsOpen: this.props.activeNode && this.props.data.treeId === this.props.activeNode.parent,  // открыта ли папка
            }
        }


        this.switchFolderState = this.switchFolderState.bind(this);
    }

    // открытие или закрытие папки
    switchFolderState() {
        this.setState({
            IsOpen : !this.state.IsOpen,
        });
    }


    render() {
        let data = this.props.data; // значение текущего узла
        let depth = this.props.depth; // текущая глубина

        const globalSettings = this.props.globalSettings;

        // icon-Menu_pack - закрытая обычная папка
        // icon-Open_folder - открытая обычная папка
        // icon-Open_folder_metka - открытая папка с точкой
        // icon-Pack_metka - закрытая папка с точкой
        // icon-Open_folder_close - открытая папка с замком
        // icon-Open_folder_bookmark -открытая папка с закладкой
        // icon-Pack_bookmark - закрытая папка с закладкой
        // icon-Pack_close - закрытая папка с замком

        if(data.children.length > 0) { // не рисовать пустые папки


            let folderIcon = "";
            let folderColor = "";
            let arrowColor = "icon-color-gray";
            let textColor = "";
            let arrowDirection = "";

            if(this.state.IsOpen) { // папка открыта

                arrowDirection = "arrow-bottom"; // стрелка вниз

                // если выбран документ внутри папки
                if(this.props.activeNode && this.props.activeNode.parent === this.props.data.treeId) {
                   if(data.info.Type === 1) { // обычная папка
                       folderIcon = "icon-Open_folder"; // какая иконка папки
                       folderColor = "icon-color-primary"; // какой цвет у папки
                       textColor = "text-color-primary"; // какой цвет текста
                   } else if(data.info.Type === 2) { // зеленая папка
                       folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                       folderColor = "icon-color-secondary-active"; // какой цвет у папки
                       textColor = "text-color-secondary-active"; // какой цвет текста
                   }
                   else if(data.info.Type === 3) { // красная папка
                       folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                       folderColor = "icon-color-danger"; // какой цвет у папки
                       textColor = "text-color-danger"; // какой цвет текста
                   }
                } else {   // если НЕ выбран документ внутри папки
                    if(data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Open_folder"; // какая иконка папки
                        folderColor = "icon-color-gray"; // какой цвет у папки
                        //TODO Задача № 19766
                        // textColor = "text-color-gray"; // какой цвет текста
                    } else if(data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                        folderColor = "icon-color-secondary"; // какой цвет у папки
                        textColor = "text-color-secondary"; // какой цвет текста
                    }
                    else if(data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                        folderColor = "icon-color-red"; // какой цвет у папки
                        textColor = "text-color-red"; // какой цвет текста
                    }
                }
            }
            else  { // папка закрыта

                arrowDirection = "arrow-right"; // стрелка вправо

                // если выбран документ внутри папки
                if(this.props.activeNode && this.props.activeNode.parent === this.props.data.treeId) {
                    if (data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Menu_pack"; // какая иконка папки
                        folderColor = "icon-color-primary"; // какой цвет у папки
                        textColor = "text-color-primary"; // какой цвет текста
                    } else if (data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-secondary-active"; // какой цвет у папки
                        textColor = "text-color-secondary-active";
                    } else if (data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-danger"; // какой цвет у папки
                        textColor = "text-color-danger"; // какой цвет текста
                    }
                } else { // если НЕ выбран документ внутри папки
                    if(data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Menu_pack"; // какая иконка папки
                        folderColor = "icon-color-gray"; // какой цвет у папки
                        //TODO Задача № 19766
                        // textColor = "text-color-gray"; // какой цвет текста
                    } else if(data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-secondary"; // какой цвет у папки
                        textColor = "text-color-secondary"; // какой цвет текста
                    }
                    else if(data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-red"; // какой цвет у папки
                        textColor = "text-color-red"; // какой цвет текста
                    }
                }
            }

            // собираем стили
            let iconStyle = `${folderIcon} ${folderColor}`;
            let folderStyle = `menu-link menu-item ${folderColor}`;
            let arrowStyle = `arrow_m ${arrowDirection} ${arrowColor} icon-Arrows_1`;
            let textStyle = `menu-text ${textColor}`;

            let folderIsEmpty = false;
            for (let i = 0; i < data.children.length; i++) {
                if(data.children[i].info.ContainsDocs) {
                    folderIsEmpty =  true;
                }
            }

            let folderActiveStyle = {};

            let lFlagHighlight = true;

            if (globalSettings.NotHighlightContainsDocs)
                if (globalSettings.NotHighlightContainsDocs === "1")
                    lFlagHighlight = false;

            //TODO подсветка типов без документов
            if (lFlagHighlight)
                if(!folderIsEmpty) {
                    folderActiveStyle = {
                        color: "rgba(63, 66, 84, 0.42)"
                    };
                }

            return(

                <li className={folderStyle}>
                    <span className="menu-link menu-toggle" onClick={this.switchFolderState}>
                        <span className="svg-icon menu-icon">
                            <i className={iconStyle} />
                        </span>
                        <span className={textStyle} style={folderActiveStyle}>{data.info.Name}</span>
                        <i className={arrowStyle}/>
                    </span>
                    <div className="menu-submenu display-block">
                        <ul className="menu-subnav">
                            {
                                this.state.IsOpen &&
                                data.children.map((item, index) => {

                                    return <TreeDocsDocumentPackagesRight
                                        key={`${item.info.Name} ${createUniqueIdString(3)}`}
                                        data={item}
                                        depth={depth+1}/>
                                })
                            }
                        </ul>
                    </div>
                </li>
            );
        }
        else return null;
    }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.packages.treeForDocuments.activeNode, // активный элемент дерева
        tree_isSearch : state.packages.treeForDocuments.isSearch, // обычный режим или режим поиска
        globalSettings: state.globalState.settings.Content,
    }
}

const  mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocsFolderPackagesRight);
