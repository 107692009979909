import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="header-mobile">
                <img src="/images/logo/logo_tek_smoll.jpg" className="max-h-30px" alt=""/>
                <div className="d-flex align-items-center">


                    <Link to="/" className={this.props.rootName === "HomeApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2"}>
                        <i className="svg-icon svg-icon-xl icon-Home"/>
                    </Link>


                    <Link to="/documents" className={this.props.rootName === "DocumentApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2 "}>
                        <i className="svg-icon svg-icon-xl icon-Documents"/>
                    </Link>

                    <Link to="/packages" className={this.props.rootName === "PackageApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2 "}>
                        <i className="svg-icon svg-icon-xl icon-Menu_pack"/>
                    </Link>

                    <Link to="/search" className={this.props.rootName === "SearchApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2 "}>
                        <i className="svg-icon svg-icon-xl icon-Search_menu"/>
                    </Link>

                    <Link to="/report" className={this.props.rootName === "ReportsApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2 "}>
                        <i className="svg-icon svg-icon-xl icon-reports"/>
                    </Link>

                    <Link to="/help" className={this.props.rootName === "HelpApp"
                        ? "nav-link btn btn-icon btn-light-success btn-sm mr-2 active"
                        : "nav-link btn btn-icon btn-light-success btn-sm mr-2 "}>
                        <i className="svg-icon svg-icon-xl icon-Doc_view_6"/>
                    </Link>


                    <span className="nav-link btn btn-icon btn-success btn-sm mr-4" onClick={this.props.setStateLeftAsideMenu}>
                        <i className="svg-icon svg-icon-xl icon-Table_grid"/>
                    </span>

                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    setStateLeftAsideMenu
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
